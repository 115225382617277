/* eslint-disable no-debugger */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import './FranchiseeJourneyList.css';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  SwitchToggle
} from '../../../components/GlobalComponents';
import AboutJourney from './AboutJourney/AboutJourney';
import Loader from 'src/components/Loader';
import {
  Box,
  Card,
  TablePagination,
  TextField,
  Grid,
  Button,
  CardContent
} from '@mui/material';
import { ApiService } from 'src/services/ApiService';
import {  InfoIcon } from 'src/assets/SVG/SvgIcons';
import { JourneyFilters } from './JourneyFilters';
import { getLocalData } from 'src/utils';


const FranchiseeJourneyGridView = ({
  journeyLists,
  sideBarfilters,
  setSidebarFilters,
  handleResetStates,
  getAllJourney
}) => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedProductItem, setSelectedProductItem] = useState([]);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openAlertNotes, setOpenAlertNotes] = useState(false);
  const [openAlertNotesDialog, setOpenAlertNotesDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const locationId = getLocalData('locationId')

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));
  const classes = useStyles();

  const handleSearchTextChange = (event) => {
    const searchText = event?.target?.value?.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = journeyLists?.filter((item) => {
    return Object?.values(item)?.some((value) =>
      value.toString()?.toLowerCase()?.includes(searchText)
    );
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  const sortedData = filteredData && [...filteredData]; // Create a copy of the filtered data array

  sortedData.sort((a, b) => {
    const nameA = a.fName?.toLowerCase();
    const nameB = b.fName?.toLowerCase();

    if (sortOrder === 'asc') {
      return nameA?.localeCompare(nameB);
    }
    // else{
    return nameB?.localeCompare(nameA);
    // }
  });
  const paginatedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  console.log(paginatedData,'journeyLists');

  /* Older changes Start */
  const showFilter = () => {
    setdisplayFilterForm(!displayFilterForm);
  };

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };


  // View location details
  const handleViewJourney = (productItem, e) => {
    console.log('productItem', productItem);
    // e.preventDefault();
    e.stopPropagation();
    let Item = productItem;
    // Set the selected location item
    setSelectedProductItem(Item);
    setOpenAlertNotes(true);
    setOpenAlertNotesDialog(true);
  };

  /* Older changes End */

  useEffect(() => {
    setPage(0);
    // setKeys(['name', 'age', 'profession']);
  }, [journeyLists]);

  
  const handleJourneyOn = (type, val) => {
  // console.log(type,'typpeeeeeee', val);
  let arr = type && type.split('_');
  let sortkey = arr && arr[1];
  // console.log(sortkey, partitionKey, "aaaa");

  // Now set the journey on off
  if(sortkey) journeyOnOff(sortkey, val);
  };
  
  /* Function to journeyOnOff */
  const journeyOnOff = async (sortkey, val) => {
    setIsLoading(true);
    let url = `${process.env.REACT_APP_API_END_POINT}pinpoint/updateLocationJourneyStatus`;
    let obj = {
      locationId: locationId,
      journeyId: sortkey,
      visibility: val ? 'ON' : 'OFF'
    };
    try {
      let response = await ApiService.put(`${url}`,obj);
    
      if (response?.statusCode === 200 && !response.error) {
        setIsLoading(false);
        getAllJourney()
        handleResetStates();
      }
    } catch (error) {
      console.error(error);
      getAllJourney()
      setIsLoading(false);
    }
  
  };

  return (
    <>
    <Loader  IsLoading={isLoading} />
      <Grid>
        <Grid xs={12}>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid
              item
              sx={{
                marginTop: '10px',
                marginBottom: '20px'
              }}
            >
              <Button
                onClick={showFilter}
                // ref={containerRef}
                variant={displayFilterForm ? 'contained' : 'outlined'}
              >
                Filter (All)
                <KeyboardArrowDownRoundedIcon
                  className={clsx(
                    !displayFilterForm && classes.closeY,
                    displayFilterForm && classes.openY
                  )}
                />
              </Button>
            </Grid>

            <Grid
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
                marginBottom: '20px'
              }}
              className="top-filter-cont"
            >
              {/* Search box on table */}
              <Box
                className="search-box"
                sx={{
                  display: 'flex'
                }}
              >
                <Box
                  width={280}
                  sx={{
                    marginRight: '20px'
                  }}
                >
                  <TextField
                    type="search"
                    label="Search Journey"
                    variant="outlined"
                    className="search-field"
                    onChange={(e) => handleSearchTextChange(e)}
                    //  onClick={handleSearch}
                    fullWidth
                    // sx={{ width: '215px' }}
                    InputProps={{
                      style: {},
                      endAdornment: !searchText ? <SearchIcon /> : null
                    }}
                    value={searchText}
                    InputLabelProps={{}} // style: { paddingLeft: '10px' }
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {displayFilterForm === true ? (
            <Grid item width={getFilterSize()}>
              {/* <Filters
                filters={sideBarfilters}
                setFilters={setSidebarFilters}
                categoryList={categoryList}
                supplierList={supplierList}
              /> */}
              <JourneyFilters
                filters={sideBarfilters}
                setFilters={setSidebarFilters}
              />
            </Grid>
          ) : // </Slide>
            null}

          <Grid
            item
            sx={{
              width: `calc(100% - ${getFilterSize()}px)})`
            }}
          >
            {/* Grid view of the product start */}
            <Box style={{ padding: '20px', marginTop: '10px' }}>
              <Grid
                container
                spacing={4}
                className="journey-grid-view"
              >
                {paginatedData?.map((Items) => (
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Card
                      // className="product-card"
                      width='100%'
                    // onClick={(e) => handleViewJourney(Items, e)}
                    >
                      <CardContent className="card-content-journey bg-white">
                        <Box sx={{flexDirection: 'column', gap:2}} container className=' d-flex align-items-center justify-content-center w-100'>
                          <Box
                            className="edit-ico product-headin heading-text d-flex align-items-center justify-content-center w-100" id={'edit_grid_bt_' + Items.sortKey}
                          >
                            {Items.Name}
                          </Box>
                          <Box 
                            className="edit-ico product-headin heading-tex d-flex align-items-center justify-content-center w-100" id={'switch_btn_' + Items.sortKey}
                          >
                            <div className="switchSe toggle-btn-block right">

                              <SwitchToggle
                                name={'journySwitch_'+Items.sortKey+'_'+Items.partitionKey}
                                // label="Auto Pay"
                                labelPlacement="start"
                                value={Items?.status === 1}
                                // onChange={(e) => handleJourneyOn(Items, e)}
                                onChange={handleJourneyOn}
                                className="w-10"
                              />
                            </div>
                          </Box>
                        </Box>
                        <Box
                          className="edit-icon-journey cursor-pointer" onClick={(e) => handleViewJourney(Items, e)} id={'edit_grid_btn_' + Items.sortKey}
                        >
                          {/* left arrow icon */}
                          <InfoIcon />
                        </Box>

                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box className="-paginate-right">
              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={filteredData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Grid>

        </Grid>
      </Grid>

      {/* Add alert and notes */}
      <Box>
        {openAlertNotes && (
          <AboutJourney
            openAlertNotesDialog={openAlertNotesDialog}
            setOpenAlertNotesDialog={setOpenAlertNotesDialog}
            setOpenAlertNotes={setOpenAlertNotes}
            journeyDetails={selectedProductItem}
          />
        )}
      </Box>
    </>
  );
};

export default FranchiseeJourneyGridView;
