/* eslint-disable spaced-comment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import { useEffect, useState, forwardRef, useContext } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Box,
  Grid,
  CircularProgress,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Slide,
  TextField
} from '@mui/material';

import {
  TransferInventoryFilter,
  TransferInventoryTable,
  TransferInventorySummary
} from 'src/components/Inventory/index';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import GlobalData from 'src/global';
import {
  CustomButton,
  SearchInput,
  CustomTextField,
  CustomSelect
} from 'src/components/Global';
import { getLocalData, setFullLocationId } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../../components/Loader';
// import Header from 'src/components/Header';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { UserContext } from 'src/contexts/UserContext.js';
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const InventoryTransfer = () => {
  // change the header values
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Transfer Inventory');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Transfer Inventory';
  const title = GlobalData[0].title;
  const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;
  const userId = getLocalData('userId');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilters, setIsFilters] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [allInventoryData, setAllInventoryData] = useState([]);
  const [allLocationsList, setAllLocationsList] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const [quantity, setQuantity] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [processStatus, setProcessStatus] = useState('');
  const { setNotification, isNotification } = useContext(UserContext);

  const [filters, setFilters] = useState({
    status: '',
    price: null,
    categoryName: null,
    sku: null
  });

  // get user role and provide access of that roles
  const franchiseeId = getLocalData('franchiseeId');
  const locationId = setFullLocationId(getLocalData('locationId'));
  const locationName = getLocalData('locationName');

  const [transferDetails, setTransferDetails] = useState({
    fromLocationId: locationId, //using hardcoded id for now, will replace with logged in user location id
    fromLocationName: locationName,
    toLocationId: '',
    notes: ''
  });
  const [isError, setIsError] = useState({
    toLocationId: false,
    notes: false
  });

  const [dialog, setDialog] = useState({
    state: false
  });

  const handleTransferDetails = (type, value) => {
    setTransferDetails({ ...transferDetails, [type]: value });
    setIsError({ ...isError, [type]: false });
  };

  function defaultQuantity() {
    const defaultQuantities = {};
    allInventoryData?.forEach((item) => {
      defaultQuantities[item.sortKey] = 0;
    });

    return defaultQuantities;
  }

  useEffect(() => {
    setQuantity(defaultQuantity());
  }, [allInventoryData]);

  useEffect(() => {
    const data = allInventoryData?.filter((item) =>
      selectedRows?.includes?.(item.id)
    );
    setSelectedRowsData(data);
  }, [selectedRows]);

  useEffect(() => {
    if (showScreen == 1 && isInitialState) {
      setSearchQuery('');
      setSelectedRows([]);
      setSelectedRowsData([]);
      setQuantity(defaultQuantity());
      // setNotesData('');
      // setSelectedReason('Breakage/Waste');
      setIsInitialState(false);
      setBtnLoading(false);
    }
  }, [showScreen, isInitialState]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const handleResetStates = () => {
    setQuantity(defaultQuantity());
    setSelectedRows([]);
    setSelectedRowsData([]);
    setIsFilters(false);
    setSearchQuery('');
    setIsInitialState(true);
    setShowScreen(1);

    setTransferDetails({
      ...transferDetails,
      toLocationId: '',
      notes: ''
    });

    setIsError({
      toLocationId: false,
      notes: false
    });

    setFilters({
      status: null,
      price: null,
      categoryName: null,
      sku: null
    });
  };

  function checkAllSelectedQuantity() {
    return selectedRows.every((itemId) => quantity[itemId] > 0);
  }

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleTransferSubmit = (status) => {
    // if (transferDetails.toLocationId == '' || transferDetails.notes == '') {
    if (transferDetails.toLocationId == '') {
      setIsError({
        toLocationId: true
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // } else if (transferDetails.notes == '') {
      //   setIsError({
      //     notes: true
      //   });
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (
      transferDetails.toLocationId === transferDetails.fromLocationId
    ) {
      setShowErrorMessage(true);
      setErrorMessage('Selected locations must be different.');
    } else if (!selectedRows?.length) {
      setShowErrorMessage(true);
      setErrorMessage('Please select atleast one inventory row');
      // toast.error('Please select atleast one inventory row');
    } else if (!checkAllSelectedQuantity()) {
      setShowErrorMessage(true);
      setErrorMessage(
        'Please add a minimum quantity of 1 to all selected rows.'
      );
      // toast.error('All selected rows must have quantity greater than 0');
    } else {
      if (status == 'Draft') {
        setProcessStatus('saved');
        setDialog({ ...dialog, state: true });
        // postData('draft');
      } else if (status == 'Proceed') {
        setProcessStatus('transferred');
        // postData('proceed');
        setShowScreen(2);
      }

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleCloseConfirm = (status) => {
    postData(status);
    setDialog({ ...dialog, state: false });
  };

  const handleDialog = (type) => {
    if (type == 'Cancel') {
      setDialog({ ...dialog, state: false });
    } else if (type == 'Confirm') {
      navigate('/transfer-history');
      setDialog({ ...dialog, state: false });
    }
  };

  function isAnyFilterApplied() {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    });
  }

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams)?.forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === -1 ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
      // else if (key === 'isActive') {
      //   filtersParams[key] = filtersParams[key] ? 'TRUE' : 'FALSE';
      // }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getData = async () => {
    setLoading(true);
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let encodedFranchiseeId = encodeURIComponent(franchiseeId);
      const inventoryData = await ApiService.get(
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1`
      );

      const locationsData = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`
      );

      if (!inventoryData?.error) {
        const modifiedInventoryData = inventoryData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.sortKey
          })
        );
        setAllInventoryData(modifiedInventoryData);
      } 

      if (!locationsData?.error) {
        const modifiedInventoryData = locationsData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.sortKey
          })
        );
        setAllLocationsList(modifiedInventoryData);
      } 
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowErrorMessage(true);
      setErrorMessage(error?.message || 'Internal Server Error');
    }
  };

  const getFilteredInventory = async () => {
    setLoading(true);
    let queryString = filtersQuery();
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      const inventoryData = await ApiService.get(
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1&${queryString}`
      );

      if (!inventoryData?.error) {
        const modifiedInventoryData = inventoryData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.sortKey
          })
        );
        setInventoryData(modifiedInventoryData);
        setLoading(false);
      } 

    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage(error?.message || 'Internal Server Error');
    }
  };

  useEffect(() => {
    getFilteredInventory();
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    getData();
  }, []);

  function findLocationName(id) {
    const foundLocation = allLocationsList?.find(
      (location) => location.locationId == id
    );

    if (foundLocation) {
      return foundLocation.locationName;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }
  function findLocationAddress(id) {
    const foundLocation = allLocationsList?.find(
      (location) => location.locationId == id
    );

    if (foundLocation) {
      return foundLocation.addressLine1;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }

  // const calculateTotalPrice = () => {
  //   const totalPrice = selectedRowsData?.reduce((total, obj) => {
  //     const price = parseFloat(obj.price) || 0;
  //     return total + price;
  //   }, 0);
  //   return totalPrice?.toFixed(2);
  // };
  const calculateTotalPrice = () => {
    const totalPrice = selectedRowsData?.reduce((total, obj) => {
      console.log(obj, 'pricing---------');
      const price = parseFloat(obj.price) || 0;
      const quan = quantity[obj.sortKey] || 0;
      return total + price * quan;
    }, 0);
    return totalPrice?.toFixed(2);
  };

  const postData = async (status) => {
    setBtnLoading(true);
    let idss = [];
    let itemsData = [];

    let orderStatus = status;

    selectedRowsData?.map((item) => {
     
      const quantityValue = quantity[item.sortKey]; // Get the corresponding quantity for the item
      idss.push({ itemId: item.sortKey });
      itemsData.push({
        itemId: item.sortKey,
        quantity: quantityValue,
        category: item?.categoryName,
        itemName: item?.fName,
        sku: item?.sku,
        variants: item?.variants
      });

      return null;
    });

    const data = {
      notes: transferDetails.notes,
      totalCost: calculateTotalPrice(),
      numberOfItems: selectedRows.length,
      items: itemsData,
      itemIds: idss,
      fromLocationId: transferDetails.fromLocationId,
      fromLocationName: locationName, 
      toLocationId: transferDetails.toLocationId,
      toLocationName: findLocationName(transferDetails.toLocationId),
      transactionStatus: orderStatus,
      createdBy: userId,
      transferAddress: findLocationAddress(transferDetails.toLocationId)
    };

    try {
      const responseData = await ApiService.post(
        `${BASE_URL}inventory/transferInventory`,
        data
      );
      if (!responseData?.error) {
        if (responseData.statusCode === 200) {
          setIsCreated(true);
          setDialog({ ...dialog, state: true });
        }
        setNotification(!isNotification);
        
      } 

    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage(error?.message || 'Something went wrong, Try again!');
    }
  };

  const handleCloseDetails = () => {
    setConfirmDialog(true);
  };

  // Handler to handle the dialog
  const handleConfirmationDialog = (value) => {
    // If the value is confirm then navigate to the inventory management page
    setConfirmDialog(false);

    if (value === 'Discard') {
      navigate('/inventory-managment');
    }
  };

  return (
    <>
      <Helmet>
        <title>Transfer Inventory | {title}</title>
      </Helmet>
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
        />
      )}

      {/* Container start */}
      <Loader IsLoading={loading} />
      {/* <Header title='Transfer Inventory' Transfer /> */}

      <Container maxWidth="100%" className="custom-container mt-10">
        {showScreen !== 2 && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseDetails()}
                  className="back-arrow-close mt-20"
                >
                  {/* left arrow icon */}
                  <ArrowLeft />
                </Box>
                <Typography className="header-blue-40 mt-">
                  Transfer Inventory
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <TransferInventorySummary
                  handleOpenScreen={handleOpenScreen}
                  setIsInitialState={setIsInitialState}
                  selectedRowsData={selectedRowsData}
                  // selectedReason={selectedReason}
                  notesData={transferDetails.notes}
                  postData={postData}
                  quantity={quantity}
                  btnLoading={btnLoading}
                  totalCost={calculateTotalPrice()}
                  fromLocationName={locationName}
                  toLocationName={findLocationName(
                    transferDetails.toLocationId
                  )}
                  transferAddress={findLocationAddress(
                    transferDetails.toLocationId
                  )}
                />
              );
            default:
              return (
                <Box
                  justifyContent="center"
                  direction="column"
                  className="custom-form"
                >
                  {/* <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        marginTop="20px"
                      >
                        <Grid item>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography
                              variant="variant2"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                textAlign: 'center'
                              }}
                              className="text-primary weight-600 font-20 font-myriad header-blue-40"
                              
                            >
                              Transfer Inventory
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}

                  <Grid
                    container
                    spacing={2}
                    columnSpacing={2}
                    columns={12}
                    mt={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="variant2"
                        color="#000"
                        fontWeight="400"
                        className="font-20 font-myriad"
                      >
                        From
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={4} marginRight="30px">
                      <CustomSelect
                        fullWidth
                        disabled
                        name="fromLocationId"
                        value={transferDetails.fromLocationId}
                        // value={locationName}
                        // value={transferDetails.fromLocationName}
                        onChange={handleTransferDetails}
                        label="LOCATION # 1"
                        options={[
                          {
                            // label: '#LOC#12123121',
                            // value: '#LOC#12123121'
                            label: locationName,
                            value: locationId
                          }
                        ]}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="variant2"
                        color="#000"
                        fontWeight="400"
                        className="font-20 font-myriad"
                      >
                        To
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                      <CustomSelect
                        fullWidth
                        name="toLocationId"
                        value={transferDetails.toLocationId}
                        onChange={handleTransferDetails}
                        label="LOCATION # 2"
                        error={isError.toLocationId}
                        helperText={
                          isError.toLocationId && 'Location is required'
                        }
                        options={allLocationsList?.map((item) => ({
                          label: item.locationName,
                          value: item.locationId
                        }))}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{ borderBottom: '1px solid #DDDDDD' }}
                    mt={5}
                  ></Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    marginTop="10px"
                  >
                    <Grid item>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <h2 className="title mb-0 mt-10 primary-color">
                          Choose Products to Transfer
                        </h2>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                    marginTop="20px"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                      }}
                    >
      
                      <CustomButton
                        onClick={() => setIsFilters(!isFilters)}
                        variant={
                          isAnyFilterApplied()
                            ? 'contained'
                            : isFilters
                              ? 'contained'
                              : 'outlined'
                        }
                      >
                        Filter (All)
                        <KeyboardArrowDownRoundedIcon
                          className={clsx(
                            !isFilters && classes.closeY,
                            isFilters && classes.openY
                          )}
                        />
                      </CustomButton>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <SearchInput
                        value={searchQuery}
                        setSearchQuery={setSearchQuery}
                      />
                    </Grid>
                  </Grid> */}
                  {/* <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    marginTop="20px"
                  >
                    <Grid
                      item

                 
                    >
                      <CustomButton
                        onClick={() => setIsFilters(!isFilters)}
                        variant={
                          isAnyFilterApplied()
                            ? 'contained'
                            : isFilters
                            ? 'contained'
                            : 'outlined'
                        }
                      >
                        Filter (All)
                        <KeyboardArrowDownRoundedIcon
                          className={clsx(
                            !isFilters && classes.closeY,
                            isFilters && classes.openY
                          )}
                        />
                      </CustomButton>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <SearchInput
                        value={searchQuery}
                        setSearchQuery={setSearchQuery}
                      />
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Grid
                      item
                      sx={{
                        marginTop: '10px',
                        marginBottom: '20px'
                      }}
                    >
                      <Button
                        onClick={() => setIsFilters(!isFilters)}
                        variant={
                          isAnyFilterApplied()
                            ? 'contained'
                            : isFilters
                            ? 'contained'
                            : 'outlined'
                        }
                      >
                        Filters
                        <KeyboardArrowDownRoundedIcon
                          className={clsx(
                            !isFilters && classes.closeY,
                            isFilters && classes.openY
                          )}
                        />
                      </Button>
                    </Grid>

                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                        // marginTop: '10px',
                        // marginBottom: '20px'
                      }}
                      className="top-filter-cont"
                    >
                      {/* Search box on table */}

                      <SearchInput
                        value={searchQuery}
                        setSearchQuery={setSearchQuery}
                        classPropName="height-auto"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    marginTop="10px"
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <TransferInventoryFilter
                            filters={filters}
                            setFilters={setFilters}
                            data={allInventoryData}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <TransferInventoryTable
                          tableRows={inventoryData}
                          searchQuery={searchQuery}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                        />
                      </Grid>
                    </>
                    {/* )} */}
                  </Grid>

                  <Grid container className="order-multiline-grid" pb={3}>
                    <Grid item xs={6} paddingRight="20px" marginTop="30px">
                      <CustomTextField
                        fullWidth
                        label="NOTES"
                        multiline
                        rows="5"
                        type="text"
                        name="notes"
                        // required
                        value={transferDetails.notes}
                        onChange={handleTransferDetails}
                        // error={isError.notes}
                        className="text-primary"
                        // helperText={isError.notes && 'Notes is required'}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      width="100%"
                      maxWidth="400px !important"
                      marginX="auto"
                      paddingLeft="20px"
                      marginTop="30px"
                      rowGap="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      className="fullWidth-btn"
                    >
                      <CustomButton
                        variant="outlined"
                        // disabled
                        loading={btnLoading}
                        onClick={() => handleTransferSubmit('Draft')}
                      >
                        Save as Draft
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        disabled={btnLoading}
                        onClick={() => handleTransferSubmit('Proceed')}
                      >
                        Proceed to Summary
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>

      <Dialog
        open={dialog.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDialog('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? `Successfully ${processStatus}!` : 'Save As Draft'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  Are you sure you want to save this as a draft?
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  {/* {`You have successfully ${processStatus} the inventory.`} */}
                  {`Inventory has been ${processStatus} successfully!`}
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleDialog(isCreated ? 'Confirm' : 'Cancel')}
          >
            {isCreated ? 'Close' : 'Cancel'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('draft')}
            >
              Save as Draft
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmationDialog('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmationDialog('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmationDialog('Continue')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InventoryTransfer;
