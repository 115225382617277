import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import Footer from 'src/components/Footer';
import Header from '../../../components/Header';
import {
  Box,
  Container,
  Grid,
} from '@mui/material';
import RecieveInventoryListAPI from './RecieveInventoryListAPI';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
import { getLocalData } from 'src/utils';

function TransferHistory() {
  const navigate = useNavigate();
  const title = GlobalData?.[0]?.title;
  useEffect(() => {
    let isLogin = getLocalData("Login_Sucess");
    if (isLogin !== 'true') {
      navigate("/login");
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Receive Inventory | {title}</title>
      </Helmet>
      <Box sx={{ paddingX: "22px" }} className='mt-10' >
        <Header title="Receive Inventory" />
       
      </Box>
      <Container maxWidth="100%" sx={{ mt: 3 }} className='main-container relative'>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item width={'100%'}>
            <RecieveInventoryListAPI />
          </Grid>
        </Grid>

      </Container>
      <Footer />

    </>
  );
}

export default TransferHistory;