import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import GiftCardPricingTable from './GiftCardPricingTable';
import { CustomTable } from '../../../components/CustomTable';
import { getLocalData } from '../../../utils';
import AddGiftCard from './AddGiftCard';
import GlobalData from 'src/global';
import { Helmet } from 'react-helmet-async';
import { ApiService } from '../../../services/ApiService';
import { GiftCardFilter } from 'src/components/Filters/GiftCardFilter';
import { filtersQuery } from '../../../utils/index';
import './style.css';
import Header from '../../../components/Header';
import { OrderSuccess } from '../Checkout/CheckoutSteps';
import {
  ActiveStatus,
  InactiveStatus,
  ExpiredStatus
} from 'src/assets/SVG/SvgIcons';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from 'src/components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const GiftCardTable = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddGiftCard, setOpenAddGiftCard] = useState(false);
  const [openAddGiftCardModal, setAddGiftCardModal] = useState(false);
  let [filters, setFilters] = useState({
    status: 1,
    validThrough: '',
    includedInSale: '',
    giftCardValue: ''
  });
  //     const [page, setPage] = useState(0);
  const [showScreen, setShowScreen] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = React.useState(false);
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userId = getLocalData('userId');
  const locationId = getLocalData('locationId');

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      return (event.returnValue = 'Are you sure you want to leave this page?');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  let elm = document.getElementsByClassName('page-header-text')[0];
  if (elm) elm.innerHTML = '';
  const title = GlobalData[0].title;

  const getGiftCard = async () => {
    setLoading(true);
    let queryString = await filtersQuery(filters);
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/getAllGiftCards?locationId=${encodedLocationId}${filtersParams}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      const result = Item.map((obj) => {
        const {
          partitionKey,
          sortKey,
          giftCardName,
          giftCardNumber,
          giftCardValue,
          status,
          discount
        } = obj;

        return {
          partitionKey,
          sortKey,
          'Card Name': giftCardName,
          'Card No': giftCardNumber,
          Price: `$${parseFloat(discount)?.toFixed(2)}`,
          Value: `$${parseFloat(giftCardValue)?.toFixed(2)}`,
          Status:
            status === 1 ? (
              <ActiveStatus />
            ) : status === 3 ? (
              <ExpiredStatus />
            ) : (
              <InactiveStatus />
            )
        };
      });

      setLoading(false);
      setTableData(result);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  useEffect(() => {
    getGiftCard();
  }, [filters]);

  const handleOnClick = (data) => {
    console.log(data);
  };

  // METHOD TO HANDLE BULK UPDATE
  const handleBulkAdd = async () => {
    console.log('handleBulkAdd');
    setIsUpdated(false);
    setOpen(true);
  };

  // Method to handle bulk update status final confirm
  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    setLoading(true);
    const data = {
      status: selectedStatus,
      updatedBy: userId,
      giftCardIds: selectedLists
    };

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.put(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/updateBulkStatus?locationId=${encodedLocationId}`,
        data
      );

      if (!response?.error) {
        setLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage(
          'All the selected gift cards have been updated successfully!'
        );
        setSelectedStatus('');
        setSelectedLists([]);
        setIsUpdated(true);
        setOpen(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response.data?.message ||
            'Something went wrong with update gift card status'
        );
      }
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  // Method to handle close of dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getGiftCard();
        setIsUpdated(false);
      }
    }
  };
  const handleGiftCardPricingClick = () => {
    setShowScreen(1);
  };
  const handleButtonClick = () => {
    setOpenAddGiftCard(true);
    setAddGiftCardModal(true);
  };
  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
    getGiftCard();
  };
  const buttons = [
    {
      label: 'Gift Card Pricing',
      onClick: handleGiftCardPricingClick,
      theme: true
    },
    {
      label: 'Manage Gift Card',
      onClick: handleButtonClick,
      theme: true
    }
  ];

  return (
    <>
      <Loader IsLoading={loading} />
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <GiftCardPricingTable
                setShowScreen={setShowScreen}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
              />
            );
          case 2:
            return (
              <OrderSuccess
                handlePage={() => setShowScreen(1)}
                from="GiftCard"
              />
            );
          default:
            return (
              <>
                {/* <Container maxWidth="100%" className='feature-main-container'> */}

                <Helmet>
                  <title>Gift Card | {title}</title>
                </Helmet>
                {showErrorMessage && (
                  <ErrorMessage
                    message={errorMessage}
                    setShowMessage={setShowErrorMessage}
                  />
                )}

                {/* Added one more callback function to identify when close the popup */}
                {showSucessMessage && (
                  <SuccessMessage
                    message={sucessMessage}
                    setShowMessage={setSuccessMessage}
                    closePopup={closePopup}
                  />
                )}
                <Box sx={{ pl: '30px', pr: '30px' }}>
                  {showScreen === 0 && (
                    <Header title="Gift Cards" buttons={buttons} />
                  )}
                </Box>
                <div className="gift-card-table">
                  <CustomTable
                    loading={loading}
                    filterCompoenent={
                      <GiftCardFilter
                        setFilters={setFilters}
                        filters={filters}
                      />
                    }
                    data={tableData}
                    isEdit={false}
                    label="All Gift Cards"
                    handleOnClick={handleOnClick}
                    selectedLists={selectedLists}
                    setSelectedLists={setSelectedLists}
                    open={open}
                    setOpen={setOpen}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    handleBulkAdd={handleBulkAdd}
                    btnLoading={btnLoading}
                  />
                </div>

                {openAddGiftCard ? (
                  <AddGiftCard
                    setOpenAddGiftCard={setOpenAddGiftCard}
                    openAddGiftCardModal={openAddGiftCardModal}
                    setAddGiftCardModal={setAddGiftCardModal}
                    setShowScreen={setShowScreen}
                  />
                ) : null}
                {/* 
                                </Container> */}
              </>
            );
        }
      })()}

      {/* Status Update Dialog confirmation */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Are you sure you want to mark Activate/Deactivate,
                  <Typography className="title-text-sub">
                    all the selected items?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected gift cards have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GiftCardTable;
