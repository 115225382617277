/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  Slide,
  Autocomplete,
  FormHelperText
} from '@mui/material';
import Loader from '../../../components/Loader';
import SignaturePad from 'react-signature-canvas';
import './addClient.css';
import { getFileFromUrl, formatPhoneNumber } from '../../../utils/index';
import { getLocalData } from '../../../utils';
import debounce from 'lodash/debounce';
// import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { ApiService } from '../../../services/ApiService';
import { makeStyles } from '@material-ui/core/styles';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import heic2any from 'heic2any';
import SearchIcon from '@mui/icons-material/Search';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const EditClient = ({
  data,
  setShowScreen,
  getEventToRefresh,
  fromTable,
  setFromTable
}) => {
  const signatureCanvasRef = useRef();
  const [imageURL, setImageURL] = React.useState('');
  const [signImageFile, setSignImageFile] = React.useState(null);

  let userId = getLocalData('userId');
  let isValid = true;

  // create a custom theme with updated hover and close icon colors
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId =
    isFranchisor === 'true'
      ? localStorage.getItem('SelectedLocationId')
      : getLocalData('locationId');
  // const franchiseeId = getLocalData('franchiseeId');
  const franchiseeId =
    isFranchisor === 'true'
      ? localStorage.getItem('SelectedFranchiseeId')
      : getLocalData('franchiseeId');
  // const userRole = getLocalData('userRole');
  // const franchiseeId = getLocalData('franchiseeId');
  // const inputRef = useRef(null);
  /* State for add client */
  // const currentDate = new Date();
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 20);
  // const minDateValue = new Date(minDate);
  // const [name, setname] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [address1, setAddress1] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [referralResource, setReferralResource] = useState('');
  const [location, setLocation] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setemail] = useState('');
  // eslint-disable-next-line no-unused-vars
  // const [selectedFile, setSelectedFile] = useState(null);
  const [zipcode, setZipcode] = useState('');
  // const [clientDob, setClientDob] = useState('');
  // const [dob, setDob] = useState('');
  // const [cities, setCities] = useState([]);
  const [setstate, setIsState] = useState([]);
  // const [emailEmergancy, setemailEmergancy] = useState('');
  // const [phone, setPhone] = useState('');
  // const [relationship, setrelationship] = useState('');
  const [locationDetails, setlocationDetails] = useState([]);
  const [liabilityWaiver, setliabilityWaiver] = useState('');
  const [liabilityWaiverId, setliabilityWaiverId] = useState('');
  // const [isStepOneValid, setStepOneValid] = useState(false);
  const [imgObjClient, setimgObjClient] = useState([]);
  const [imgUrlClient, setimgUrlClient] = useState('');
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  /* States for error messages in client add */
  const [locationError, setlocationError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [stateError, setStateError] = useState('');
  const [cityError, setCityError] = useState('');
  // const [dobError, setDobError] = useState('');
  const [referralResourceError, setReferralResourceError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [emailError, setemailError] = useState('');
  // const [emailEmergancyError, setemailEmergancyError] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  // const [emergencyPhoneError, setEmergencyPhoneError] = useState('');
  let clientDetails = data;

  // const [imgObj, setimgObj] = useState([]);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  // const [openAddClient, setopenAddClient] = useState(false);
  const [open, setOpen] = React.useState(false);
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [existingClient, setExistingClient] = useState(null);
  const [businessEntity, setBusinessEntity] = useState(null);
  const [openAddReferralSource, setOpenAddReferralSource] = useState(false);
  const [openLiabilitySigned, setOpenLiabilitySigned] = useState(false);
  const [referalSourceList, setReferalSourceList] = useState([]);
  const [existingClientList, setExistingClientList] = useState([]);
  const [isExistingClient, setIsExistingClient] = useState('');
  const [businessEntityList, setBusinessEntityList] = useState([]);
  const [sourceName, setSourceName] = useState('');
  const [sourceNameError, setSourceNameError] = useState('');
  // const [sourceEmail, setSourceEmail] = useState('');
  // const [sourceEmailError, setSourceEmailError] = useState('');
  const [isLiabilityWaiverSigned, setIsLiabilityWaiverSigned] = useState(false);
  const [profileImageError, setProfileImageError] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [clientLoading, setClientLoading] = useState(false);

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="left" ref={ref} {...props} />;
  // });

  const saveSignature = async () => {
    if (
      signatureCanvasRef &&
      signatureCanvasRef.current &&
      signatureCanvasRef.current.isEmpty() === false &&
      signatureCanvasRef.current.getTrimmedCanvas()
    ) {
      setImageURL(
        signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
      );
      // convert base64 to file object if signature Image
      let fileSign = await getFileFromUrl(
        signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
      );
      setSignImageFile(fileSign);
      setIsFieldUpdated(true);
      setIsLiabilityWaiverSigned(true);
    } else {
      setImageURL('');
      setSignImageFile('');
      setIsLiabilityWaiverSigned(false);
    }
  };

  // const steps = ['Edit Client Info', 'Edit Pet Info'];

  useEffect(() => {
    getReferalSource();
    getState();
    getBusinessEntity();
    getClientList();
    // setopenAddClient(true);
    getLocation();
    getLiabilityWaiver();
  }, []);

  useEffect(() => {
    getClientDetails();
  }, [clientDetails]);

  useEffect(() => {}, [data]);

  // Check if unsaved changes are there and loading/closing browser tab
  useEffect(() => {
    if (isFieldUpdated) window.addEventListener('beforeunload', alertUnsaved);
    // return () => {
    //   window.removeEventListener("beforeunload", alertUnsaved);
    // };
  }, [isFieldUpdated]);

  // Unsaved changes alert
  const alertUnsaved = (e) => {
    e.preventDefault();
    e.returnValue = 'You have unsaved changes, what do you want to do?';
  };

  // method to get the liability waiver
  const getLiabilityWaiver = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}liability-waiver/recent`
      );
      let data = response;
      if (data.statusCode && !data.error) {
        // setliabilityWaiver(data.data.content);
        setliabilityWaiver(data.data.content);
        setliabilityWaiverId(data.data.sortKey);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Set the logged in location
    setLocation(locationId);
    // if (locationId) setLocation(locationId);
  }, [locationId]);

  useEffect(() => {
    // Set the logged in location
    let locId = getLocalData('locationId');
    if (locId) {
      const interval = setInterval(() => {
        setLocation(locationId);
      }, 100);
      return () => clearInterval(interval);
    }
    setLocation(locId);
    // if (locationId) setLocation(locationId);
  }, []);

  // useEffect(() => {
  //   if (!locationId) {
  //     const interval = setInterval(() => {
  //       setLocation(locationId);
  //     }, 500);
  //     return () => clearInterval(interval);
  //   }
  // }, []);

  // set interval to check if the location is updated
  // useEffect(() => {
  //   if (isUpdated) {
  //     const interval = setInterval(() => {
  //       setIsUpdated(false);
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }
  // }, [isUpdated]);

  const getClientDetails = () => {
    // Client Data
    // let clientDetails = [];
    // if (isFranchisor === 'true') clientDetails.push(clientDetails);
    if (clientDetails && clientDetails?.length > 0) {
      setLocation(clientDetails[0]?.locationId);
      setFirstName(clientDetails[0]?.firstName);
      setLastName(clientDetails[0]?.lastName);
      setMobileNumber(clientDetails[0]?.mobilePhone);
      // setHomePhone(clientDetails[0]?.homePhone);
      // setWorkPhone(clientDetails[0]?.workPhone);
      // setClientDob(clientDetails[0]?.birthDate);
      setemail(clientDetails[0]?.email);
      // setDob(formatDate(clientDetails[0][0]?.birthDate));
      // setDob(clientDetails[0][0]?.birthDate);
      setReferralResource(clientDetails[0]?.referralSource);

      setZipcode(clientDetails[0]?.postalCode);
      setAddress(clientDetails[0]?.addressLine1);
      setAddress1(clientDetails[0]?.addressLine2);
      setSelectedCity(clientDetails[0]?.city);
      setSelectedState(clientDetails[0]?.state);
      setimgUrlClient(clientDetails[0]?.profileImage);
      // setname(clientDetails[0]?.emergencyContactName);
      // setPhone(clientDetails[0]?.emergencyContactPhone);
      // setemailEmergancy(clientDetails[0]?.emergencyContactEmail);
      // setrelationship(clientDetails[0]?.emergencyContactRelationShip);
      setImageURL(
        clientDetails[0]?.signatureImage ? clientDetails[0]?.signatureImage : ''
      );
      if (
        clientDetails[0]?.signatureImage &&
        clientDetails[0]?.signatureImage
      ) {
        setIsLiabilityWaiverSigned(true);
      }
      setSelectedStatus(clientDetails[0]?.status);
      if (clientDetails[0]?.referralSource === 'Existing Client') {
        setIsExistingClient('client');

        // If existing client is selected then set the existing client details
        if (clientDetails[0]?.referralDetails) {
          let details = JSON.parse(clientDetails[0]?.referralDetails);
          let name = details?.referralSourceName;
          let arr = name && name.split(' ');

          let referralData = {
            sortKey: details?.referralSourceId,
            // firstName: details?.referralSourceName,
            firstName: arr?.length > 0 ? arr[0] : '',
            lastName: arr?.length > 1 ? arr[1] : '',
            email: details?.referralSourceEmail
          };
          setExistingClient(referralData);
        }
      } else if (clientDetails[0]?.referralSource === 'Business Entity') {
        setIsExistingClient('businessEntity');

        // If the referral details is not null then set the business entity
        if (clientDetails[0]?.referralDetails) {
          let details = JSON.parse(clientDetails[0]?.referralDetails);
          if (details?.referralSourceId) {
            let referralData = {
              sortKey: details?.referralSourceId,
              businessName: details?.referralSourceName,
              contactEmail: details?.referralSourceEmail
            };
            setBusinessEntity(referralData);
          }
        }
      }
      setliabilityWaiverId(clientDetails[0]?.liabilityWaiverId);
      setliabilityWaiver(clientDetails[0]?.liabilityWaiverContent);
    }

    getLiabilityWaiver();
  };

  /* Function to get state in drop down */
  const getState = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`
      );
      if (response.statusCode === 200) {
        // let Item = response?.data?.Items.slice(0, 50);
        let Item = response?.data?.Items || [];
        setIsState(Item);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get the referal source */

  const getReferalSource = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getReferralSource?franchiseeId=${encodedFranchiseeId}`
      );
      if (response.statusCode === 200) {
        setReferalSourceList(response.data);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get the existing client */

  const getClientList = async (searchText) => {
    setClientLoading(true);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getClientsBySearch?searchText=${searchText}`
      );
      if (response.statusCode === 200) {
        setExistingClientList(response.data.Items);
        setClientLoading(false);
      } else {
        setClientLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get the existing client */

  const getBusinessEntity = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getBusinessEntityBySearch?locationId=${encodedLocationId}`
      );
      if (response.statusCode === 200) {
        setBusinessEntityList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmClose = (val) => {
    if (val === 'Discard') {
      setOpenConfirmPopup(false);
      if (fromTable) {
        setShowScreen(0);
        setFromTable(false);
      } else {
        setShowScreen(3);
      }
    }
    if (val === 'Save') {
      setOpenConfirmPopup(false);
    }
  };

  // Method to open the update dialog
  const handleClickOpen = () => {
    if (!emailError && firstName && lastName) {
      setOpen(true);
    }
  };

  /* Function to get location details */
  const getLocation = async () => {
    let url;
    // let encodedLocationId = encodeURIComponent(locationId);
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);

    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && franchiseeId) {
      url = `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      locationId
    ) {
      url = `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500`;
    }

    try {
      const response = await ApiService.get(`${url}`);
      if (response.statusCode === 200) {
        let Item = response?.data?.Items.slice(0, 50);
        setlocationDetails(Item);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to upload image Add Client */
  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  /* Function to clear the signature */
  const handleSignature = () => {
    if (imageURL) setImageURL(null);
    if (signatureCanvasRef && signatureCanvasRef.current)
      signatureCanvasRef.current.clear();

    setIsLiabilityWaiverSigned(false);
  };

  // Function to close modal popUp
  const handleConfClose = (val) => {
    if (val === 'Cancel') {
      setOpenLiabilitySigned(false);
    } else if (val === 'Close') {
      setOpenLiabilitySigned(false);
      setActiveStep(activeStep + 1);
    }
  };
  const getStateFromSortKey = (sortKey) => {
    const foundObject = locationDetails?.find(
      (item) => item?.sortKey === sortKey
    );
    return foundObject ? foundObject?.state : null;
  };

  /* Function to create client */

  // eslint-disable-next-line no-unused-vars
  const updateClientDetails = async () => {
    // Show loader
    setIsLoading(true);
    setShowError(false);
    setErrorMessage('');
    let clientId = clientDetails[0]?.sortKey;

    const formData = new FormData();
    formData.append('locationId', location);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('mobilePhone', mobileNumber);
    // formData.append('birthDate', ''); // clientDob
    formData.append('addressLine1', address);
    formData.append('addressLine2', address1 || '');
    formData.append('state', selectedState);
    formData.append('city', selectedCity);
    formData.append('referralSource', referralResource);
    // formData.append('emergencyContactName', '');
    // formData.append('emergencyContactPhone', '');
    // formData.append('emergencyContactRelationShip', '');
    // formData.append('emergencyContactEmail', '');
    // formData.append('isLiabilityWaiverSigned', true);
    formData.append('isLiabilityWaiverSigned', isLiabilityWaiverSigned);
    if (zipcode !== undefined) {
      formData.append('postalCode', zipcode);
    }
    formData.append('updatedBy', userId);
    formData.append('status', selectedStatus);
    formData.append('liabilityWaiverId', liabilityWaiverId);
    formData.append('liabilityWaiverContent', liabilityWaiver);
    formData.append('locationState', getStateFromSortKey(location));

    if (imgObjClient) formData.append('profileImage', imgObjClient);
    if (signImageFile) formData.append('signatureImage', signImageFile);

    let referralData = {};
    if (isExistingClient === 'client') {
      referralData.referralSourceId = existingClient?.sortKey;
      let fname = existingClient?.firstName;
      let lname = existingClient?.lastName;
      let fullname;
      if (fname) {
        if (lname) {
          fullname = fname + ' ' + lname;
        } else {
          fullname = fname;
        }
      }
      referralData.referralSourceName =
        // existingClient.firstName + ' ' + existingClient.lastName;
        fullname;
      referralData.firstName = fname;
      referralData.lastName = lname;
      referralData.referralSourceEmail = existingClient?.email;
    } else if (isExistingClient === 'businessEntity') {
      referralData.referralSourceId = businessEntity?.sortKey;
      referralData.referralSourceName = businessEntity?.businessName;
      referralData.referralSourceEmail = businessEntity?.contactEmail;
    }

    formData.append('referralDetails', JSON.stringify(referralData));

    let encodedClientId = encodeURIComponent(clientId);
    try {
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_END_POINT}client?id=${encodedClientId}`,
        formData
      );
      const data = response;
      // console.log(data);
      if (data.statusCode === 200) {
        // Now hide the loader
        setIsLoading(false);
        // Now set to all location data updated
        setIsUpdated(true);
        // set field updated to false
        setIsFieldUpdated(false);
        // getEventToRefresh();
        handleClickOpen();
      } else {
        // Now hide the loader
        setIsLoading(false);
        setShowError(true);
        setErrorMessage(data?.message ?? data?.error);
      }
      // })
    } catch (error) {
      // Handle error here
      console.log('Error:', error);
      // Now hide the loader
      setIsLoading(false);
      setShowError(true);
      setErrorMessage(error.message);
    }
  };

  /* Function to Submit details of client  */
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let mobileNumberValid = true;
    let isemailValid = true;
    let isMobileValid = true;

    // if (!location) {
    //   // setStepOneValid(false);
    //   isValid = false;
    //   setlocationError('Location is required');
    // } else {
    //   setlocationError('');
    //   isValid = true;
    //   // setStepOneValid(true);
    //   // Handle form submission
    // }

    if (!firstName) {
      // setStepOneValid(false);
      isValid = false;
      setFirstNameError('First Name is required');
    } else {
      setFirstNameError('');
      isValid = true;
      // setStepOneValid(true);
    }

    if (!lastName) {
      // setStepOneValid(false);
      isValid = false;
      setLastNameError('Last Name is required');
    } else {
      setLastNameError('');
      isValid = true;
      //  setStepOneValid(true);
    }

    if (emailError) {
      isemailValid = false;
    } else if (!email) {
      setemailError('Email is required');
      isValid = false;
      // setStepOneValid(false);
    } else {
      setemailError('');
      isValid = true;
      // setStepOneValid(true);
    }
    // if (!address) {
    //   setAddressError('Address is required');
    //   isValid = false;
    //   // setStepOneValid(false);
    // } else {
    //   setAddressError('');
    //   isValid = true;
    //   //  setStepOneValid(true);
    // }
    // if (!selectedState) {
    //   setStateError('Please select the State');
    //   isValid = false;
    //   // setStepOneValid(false);
    // } else {
    //   setStateError('');
    //   // setStepOneValid(true);
    // }
    // if (!selectedCity) {
    //   setCityError('Please select the City');
    //   isValid = false;
    //   // setStepOneValid(false);
    // } else {
    //   setCityError('');
    //   isValid = true;
    //   // setStepOneValid(true);
    // }
    // if (!zipcode) {
    //   setZipcodeError('Zip code is required');
    //   // zipcodeError = "Zip Code is required";
    //   isValid = false;
    //   // setStepOneValid(false);
    // }

    // else {
    //   setZipcodeError('');
    //   isValid = true;
    //   // setStepOneValid(true);
    // }

    // if (!referralResource) {
    //   setReferralResourceError('Please select the Referral Resource');
    //   isValid = false;
    //   // setStepOneValid(false);
    // } else {
    //   setReferralResourceError('');
    //   isValid = true;
    //   // setStepOneValid(true);
    //   // Handle form submission
    // }

    // if (!mobileNumber) {
    //   setMobileNumberError('Mobile number is required');
    //   isValid = false;
    //   // setStepOneValid(false);
    // } else {
    //   setMobileNumberError('');
    //   isValid = true;
    //   //  setStepOneValid(true);
    // }

    if (mobileNumberError) {
      isMobileValid = false;
    } else if (mobileNumber) {
      if (mobileNumber && !/^\d{3}-\d{3}-\d{4}$/.test(mobileNumber)) {
        // setWorkPhoneNumberError(true);
        mobileNumberValid = false;
        setMobileNumberError(
          'Please enter a valid mobile number in format: xxx-xxx-xxxx'
        );
      } else {
        setMobileNumberError('');
      }
    } else {
      setMobileNumberError('Mobile number is required');
      isValid = false;
    }

    if (!imageURL) saveSignature();

    if (
      !zipcodeError?.length > 0 &&
      isValid &&
      mobileNumberValid &&
      // selectedCity &&
      isemailValid &&
      isMobileValid
    ) {
      // handleNext();
      if (isFieldUpdated) {
        handleClickOpen();
      }
    }
  };

  /* Function to set location name */

  const SetLocationName = (val) => {
    setIsFieldUpdated(true);
    if (!val) {
      setlocationError('Location is required');
    } else {
      setlocationError('');
      setIsFieldUpdated(true);
      setLocation(val);
    }
  };

  /* Function to set first name */

  const SetFirstName = (val) => {
    setIsFieldUpdated(true);
    if (!val) {
      setFirstName('');
      setFirstNameError('First name is required');
    } else {
      setFirstNameError('');
      setFirstName(val);
    }
  };

  /* Function to set last name */

  const SetLastName = (val) => {
    setIsFieldUpdated(true);
    if (!val) {
      setLastName('');
      setLastNameError('Last name is required');
    } else {
      setLastNameError('');
      setLastName(val);
    }
  };

  /* Function to set Email */

  const SetEmail = (e) => {
    const emailValue = e.target.value;
    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let manufacturerPattern = /\S+@\S+\.\S+/;
    // set field updated to true

    if (!emailValue) {
      setIsFieldUpdated(true);
      setemailError('Email is required');
      setemail('');
    } else if (!manufacturerPattern.test(emailValue)) {
      setIsFieldUpdated(true);
      setemailError('Please enter valid email address');
      setemail(emailValue);
    } else if (emailValue.length >= 6) {
      setemail(emailValue);
      debouncedCheckEmailExist(emailValue);
      setIsFieldUpdated(true);
    } else {
      setIsFieldUpdated(true);
      setemailError('');
      setemail(emailValue);
    }
  };

  // Method to change the phone number format to "123-456-7890"
  /* const formatPhone = (value) => {
     const input = value.replace(/\D/g, '').substring(0, 10);
     // Divide numbers in 3 parts :"123-456-7890"
     const first = input.substring(0, 3);
     const middle = input.substring(3, 6);
     const last = input.substring(6, 10);
     let finalValue = '';
     if (input.length > 6) {
       // return `${first}-${middle}-${last}`;
       finalValue = `${first}-${middle}-${last}`;
     } else if (input.length > 3) {
       // return `${first}-${middle}`;
       finalValue = `${first}-${middle}`;
     } else if (input.length >= 0) {
       // return input;
       finalValue = input;
     }
     return finalValue;
   }; */

  /* Function to set mobile number */

  const SetMobileNumber = (e) => {
    setIsFieldUpdated(true);
    const mobileNumber = e.target.value;
    const validation = /^\d{3}-\d{3}-\d{4}$/;

    if (mobileNumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(mobileNumber);
      setMobileNumber(formatWorkPhoneNumber);
      setIsFieldUpdated(true);

      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setMobileNumberError(
          'Please enter a valid mobile number in format: xxx-xxx-xxxx'
        );
      } else if (formatWorkPhoneNumber.length >= 11) {
        setMobileNumberError('');
        debouncedCheckMobileNumberExist(formatWorkPhoneNumber);
      } else {
        setMobileNumberError('');
      }
    } else {
      setMobileNumberError('Mobile number is required');
      setMobileNumber('');
    }
  };

  /* Function to set date of birth */

  // const SetDateOfBirth = (val) => {
  //   if (!val) {
  //     setDobError('Date of birth is required');
  //   } else {
  //     let newDate;
  //     setDobError('');
  //     let objectDate = new Date(val);
  //     let date = objectDate.getDate();
  //     if (date < 10) {
  //       newDate = '0' + date;
  //     } else {
  //       newDate = date;
  //     }
  //     let month = objectDate.getMonth() + 1;
  //     let newMonth = '0' + month;
  //     let year = objectDate.getFullYear();
  //     let finalDate = newMonth + '-' + newDate + '-' + year;
  //     setDob(finalDate);
  //   }
  // };
  /* const SetDateOfBirth = (val) => {
     if (val === '01-01-1970') {
       return;
     }
     if (!val) {
       // setDobError('Date of birth is required');
     } else {
       // setDobError('');
       setIsFieldUpdated(true);
       setClientDob(val);
     }
   }; */

  /* Function to set  Referal source */

  const SetReferalSource = (val) => {
    setIsFieldUpdated(true);
    if (!val) {
      setReferralResourceError('Reference source is required');
      setIsExistingClient('');
    } else {
      if (val === 'Existing Client') {
        setIsExistingClient('client');
      } else if (val === 'Business Entity') {
        setIsExistingClient('businessEntity');
      } else {
        setIsExistingClient('');
      }
      setReferralResourceError('');
      setReferralResource(val);
    }
  };

  /* Function to set Address  */

  const SetAddress = (val) => {
    setIsFieldUpdated(true);
    if (!val) {
      setAddress('');
      setAddressError('');
    } else {
      setAddressError('');
      setAddress(val);
    }
  };
  /* Function to set Address1  */

  const SetAddress1 = (val) => {
    setIsFieldUpdated(true);
    setAddress1(val);
  };

  /* Function to set state */

  const SetState = (val) => {
    setIsFieldUpdated(true);
    if (!val) {
      setStateError('State is required');
    } else {
      setStateError('');
      setSelectedState(val);
    }
  };

  /* Function to set city */

  const SetCity = (val) => {
    setIsFieldUpdated(true);
    if (!val) {
      setSelectedCity('');
      setCityError('');
    } else {
      setCityError('');
      setSelectedCity(val);
    }
  };

  /* Function to set zip code */

  const SetZipCode = (val) => {
    const zipCodePattern = val.replace(/\D/g, ''); // Remove non-digit characters

    if (!zipCodePattern) {
      setIsFieldUpdated(true);
      setZipcodeError('');
      setZipcode('');
    } else if (zipCodePattern.length !== 5) {
      setIsFieldUpdated(true);
      setZipcodeError('Zip code should have exactly 5 digits');
      setZipcode(zipCodePattern.substring(0, 5)); // Truncate to 5 digits
    } else {
      setIsFieldUpdated(true);
      setZipcodeError('');
      setZipcode(zipCodePattern);
    }
  };

  /* Function to set name */
  /*
    const SetName = (val) => {
      setIsFieldUpdated(true);
      setname(val);
    }; */

  /* Function to set Email */

  /* const SetEmailEmergancy = (val) => {
     let manufacturerPattern = /\S+@\S+\.\S+/;
     // set field updated to true
 
     if (!manufacturerPattern.test(val)) {
       setIsFieldUpdated(true);
       setemailEmergancyError('Please enter valid email address');
       setemailEmergancy(val);
     } else {
       setIsFieldUpdated(true);
       setemailEmergancyError('');
       setemailEmergancy(val);
     }
   }; */

  /* Function to set phone */
  /*
    const SetPhone = (val) => {
      setIsFieldUpdated(true);
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      if (val) {
        let formatWorkPhoneNumber = formatPhone(val);
        setPhone(formatWorkPhoneNumber);
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setEmergencyPhoneError(
            'Please enter a valid phone number in format: xxx-xxx-xxxx'
          );
        } else {
          setEmergencyPhoneError('');
        }
      } else {
        setEmergencyPhoneError('');
        setPhone('');
      }
    }; */

  /* Function to set relationship */
  /*
    const SetRelationship = (val) => {
      setIsFieldUpdated(true);
      setrelationship(val);
    }; */

  /* Function to Liability waiver */

  // const SetLiabilityWaiver = (val) => {
  //   setliabilityWaiver(val);
  // };

  // Function to move to create user form page
  // const handleNext = () => {
  //   // saveSignature();
  //   if (
  //     signatureCanvasRef &&
  //     signatureCanvasRef.current &&
  //     signatureCanvasRef.current.isEmpty() === false &&
  //     signatureCanvasRef.current.getTrimmedCanvas()
  //   ) {
  //     setActiveStep(activeStep + 1);
  //   }
  //   else {
  //     setOpenLiabilitySigned(true);
  //   }
  //   // setActiveStep(activeStep + 1);
  // };

  // Function to close modal popUp

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Discard') {
      setOpen(false);
      setShowScreen(0);
      getEventToRefresh();
    }
    if (val === 'Save') {
      setOpen(false);
      updateClientDetails();
    }
  };

  // // Method to change the status
  // const handleStatusChange = (val) => {
  //   setSelectedStatus(val);
  //   setIsFieldUpdated(true);
  // };

  /* Function to upload image of client */
  const handleImageUploadClient = async (e) => {
    setProfileImageError('');
    const maxAllowedSize = 5 * 1024 * 1024;
    let filesLength = e.target.files[0].size;
    const imageFile = e.target.files[0];

    if (imageFile && filesLength <= maxAllowedSize) {
      if (imageFile.name.toLowerCase().endsWith('.heic')) {
        try {
          // Convert HEIC to JPEG
          const jpegBlob = await heic2any({
            blob: imageFile,
            toType: 'image/jpeg',
            quality: 0.7 // Adjust quality as needed
          });

          const jpegFile = new File([jpegBlob], `${imageFile.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setimgObjClient(jpegFile);
          setimgUrlClient(URL.createObjectURL(jpegFile));
          setIsFieldUpdated(true);
        } catch (error) {
          console.error('Error converting HEIC to JPEG:', error);
          // Handle error as needed
          setProfileImageError(
            'Error converting HEIC to JPEG. Please try again.'
          );
        }
      } else if (/\.(png|jpe?g)$/i.test(imageFile.name)) {
        // If it's a supported format (png, jpeg, jpg), handle it as usual
        setimgObjClient(imageFile);
        setimgUrlClient(URL.createObjectURL(imageFile));
        setIsFieldUpdated(true);
      } else {
        // If it's not a supported format, display an error message
        console.error('Unsupported image format:', imageFile.name);
        // Handle error as needed
        setProfileImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    } else {
      setProfileImageError('Image size is more then 5mb.');
    }
  };

  // Method to check the email already exist or not
  const checkEmailExist = async (email) => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}client/checkEmailExists?email=${email}`
      );
      // Process the response here
      if (response.isExists) {
        let msg = response.message;
        // Now email is already exist
        setemailError(msg);
      } else {
        // Now email is not exist
        setemailError('');
      }
    } catch (error) {
      console.error('Error checking email existence:', error);
    }
  };

  // Method to check the email already exist or not
  const checkMobileNumberExist = async (mobileNumber) => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}client/checkMobilePhoneExists?mobilePhone=${mobileNumber}`
      );

      // Process the response here
      if (response.isExists) {
        let msg = response.message;
        // Now email is already exist
        setMobileNumberError(msg);
      } else {
        // Now email is not exist
        setMobileNumberError('');
      }
    } catch (error) {
      console.error('Error checking email existence:', error);
    }
  };
  // Function to close the edit location dialog box
  const handleEditClientClose = () => {
    if (isFieldUpdated) {
      setOpenConfirmPopup(true);
      // setOpen(true);
    } else {
      setOpenConfirmPopup(false);
      if (fromTable) {
        setShowScreen(0);
        setFromTable(false);
      } else {
        setShowScreen(3);
      }
      // setOpen(false);
    }
  };

  // Debounced function to call API
  const debouncedCheckEmailExist = debounce(checkEmailExist, 500); // Adjust the debounce delay as per your requirements
  const debouncedCheckMobileNumberExist = debounce(checkMobileNumberExist, 500); // Adjust the debounce delay as per your requirements

  if (!locationDetails.length) {
    return null;
  }

  const changeExistingClient = (selectedclient) => {
    setIsFieldUpdated(true);
    if (selectedclient) {
      setExistingClient(selectedclient);
    } else {
      setExistingClient(null);
    }
  };

  const handleSearchClient = (e) => {
    let value = '';
    value = e.target.value;
    setSearchValue(value);
    if (value.length) {
      getClientList(value);
    } else setExistingClientList([]);
  };

  // methdo to change the business entity
  const changeBusinessEntity = (selectedEntity) => {
    setIsFieldUpdated(true);
    if (selectedEntity) {
      setBusinessEntity(selectedEntity);
    } else {
      setBusinessEntity(null);
    }
  };

  /* Method for Add new referral source modal */
  // const addNewReferralSource = () => {
  //   setSourceName('');
  //   setSourceNameError('');
  //   // setSourceEmail('');
  //   setOpenAddReferralSource(true);
  // };

  // Handle the source name field's value
  // useEffect(() => {
  //   if(sourceName) {
  //     setSourceNameError('');
  //   } else {
  //     setSourceNameError('Source name is required');
  //   }
  // }, [sourceName]);

  // close referal source dialog
  const handleCloseVariant = () => {
    setOpenAddReferralSource(false);
  };

  // Now create refereral source
  const handleCreateReferralSource = () => {
    //  && sourceEmail && !sourceEmailError
    if (sourceName) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        franchiseeId: franchiseeId,
        locationId: locationId,
        referralSourceName: sourceName,
        // referralSourceEmail: sourceEmail,
        createdBy: userId
      };
      createReferralSource(data);
    } else {
      setSourceNameError('Source name is required');
    }
  };
  // create referal source
  const createReferralSource = async (data) => {
    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/createReferralSource`,
        data
      );

      if (response.statusCode === 200 && !response.error) {
        setSourceName('');
        // setSourceEmail('');
        getReferalSource();
        // setproductCategoryId(res.data.data.categoryId);
        setOpenAddReferralSource(false);
        // Now hide the loader
        setIsLoading(false);
        setIsFieldUpdated(true);
      }
    } catch (err) {
      console.log(err);
      // Now hide the loader
      setIsLoading(false);
    }
  };

  /* Function to set SourceEmail */

  // const SetSourceEmail = (val) => {
  //   const emailValue = val;
  //   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   // let emailPattern = /\S+@\S+\.\S+/;

  //   if (!emailValue) {
  //     setSourceEmailError('Email is required');
  //     setSourceEmail('');
  //   } else if (!emailPattern.test(emailValue)) {
  //     setSourceEmailError('Please enter valid email address');
  //     setSourceEmail(emailValue);
  //   }
  //   else {
  //     setSourceEmailError('');
  //     setSourceEmail(emailValue);
  //   }
  // };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {errorMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setErrorMessage} />
      )}
      {/* Added fragment to get rid the decendant error */}
      <>
        <Grid sx={{ padding: '0 2rem' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleEditClientClose()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>
                <Typography
                  className="header-blue-32"
                  // style={{ marginTop: '-5px', marginLeft: '5px' }}
                >
                  Edit Client
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                  type="submit"
                  className="btn-primary m-0"

                >
                  Save
                </Button> */}
              </Box>
            </Grid>
          </Grid>

          <Box
            component="form"
            onSubmit={handleSubmit}
            // onSubmit={createClient}
            sx={{ margin: 'auto', width: '98%' }}
          >
            <Grid container spacing={2} sx={{ marginTop: '5px' }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className="header-blue-22">
                  Client Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  error={locationError}
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    LOCATION
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="LOCATION"
                    className="input-field-styling"
                    value={location}
                    disabled
                    onChange={(e) => SetLocationName(e.target.value)}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 200 } }
                    }}
                  >
                    {locationDetails.map((location) => (
                      <MenuItem
                        key={location.locationName}
                        value={location.sortKey}
                      >
                        {location.locationName}
                      </MenuItem>
                    ))}
                  </Select>
                  {locationError && (
                    <FormHelperText error>{locationError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="First name"
                  autocomplete="First name"
                  label="FIRST NAME"
                  placeholder="FIRST NAME"
                  value={firstName}
                  className="input-field-styling required-field"
                  onChange={(e) => SetFirstName(e.target.value)}
                  error={Boolean(firstNameError)}
                  helperText={firstNameError}
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label',
                    shrink: 'true'
                  }}
                  InputProps={{}}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="Last name"
                  autocomplete="Last name"
                  label="LAST NAME"
                  placeholder="LAST NAME"
                  className="input-field-styling required-field"
                  value={lastName}
                  onChange={(e) => SetLastName(e.target.value)}
                  error={Boolean(lastNameError)}
                  helperText={lastNameError}
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label',
                    shrink: 'true'
                  }}
                  InputProps={{}}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="Email"
                  autocomplete="Email"
                  label="EMAIL"
                  placeholder="EMAIL"
                  className="input-field-styling required-field"
                  value={email}
                  onChange={SetEmail}
                  error={Boolean(emailError)}
                  helperText={emailError}
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label',
                    shrink: 'true'
                  }}
                  InputProps={{}}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    fullWidth
                    id="Work phone"
                    name="Work phone"
                    label="WORK PHONE"
                    type="text"
                    placeholder="777-7777-777"
                    onChange={(e) => SetWorkPhone(e.target.value)}
                    value={workPhone}
                    error={Boolean(workPhoneError)}
                    helperText={workPhoneError}
                    className="input-field-styling"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee',
                      shrink: 'true'
                    }}
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    fullWidth
                    id="homePhoneNumber"
                    name="Home phone"
                    label="HOME PHONE"
                    className="input-field-styling"
                    type="text"
                    placeholder="777-7777-777"
                    onChange={(e) => SetHomePhone(e.target.value)}
                    value={homePhone}
                    error={Boolean(homePhoneError)}
                    helperText={homePhoneError}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee',
                      shrink: 'true'
                    }}
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid> */}
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  id="Mobile number"
                  name="Mobile number"
                  label="MOBILE NUMBER"
                  className="input-field-styling required-field"
                  type="text"
                  placeholder="777-777-7777"
                  // onChange={(e) => SetMobileNumber(e.target.value)}
                  onChange={SetMobileNumber}
                  value={mobileNumber}
                  error={Boolean(mobileNumberError)}
                  helperText={mobileNumberError}
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label',
                    shrink: 'true'
                  }}
                  inputProps={{ maxLength: 12 }}
                />
              </Grid>
              {/* <Grid
              item
              xs={12}
              sm={4}
              md={4}
              className="form-datepicker-container"
            >
              <CustomDatePicker
                label="DATE OF BIRTH"
                onChange={SetDateOfBirth}
                // minDate={minDateValue}
                fieldValue={clientDob}
              // maxDate={currentDate}
              // className="input-field-styling"
              />
            </Grid> */}

              <Grid
                item
                xs={12}
                sm={1.5}
                md={1.5}
                sx={
                  {
                    // marginLeft: '10px'
                  }
                }
              >
                <Avatar
                  src={imgUrlClient !== '' ? imgUrlClient : null}
                  sx={{ height: '90px', width: '90px' }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2.5}
                md={2.5}
                sx={
                  {
                    // marginLeft: '20px'
                  }
                }
              >
                <Box>
                  <Typography className="check-box-text">
                    Edit Profile Image
                  </Typography>
                  <input
                    accept="image/*"
                    multiple
                    type="file"
                    id="select-file"
                    name="profileImage"
                    style={{ display: 'none' }}
                    onChange={(event) => handleImageUploadClient(event)}
                  />
                  <label htmlFor="select-file">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload-image common-button"
                      sx={{ marginTop: '24px' }}
                    >
                      <Typography className="upload-text">Upload </Typography>
                    </Button>
                  </label>
                </Box>
                {profileImageError && (
                  <FormHelperText>{profileImageError}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  error={referralResourceError}
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling"
                  >
                    REFERRAL SOURCES
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className="input-field-styling"
                    label="REFERRAL SOURCES"
                    value={referralResource}
                    onChange={(e) => SetReferalSource(e.target.value)}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 200 } }
                    }}
                  >
                    {referalSourceList &&
                      referalSourceList.map((item) => (
                        <MenuItem
                          key={item.sortKey}
                          value={item.referralSourceName}
                        >
                          {item.referralSourceName}
                        </MenuItem>
                      ))}
                  </Select>
                  {referralResourceError && (
                    <FormHelperText error>
                      {referralResourceError}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 700,
                  color: '#003087',
                  textDecoration: 'underline',
                  marginRight: '5px'
                  // display: 'none'
                }}
                className="add-new-category align-right"
              >
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    addNewReferralSource();
                  }}
                  onKeyDown={() => {
                    addNewReferralSource();
                  }}
                  role='button'
                  tabIndex={0}
                >
                  +Add New Referral Source
                </span>
              </Typography> */}
              </Grid>
              {isExistingClient === 'client' && (
                <Grid item xs={12} sm={4} md={4}>
                  {/* <Box marginTop={1}> */}
                  <Autocomplete
                    disablePortal
                    value={existingClient ?? null}
                    classes={autoCompleteClasses}
                    onChange={(event, selectedOptions) => {
                      changeExistingClient(selectedOptions);
                    }}
                    className="status-field field-label-css search-field-autocss"
                    options={existingClientList}
                    noOptionsText={
                      clientLoading ? 'Loading...' : 'No client found'
                    }
                    getOptionLabel={
                      (option) => {
                        return (
                          option &&
                          option?.firstName &&
                          option?.lastName &&
                          option.firstName + ' ' + option.lastName
                        );
                      }
                      // option.firstName
                      // +
                      // ' ' +
                      // (option.lastName && option.lastName)
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.sortKey}>
                          {option.firstName} {option.lastName}
                        </li>
                      );
                    }}
                    sx={{
                      width: '100%'
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <SearchIcon />
                        }}
                        label={params?.label || 'SEARCH CLIENT'}
                        className="input-field-styling"
                        InputLabelProps={{
                          className: 'input-field-dropdown-styling'
                        }}
                        value={searchValue}
                        autoComplete="off"
                        onChange={handleSearchClient}
                      />
                    )}
                  />
                  {/* </Box> */}
                </Grid>
              )}
              {isExistingClient === 'businessEntity' && (
                <Grid item xs={12} sm={4} md={4}>
                  {/* <Box marginTop={1}> */}
                  <Autocomplete
                    disablePortal
                    value={businessEntity ?? null}
                    // value={businessEntity?.businessName}
                    classes={autoCompleteClasses}
                    className="status-field field-label-css search-field-autocss"
                    options={businessEntityList}
                    getOptionLabel={(option) => option.businessName}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.sortKey}>
                          {option.businessName}
                        </li>
                      );
                    }}
                    onChange={(event, selectedOptions) => {
                      changeBusinessEntity(selectedOptions);
                    }}
                    // options={businessEntityList && businessEntityList.map((option) => option.businessName)}
                    sx={{
                      width: '100%'
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={params?.label || 'SEARCH BUSINESS ENTITY'}
                        className="input-field-styling"
                        InputLabelProps={{
                          className: 'input-field-dropdown-styling'
                        }}
                      />
                    )}
                  />
                  {/* </Box> */}
                </Grid>
              )}

              {/* <Grid container spacing={4} xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling"
                  >
                    STATUS
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="STATUS"
                    className="input-field-styling"
                    value={selectedStatus}
                    onChange={(e) => handleStatusChange(e.target.value)}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}

              <Grid item xs={12} sm={12} md={12}>
                <Typography className="heading-text">
                  Client Address Detail
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="Address"
                  autocomplete="Address"
                  label="ADDRESS 1"
                  placeholder="Address 1"
                  value={address}
                  onChange={(e) => SetAddress(e.target.value)}
                  error={Boolean(addressError)}
                  helperText={addressError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee',
                    shrink: 'true'
                  }}
                  InputProps={{}}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="Address"
                  value={address1 ?? ''}
                  autocomplete="Address"
                  label="ADDRESS 2"
                  placeholder="Address 2"
                  defaultValue={address1}
                  onChange={(e) => SetAddress1(e.target.value)}
                  className="input-field-styling"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee',
                    shrink: 'true'
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  error={stateError}
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling"
                  >
                    STATE
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className="input-field-styling"
                    label="STATE"
                    value={selectedState}
                    onChange={(e) => SetState(e.target.value)}
                    error={Boolean(stateError)}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 200 } }
                    }}
                  >
                    {setstate.map((state) => (
                      <MenuItem key={state.state} value={state.state}>
                        {state.state}
                      </MenuItem>
                    ))}
                  </Select>
                  {stateError && (
                    <FormHelperText error>{stateError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                {/* <FormControl
                        error={cityError}
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          CITY
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="CITY"
                          value={selectedCity}
                          onChange={(e) => SetCity(e.target.value)}
                          error={Boolean(cityError)}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } }
                          }}
                        >
                          {cities.map((city) => (
                            <MenuItem key={city.city} value={city.city}>
                              {city.city}
                            </MenuItem>
                          ))}
                        </Select>
                        {cityError && (
                          <FormHelperText error>{cityError}</FormHelperText>
                        )}
                      </FormControl> */}
                <TextField
                  fullWidth
                  label="CITY"
                  placeholder="CITY"
                  name="city"
                  onChange={(e) => SetCity(e.target.value)}
                  value={selectedCity}
                  error={Boolean(cityError)}
                  helperText={cityError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee',
                    shrink: 'true'
                  }}
                  inputProps={{}}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  fullWidth
                  name="Zipcode"
                  autocomplete="Zipcode"
                  label="ZIP CODE"
                  placeholder="Zipcode"
                  value={zipcode}
                  onChange={(e) => SetZipCode(e.target.value)}
                  error={Boolean(zipcodeError)}
                  helperText={zipcodeError}
                  className="input-field-styling "
                  InputLabelProps={{
                    className: 'label-style-create-franchisee ',
                    shrink: 'true'
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength: 5
                    }
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12}>
              <Typography className="heading-text">
                Emergency Contact Person Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                fullWidth
                name="name"
                autocomplete="Name"
                label="NAME"
                placeholder="Name"
                value={name}
                onChange={(e) => SetName(e.target.value)}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee',
                  shrink: 'true'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                fullWidth
                name="Email"
                autocomplete="Email"
                label="EMAIL"
                placeholder="Email"
                value={emailEmergancy}
                onChange={(e) => SetEmailEmergancy(e.target.value)}
                error={Boolean(emailEmergancyError)}
                helperText={emailEmergancyError}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee',
                  shrink: 'true'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                fullWidth
                id="Phone"
                name="Phone"
                label="PHONE"
                className="input-field-styling"
                type="text"
                placeholder="777-777-7777"
                onChange={(e) => SetPhone(e.target.value)}
                value={phone}
                helperText={emergencyPhoneError}
                InputLabelProps={{
                  className: 'label-style-create-franchisee',
                  shrink: 'true'
                }}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                fullWidth
                name="Relationship"
                autocomplete="Relationship"
                label="RELATIONSHIP"
                placeholder="Relationship"
                value={relationship}
                onChange={(e) => SetRelationship(e.target.value)}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee',
                  shrink: 'true'
                }}
                InputProps={{}}
              />
            </Grid> */}
              {/* <Grid item xs={12} sm={4} md={4}>
                      <Box
                        className="Profile-parent"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <label
                          htmlFor="select-parent-file"
                          className="label-select"
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          ATTACH PARENT PROFILE
                        </label>
                        <input
                          type="file"
                          accept=".pdf, .jpg, .heif, .hevc"
                          hidden
                          id="select-parent-file"
                          onChange={handleFileUpload}
                        />
                      </Box>
                    </Grid> */}
              <Grid item xs={12} sm={12} md={12}>
                <Typography className="heading-text">
                  Liability Waiver
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {/* <TextField
                sx={{ borderRadius: '10px', padding: '5px' }}
                fullWidth
                multiline
                id="my-textarea"
                name="Description"
                value=""
                // onChange={(e) => SetLiabilityWaiver(e.target.value)}
                placeholder="Notes here . . . "
                variant="outlined"
                readonly
                className="field-top-margin-edit-location "
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{ rows: 4 }}
              /> */}
                <Box
                  sx={{ borderRadius: '10px', padding: '5px' }}
                  fullWidth
                  multiline
                  id="my-textarea"
                  name="Description"
                  dangerouslySetInnerHTML={{ __html: liabilityWaiver }}
                  className="liability-section-container"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className="signature-text">
                  By signing here you agree to Zoom Room's liability waiver and
                  terms and conditions.
                </Typography>
              </Grid>
              <Box
                sx={{
                  marginTop: '10px',
                  marginLeft: '12px',
                  width: '100%'
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    {imageURL ? (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        className="location-details-col"
                      >
                        <Box
                          className="location-details-value location-image"
                          sx={{
                            height: '108px',
                            width: '100%',
                            border: '2px solid rgba(0, 48, 135, 0.5)',
                            borderRadius: '10px',
                            padding: '15px',
                            float: 'left',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            src={imageURL}
                            alt="my signature"
                            style={{
                              maxHeight: '100%',
                              maxWidth: '100%',
                              objectFit: 'cover'
                            }}
                          />
                        </Box>
                      </Grid>
                    ) : (
                      <SignaturePad
                        canvasProps={{
                          className: 'signatureCanvas',
                          style: {
                            height: '103px',
                            width: '450px',
                            border: '2px solid rgba(0, 48, 135, 0.5)',
                            borderRadius: '10px',
                            padding: '15px',
                            marginLeft: '10px',
                            marginRight: '20px',
                            float: 'left'
                          }
                        }}
                        penColor={'#003087'}
                        backgroundColor={'#fff'}
                        ref={signatureCanvasRef}
                      />
                    )}

                    {imageURL === '' && (
                      <Button
                        sx={{
                          border: '2px solid #003087 !important',
                          color: '#003087 !important',
                          display: 'inline-block',
                          marginTop: '54px'
                        }}
                        variant="outlined"
                        className="btn-primary common-butto"
                        onClick={handleSignature}
                      >
                        {' '}
                        Clear{' '}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{ mt: { xs: 0, md: 0 } }}
                  variant="contained"
                  type="submit"
                  className="btn-primary common-button"
                >
                  NEXT
                </Button>
              </Box> */}
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'end'
              }}
            >
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                type="submit"
                className="btn-primary common-button"
              >
                Update
              </Button>
            </Box>
            <Grid item xs={12} sm={6}>
              {showError && <Box className="error-message">{errorMessage}</Box>}
            </Grid>
          </Box>
        </Grid>
      </>

      {/* Create Category dialog */}
      <Dialog
        open={openAddReferralSource}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Referral Source'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="SOURCE NAME"
                    name="sourceName"
                    className="location-field input-field-styling "
                    onChange={(e) => setSourceName(e.target.value)}
                    // required
                    value={sourceName}
                    error={Boolean(sourceNameError)}
                    helperText={sourceNameError}
                    variant="outlined"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="SOURCE EMAIL"
                    name="sourceEmail"
                    className="location-field input-field-styling "
                    onChange={(e) => SetSourceEmail(e.target.value)}
                    required
                    value={sourceEmail}
                    error={Boolean(sourceEmailError)}
                    helperText={sourceEmailError}
                    variant="outlined"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                  />
                </Grid> */}
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateReferralSource()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
        className={!open ? 'closeEditClient' : ''}
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Save Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Please confirm that you want to save your changes?
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  {/* The client has been updated successfully! */}
                  The client details have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() =>
              isUpdated ? handleClose('Discard') : setOpen(false)
            }
          >
            {isUpdated ? 'Close' : 'Go Back to Editing'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* confirmation dialog */}

      <Dialog
        open={openConfirmPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
        className={!openConfirmPopup ? 'closeEditClient' : ''}
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?{' '}
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog
        open={openLiabilitySigned}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfClose('Close')}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle className="alert-title align-center">
          {'Delete'}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-su font-13">
                The client cannot buy any services unless
                <Typography className="title-text-su font-13">
                  they have signed the liability waiver. Do you
                </Typography>
                <Typography className="title-text-su font-13">
                  want to add the client without signing the
                </Typography>
                <Typography className="title-text-su font-13">
                  liability waiver?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleConfClose('Close')}
          >
            Yes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleConfClose('Cancel')}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditClient;
