import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Typography
} from '@mui/material';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const HolidayConfirmationDialog = ({
  openAddTimeOffDialog,
  handleAddHoliday,
  handleCancelHoliday,
  holidayCancelled,
  setOpenAddTimeOffDialog
}) => {
  const handleClose = () => {
    setOpenAddTimeOffDialog(true);
  };
  return (
    <Dialog
      open={openAddTimeOffDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose('Cancel')}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="alert-title align-center">
        Save Changes?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription align-center"
        >
          <Box display="flex" justifyContent="center" direction="column">
            {!holidayCancelled ? (
              <Typography className="title-text-sub">
                Please confirm that you want to add your holiday?
              </Typography>
            ) : (
              <Typography className="title-text-sub">
                Please confirm that you want to cancel your holiday?
              </Typography>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className="alert-button-control">
        <Button
          className="font-17 no-hover"
          onClick={() =>
            !holidayCancelled
              ? handleAddHoliday('Yes')
              : handleCancelHoliday('Yes')
          }
        >
          Confirm
        </Button>
        <Divider className="vertical-divider" />
        <Button
          className="font-17 cancel-btn no-hover"
          onClick={() =>
            !holidayCancelled
              ? handleAddHoliday('No')
              : handleCancelHoliday('No')
          }
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HolidayConfirmationDialog;
