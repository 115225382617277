/* eslint-disable no-unused-vars */
import {
  Box,
  Stack,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button
} from '@mui/material';
import React, { forwardRef } from 'react';
import { CustomButton, Toggle } from '../Global';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ArrowLeft } from '../../assets/SVG/SvgIcons';
import { getLocalData } from 'src/utils';
import Loader from '../Loader';
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const OrderReceipt = (props) => {
  const {
    handleResetStates,
    userEmail,
    allSuppliers,
    orderDetails,
    handleEndDetails,
    endNotes,
    handleOpenScreen,
    isToggleOne,
    isToggleTwo,
    setShowErrorMessage,
    setErrorMessage,
    setShowSuccessMessage,
    setSuccessMessage
  } = props;

  // console.log(allSuppliers);
  const supplierEmail = allSuppliers.filter(
    (items) => items.id === orderDetails.supplier
  );
  // console.log(supplierEmail, 'supplierEmail', userEmail, "handleSummaryClose", endNotes, orderDetails);

  const currentUser = JSON.parse(localStorage.getItem('UsersDetail'));
  const currentUserEmail = currentUser?.email;
  const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;
  const locationId = getLocalData('locationId');
  const [loading, setIsLoading] = React.useState(false);

  // Method to close the order receipt on back button click
  const handleSummaryClose = () => {
    handleResetStates();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  const sendOrderPDFToSelfAndSupplier = async () => {
    // Show loader
    setIsLoading(true);

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      let isSendToSupplier = endNotes.shareSupplierMail;
      let isSendToSelf = endNotes.shareSelfMail;
      let selfEmail = userEmail || currentUserEmail;
      let poId =
        orderDetails &&
        orderDetails?.purchaseOrderId &&
        orderDetails?.purchaseOrderId;
      if (poId && !poId.includes('#PO#') && poId !== 'undefined') {
        poId = '#PO#' + poId;
      }
      let encodedOrderId = poId && encodeURIComponent(poId);
      const responseData = await ApiService.get(
        `${BASE_URL}purchase-orders/mailSentToselfAndSupplier?locationId=${encodedLocationId}&id=${encodedOrderId}&sendMe=${isSendToSelf}&sendToSupplier=${isSendToSupplier}&sendToMe=${selfEmail}`
      );

      if (!responseData?.error) {
        setSuccessMessage(
          'Purchase order pdf receipt has been sent successfully!'
        );
        handleSummaryClose();
        // hide loader
        setIsLoading(false);
      } else {
        setErrorMessage(
          responseData?.message ||
            'Something went wrong with send order pdf to self and supplier!'
        );
        // hide loader
        setIsLoading(false);
        handleSummaryClose();
      }
    } catch (error) {
      // hide loader
      setIsLoading(false);
      handleSummaryClose();
      setErrorMessage(
        'Something went wrong with send order pdf to self and supplier!'
      );
    }
  };

  return (
    <div className="service-container order-container">
      <Loader IsLoading={loading} />
      <Box
        sx={{ width: '100%' }}
        paddingBottom="20px"
        className="Main-container common-form-container"
      >
        <Stack
          direction="column"
          className="container"
          // marginTop="50px"
          paddingBottom="20px"
          borderBottom="1px solid #e0e0e0"
        >
          <Typography
            variant="variant2"
            sx={{
              fontSize: '32px',
              color: '#000',
              textAlign: 'left',
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              cursor: 'pointer'
            }}
          >
            {/* <KeyboardBackspaceIcon
              color="primary"
              sx={{
                marginTop: '2px',
                width: '30px',
                height: '30px',
                cursor: 'pointer'
              }}
              onClick={handleSummaryClose}
              className="font-myriad"
            />
            {`Order ${orderDetails.number} Generated Successfully!`} */}
            <Box onClick={handleSummaryClose} className="back-arrow-clse">
              <ArrowLeft />
            </Box>

            <Typography
              className=""
              sx={{
                fontSize: '32px'
              }}
            >
              {`Order`}{' '}
              <span className="primary-color"> {orderDetails.number} </span>
              {`Generated Successfully!`}
            </Typography>
          </Typography>

          <Stack
            marginTop="12px"
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Typography
              variant="variant2"
              sx={{ fontSize: '18px', color: '#000' }}
              className="font-myriad"
            >
              {orderDetails.title}
            </Typography>

            <Typography
              variant="variant2"
              sx={{ fontSize: '16px', color: '#807b7b', fontWeight: '500' }}
              className="font-myriad"
            >
              {formattedDate}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="column" className="container" marginTop="30px">
          <Typography
            variant="variant2"
            sx={{ fontSize: '24px', textAlign: 'left' }}
            className="detail-title font-myriad"
          >
            Share Order
          </Typography>

          <Stack marginY="8px" marginTop="12px" direction="column" width="100%">
            <Typography
              variant="variant2"
              sx={{ fontSize: '18px', color: '#000', textAlign: 'left' }}
              className="font-myriad"
            >
              TO MYSELF
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              backgroundColor="#eef5ff"
              marginY="16px"
              border="1px solid #e0e0e0"
              borderRadius="12px"
            >
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                paddingX="16px"
                paddingY="12px"
                className="order-recipt-stack"
              >
                <Stack direction="column">
                  <Typography
                    variant="variant2"
                    marginBottom="8px"
                    sx={{ fontSize: '15px', color: '#000' }}
                    className="font-myriad"
                  >
                    Send copy to myself
                  </Typography>

                  <Typography
                    variant="variant2"
                    sx={{
                      fontSize: '14px',
                      color: '#807b7b',
                      fontWeight: '400'
                    }}
                    className="font-myriad"
                  >
                    {userEmail || currentUserEmail}
                  </Typography>
                </Stack>

                <Toggle
                  name="shareSelfMail"
                  value={endNotes.shareSelfMail}
                  onChange={handleEndDetails}
                />
              </Stack>
            </Box>
          </Stack>

          <Stack marginY="8px" direction="column" width="100%">
            <Typography
              variant="variant2"
              sx={{ fontSize: '18px', color: '#000', textAlign: 'left' }}
              className="font-myriad"
            >
              TO SUPPLIER
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              backgroundColor="#eef5ff"
              marginY="16px"
              border="1px solid #e0e0e0"
              borderRadius="12px"
            >
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                paddingX="16px"
                paddingY="12px"
                className="order-recipt-stack"
              >
                <Stack direction="column">
                  <Typography
                    variant="variant2"
                    marginBottom="8px"
                    sx={{ fontSize: '15px', color: '#000' }}
                    className="font-myriad"
                  >
                    Send copy to supplier
                  </Typography>

                  <Typography
                    variant="variant2"
                    sx={{
                      fontSize: '14px',
                      color: '#807b7b',
                      fontWeight: '400'
                    }}
                    className="font-myriad"
                  >
                    {supplierEmail[0]?.businessEmail}
                  </Typography>
                </Stack>
                <Toggle
                  name="shareSupplierMail"
                  value={endNotes.shareSupplierMail}
                  onChange={handleEndDetails}
                />
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <Stack direction="column" className="container" marginTop="12px">
          <TextField
            id="outlined-multiline-static"
            label="NOTES"
            className="font-myriad"
            multiline
            rows={10}
            value={endNotes.notes}
            onChange={(e) => handleEndDetails('notes', e.target.value)}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
          marginTop={5}
        >
          <CustomButton
            variant="outlined"
            className="font-myriad"
            onClick={handleSummaryClose}
          >
            Close
          </CustomButton>

          {!isToggleOne && !isToggleTwo ? (
            <CustomButton variant="contained" disabled className="font-myriad">
              Confirm & Share
            </CustomButton>
          ) : (
            <CustomButton
              variant="contained"
              onClick={sendOrderPDFToSelfAndSupplier} // handleSummaryClose
              className="font-myriad"
            >
              Confirm & Share
            </CustomButton>
          )}
          {/* <CustomButton variant="contained" onClick={handleSummaryClose} className="font-myriad">
            Confirm & Share
          </CustomButton> */}
        </Stack>
      </Box>
    </div>
  );
};
