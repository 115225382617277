import React, { useState, useRef, useEffect} from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  // Avatar,
  Button,
  Grid,
  // CardHeader
} from '@mui/material';
import Loader from '../Loader';
import AddRoom from './AddRoom';
import { getLocalData } from '../../utils';
import { ApiService } from 'src/services/ApiService';

// Apply franchisee filter
const applyFilters = (roomsLists, searchText) => {
  // Commented the above code and added the below code for search functionality
  let arrayOfMatchedObjects = roomsLists.filter((object) => {
    return JSON.stringify(object).toString().toLowerCase().includes(searchText);
  });
  return arrayOfMatchedObjects;
};

// Apply paination on table
const applyPagination = (roomsLists, page, limit) => {
  // if(page === 0) {
  //   limit = roomsLists.totalCount;
  // }
  return roomsLists.slice(page * limit, page * limit + limit);
};

// Main methdo to draw the franchisee table
// handleOpenScreen
const AllRooms = () => {
  const childRef = useRef(null);
  const [selectedRoomLists] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchText] = useState(''); // New state for search functionality
  const [isAddRoomOpen, setIsAddRoomOpen] = useState(false);
  /* State for opening of add team dialog box */
  const [openAddRoom, setOpenAddRoom] = useState(false);
  // all teams list
  const [roomsLists, setAllRooms] = React.useState([]);
  const locationId = getLocalData('locationId');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllRooms();
  }, []);

  // To change the page into the table
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // To change the list
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  // Apply filter from object and below is the pagination order
  const filteredRoomLists = applyFilters(roomsLists, searchText); // applyFilters(roomsLists, filters);
  const paginatedRoomLists = applyPagination(filteredRoomLists, page, limit);

  // Get All the teams method
  const getAllRooms = async () => {
    // Shwo loader
    setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationId);
   
    try{
      const data = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}rooms/getAllRooms?locationId=${encodedLocationId}`)
        if (data?.statusCode && !data?.error) {
          setAllRooms(data?.data?.Items);
          setIsLoading(false);
        }
      }catch(error){
        console.error(error)
      }
  };


  // Methdo to add the team
  const addNewRoom = () => {
    // Now set true to add team and false to all teams
    // setAddTeam(true);
    setIsAddRoomOpen(true);
  };
  // const handleCloseTeam = () =>{
  //   setShowScreen(1);
  //   // handleOpenScreen(1);
  // }

  // HTML rendering started
  return (
    <>
    <Loader IsLoading={isLoading} />
    <Grid>
      <Grid container spacing={2}>
        <Grid width={1} item ml={3} mr={3}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{mb:4,  mt:1}}>

          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between',ml:'10px'}}>

              {/* <Box
                onClick={() => handleCloseTeam()}
                className="back-arrow-close">
           
                <ArrowLeft sx={{ mt: 3 }} />
              </Box> */}
              <Typography className="header-blue-32">
              All Rooms
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Button
                variant="contained"
                className="common-button"
                onClick={addNewRoom}
              >
                <Typography>Add Room</Typography>
              </Button>
            </Box>
          </Grid>
          </Grid>
          {/* Add new team condition block */}
          <Card className="p-15">
            
            {/* <Divider /> */}
            
            <TableContainer className="franchise-table-container">
              <Table>
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell sx={{minWidth: "250px"}}>Room Name</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedRoomLists.map((roomItem) => {
                    const isRoomItemSelected = selectedRoomLists.includes(
                      roomItem.roomId
                    );
                    return (
                      <TableRow
                        hover
                        key={roomItem.roomId}
                        selected={isRoomItemSelected}
                        className="table-row cursor-pointer"
                      >
                        <TableCell className="table-td d-flex">
                         
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            sx={{ marginTop: 1 }}
                            gutterBottom
                          >
                            {/* <Typography>
                              <Avatar
                                alt="icon"
                                src="/static/images/logo/usergrp.png"
                                sx={{ marginRight: 2, float: 'left' }}
                                className="card-avatar-img"
                              />
                            </Typography> */}
                            <Typography> {roomItem.name}</Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                          >
                            {roomItem.description}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredRoomLists.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 30]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
    {isAddRoomOpen ? (
      <AddRoom ref={childRef} getAllRooms={getAllRooms} openAddRoom={openAddRoom} setOpenAddRoom={setOpenAddRoom} isAddRoomOpen={isAddRoomOpen} setIsAddRoomOpen={setIsAddRoomOpen} />
    ):null}
    </>
  );
};

export default AllRooms;
