/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Hidden,
  Stack,
  Typography,
  CircularProgress,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { CustomButton, SearchInput } from 'src/components/Global';

import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../components/Loader';
import { getLocalData, setFullLocationId } from 'src/utils';
import { Summary } from './Summary';
import GlobalData from 'src/global';
import Header from 'src/components/Header';
import { BusinessEntitiesTable } from './BusinessEntitiesTable';
import { BusinessEntitiesFilter } from './BusinessEntitiesFilter';
import AddBusinessEntities from './AddBusinessEntities';
import EditBusnessEntities from './EditBusinessEntities';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const BusinessEntities = () => {
  const locationId = setFullLocationId(getLocalData('locationId'));
  // change the header values
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Service Management');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Service Management';
  const title = GlobalData[0].title;
  // const BASE_URL = 'https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev';
  const userId = getLocalData('userId');
  const [loading, setLoading] = useState(true);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allPreReq, setAllPreReq] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [serviceDetails, setServiceDetails] = useState();
  const [isServiceEdit, setIsServiceEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);

  const [filters, setFilters] = useState({
    // isActive: null,
    status: null,
    businessEntityType: [],
    typeOfService: null,
    difficultyLevel: [],
    isFree: null,
    cost: null
  });

  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);

  useEffect(() => {
    const data = servicesData?.filter((item) =>
      selectedRows?.includes?.(item.id)
    );
    setSelectedRowsData(data);
  }, [selectedRows]);

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
    setIsServiceEdit(false);
    setServiceDetails();
    setSelectedRows([]);
    setSelectedRowsData([]);
    setSelectedStatus('');

    //call api for getting latest data of services
    getData();
    getAllBusinessEntities();
  };

  function findObjectById(id) {
    return servicesData?.find((obj) => obj.sortKey === id);
  }

  const openDetail = (id, screen, e) => {
    e.preventDefault();
    e.stopPropagation();

    setServiceDetails(findObjectById(id));
    setIsServiceEdit(true);
    handleOpenScreen(screen);
  };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  //   ===============call api here=============

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === -1 ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined ||
        (Array.isArray(filtersParams[key]) && filtersParams[key].length === 0)
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getAllBusinessEntities = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    setLoading(true);
    setTableLoading(true);
    let queryString = filtersQuery();

    try {
      const [servicesResponse] = await Promise.all([
        ApiService.get(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?locationId=${encodedLocationId}&${queryString}`
        )
      ]);

      if (!servicesResponse?.error) {
        const modifiedData = servicesResponse?.data?.Items?.map((item) => ({
          ...item,
          id: item.serviceId
        }));
        setServicesData(modifiedData);
        setLoading(false);
      } else {
        setLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          servicesResponse.message || 'Something went wrong with Services'
        );
      }
      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const getData = async () => {
    setTableLoading(true);

    try {
      const [categoriesResponse] = await Promise.all([
        ApiService.get(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntityTypes`
        )
      ]);

      if (!categoriesResponse?.error) {
        setAllCategories(categoriesResponse?.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          categoriesResponse.message ||
            'Something went wrong with get all business types'
        );
      }

      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  useEffect(() => {
    getAllBusinessEntities();
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    getData();
  }, []);

  function getServiceIds() {
    let array = [];
    selectedRowsData?.map((item) => array.push(item.sortKey));

    return array;
  }

  const handleButtonClick = () => {
    handleOpenScreen(2);
  };
  const buttons = [
    {
      label: 'Add Business Entity',
      onClick: handleButtonClick,
      theam: true
    }
  ];

  console.log(serviceDetails, 'servicedetails');
  return (
    <>
      <Helmet>
        <title>Business Entities | {title}</title>
      </Helmet>
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          getDetails={getAllBusinessEntities}
        />
      )}

      <Container maxWidth="100%" className="custom-container">
        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <Box justifyContent="center" direction="column">
                  <AddBusinessEntities
                    handleOpenScreen={handleOpenScreen}
                    handleResetStates={handleResetStates}
                    isServiceEdit={isServiceEdit}
                    setIsServiceEdit={setIsServiceEdit}
                    allCategories={allCategories}
                    allLevels={allLevels}
                    allPreReq={allPreReq}
                    serviceDetails={serviceDetails}
                    setShowScreen={setShowScreen}
                    refreshData={getAllBusinessEntities}
                  />
                </Box>
              );

            case 3:
              return (
                <Box justifyContent="center" direction="column">
                  <Summary
                    handleOpenScreen={openDetail}
                    handleResetStates={handleResetStates}
                    serviceDetails={serviceDetails}
                    allPreReq={allPreReq}
                    setShowScreen={setShowScreen}
                  />
                </Box>
              );

            case 4:
              return (
                <Box justifyContent="center" direction="column">
                  <EditBusnessEntities
                    data={serviceDetails}
                    // openProductEditPopup={openProductEditPopup}
                    // setopenProductEditPopup={setopenProductEditPopup}
                    // refreshProductData={refreshProductData}
                    setShowScreen={setShowScreen}
                    refreshData={getAllBusinessEntities}
                  />
                </Box>
              );

            default:
              return (
                <Box justifyContent="center" direction="column">
                  {/* <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    gap="16px"
                  >
                    <Grid item>
                      <CustomButton
                        text="Add New"
                        variant="contained"
                        onClick={() => handleOpenScreen(2)}
                      >
                        Add New
                      </CustomButton>
                    </Grid> */}
                  {/* <Grid item>
                      <CustomButton variant="outlined">Manage</CustomButton>
                    </Grid> */}
                  {/* </Grid> */}
                  <Header title="Business Entities" buttons={buttons} />
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item marginY="20px">
                            <CustomButton
                              onClick={() => setIsFilters(!isFilters)}
                              variant={isFilters ? 'contained' : 'outlined'}
                            >
                              Filters
                              <KeyboardArrowDownRoundedIcon
                                className={clsx(
                                  !isFilters && classes.closeY,
                                  isFilters && classes.openY
                                )}
                              />
                            </CustomButton>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            marginY={{ sm: '20px' }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <SearchInput
                              value={searchQuery}
                              setSearchQuery={setSearchQuery}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <BusinessEntitiesFilter
                            filters={filters}
                            setFilters={setFilters}
                            allCategories={allCategories}
                            allLevels={allLevels}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <BusinessEntitiesTable
                          tableRows={servicesData}
                          searchQuery={searchQuery}
                          openDetail={openDetail}
                          loading={tableLoading}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          selectedStatus={selectedStatus}
                          setSelectedStatus={setSelectedStatus}
                          btnLoading={btnLoading}
                        />
                      </Grid>
                    </>
                    {/* )} */}
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>
    </>
  );
};

export default BusinessEntities;
