import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Avatar,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { ApiService } from '../../../services/ApiService';
// import { Helmet } from 'react-helmet-async';
// import GlobalData from '../../../global';
import Loader from '../../../components/Loader';
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons';
import { getLocalData } from 'src/utils';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const UserDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

// LocationDetails page main method
const UserDetails = ({
  data,
  refreshUserList,
  setShowScreen,
  setSearchText
}) => {
  const navigate = useNavigate();
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let userDetails = data;
  console.log(userDetails);
  const [open, setOpen] = React.useState(false);
  // Get the loggedIn user Id
  const userId = getLocalData('userId');
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);

  // get the id from the url
  // let id = searchParams.get("Id");
  // const hasRunRef = useRef(false);
  // const theme = useTheme();

  // Method to open the delete dialog
  // const handleClickOpen = () => {
  //   setShowScreen(2);
  // };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      // call the api to delete the user
      deleteUser();
    }
    setOpen(false);
  };

  const handleCloseDialog = (val) => {
    if (val === 'Close') {
      setOpenMessageDialog(false);
      setShowScreen(0);
      setSearchText('');
      refreshUserList();
    }
  };

  const confirmDelete = () => {
    setOpen(true);
  };

  // Method to open the edit dialog
  const handleClickEdit = () => {
    setShowScreen(2);
  };

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return ''; // Handle empty input gracefully
    }
    let date;
    let month;
    let year;
    inputDate = new Date(inputDate);
    if (Number.isNaN(inputDate.getTime())) {
      return ''; // Handle invalid date input gracefully
    }
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${month}-${date}-${year}`;
  };

  // useeffect state starts here
  useEffect(() => {
    // if (id) setSearchParams({ Id: id });

    // check if the user is logged in or not
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [userDetails, navigate]);

  // Method to delete the User
  const deleteUser = async () => {
    let deletedUserId = userDetails?.sortKey;
    let body = { userId: deletedUserId, updatedBy: userId };
    if (userId && body) {
      // Now show the loader
      setIsLoading(true);
      // call the api to delete the User
      let url = `${process.env.REACT_APP_API_END_POINT}users/disableUser`;

      // execute the api
      try {
        const data = await ApiService.post(`${url}`, body);

        if (data) {
          setIsLoading(false);
          setOpenMessageDialog(true);
        }
      } catch (error) {
        console.error('Error:', error);
        // set error message
        setShowError(true);
        setErrorMessage(error?.message);
        setIsLoading(false);
      }
    }
  };

  const handleCloseDetails = () => {
    setShowScreen(0);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Helmet>
        <title>User Details</title>
      </Helmet>

      {/* {(length === 0) ? */}
      <UserDetailsWrapper
        maxWidth={'100%'}
        className="user-details-wrapper p-0"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">User Details</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className="details-remove-icon no-hover"
                onClick={confirmDelete}
              >
                <DeleteIcon />
              </Button>
              {/* Edit Button */}
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                id={userDetails.userId}
                onClick={handleClickEdit}
                className="btn-primary edit-button"
              >
                Edit
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid container className="user-header-container">
          <Grid
            xs={8}
            sm={8}
            md={8}
            lg={8}
            className="page-header title-adjust"
          >
            <span className="text-wrap">User Details</span>
          </Grid>
          {/* Delete icon and edit button */}
        {/* <Grid xs={4} sm={4} md={4} lg={4} className="align-right"> */}
        {/* remove icon */}
        {/* <Button className="details-remove-icon no-hover" onClick={handleClickOpen} >
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button> */}
        {/* Edit Button */}
        {/* <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              id={userDetails.userId}
              onClick={handleClickEdit}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid> */}
        {/* </Grid> */}

        <Box className="user-details-container">
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                NAME
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.firstName} {userDetails.lastName}{' '}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                EMAIL
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.email}
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3} className="details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                PHONE NUMBER
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.phoneNumber}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                WORK PHONE NUMBER
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails?.workPhoneNumber
                  ? userDetails?.workPhoneNumber
                  : ''}
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3} className="details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                GENDER
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.gender ?? ''}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                TEAM
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails?.teams[0]?.teamName
                  ? userDetails?.teams[0]?.teamName
                  : ''}
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3} className="details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                ROLE
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.roleName ?? ''}
              </Box>
            </Grid>

            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                PROFILE IMAGE
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                <Avatar
                  alt="icon"
                  src={userDetails.profileImage ?? ''}
                  sx={{ marginLeft: 2 }}
                  className="card-avatar-img"
                />
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                Status
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
               
                {userDetails.status === 1 && 'Active'}
                {userDetails.status === 0 && 'Inactive'}
                {userDetails.status === 2 && 'Deleted'}
              </Box>
            </Grid> */}
          </Grid>

          <Grid container mt={3} className="details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                ADDRESS
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.addressLine1} {userDetails.addressLine2},{' '}
                {userDetails.city}, {userDetails.state} {userDetails.postalCode}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                Status
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.status === 1 && 'Active'}
                {userDetails.status === 0 && 'Inactive'}
                {userDetails.status === 2 && 'Deleted'}
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                UPDATED AT
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.updatedAt ? formatDate(userDetails.updatedAt) : ''}
              </Box>
            </Grid> */}
          </Grid>
          <Grid container mt={3} className="details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                CREATED AT
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.createdAt ? formatDate(userDetails.createdAt) : ''}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                UPDATED AT
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.updatedAt ? formatDate(userDetails.updatedAt) : ''}
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3} className="details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                LAST LOGIN
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.createdAt
                  ? formatDate(userDetails.lastLoginTime)
                  : ''}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                DELETED AT
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {userDetails.deletedAt ? formatDate(userDetails.deletedAt) : ''}
              </Box>
            </Grid>
          </Grid>

          {showError && (
            <Grid
              container
              mt={3}
              className="details-row"
              style={{ marginTop: '0px' }}
            >
              <Box className="error-message">{errorMessage}</Box>
            </Grid>
          )}
        </Box>
      </UserDetailsWrapper>
      {/* : <div className="no-data">No details available</div>} */}

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover cancel-btn"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMessageDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseDialog('Close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected user has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseDialog('Close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDetails;
