import React from "react";
import { Button } from "@mui/material";
import styled from "styled-components";

export const Buttons = styled(({ isActive, ...rest }) => <Button {...rest} />)(
  ({ isActive }) => ({
    padding: "17px 20px",
    height: "50px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: "0.04em",
    borderRadius: "10px",
    cursor: "pointer",
    fontWeight: isActive ? "500" : "600",
    color: isActive ? "#ffffff" : "#003087",
    background: isActive ? "#003087" : "none",
    border: isActive ? "none" : "2px solid #003087",
    transition: "background 300ms ease-out",
    "&:hover": {
      background: isActive ? "#003087" : "rgba(0, 48, 135, 0.1)",
    },
    "&:focus": {
      outline: "none",
      boxShadow: `0 0 0 2px ${
        isActive ? "rgba(0, 48, 135, 0.5)" : "rgba(0, 48, 135, 0.3)"
      }`,
    },
  })
);
