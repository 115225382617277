import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
  DialogTitle,
  Slide,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ApiService } from '../../../services/ApiService';
import Loader from '../../../components/Loader';
import { formatPhoneNumber, getLocalData, setFullLocationId } from 'src/utils';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function EditBusnessEntities({
  data,

  setShowScreen,
  refreshData
}) {
  let userId = getLocalData('userId');
  // get user role and provide access of that roles
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [manufacturerUrl, setmanufacturerUrl] = useState('');
  const [zoomRoomProductName, setzoomRoomProductName] = useState('');
  const [varientType, setvarientType] = useState([]);

  const [productDescription, setproductDescription] = useState('');
  const [productCategoryList, setproductCategoryList] = useState([]);

  const [productId, setproductId] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [address2, setaddress2] = useState('');
  const [businessEntity, setBusinessEntity] = useState([]);
  const [businessEntityTypeId, setBusinessEntityTypeId] = useState('');
  const [businessEntityTypeName, setBusinessEntityTypeName] = useState('');
  const [stateType, setStateType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  /* States for getting and setting add product errors  */
  const [businessNameError, setBusinessNameError] = useState('');
  const [businessEmailerror, setBusinessEmailerror] = useState('');
  const [businessPhoneError, setBusinessPhoneError] = useState('');
  const [contactPhoneError, setContactPhoneError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [contactNameError, setContactNameError] = useState('');
  const [contactEmailError, setContactEmailError] = useState('');

  const [stateTypeError, setStateTypeError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [manufacturerUrlError, setmanufacturerUrlError] = useState('');

  const [businessEntityTypeError, setBusinessEntityTypeError] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);

  const [openAddVariant, setopenAddVariant] = useState(false);
  const [openAddCategory, setopenAddCategory] = useState(false);
  const [openAddVarientType, setopenAddVarientType] = useState(false);
  const [variantName, setvariantName] = useState('');
  const [categoryName, setcategoryName] = useState('');
  const [categoryDescription, setcategoryDescription] = useState('');
  const [varientTypeName, setvarientTypeName] = useState('');
  const [variantTypeId, setvariantTypeId] = useState('');

  const [openPopup, setOpenPopup] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getProducts();
    getCategory();
    getVariantType();
  }, [data]);

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
    }
    if (val === 'Discard') {
      setShowScreen(0);
      refreshData();
      setOpen(false);

      setproductId('');
    }
  };

  /* Function to close the Edit product dialog box */
  const handleCloseEditProduct = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);

      setOpen(false);
      setproductId('');
    }
  };

  // close variant dialog
  const handleCloseVariant = () => {
    setopenAddVariant(false);
    setopenAddCategory(false);
    setopenAddVarientType(false);
  };

  // create new variant
  const handleCreateVariantType = () => {
    if (varientTypeName) {
      setIsLoading(true);
      const data = {
        variantType: varientTypeName,
        createdBy: userId
      };
      createVariantType(data);
    }
  };

  // create variant type
  const createVariantType = async (data) => {
    try {
      await ApiService.post(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}/variants`,
        data
      ).then((response) => {
        if (!response?.error) {
          setvarientTypeName('');
          getVariantType();

          setopenAddVarientType(false);
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Method to set variant type
  const handleVariantTypeChange = (e) => {
    setvariantTypeId(e.target.value);
  };

  // Method to create new variant
  const handleCreateVariant = () => {
    if (variantName && variantTypeId) {
      setIsLoading(true);
      const data = {
        value: variantName,
        variantId: variantTypeId,
        createdBy: userId
      };
      createVariant(data);
    }
  };

  // create variant
  const createVariant = async (data) => {
    try {
      await ApiService.post(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}/variant-values`,
        data
      ).then((response) => {
        if (!response?.error) {
          setvariantName('');
          setvariantTypeId('');

          setopenAddVariant(false);
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateCategory = () => {
    if (categoryName && categoryDescription) {
      setIsLoading(true);
      const data = {
        categoryName: categoryName,
        description: categoryDescription,
        createdBy: userId
      };
      createCategory(data);
    }
  };

  // create category
  const createCategory = async (data) => {
    try {
      await ApiService.post(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}/category`,
        data
      ).then((response) => {
        if (!response?.error) {
          setcategoryName('');
          setcategoryDescription('');
          getCategory();

          setopenAddCategory(false);
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* Function to get category of products */
  const getCategory = async () => {
    try {
      setIsLoading(true);
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntityTypes`
      );
      setproductCategoryList(response.data.Items);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = () => {
    let businessEntitySet =
      data && data.businessEntityTypeId + '|' + data.businessEntityType;
    setIsUpdated(false);
    setBusinessName(data?.businessName);
    setBusinessEmail(data?.businessEmail);
    setBusinessPhone(data?.phone);
    setmanufacturerUrl(data?.businessUrl);
    setzoomRoomProductName(data?.addressLine1);
    setaddress2(data?.addressLine2);
    setCity(data?.city);
    setZipCode(data?.postalCode);
    setContactName(data?.contactName);
    setContactEmail(data?.contactEmail);
    setContactPhone(data?.contactPhone);
    setproductDescription(data?.notes);
    setStateType(data?.state);
    setBusinessEntityTypeId(data?.businessEntityTypeId);
    setBusinessEntityTypeName(data?.businessEntityType);
    setBusinessName(data?.businessName);
    setproductId(data?.sortKey);
    setBusinessEntity(businessEntitySet);
  };

  /* Function to get  states */

  const getVariantType = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`
      );
      if (!response?.error) {
        setvarientType(response?.data.Items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* Function to validate and check all field are filled */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!businessEntityTypeId) {
      setBusinessEntityTypeError(true);
    }

    if (!businessName) {
      setBusinessNameError('Business name is required');
    }
    if (businessName) {
      if (isFieldUpdated) {
        setOpenPopup(true);
      }
    }
  };

  const handleClosePopup = (val) => {
    if (val === 'Save') {
      editBusinessEntity();
      setOpenPopup(false);
    } else if (val === 'Discard') {
      setOpenPopup(false);
    }
  };

  /* Function to edit product details */
  const editBusinessEntity = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    let encodedproductId = encodeURIComponent(productId);
    setIsLoading(true);

    /* Json for add product  */
    const dataEditProduct = {
      businessEntityTypeId: businessEntityTypeId,
      businessEntityType: businessEntityTypeName,
      businessName: businessName,
      businessEmail: businessEmail,
      phone: businessPhone,
      businessUrl: manufacturerUrl,
      addressLine1: zoomRoomProductName,
      addressLine2: address2,
      state: stateType,
      city: city,
      postalCode: zipCode,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhone: contactPhone,
      notes: productDescription,
      updatedBy: userId,
      status: 1
    };

    try {
      let response = await ApiService.put(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/updateBusinessEntityById?locationId=${encodedLocationId}&id=${encodedproductId}`,
        dataEditProduct
      );
      if (response.statusCode === 200) {
        setOpen(true);
        setIsUpdated(true);
        setIsLoading(false);

        setIsFieldUpdated(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went wrong, Try again!');
      console.log(error);
    }
  };

  const businessNameValidate = (businessName) => {
    if (!businessName) {
      setIsFieldUpdated(true);
      setBusinessName('');
      setBusinessNameError('Business  name is required');
    } else {
      setIsFieldUpdated(true);
      setBusinessNameError('');
      setBusinessName(businessName);
    }
  };

  /* Function to set manufacturer contact name setting value */
  const contactNameValidate = (contactName) => {
    if (!contactName) {
      setIsFieldUpdated(true);
      setContactName('');
    } else {
      setIsFieldUpdated(true);
      setContactNameError('');
      setContactName(contactName);
    }
  };

  /* Function for validation of Business email  */
  const businessEmailValidate = (businessEmail) => {
    let manufacturerPattern = /\S+@\S+\.\S+/;
    if (!businessEmail) {
      setIsFieldUpdated(true);
      setBusinessEmailerror('');
      setBusinessEmail('');
    } else if (!manufacturerPattern.test(businessEmail)) {
      setIsFieldUpdated(false);
      setBusinessEmail(businessEmail);
      setBusinessEmailerror('Please enter valid email address');
    } else {
      setIsFieldUpdated(true);
      setBusinessEmailerror('');
      setBusinessEmail(businessEmail);
    }
  };

  /* Function for validation of contact email  */
  const contactEmailValidate = (contactEmail) => {
    let manufacturerPattern = /\S+@\S+\.\S+/;
    if (!contactEmail) {
      setIsFieldUpdated(true);
      setContactEmailError('');
      setContactEmail('');
    } else if (!manufacturerPattern.test(contactEmail)) {
      setIsFieldUpdated(true);
      setContactEmailError('Please enter valid email address');
      setContactEmail(contactEmail);
    } else {
      setIsFieldUpdated(true);
      setContactEmailError('');
      setContactEmail(contactEmail);
    }
  };

  /* Function for validation of Business phone  */
  const businessPhoneValidate = (contactPhone) => {
    let formatWorkPhoneNumber = formatPhoneNumber(contactPhone);
    if (!formatWorkPhoneNumber) {
      setIsFieldUpdated(true);
      setBusinessPhoneError('');
      setBusinessPhone('');
    } else if (formatWorkPhoneNumber.length !== 12) {
      setIsFieldUpdated(true);
      setBusinessPhoneError('Business phone should have exactly 10 digits');
      setBusinessPhone(formatWorkPhoneNumber.substring(0, 12)); // Truncate to 10 digits
    } else {
      setIsFieldUpdated(true);
      setBusinessPhoneError('');
      setBusinessPhone(formatWorkPhoneNumber);
    }
  };

  /* Function for validation of contact phone  */
  const contactPhoneValidate = (contactPhone) => {
    let formatWorkPhoneNumber = formatPhoneNumber(contactPhone);
    if (!formatWorkPhoneNumber) {
      setIsFieldUpdated(true);
      setContactPhoneError('');
      setContactPhone('');
    } else if (formatWorkPhoneNumber.length !== 12) {
      setIsFieldUpdated(true);
      setContactPhoneError('Contact phone should have exactly 10 digits');
      setContactPhone(formatWorkPhoneNumber.substring(0, 12)); // Truncate to 10 digits
    } else {
      setIsFieldUpdated(true);
      setContactPhoneError('');
      setContactPhone(formatWorkPhoneNumber);
    }
  };

  /* Function for validation of zipcode  */
  const zipCodeValidate = (zipCode) => {
    let zipCodePattern = zipCode.replace(/\D/g, ''); // Remove non-digit characters
    if (zipCodePattern.length > 5) {
      zipCodePattern = zipCodePattern.substring(0, 5);
    }
    if (!zipCodePattern) {
      setIsFieldUpdated(true);
      setZipCodeError('');
      setZipCode('');
    } else if (zipCodePattern.length !== 5) {
      setIsFieldUpdated(true);
      setZipCodeError('ZIP code should have exactly 5 digits');
      setZipCode(zipCodePattern.substring(0, 5)); // Truncate to 5 digits
    } else {
      setIsFieldUpdated(true);
      setZipCodeError('');
      setZipCode(zipCodePattern);
    }
  };

  const address2validate = (address2) => {
    if (!address2) {
      setaddress2(address2 !== '' ? '' : address2);
      setIsFieldUpdated(true);
    } else {
      setIsFieldUpdated(true);

      setaddress2(address2);
    }
  };

  /* Function for setting value of product category validate */
  const businessEntityTypeValidate = (businessEntityType) => {
    const [Id, Name] = businessEntityType.split('|');
    if (!Id && !Name) {
      setIsFieldUpdated(true);
      setBusinessEntityTypeError(true);
      setBusinessEntityTypeId('');
      setBusinessEntityTypeName('');
    } else {
      setIsFieldUpdated(true);
      setBusinessEntityTypeError(false);
      setBusinessEntity(businessEntityType);
      setBusinessEntityTypeId(Id);
      setBusinessEntityTypeName(Name);
    }
  };

  /* Function for setting value of product category validate */
  const stateTypeValidate = (stateTypeValidate) => {
    if (!stateTypeValidate) {
      setIsFieldUpdated(true);
      setStateTypeError(true);
      setStateType('');
    } else {
      setIsFieldUpdated(true);
      setStateTypeError(false);
      setStateType(stateTypeValidate);
    }
  };

  /* Function for setting value of product category validate */
  const cityValidate = (cityTypeValidate) => {
    if (!stateTypeValidate) {
      setIsFieldUpdated(true);
      setCityError(true);
      setCity('');
    } else {
      setIsFieldUpdated(true);
      setCityError(false);
      setCity(cityTypeValidate);
    }
  };

  /* Function for setting value of manufacturer url */
  const manufacturerUrlvalidate = (manufacturerUrl) => {
    const URL_REGEX =
      /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(\/[^\s]*)?$/;

    if (manufacturerUrl && !URL_REGEX.test(manufacturerUrl)) {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('business url is not valid');

      setmanufacturerUrl(manufacturerUrl);
    } else {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('');
      setmanufacturerUrl(manufacturerUrl);
    }
  };

  /* Function for validation of Zoom room product name  */
  const zoomRoomProductNamevalidate = (zoomRoomProductName) => {
    if (!zoomRoomProductName) {
      setzoomRoomProductName(
        zoomRoomProductName !== '' ? '' : zoomRoomProductName
      );
      setIsFieldUpdated(true);
    } else {
      setIsFieldUpdated(true);

      setzoomRoomProductName(zoomRoomProductName);
    }
  };

  /* Function to set value of product description */
  const productDescriptionSet = (productDescription) => {
    setIsFieldUpdated(true);

    setproductDescription(productDescription);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={1}
          mb={4}
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseEditProduct()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                Edit Business Entity
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="button"
                variant="contained"
                className="common-button"
                onClick={handleEditSubmit}
              >
                <Typography className="next-button-text">Save</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box className="location-details-container" display={'flex'}>
          <Grid container xs={12}>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={12} className="location-details-col">
                <Box className="container-text-field">
                  <Card
                    sx={{
                      backgroundColor: '#FFFFFF'
                    }}
                  >
                    <CardContent className="pr-0 pl-0">
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                          <Typography
                            className="table-heade header-blue-20"
                            mb={2}
                          >
                            Business Information
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            component="form"
                            id="form-add"
                          >
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="select-drop-down-label-styling  required-field"
                                fullWidth
                                error={businessEntityTypeError}
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling required-label"
                                >
                                  BUSINESS ENTITY TYPE
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="BUSINESS ENTITY TYPE"
                                  className="input-field-styling"
                                  value={businessEntity}
                                  onChange={(e) =>
                                    businessEntityTypeValidate(e.target.value)
                                  }
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                >
                                  {productCategoryList.length > 0 &&
                                    productCategoryList.map((option) => (
                                      <MenuItem
                                        key={option.sortKey}
                                        value={
                                          option.sortKey +
                                          '|' +
                                          option.businessEntityType
                                        }
                                      >
                                        {option.businessEntityType}
                                      </MenuItem>
                                    ))}
                                </Select>
                                {businessEntityTypeError && (
                                  <FormHelperText>
                                    Please select business entity type
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="BUSINESS NAME"
                                autocomplete="BUSINESS NAME"
                                label="BUSINESS NAME"
                                placeholder="BUSINESS NAME"
                                id="locationId"
                                value={businessName}
                                className="location-field input-field-styling required-field"
                                error={Boolean(businessNameError)}
                                helperText={businessNameError}
                                onChange={(e) =>
                                  businessNameValidate(e.target.value)
                                }
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee required-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="BUSINESS EMAIL"
                                autocomplete="BUSINESS EMAIL"
                                label="BUSINESS EMAIL"
                                className="field-top-margin-edit-location location-field input-field-styling"
                                error={Boolean(businessEmailerror)}
                                helperText={businessEmailerror}
                                value={businessEmail}
                                placeholder="BUSINESS EMAIL"
                                onChange={(e) =>
                                  businessEmailValidate(e.target.value)
                                }
                                InputLabelProps={{
                                  className: 'label-style-create-franchisee'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="BUSINESS PHONE"
                                autocomplete="BUSINESS PHONE"
                                label="BUSINESS PHONE"
                                className="field-top-margin-edit-location location-field input-field-styling"
                                error={Boolean(businessPhoneError)}
                                helperText={businessPhoneError}
                                value={businessPhone}
                                placeholder="BUSINESS PHONE"
                                onChange={(e) =>
                                  businessPhoneValidate(e.target.value)
                                }
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee required-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="BUSINESS URL"
                                autocomplete="BUSINESS URL"
                                label="BUSINESS URL"
                                className="field-top-margin-edit-location location-field input-field-styling"
                                value={manufacturerUrl}
                                error={Boolean(manufacturerUrlError)}
                                helperText={manufacturerUrlError}
                                onChange={(e) =>
                                  manufacturerUrlvalidate(e.target.value)
                                }
                                placeholder="BUSINESS URL"
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="ADDRESS"
                                autocomplete="ADDRESS"
                                label="ADDRESS"
                                placeholder="ADDRESS"
                                className=" location-field required-field input-field-styling"
                                onChange={(e) =>
                                  zoomRoomProductNamevalidate(e.target.value)
                                }
                                value={zoomRoomProductName}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="ADDRESS 2"
                                autocomplete="ADDRESS 2"
                                label="ADDRESS 2"
                                placeholder="ADDRESS 2"
                                className=" location-field  input-field-styling"
                                onChange={(e) =>
                                  address2validate(e.target.value)
                                }
                                value={address2}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} mt={2}>
                              <FormControl
                                error={stateTypeError}
                                fullWidth
                                className="select-drop-down-label-styling"
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling"
                                >
                                  STATE
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="STATE"
                                  name="state"
                                  value={stateType}
                                  onChange={(e) =>
                                    stateTypeValidate(e.target.value)
                                  }
                                  className="input-field-styling"
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                >
                                  {varientType.length > 0 &&
                                    varientType.map((r, i) => (
                                      <MenuItem key={i} value={r.state}>
                                        {r.state}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="CITY"
                                autocomplete="CITY"
                                label="CITY"
                                placeholder="CITY"
                                className=" location-field required-field input-field-styling"
                                error={Boolean(cityError)}
                                helperText={cityError}
                                onChange={(e) => cityValidate(e.target.value)}
                                value={city}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="ZIPCODE"
                                autocomplete="ZIPCODE"
                                label="ZIPCODE"
                                placeholder="ZIPCODE"
                                className=" location-field  input-field-styling"
                                error={Boolean(zipCodeError)}
                                helperText={zipCodeError}
                                onChange={(e) =>
                                  zipCodeValidate(e.target.value)
                                }
                                value={zipCode}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Typography
                            className="table-heade header-blue-20"
                            mb={2}
                          >
                            Primary Contact Information
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            component="form"
                            id="form-add"
                          >
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="Contact Name"
                                autocomplete="Contact Name"
                                label="Contact Name"
                                placeholder="Contact Name"
                                className=" location-field input-field-styling"
                                error={Boolean(contactNameError)}
                                helperText={contactNameError}
                                onChange={(e) =>
                                  contactNameValidate(e.target.value)
                                }
                                value={contactName}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="Contact Email"
                                autocomplete="Contact Email"
                                label="Contact Email"
                                placeholder="Contact Email"
                                className=" location-field required-field input-field-styling"
                                error={Boolean(contactEmailError)}
                                helperText={contactEmailError}
                                onChange={(e) =>
                                  contactEmailValidate(e.target.value)
                                }
                                value={contactEmail}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="Contact Phone"
                                autocomplete="Contact Phone"
                                label="Contact Phone"
                                placeholder="Contact Phone"
                                className=" location-field input-field-styling"
                                error={Boolean(contactPhoneError)}
                                helperText={contactPhoneError}
                                onChange={(e) =>
                                  contactPhoneValidate(e.target.value)
                                }
                                value={contactPhone}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                fullWidth
                                multiline
                                id="my-textarea"
                                name="Notes"
                                autocomplete="Notes"
                                label="Notes"
                                placeholder="Notes"
                                variant="outlined"
                                value={productDescription}
                                className="location-description field-top-margin-edit-location"
                                onChange={(e) =>
                                  productDescriptionSet(e.target.value)
                                }
                                InputLabelProps={{
                                  className: 'label-style-create-franchisee'
                                }}
                                InputProps={{ rows: 6 }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Save Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Please confirm that you want to save your changes?
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Business Entity has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddVarientType}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Add Variant Type'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="VARIANT TYPE"
                    name="variantName"
                    className="input-field-styling label-style-create-franchisee"
                    onChange={(e) => setvarientTypeName(e.target.value)}
                    required
                    value={variantName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateVariantType()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddCategory}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Add Category'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="CATEGORY NAME"
                    name="categoryName"
                    className="input-field-styling label-style-create-franchisee"
                    onChange={(e) => setcategoryName(e.target.value)}
                    required
                    value={categoryName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    multiline
                    id="categoryDescription"
                    name="CatDescription"
                    autocomplete="Category Description"
                    label="CATEGORY DESCRIPTION"
                    placeholder="Description . . . "
                    required
                    value={categoryDescription}
                    variant="outlined"
                    className="location-description field-top-margin-edit-location"
                    onChange={(e) => setcategoryDescription(e.target.value)}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                    InputProps={{ rows: 4 }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateCategory()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            Go Back to Editing
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleClosePopup('Save')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddVariant}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Add Variant'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="VARIANT NAME"
                    name="variantName"
                    className="input-field-styling label-style-create-franchisee"
                    onChange={(e) => setvariantName(e.target.value)}
                    required
                    value={variantName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    className="select-drop-down-label-styling"
                  >
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className="weight-700 font-15"
                    >
                      VARIANT TYPE
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="variantId"
                      onChange={handleVariantTypeChange}
                      value={variantTypeId}
                      label="VARIANT TYPE"
                      className="input-field-styling align-left"
                      required
                      MenuProps={{
                        PaperProps: { style: { maxHeight: 200 } }
                      }}
                    >
                      {varientType.length > 0 &&
                        varientType.map((r, i) => (
                          <MenuItem key={i} value={r.variantId}>
                            {r.variantType}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateVariant()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditBusnessEntities;
