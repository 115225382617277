import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  DialogActions,
  Divider,
} from '@mui/material';

import './AddDescription.css';
import Loader from '../../../../components/Loader';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddDescription({
  openDescriptionDialog,
  setOpenDescriptionDialog,
  setOpenDescription,
  handleResetStates,
  getJourneyItem
}) {

  const [commentNotes, setCommentNotes] = useState('');
  const [isLoading, setLoading] = useState(false);

  /* State to open/close confirmation dialog box */
  const [openConfirm, setOpen] = useState(false);

  /* State for checking the state of field update */
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);

  /* State to check whether the task  is added  */
  const [isUpdated, setIsUpdated] = useState(false);

  /* States for error messages */
  const [commentNotesError, setCommentNotesError] = useState('');

  useEffect(() => {
    setOpen(false);
    setIsUpdated(false);
    clearFields();
  }, []);

  /* Function to add task  */

  const addAlertNotes = async() => {
    setLoading(true);

    let url = `${process.env.REACT_APP_API_END_POINT}pinpoint/journey/addDescriptionForFranchisor`;
    let obj = {
      partitionKey: getJourneyItem.partitionKey,
      sortKey: getJourneyItem.sortKey,
      description: commentNotes
    };

    try{
      const data = await ApiService.post(`${url}`, obj)

    if (data?.statusCode && !data?.error) {
          setIsUpdated(true);
          setOpen(true);
          setLoading(false);
          setOpenDescriptionDialog(false);
          handleResetStates();
        } else {
          setIsUpdated(false);
          setOpen(false);
          setLoading(false);
        }
      } catch(error){
        console.error(error)
      }
  };

  /* Function to close the main dialog box */
  const handleClose = () => {
    if (isFieldUpdated) {
      setOpenDescriptionDialog(true);
      setOpen(true);
    } else {
      setOpen(false);
      setOpenDescriptionDialog(false);
      setOpenDescription(false);
    }
  };

  /* Function to close the confirmation dialog box */

  const handleConfirmClose = (e) => {
    if (e === 'Discard') {
      setOpen(false);
      setOpenDescriptionDialog(false);
      setIsFieldUpdated(false);
      setOpenDescription(false);
      clearFields();
    } else if (e === 'Save') {
      setOpenDescription(true);
      setOpen(false);
      setOpenDescriptionDialog(true);
    }
  };

  /* Function to clear all fields */

  const clearFields = () => {
    setCommentNotes('');
    setCommentNotesError('');
  };

  /* Function to set task details  */
  const handleCommentNotes = (val) => {
    if (!val) {
      setCommentNotes('');
      setCommentNotesError(`Description is required`);
    } else {
      setIsFieldUpdated(true);
      setCommentNotesError('');
      setCommentNotes(val);
    }
  };

  /* Function to validateFields */
  const validateFields = () => {

    if (!commentNotes) {
      setCommentNotesError(`Description is required`);
    }

    if (commentNotes) {
      addAlertNotes();
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        open={openDescriptionDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box-right-addTask"
        maxWidth="sm"
      >
        <DialogTitle
          className="alert-title align-right"
          sx={{ backgroundColor: '#FFFFFF ! important' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className="font-20 weight-600">ADD A DESCRIPTION</Typography>
            {/* remove icon */}
            <Button
              className="details-remove-icon task-cross p-0 no-hover"
              onClick={() => handleClose('Close')}
            >
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                  fill="#E64646"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#FFFFFF ! important' }}>

          <Box>
            <Grid container className="grid-containe">

              <Grid item xs={12} sm={12} className='w-100vh'>
                <TextField
                  fullWidth
                  multiline
                  id="my-textarea"
                  name="comments"
                  label='DESCRIPTION'
                  value={commentNotes}
                  onChange={(e) => handleCommentNotes(e.target.value)}
                  error={Boolean(commentNotesError)}
                  helperText={commentNotesError}
                  placeholder="Type here..."
                  variant="outlined"
                  className={`field-top-margin-edit-location required-field`}
                  InputLabelProps={{
                    className: `label-style-create-franchisee required-label`
                  }}
                  InputProps={{ rows: 6 }}
                />
              </Grid>

              <Grid container spacing={2} className="mt-3">
                <Grid item xs={12} sm={6}>
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    className="button-style-cancel field-top-margin-edit-location button-style-add-team"
                    sx={{ border: '2px solid #003087!important' }}
                    onClick={() => handleClose('Close')}
                  >
                    <Typography className="next-button-tex weight-700">Cancel</Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="button-style-add-team field-top-margin-edit-location"
                    onClick={validateFields}
                  >
                    <Typography className="next-button-text">Save</Typography>
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      {/* Confirmation dialog */}
      <Dialog
        open={openConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Discard')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {!isUpdated ? 'Discard Changes?' : 'Congratulations'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes, what do you want
                  <Typography className="title-text-sub">to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Description has been added successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleConfirmClose('Save')}
            >
              Go back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddDescription;
