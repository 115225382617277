/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  FormHelperText,
  Card,
  TableContainer,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  DialogActions,
  Divider
} from '@mui/material';
import { Box } from '@mui/system';
import { AutoComplete } from 'src/components/Global';
import {
  generateTimeOptions,
  isEndTimeGreaterOrEqual
} from 'src/components/Schedules';
import { ApiService } from 'src/services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { getLocalData } from 'src/utils';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from 'src/components/Loader';
import StaffService from './StaffService';
import moment from 'moment';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';
import { debounce } from 'lodash';
import { useMemo } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

/**
 * Schedule date header template sample
 */

const Availibility = ({
  setShowScreen,
  staffItem,
  getStaffDetailById,
  getStaff
}) => {
  const dayOptionDefault = useMemo(
    () => [
      { id: 1, label: 'Monday', value: 'Monday' },
      { id: 2, label: 'Tuesday', value: 'Tuesday' },
      { id: 3, label: 'Wednesday', value: 'Wednesday' },
      { id: 4, label: 'Thursday', value: 'Thursday' },
      { id: 5, label: 'Friday', value: 'Friday' },
      { id: 6, label: 'Saturday', value: 'Saturday' },
      { id: 7, label: 'Sunday', value: 'Sunday' }
    ],
    []
  );
  const [dayOptions, setDayOptions] = useState([]);
  const locationId = getLocalData('locationId');
  const [debouncedCheck, setDebouncedCheck] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [fromTime, setfromTime] = useState('');
  const [endTime, setendTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [selectDayError, setSelectDayError] = useState('');
  const [startFromTimeError, setStartFromTimeError] = useState(false);
  const [fromTimeErrorMsg, setFromTimeErrorMsg] = useState('');
  const [toTimeErrorMsg, setToTimeErrorMsg] = useState('');
  const [endDateErrorMsg, setEndDateErrorMsg] = useState('');
  const [startDateErrorMsg, setFromDateErrorMsg] = useState('');
  const [toEndTimeError, setToEndTimeError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endMinDate, setEndMinDate] = useState(new Date());
  const [endDateError, setEndDateError] = useState(false);
  const [editData, setEditData] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [day, setday] = useState([]);
  const [daysValue, setDaysValue] = useState([]);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [availibilityData, setAvailibilityData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [opening, setOpening] = useState(false);

  const theme = useTheme();
  const handleScheduleInfo = (type, value) => {
    if (type === 'fromTime') {
      setFromTimeErrorMsg('');
      setfromTime(value);
    } else if (type === 'toTime') {
      setToTimeErrorMsg('');
      setendTime(value);
    }
  };
  useEffect(() => {
    if (toEndTimeError) {
      setDisabledSaveButton(true);
    }
  }, [toEndTimeError, disabledSaveButton]);
  const updateDayOptions = (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      setDayOptions([]);
      return;
    }

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const diffInDays = Math.round(
      (new Date(toDate) - new Date(fromDate)) / oneDay
    );

    if (diffInDays < 7) {
      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      const dates = [];
      let currentDate = new Date(fromDate);
      for (let i = 0; i <= diffInDays; i++) {
        dates.push(currentDate);
        currentDate = new Date(currentDate.getTime() + oneDay);
      }
      const newDayOptions = dates.map((date) => ({
        
        id:
          daysOfWeek.indexOf(
            date.toLocaleDateString('en-US', { weekday: 'long' })
          ) + 1,
        label: daysOfWeek[date.getDay()],
        value: daysOfWeek[date.getDay()]
      }));

      setDayOptions(newDayOptions);
    } else {
      setDayOptions(dayOptionDefault);
    }
  };
  useEffect(() => {
    if (startDate && endDate) {
      updateDayOptions(startDate, endDate);
    }
   
  }, [startDate, endDate, isEdit]);
  // useEffect(() => {
  //   if (startDate) {
  //     let sDate = new Date(startDate)
  //     setEndMinDate(sDate)
  //   }else{
  //     setEndMinDate(new Date())
  //   }
  // }, [startDate, endDate]);
  const handleEndTimeError = () => {
    if (!isEndTimeGreaterOrEqual(fromTime, endTime) || fromTime === endTime) {
      setToEndTimeError(true);
      setToTimeErrorMsg('Invalid Time.');
      setDisabledSaveButton(true);
    } else {
      setToEndTimeError(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  const paginatedLocationLists = availibilityData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleDayChange = (e) => {
    const { name, value } = e.target;
    if (name === 'day') {
      const selectedDays = value.map((item) => {
        const dayName = item.label;

        return { day: dayName };
      });

      setday(selectedDays);
      setDaysValue(value);
      setSelectDayError(false);
    }
  };
  useEffect(() => {
    if (staffItem) {
      getAvailibility();
    }
  }, [staffItem]);

  useEffect(() => {
    if (!day?.length) {
      setDisabledSaveButton(true);
    }
  }, [day]);
  // useEffect(() => {
  //   if (day.length > 0 && fromTime && endTime && startDate && endDate)
  //     checkStaffAvailability();
  // }, [day, fromTime, endTime, startDate, endDate]);
useEffect(() => {
    if (
      fromTime &&
      endTime &&
      startDate &&
      endDate &&
      new Date(startDate) <= new Date(endDate) &&
      isEndTimeGreaterOrEqual(fromTime, endTime)
    ) {
      checkStaffAvailability();
    }
  }, [fromTime, endTime, startDate, endDate,daysValue]);
  // Separate hook for resetting the day state when start date is greater than end date
  // useEffect(() => {
  //   if (new Date(startDate) > new Date(endDate)) {
  //     setDayOptions([]);
  //     setday([]);
  //     setDaysValue([]);
  //   }
  // }, [startDate, endDate]);

  useEffect(() => {
    if (endTime) {
      handleEndTimeError();
    }
  }, [endTime, fromTime]);

  const addAvailibility = () => {
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const clearStates = () => {
    setDisabledSaveButton(true);
    setIsEdit(false);
    setday([]);
    setDayOptions([]);
    setDaysValue([]);
    setfromTime('');
    setendTime('');
    setStartDate('');
    setEndDate('');
    setSelectDayError('');
    setEndDateError(false);
    setStartDateError(false);
    setToEndTimeError(false);
    setStartFromTimeError(false);
    setEndTimeError('');

  };

  const handleDialog = (type) => {
    if (type === 'cancel') {
      setOpen(false);
      setStartFromTimeError(false);
      clearStates();
    }
  };

  const handleClose = () => {
    getStaff();
    setDaysValue([]);
    setfromTime('');
    setendTime('');
    setShowScreen(0);
    clearStates();
    getStaffDetailById();
  };
  const handleEditAvailibility = (e, row) => {
    setEditData(row);
    setIsEdit(true);

    handleOpenModal();
  };
  const handleDeleteAvailibility = async (row) => {
    setOpenDeleteDialog(true);
    setDeleteData(row);
  };
  const handleCloseDelete = (val) => {
    if (val === 'Delete') {
      handleDelete();
    } else if (val === 'Cancel') {
      setOpenDeleteDialog(false);
    }
  };
  const handleDelete = async () => {
    setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedAvailibilityId = encodeURIComponent(deleteData.sortKey);

    try {
      let response = await ApiService.delete(
        `${process.env.REACT_APP_API_END_POINT}staff/deleteStaffAllAvailability?locationId=${encodedLocationId}&availabilityId=${encodedAvailibilityId}`
      );
      if (response.statusCode === 200 && !response.error && response?.data?.isExist) {
        setIsLoading(false);
        getAvailibility();
        setOpenDeleteDialog(false);
        setDeleteData(false);
        clearStates();
        setShowErrorMessage(true);
        let errmsg = response.message;
        setErrorMessage(errmsg);
      } else {
        setIsLoading(false);
        getAvailibility();
        setOpenDeleteDialog(false);
        setSuccessMessage(
          'Selected availability has been deleted successfully!'
        );
        setShowSuccessMessage(true);
       
        clearStates();
        setOpen(false);
       
      }
    } catch (error) {
      let errmsg = error.message;
      setOpenDeleteDialog(false);
      setErrorMessage(errmsg);
      setShowErrorMessage(true);
      setIsLoading(false);
    }
    setSelectDayError(false);
    setStartFromTimeError(false);
    setToEndTimeError(false);
  };
  useEffect(() => {
    if (isEdit) {
      const selectedDay = editData?.availableSchedules.map((schedule) =>
        dayOptionDefault.find((item) => item.label === schedule.day)
      );
      // if (selectedDay) {
      //   setDaysValue(selectedDay);
      // }

      let fDate = convertToCustomFormat(editData?.fromDate);
      let eDate = convertToCustomFormat(editData?.toDate);
      console.log(fDate,'fDatefDatefDatefDatefDate')
      setStartDate(fDate);
      setEndDate(eDate);
      let daysValue = selectedDay.map((i) => {
        return { day: i.label };
      });

      setday(daysValue);
      setendTime(editData?.availableSchedules[0]?.endTime);
      setfromTime(editData?.availableSchedules[0]?.startTime);
    }
  }, [isEdit, editData]);
  useEffect(() => {
    if (dayOptions.length > 0 && isEdit) {
      Promise.all(
        editData?.availableSchedules.map((schedule) =>
          Promise.resolve(
            dayOptions.find((item) => item.label === schedule.day)
          )
        )
      ).then((selectedDays) => {
        setDaysValue(selectedDays.filter(Boolean));
      });
    }
  }, [isEdit, dayOptions, editData]);

  const getAvailibility = async () => {
    setIsLoading(true);
    try {
      let staffId = encodeURIComponent(staffItem.sortKey);
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}staff/getStaffAllAvailability?locationId=${encodedLocationId}&staffId=${staffId}`
      );
      if (response && !response.error) {
        let Item =
          response && response.data && response.data.Item
            ? response.data.Item
            : [];
        setAvailibilityData(Item?.availabilities);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  const formatDateForComparison = (dateString) => {
    // Parse the input date string and format it to 'YYYY-MM-DDTHH:mm:ss'
    return moment(dateString, 'MM-DD-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss');
  };

  const validateField = () => {
    let isError = false;
    if (daysValue.length === 0) {
      setSelectDayError(true);
      isError = true;
    }
    if (fromTime === '') {
      setStartFromTimeError(true);
      setFromTimeErrorMsg('From time is required');
      isError = true;
    }
    if (endTime === '') {
      setToEndTimeError(true);
      setToTimeErrorMsg('To time is required');
      isError = true;
    }
    if (startDate === '') {
      setStartDateError(true);
      setFromDateErrorMsg('From date is required');
      isError = true;
    }
    if (endDate === '') {
      setEndDateError(true);
      setEndDateErrorMsg('End date is required');

      isError = true;
    }
    return isError;
  };
  useEffect(() => {
    getAvailibility();
  }, [opening]);
  useEffect(() => {
    if (startDate && endDate) {
      let fDate = new Date(startDate);
      let tDate = new Date(endDate);
      if (fDate > tDate) {
        setStartDateError(true);
        setDisabledSaveButton(true)
        setFromDateErrorMsg('Start date can not be greater than end date');
      } else {
        setStartDateError(false);
        setFromDateErrorMsg('');
      }
    }
  }, [startDate, endDate]);

  const handleSubmit = () => {
    if (!validateField()) {
      handleAddScheduleData();
    }
  };

  const handleAddScheduleData = async () => {
    setIsLoading(true);
    let modifiedData = day.map((d) => {
      return {
        day: d.day,
        startTime: fromTime,
        endTime: endTime
      };
    });
    const mapFromDays = new Map(
      modifiedData.map(c => [c.day, c])
    );
    
    const uniqueDays = [...mapFromDays.values()];
    let data = {
      locationId: locationId,
      fromDate: formatDateForComparison(startDate),
      toDate: formatDateForComparison(endDate),
      staffId: staffItem.sortKey,
      availableSchedules: uniqueDays
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}staff/addStaffAvailability `,
        data
      );
      if (response.statusCode === 200 && !response.error) {
        setOpening(true);
        setIsLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage('Availability has been created successfully!');
        setOpen(false);
        setDisabledSaveButton(true);
        clearStates();
      } else {
        setIsLoading(false);
        setDisabledSaveButton(true);
        let errmsg = response.message;
        clearStates();
        setOpen(false);
        setErrorMessage(errmsg);
        setShowErrorMessage(true);
      }
    } catch (error) {
      setIsLoading(false);
      let errmsg = error.message;
      setErrorMessage(errmsg);
      setShowErrorMessage(true);
    } finally {
      getAvailibility();
    }
    setSelectDayError(false);
    setStartFromTimeError(false);
    setToEndTimeError(false);
    setIsLoading(false);
  };
  const handleEditScheduleData = async () => {
    if (daysValue.length === 0) {
      setSelectDayError(true);
    } else if (!fromTime) {
      setStartFromTimeError(true);
    } else if (!endTime) {
      setToEndTimeError(true);
    } else {
      setIsLoading(true);
      let modifiedData = day.map((d) => {
        return {
          day: d.day,
          // startTime: convertTimeToUTC(fromTime),
          // endTime: convertTimeToUTC(endTime)
          startTime: fromTime,
          endTime: endTime
        };
      });
      let data = {
        locationId: locationId,
        availabilityId: editData.sortKey,
        staffId: staffItem.sortKey,
        availableSchedules: modifiedData,
        fromDate: formatDateForComparison(startDate),
        toDate: formatDateForComparison(endDate)
      };
      try {
        let response = await ApiService.put(
          `${process.env.REACT_APP_API_END_POINT}staff/updateStaffAvailability`,
          data
        );
        if (response.statusCode === 200 && !response.error) {
          setOpening(true);
          setIsLoading(false);
          clearStates();
          setOpen(false);
          setSuccessMessage(
            'Availability details has been updated successfully!'
          );
          setShowSuccessMessage(true);
        } else {
          setIsLoading(false);

          let errmsg = response.message;
          clearStates();
          setOpen(false);
          setErrorMessage(errmsg);
          setShowErrorMessage(true);
        }
      } catch (error) {
        setIsLoading(false);
        let errmsg = error.message;
        setErrorMessage(errmsg);
        setShowErrorMessage(true);
      }
      setSelectDayError(false);
      setStartFromTimeError(false);
      setToEndTimeError(false);
      setIsLoading(false);
    }
  };
  const checkStaffAvailability = async () => {
    setDisabledSaveButton(true);
   let sDate = startDate.replaceAll('/','-');
   let eDate = endDate.replaceAll('/','-')
    let modifiedData = day.map((d) => {
      return {
        day: d.day,
        startTime: fromTime,
        endTime: endTime
      };
    });

    let data = {
      fromDate: formatDateForComparison(sDate),
      toDate: formatDateForComparison(eDate),
      locationId: locationId,
      staffId: staffItem.sortKey,
      availableSchedules: modifiedData
    };
    if (isEdit) {
      data.id = editData.sortKey;
    }
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}staff/checkAvailability`,
        data
      );
      if (response.statusCode === 200 && response.data.isValid) {
        setDisabledSaveButton(false);
      } else {
        setDisabledSaveButton(true);
        setShowErrorMessage(true);
        setErrorMessage(response.message);
      }
    } catch (error) {
      setDisabledSaveButton(true);
     
        // setShowErrorMessage(true);
        // setErrorMessage('Staff is not available for the given time slot');
      
    }
    return undefined;
  };

  const handleEndDate = (date) => {
    if (date === '01-01-1970') {
      return;
    }
    setEndDateErrorMsg('');
    setEndDate(date.replaceAll('-','/'));
  };
  const handleStartDate = (date) => {
    if (date === '01-01-1970') {
      return;
    }
    setStartDate(date.replaceAll('-','/'));
    setFromDateErrorMsg('');
  };

  function formatStartEndDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
  }
  const convertToCustomFormat = (date )=>{
    let utc;
    utc = date ? moment?.utc(date).format('MM/DD/YYYY') : '';
    return utc;
  }
  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={() => {
            setOpening(false);
            setShowSuccessMessage(false);
            getStaffDetailById();
          }}
        />
      )}

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ paddingBottom: '15px' }}
      >
        <Grid item mt={-1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box className="back-arrow-close" onClick={() => handleClose()}>
              <svg
                width="27"
                height="19"
                viewBox="0 0 27 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                  fill="#003087"
                />
              </svg>
            </Box>
            <Typography className="header-blue-32">
              Add Service and Availability
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button
            sx={{ mt: { xs: 0, md: 0 } }}
            variant="contained"
            className="btn-primary"
            onClick={addAvailibility}
          >
            Add Availability
          </Button>
        </Grid>
      </Grid>
      <Grid pr={0} mt={2}>
        <Card
          sx={{
            padding: '15px',
            borderRadius: '19px 19px 0px 0px'
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            ml={2}
          >
            <Grid item>
              <Typography className="table-heade header-blue-20">
                {' '}
                Staff Availibility
              </Typography>
            </Grid>
          </Grid>
          <TableContainer
            className="location-table-container servies-details-table"
            sx={{
              padding: '10px',
              borderRadius: '19px 19px 0px 0px',
              backgroundColor: 'rgba(248, 251, 255, 1)'
            }}
          >
            <Table>
              <TableHead>
                <TableRow className="table-header">
                  <TableCell>Date</TableCell>
                  <TableCell>Occurrence</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedLocationLists?.map((row, index) => {
                  if (row.availabilityType === 'available') {
                    // const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        key={index}
                        role="checkbox"
                        tabIndex={-1}
                        className="table-row cursor-pointer"
                      >
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            sx={{ marginTop: 1 }}
                            gutterBottom
                            noWrap
                          >
                            {convertToCustomFormat(row.fromDate,true)}
                            <span style={{ padding: '0 15px' }}>to</span>
                            {convertToCustomFormat(row.toDate,true)}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {row?.availableSchedules
                              .map((i) => i.day)
                              .join(', ')}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {row?.availableSchedules[0]?.startTime}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {row?.availableSchedules[0]?.endTime}
                          </Typography>
                        </TableCell>

                        <TableCell className="table-td">
                          <IconButton
                            sx={{
                              ml: '50%',
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                            className="edit-btn"
                            id={'edit_btn_' + index}
                            onClick={(e) => handleEditAvailibility(e, row)}
                          >
                            Edit
                          </IconButton>
                        </TableCell>
                        <TableCell className="">
                          <IconButton
                            sx={{
                              '&:hover': {
                                background: theme.colors.primary.lighter
                              },
                              color: theme.palette.primary.main
                            }}
                            color="inherit"
                            size="small"
                            className="edit-btn"
                            id={'edit_btn_' + index}
                            onClick={(e) => handleDeleteAvailibility(row)}
                          >
                            Delete
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={availibilityData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Grid>
      <StaffService setShowScreen={setShowScreen} staffItem={staffItem} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          handleDialog('cancel');
        }}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-availibility dialog-box-right not"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: 'fit-content',
            maxHeight: '530px'
          }
        }}
      >
        <Box sx={{ backgroundColor: 'white' }}>
          <DialogTitle className=" dialog-availibility -team-container-white-box">
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className="header-blue-35">
                {isEdit ? 'Edit Availability' : 'Add Availability'}
              </Typography>
              {/* remove icon */}
              <Button
                className="details-remove-icon task-cross p-0 no-hover"
                onClick={() => handleDialog('cancel')}
              >
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                    fill="#E64646"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
        </Box>
        <DialogContent className="-team-container-white-box" sx={{ pt: '0px' }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} xs={12} mb={1}>
                <Grid container spacing={5}>
                  <Grid item md={6} sm={6} xs={6}>
                    <CustomDatePickerForAllBrowser
                      label="START DATE"
                      required
                      fieldValue={startDate}
                      name="startDate"
                      onChange={handleStartDate}
                      // maxDate={new Date(endDate)}
                      // minDate={new Date()}
                      className="field-top-margin-edit-location show"
                    />
                    {startDateError && (
                      <FormHelperText>{startDateErrorMsg}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <CustomDatePickerForAllBrowser
                      label="END DATE"
                      required
                      name="endDate"
                      fieldValue={endDate}
                      onChange={handleEndDate}
                      // minDate={endMinDate}
                      className="field-top-margin-edit-location show"
                    />
                    {endDateError && (
                      <FormHelperText>{endDateErrorMsg}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12} mb={2}>
                <Grid container spacing={5}>
                  <Grid item md={6} sm={6} xs={6}>
                    <AutoComplete
                      fullWidth
                      freeSolo
                      disableClearable
                      label="FROM *"
                      name="fromTime"
                      options={generateTimeOptions()}
                      value={fromTime}
                      onChange={handleScheduleInfo}
                      // helperText={
                      //   startFromTimeError ? fromTimeErrorMsg : ''
                      // }
                      className="field-top-margin-edit-location -min-field-height input-field-dropdown-styling select-field "
                      InputLabelProps={{
                        className:
                          'label-style-create-franchise select-staff label-style-text-field  weight-600'
                      }}
                    />
                    {startFromTimeError && (
                      <FormHelperText>{fromTimeErrorMsg}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item md={6} sm={6} xs={6}>
                    <AutoComplete
                      fullWidth
                      freeSolo
                      // className="input-field-dropdown-to-from"
                      disableClearable
                      label="TO *"
                      name="toTime"
                      options={generateTimeOptions()}
                      value={endTime}
                      onChange={handleScheduleInfo}
                      error={endTimeError !== ''}
                      className="field-top-margin-edit-location -min-field-height input-field-dropdown-styling select-field "
                      InputLabelProps={{
                        className:
                          'label-style-create-franchise select-staff label-style-text-field  weight-600'
                      }}
                    />
                    {toEndTimeError && (
                      <FormHelperText>{toTimeErrorMsg}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5.7} sm={6} xs={6}>
                <FormControl
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    SELECT DAY
                  </InputLabel>
                  <Select
                    multiple
                    name="day"
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="SELECT DAY"
                    className="input-field-styling"
                    onChange={handleDayChange}
                    // disabled={isEdit}
                    value={daysValue}
                  >
                    {dayOptions.length > 0 &&
                      dayOptions.map((item) => (
                        <MenuItem id={item.id} value={item}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {selectDayError && (
                    <FormHelperText error>
                      {'Please select at least one day.'}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {!disabledSaveButton ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="button-style-add-availibility  mt-35"
                onClick={isEdit ? handleEditScheduleData : handleSubmit}
              >
                <Typography className="next-button-text">
                  {isEdit ? 'Update' : 'Save'}
                </Typography>
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="button-style-add-team disabled-btn field-top-margin-edit-location bookClassBtndisabled-availibility"
                disabled
              >
                <Typography className="next-button-text">
                  {' '}
                  {isEdit ? 'Update' : 'Save'}
                </Typography>
              </Button>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Delete'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to delete this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseDelete('Delete')}
          >
            Delete
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleCloseDelete('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Availibility;
