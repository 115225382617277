/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { ApiService } from '../../../services/ApiService';
import {
  Box,
  Hidden,
  Stack,
  Typography,
  CircularProgress,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import clsx from 'clsx';
import Loader from '../../../components/Loader';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { CustomButton, SearchInput } from 'src/components/Global';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

import { getLocalData, setFullLocationId } from 'src/utils';
import GlobalData from 'src/global';
import Header from 'src/components/Header';
import TemplateFilter from './TemplateFilter';
import { TemplateTable } from './TemplateTable';
import { TemplateDetail } from './TemplateDetail';
import { CreateTemplate } from './CreateTemplate';
import { EditTemplate } from './EditTemplate';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Template = () => {
  const franchiseeId = getLocalData('franchiseeId');
  console.log(franchiseeId, 'franchiseeId');
  const title = GlobalData[0].title;
  const userId = getLocalData('userId');
  const [showScreen, setShowScreen] = useState(0);
  const [isInitialState, setIsInitialState] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [serviceDetails, setServiceDetails] = useState();
  const [isServiceEdit, setIsServiceEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rowData, setRowData] = useState([]);
  const [getFranchisee, setFranchisee] = useState([]);
  // check updated or saved
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    // isActive: null,
    // status: -1,
    channel: 'All',
    createdBy: null
  });
  // setServicesData(tableData)
  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);
  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
    setIsServiceEdit(false);
    setServiceDetails();
    setSelectedRows([]);
    setSelectedRowsData([]);
    setSelectedStatus('');
  };

  function findObjectById(id) {
    return rowData?.find((obj) => obj.sortKey === id);
  }

  //   const openDetail = (row) => {
  //     console.log(rowData, 'rowDatarowData');
  //     setRowData(row);
  //     setShowScreen(1)
  // };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  //   ===============call api here=============
  const getTemplate = async () => {
    setIsLoading(true);
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === 'All' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllFranchiseeTemplates?${queryString}&franchiseeId=${encodedFranchiseeId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      setRowData(Item);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getFranchiseeName = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}franchisees/getFranchiseeDetail?franchiseeId=${encodedFranchiseeId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      setFranchisee(Item.franchiseeName);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(getFranchisee, 'Item');
  useEffect(() => {
    getFranchiseeName();
  }, []);
  // setFranchiseeId(locationList.partitionKey);
  // gets call the method to get the data
  console.log(rowData, 'sssssss');
  useEffect(() => {
    // Not call the get location method
    getTemplate();
  }, [JSON.stringify(filters)]);

  function getServiceIds() {
    let array = [];
    selectedRowsData?.map((item) => array.push(item.sortKey));

    return array;
  }

  console.log(serviceDetails, 'servicedetails');
  return (
    <>
      <Helmet>
        <title>Template | {title}</title>
      </Helmet>
      {/* <Loader IsLoading={loading} /> */}
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          getDetails={rowData}
        />
      )}

      <Container maxWidth="100%" className="custom-container">
        {(() => {
          switch (showScreen) {
            case 1:
              return (
                <Box justifyContent="center" direction="column">
                  {/* AddCampaign component */}
                  <TemplateDetail
                    setShowScreen={setShowScreen}
                    selectedRows={selectedRows}
                  />
                </Box>
              );
            case 2:
              return (
                <Box justifyContent="center" direction="column">
                  {/* AddCampaign component */}
                  <CreateTemplate
                    setShowScreen={setShowScreen}
                    selectedRows={selectedRows}
                    getFranchisee={getFranchisee}
                    getTemplate={getTemplate}
                    rowData={rowData}
                  />
                </Box>
              );
            case 3:
              return (
                <Box justifyContent="center" direction="column">
                  {/* AddCampaign component */}
                  <EditTemplate
                    setShowScreen={setShowScreen}
                    selectedRows={selectedRows}
                    getTemplate={getTemplate}
                  />
                </Box>
              );
            default:
              return (
                <Box justifyContent="center" direction="column">
                  <Header title="Template" />
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item marginY="20px">
                            <CustomButton
                              onClick={() => setIsFilters(!isFilters)}
                              variant={isFilters ? 'contained' : 'outlined'}
                            >
                              Filters
                              <KeyboardArrowDownRoundedIcon
                                className={clsx(
                                  !isFilters && classes.closeY,
                                  isFilters && classes.openY
                                )}
                              />
                            </CustomButton>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            marginY={{ sm: '20px' }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <SearchInput
                              value={searchQuery}
                              setSearchQuery={setSearchQuery}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <TemplateFilter
                            filters={filters}
                            setFilters={setFilters}
                            tableData={rowData}
                            getFranchisee={getFranchisee}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <TemplateTable
                          setRowData={setRowData}
                          rowData={rowData}
                          setIsLoading={setIsLoading}
                          isLoading={isLoading}
                          tableRows={rowData}
                          searchQuery={searchQuery}
                          setShowScreen={setShowScreen}
                          loading={tableLoading}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          selectedStatus={selectedStatus}
                          setSelectedStatus={setSelectedStatus}
                          btnLoading={btnLoading}
                        />
                      </Grid>
                    </>
                    {/* )} */}
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>
    </>
  );
};

export default Template;
