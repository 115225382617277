/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Slide,
  Card,
} from '@mui/material';
import './index.css';
import React, { useEffect, useState, useRef } from 'react';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { closest } from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ApiService } from '../../../services/ApiService';
import moment from 'moment';
import { getLocalData } from 'src/utils';
import Loader from '../../../components/Loader';

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
  ({ theme }) => `
          margin-top: ${theme.spacing(2)};
  `
);

export const CohortsDetail = (props) => {
  const { selectedRow, setShowScreen, setIsDuplicate } =
    props;
    const locationId = getLocalData('locationId');
  const [isLoading, setIsLoading] = useState(false);
  const [columnData, setcolumnData] = useState([]);
  const [columns, setColumns] = useState([]);

  let qbObj = useRef(null);
  const [importRules, setImportRules] = useState({});

  useEffect(() => {
    if (selectedRow?.Segments.length > 0) {
      const updatedImportRules = { ...selectedRow.Segments[0] };
      mapFieldDataToRules(updatedImportRules.rules);
      setImportRules(updatedImportRules); // Set the modified rules to state if required elsewhere
    }
  }, [selectedRow]);

  function getStatusNames(status) {
    switch (status) {
      case 'earlyCancel':
        return 'Early Cancel';
      case 'lateCancel':
        return 'Late Cancel';
      case 'checkIn':
        return 'Check In';
      case 'arrivedLate':
        return 'Arrived Late';
      case 'absent':
        return 'Absent';
      // Add more cases as needed
      default:
        return '';
    }
  }
  function mapFieldDataToRules(rules) {
    rules?.forEach(rule => {
      if (rule.operator === 'between' && Array.isArray(rule.value) && rule.value.length === 2) {
        rule.value[0] = convertDateFormat(rule.value[0]);
        rule.value[1] = convertDateFormat(rule.value[1]);
      }
      if(rule.field==='status'){
        rule.value = getStatusNames(rule.value)
      }
      if (rule.rules && rule.rules.length > 0) {
        mapFieldDataToRules(rule.rules); // Recursively call the function for nested rules
      }
    });
  }


  function convertDateFormat(inputDate) {
    const date = new Date(inputDate);

    if (Number.isNaN(date)) {
      console.error('Invalid date format. Please provide a valid date.');
      return null; // Or handle the error in your specific way
    }

    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }
  const getAllAttribute = async () => {
    setIsLoading(true)
    let encodedLocationId = encodeURIComponent(locationId)
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAttributeFilter?locationId=${encodedLocationId}`
      );

      if (!response.error) {
        setcolumnData(response.data)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (columnData?.length > 0) {
      const updatedColumnData = columnData.map((field) => {
        if(field.field==='booked'||field.field==='purchase'){
          return {
            ...field,
            values: ['True', 'False']
          };
  
        }
        return field;
      }); 
      setColumns(updatedColumnData);
    }
  }, [columnData]);
  useEffect(() => {
    getAllAttribute()
  }, [])
  function convertIsoToNormalDateAndTime(isoDate) {
    const normalDate = moment.utc(isoDate).format('MMMM Do YYYY, h:mm, [UTC]');
    return normalDate;
  }

  const handleResetStates = () => {
    setShowScreen(0)
  }

  const handleOpenScreen = () => {
    setIsDuplicate(true)
    setShowScreen(2)
  }
  const handleOpenScreenEdit = () => {
    setShowScreen(3)
  }

  const headerTemplate = (props) => {
    let items;
    let fields;

    items = [
      { key: 'AND', value: 'and' },
      { key: 'OR', value: 'or' }
    ];
    fields = { text: 'key', value: 'value' };
    let state = { ...props };

  
    const args = state;
    return (
      <div className="query-template-control">
        <div className="e-groupheader">
          <DropDownListComponent
            id={args.ruleID + '_cndtn'}
            cssClass="e-custom-group-btn"
            width="100px"
            dataSource={items}
            fields={fields}
            value={args.condition}
          />
          <div className="e-header">
            {(() => {
              if (args.groupID === null) {
                return (
                  <div className="e-qb-hdr-content">
                    <ButtonComponent 
                      className="e-grp-btn btn-style-cohort"
                      cssClass="e-primary"
                    >
                      Add Group
                    </ButtonComponent>
                  </div>
                );
              }
              return null;
            })()}

            <div className="e-qb-hdr-content ">
              <ButtonComponent
                className="e-cond-btn btn-style-cohort"
                cssClass="e-primary"
              >
                Add Condition
              </ButtonComponent>
            </div>

            {(() => {
                if (args.groupID !== null) {
                return (
                  <div className="e-qb-hdr-content ">
                    <ButtonComponent
                      id={args.ruleID + '_dltbtn'}
                      cssClass="e-danger"
                      className="btn-style-cohort"
                    >
                      Remove
                    </ButtonComponent>
                  </div>
                );
              }
              return null;
            })()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <><Loader IsLoading={isLoading} /><div className="custom-summary">
      <ServiceDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"

      >

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleResetStates()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {selectedRow?.Name}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            {/* <Button
        disabled={btnLoading}
        className="details-remove-icon no-hover"
        onClick={() => setDeleteDialog(true)}
      >
        <DeleteIcon />
      </Button> */}

            <Grid>
              <Button className='cohorts-outlined-btn' variant='outlined' onClick={(e) => handleOpenScreen()}>
                Duplicate
              </Button>
              {selectedRow && selectedRow.createdBy !== 'Franchisor' && (
                <Button
                  sx={{ ml: '10px' }}
                  variant="contained"
                  onClick={(e) => handleOpenScreenEdit()}
                  className="btn-primary edit-button template-edit-btn"
                >
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box className="location-details-container" mt={5} ml={3}>
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                CREATED BY
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {selectedRow?.createdBy}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                CREATED AT
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {convertIsoToNormalDateAndTime(selectedRow?.CreationDate)}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                LAST MODIFIED
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {convertIsoToNormalDateAndTime(selectedRow?.LastModifiedDate)}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Card
          sx={{
            padding: '15px',
            borderRadius: '19px 19px 0px 0px',
            mt: 5
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            ml={2}
          >
            <Grid item>
              <Typography className="table-heade header-blue-20">
                {' '}
                Criteria
              </Typography>
            </Grid>
          </Grid>
          {(columns?.length > 0) &&
            <div className="control-pane">
              <div className="control-section">
                <div className="row">
                  <div className="col-lg-12 control-section disabled-card ">
                    <QueryBuilderComponent
                      columns={columns}
                      rule={importRules}
                      ref={qbObj}
                      headerTemplate={headerTemplate}
                      className="outlined-inputs" />

                  </div>
                </div>
              </div>
            </div>
          }
         

        </Card>
      </ServiceDetailsWrapper>


    </div></>
  );
};
