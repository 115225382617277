/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { forwardRef, useState, useEffect, useRef } from 'react';

import {
  Slide,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  CircularProgress
} from '@mui/material';
import {
  CustomTextField,
  CustomSelect,
  CustomButton
} from 'src/components/Global';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { PricingTable } from './PricingTable';

import { getLocalData } from 'src/utils'; // setFullLocationId
import Loader from 'src/components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const AddService = (props) => {
  const {
    isServiceEdit,
    setIsServiceEdit,
    serviceDetails,
    allCategories,
    allLevels,
    allPreReq,
    handleResetStates
  } = props;

  console.log(allPreReq, 'allPreReqallPreReq');

  const addRef = useRef(null);
  // const BASE_URL = 'https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev';
  const userId = getLocalData('userId');
  // const locationId = setFullLocationId(getLocalData('locationId'));
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');

  const [serviceType, setServiceType] = useState('enrollment');
  const [pricingLoading, setPricingLoading] = useState(true);
  const [allPricingOptions, setAllPricingOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [preValues, setPreValues] = useState({});
  const [categories, setCategories] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setInitialValue] = useState({
    category: '',
    name: '',
    description: '',
    preRequistie: [],
    level: '',
    duration: '',
    emailNote: '',
    maxCapacity: '',
    maxWaitlist: '',
    noOfSesssion: '',
    serviceId: '',
    pricingOptionId: '',
    isFree: '',
    servicePrice: '',
    status: '',
    sortKey: ''
  });
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);

  const [serviceUpdateSuccess, setServiceUpdateSuccess] = useState(false);

  const [isError, setIsError] = useState({
    category: false,
    name: false,
    description: false,
    preRequistie: false,
    duration: false,
    emailNote: false,
    maxCapacity: false,
    maxWaitlist: false,
    noOfSesssion: false
  });
  const [selectedPricingOptions, setSelectedPricingOptions] = useState({});

  const [disabledFiledValues, setdisabledFiledValues] = useState('');

  useEffect(() => {
    setCategories(allCategories);
  }, [allCategories]);

  console.log(disabledFiledValues, 'isFranchisorisFranchisorisFranchisor');

  useEffect(() => {
    getServiceCategories(serviceType);
  }, [serviceType]);

  const [pricingDialog, setPricingDialog] = useState(false);

  const [dialog, setDialog] = useState({
    state: false,
    isCreated: false
  });
  // const [selectedStatus, setSelectedStatus] = useState('');

  function sortSelectedPreReq(array) {
    if (array?.length) {
      return array?.map((item) => item.id);
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (isServiceEdit && serviceDetails) {
      setServiceType(
        serviceDetails?.typeOfService &&
          serviceDetails?.typeOfService.toLowerCase()
      );
      let data = {
        serviceId: encodeURIComponent(serviceDetails?.sortKey),
        category: decodeURIComponent(serviceDetails?.categoryId),
        level: decodeURIComponent(serviceDetails?.difficultyLevelId),
        pricingOptionId: serviceDetails?.defaultPricingOption?.id
          ? serviceDetails?.defaultPricingOption?.id
          : {},
        preRequistie: sortSelectedPreReq(serviceDetails?.prerequisites),
        sortKey: serviceDetails?.sortKey,
        name: serviceDetails?.name,
        description: serviceDetails?.description,
        duration: serviceDetails?.duration,
        emailNote: serviceDetails?.emailNote,
        maxCapacity: serviceDetails?.maxCapacity,
        maxWaitlist: serviceDetails?.maxWaitlist,
        noOfSesssion: serviceDetails?.session,
        isFree: serviceDetails?.isFree,
        servicePrice: initialValue.servicePrice.toString(),
        status: serviceDetails?.status
      };
      if (
        serviceDetails?.isFranchisor === true &&
        serviceDetails?.typeOfService === 'appointment'
      ) {
        setdisabledFiledValues('franchisorAppointment');
      } else if (
        serviceDetails?.isFranchisor === true &&
        serviceDetails?.typeOfService === 'enrollment'
      ) {
        setdisabledFiledValues('franchisorEnrollment');
      } else if (serviceDetails?.isFranchisor === false) {
        setdisabledFiledValues('franchise');
      }
      setInitialValue(data);
      setPreValues(data);
      setSelectedPricingOptions(serviceDetails.defaultPricingOption);
    }

    // getServiceCategories(serviceType);
  }, []);

  // const handleServiceInfo = (type, value) => {
  //   setFieldUpdated(true);
  //   setInitialValue({ ...initialValue, [type]: value });
  //   setIsError({ ...isError, [type]: false });
  // };

  const handleServiceInfo = (type, value) => {
    console.log(type, 'typpeeeeeee', value);
    setFieldUpdated(true);

    // Check for negative values for the 'duration' field
    if (type === 'duration' && value < 0) {
      return; // Exit the function if the value is negative
    }

    setInitialValue({ ...initialValue, [type]: value });
    setIsError({ ...isError, [type]: false });
  };

  const handleServiceType = (event) => {
    setServiceType(event.target.value);
    // getServiceCategories(event.target.value);
  };

  const getServiceCategories = async (serviceType) => {
    let encodedLocationId = encodeURIComponent(locationId);

    let selectedService = serviceType.toLowerCase();
    try {
      const [categoriesResponse] = await Promise.all([
        ApiService.get(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}services/getAllServiceCategories?locationId=${encodedLocationId}&serviceType=${serviceType}`
        )
      ]);

      if (!categoriesResponse?.data?.error) {
        // initialValue.category = '';
        setCategories(categoriesResponse?.data?.Items);
      }
    } catch (error) {
      console.warn('API Error', error);
    }
  };

  const handlePricingSelect = (option) => {
    setInitialValue({
      ...initialValue,
      pricingOptionId: option.sortKey
      // isFree: option.free,
      // servicePrice: option.price,
      // numberOfSessions: option.numberOfSessions
    });
    setSelectedPricingOptions(option);
  };

  const clearStates = () => {
    setServiceType('enrollment');
    setInitialValue({
      category: '',
      name: '',
      description: '',
      preRequistie: [],
      level: '',
      duration: '',
      emailNote: '',
      maxCapacity: '',
      maxWaitlist: '',
      noOfSesssion: '',
      serviceId: '',
      pricingOptionId: '',
      isFree: '',
      servicePrice: '',
      status: '',
      sortKey: ''
    });
  };

  const valuesAreDifferent = () => {
    if (!isServiceEdit) {
      return Object?.entries(initialValue)?.some(([key, value]) => {
        if (Array.isArray(value)) {
          return value?.length > 0; // Consider non-empty arrays as different
        } else {
          return value !== ''; // Consider non-empty strings as different
        }
      });
    } else {
      const keys = Object.keys(initialValue);
      return keys.some((key) => initialValue[key] !== preValues[key]);
    }
  };

  const handleCloseScreen = () => {
    if (valuesAreDifferent()) {
      setDialog({ ...dialog, state: true });
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        state: false,
        isCreated: false
      });
    } else if (value === 'Discard') {
      handleResetStates();
      setDialog({
        state: false,
        isCreated: false
      });
    } else if (value === 'Cancel') {
      handleCloseScreen();
    } else if (value === 'Save') {
      handleResetStates();
    }
  };

  const getNameById = (id, array) => {
    const matchedObject = array.find((obj) => obj.sortKey === id);
    const name = matchedObject ? matchedObject.name : '';
    return name;
  };

  const convertToObjects = (ids) => {
    return ids.map((id) => ({ id }));
  };

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    const alwaysCheck = [
      'category',
      'name',
      'description',
      // 'preRequistie',
      'duration'
      // 'emailNote'
    ];
    for (const key of alwaysCheck) {
      const value = initialValue[key];
      const isEmpty =
        value === '' || (Array.isArray(value) && value?.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }

    // Check additional values if serviceType === 'Enrollment'
    if (serviceType === 'enrollment') {
      // const additionalCheck = ['maxCapacity', 'maxWaitlist', 'noOfSesssion'];
      const additionalCheck = ['maxCapacity', 'maxWaitlist'];
      for (const key of additionalCheck) {
        const value = initialValue[key];
        const isEmpty =
          value === '' || (Array.isArray(value) && value.length === 0);
        updatedErrors[key] = isEmpty;

        if (isEmpty) {
          hasError = true;
        }
      }
    }

    setIsError(updatedErrors);
    return hasError;
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    if (!checkForEmptyValues()) {
      setBtnLoading((prev) => {
        let toggleId;
        if (prev['update_service']) {
          toggleId = { ['update_service']: false };
        } else {
          toggleId = { ['update_service']: true };
        }
        return { ...toggleId };
      });

      if (isServiceEdit) {
        // handleUpdateService();
        if (fieldUpdated) {
          setConfirmEdit(true);
        } else {
          setBtnLoading(false);
        }
      } else {
        handleAddService();
      }
    } else {
      // toast.error('Please fill all the required fields');
      console.log('Please fill all the required fields');
    }
  };

  const handleEditDialog = (value) => {
    if (value === 'Continue') {
      handleUpdateService();
      setConfirmEdit(false);
    } else if (value === 'Discard') {
      setBtnLoading(false);
      setConfirmEdit(false);
    } else if (value === 'Cancel') {
      setConfirmEdit(false);
    }
  };

  const handleAddService = async () => {
    setIsLoading(true);
    // let encodedLocationId = encodeURIComponent(locationId);
    // debugger

    const data = {
      locationId: locationId,
      franchiseeId: franchiseeId,
      typeOfService: serviceType && serviceType.toLowerCase(),
      name: initialValue.name,
      emailNote: initialValue.emailNote,
      description: initialValue.description,
      duration: initialValue.duration,
      // difficultyLevelId: initialValue.level,
      // difficultyLevel: getNameById(initialValue.level, allLevels),
      categoryId: initialValue.category,
      categoryName: getNameById(initialValue.category, categories),
      // prerequisites: convertToObjects(initialValue.preRequistie),
      createdBy: userId
      // providerName: '',
      // pricingOptionId: '',
      // isActive: true,
      // isFree: true,
      // servicePrice: initialValue.servicePrice,
      // lockStatus: false
    };

    if (serviceType === 'enrollment') {
      data.maxCapacity = initialValue.maxCapacity;
      data.maxWaitlist = initialValue.maxWaitlist;
      // data.session = initialValue.noOfSesssion;
      data.prerequisites = convertToObjects(initialValue.preRequistie);
      // data.defaultPricingOption = selectedPricingOptions; // initialValue.pricingOptionId; // allPricingOptions[0]
    }

    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}services`,
        data
      );

      if (!response.data?.error) {
        // toast.success(response.data?.message);
        console.log(response.data?.message);
        getAllPricingOptions(response.data?.serviceId);
        setInitialValue({
          ...initialValue,
          serviceId: encodeURIComponent(response.data?.serviceId)
        });
        setIsServiceEdit(false);
        setDialog({
          isCreated: true,
          state: true
        });
      } else {
        // toast.error(response.data?.message || 'Something went wrong with add Service');
        console.log(
          response.data?.message || 'Something went wrong with add Service'
        );
        setShowMessage(true);
        setErrorMessage(
          response.data?.message || 'Something went wrong with add Service'
        );
      }

      setBtnLoading(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      console.error(error);
      setBtnLoading(false);
      setIsLoading(false);
      // toast.error('Something went wrong, Try again!');
      setShowMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      console.log('Something went wrong, Try again!');
    }
  };

  const handleUpdateService = async () => {
    setIsLoading(true);
    const encodedLocationId = encodeURIComponent(locationId);

    const data = {
      franchiseeId: franchiseeId,
      // locationId: locationId,
      typeOfService: serviceType && serviceType.toLowerCase(),
      name: initialValue?.name,
      emailNote: initialValue?.emailNote,
      description: initialValue?.description,
      duration: initialValue?.duration,
      // difficultyLevelId: initialValue.level,
      // difficultyLevel: getNameById(initialValue.level, allLevels),
      categoryId: initialValue?.category,
      categoryName: getNameById(initialValue?.category, categories),
      // prerequisites: convertToObjects(initialValue.preRequistie),
      // servicePrice: initialValue.servicePrice.toString(),
      updatedBy: userId,
      // providerName: '',
      // lockStatus: false,
      // pricingOptionId: initialValue.pricingOptionId,
      status: initialValue?.status === '' ? 1 : initialValue?.status,
      // credit: initialValue?.numberOfSessions
      isFranchisor: serviceDetails?.isFranchisor
    };

    if (serviceType === 'enrollment') {
      data.maxCapacity = initialValue?.maxCapacity;
      data.maxWaitlist = initialValue?.maxWaitlist;
      data.prerequisites = convertToObjects(initialValue?.preRequistie);
      data.defaultPricingOption = selectedPricingOptions; // initialValue.pricingOptionId; // allPricingOptions[0]
      // data.session = initialValue?.numberOfSessions
      //  ? initialValue?.numberOfSessions.toString()
      //  : '';
    }
    if (serviceType === 'appointment') {
      data.defaultPricingOption = selectedPricingOptions; // initialValue.pricingOptionId;
    }

    try {
      const response = await ApiService.put(
        // `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}services/updateService?locationId=${encodedLocationId}&serviceId=${initialValue.serviceId}`,
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}services/updateService?locationId=${encodedLocationId}&serviceId=${initialValue.serviceId}`,
        data
      );
      if (!response?.error) {
        setIsLoading(false);
        setIsServiceEdit(true);
        setFieldUpdated(false);
        setConfirmEdit(false);
        setDialog({
          isCreated: true,
          state: true
        });
        setServiceUpdateSuccess(true);
        clearStates();
      }
    } catch (error) {
      setIsLoading(false);

      console.error(error);
      setBtnLoading(false);
      // toast.error('Something went wrong, Try again!');
      console.log('Something went wrong, Try again!');
      setShowMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  const getAllPricingOptions = async (serviceid) => {
    let categoryID = encodeURIComponent(initialValue.category);
    let serviceID = encodeURIComponent(serviceid);
    let locationID = encodeURIComponent(locationId);

    setPricingLoading(true);
    try {
      const [pricingOptionsResponse] = await Promise.all([
        ApiService.get(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options/getPricingOptionWithCategoryAndServiceId?locationId=${locationID}&categoryId=${categoryID}&serviceId=${serviceID}`
        )
      ]);
      if (!pricingOptionsResponse?.data?.error) {
        const modifiedData = pricingOptionsResponse?.data?.map((item) => ({
          ...item,
          id: item.sortKey
        }));

        setAllPricingOptions(modifiedData);
      } else {
        // toast.error( pricingOptionsResponse.message || 'Something went wrong with Pricing options');
        console.log(
          pricingOptionsResponse.message ||
            'Something went wrong with Pricing options'
        );
      }

      setPricingLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setPricingLoading(false);
      // toast.error('Internal Server Error');
      console.log('Internal Server Error');
    }
  };

  const getAllPricingOptionsEdit = async (serviceId) => {
    let categoryID = encodeURIComponent(serviceDetails.categoryId);

    let locationID = encodeURIComponent(locationId);

    setPricingLoading(true);
    try {
      const [pricingOptionsResponse] = await Promise.all([
        ApiService.get(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options/getPricingOptionWithCategoryAndServiceId?locationId=${locationID}&categoryId=${categoryID}&serviceId=${serviceId}`
        )
      ]);
      if (!pricingOptionsResponse?.data?.error) {
        const modifiedData = pricingOptionsResponse?.data?.map((item) => ({
          ...item,
          id: item.sortKey
        }));
        console.log(
          pricingOptionsResponse.data,
          'modifiedDatamodifiedDatamodifiedDatamodifiedData'
        );
        setAllPricingOptions(modifiedData);
      } else {
        // toast.error( pricingOptionsResponse.message || 'Something went wrong with Pricing options');
        console.log(
          pricingOptionsResponse.message ||
            'Something went wrong with Pricing options'
        );
      }

      setPricingLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setPricingLoading(false);
      // toast.error('Internal Server Error');
      console.log('Internal Server Error');
    }
  };

  useEffect(() => {
    if (isServiceEdit) {
      getAllPricingOptionsEdit(encodeURIComponent(serviceDetails?.sortKey));
    }
  }, [isServiceEdit]);

  console.log(serviceDetails, 'serviceDetails');

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      <div className="service-container">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={1}
          mb={4}
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleCloseScreen()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {isServiceEdit ? 'Edit Service' : 'Add Service'}
              </Typography>
            </Box>
          </Grid>
          <Grid item></Grid>
        </Grid>

        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <form className="custom-form" onSubmit={handleFormSubmission}>
            <div>
              <Grid container spacing={2} columns={12} mt={2}>
                <Grid item xs={6} sm={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 32,
                          color: '#003087'
                        },
                        '& .MuiFormControlLabel-label': {
                          color: '#000000'
                        },
                        '& .MuiFormControlLabel-root': {
                          marginRight: '40px'
                        }
                      }}
                    >
                      <FormControlLabel
                        value="enrollment"
                        control={<Radio />}
                        label="Enrollment"
                        checked={serviceType === 'enrollment'}
                        onChange={handleServiceType}
                        disabled={isServiceEdit}
                      />
                      <FormControlLabel
                        value="appointment"
                        control={<Radio />}
                        label="Appointment"
                        checked={serviceType === 'appointment'}
                        onChange={handleServiceType}
                        disabled={isServiceEdit}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    fullWidth
                    name="category"
                    value={initialValue.category}
                    onChange={handleServiceInfo}
                    label="SERVICE CATEGORY"
                    required={Boolean(true)}
                    // error={isError.category}
                    classLabelProps="input-field-dropdown-styling"
                    helperText={isError.category && 'Category is required'}
                    options={categories?.map((item) => ({
                      label: item.name,
                      value: item.sortKey
                    }))}
                    disabled={
                      isServiceEdit &&
                      (disabledFiledValues === 'franchisorEnrollment' ||
                        disabledFiledValues === 'franchisorAppointment')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    type="text"
                    name="name"
                    label="NAME"
                    fullWidth
                    // error={isError.name}
                    required={Boolean(true)}
                    disabled={
                      isServiceEdit &&
                      (disabledFiledValues === 'franchisorEnrollment' ||
                        disabledFiledValues === 'franchisorAppointment')
                    }
                    helperText={isError.name && 'Name is required'}
                    value={initialValue.name}
                    onChange={handleServiceInfo}
                  />
                </Grid>
              </Grid>

              {/* <Grid container spacing={2} columns={12} mt={1}>
                      
                      <Grid item xs={12} sm={6}>
                        <CustomSelect
                          fullWidth
                          label="PREREQUISTIE"
                          multiline
                          rows="6"
                          type="text"
                          name="preRequistie"
                          // error={isError.preRequistie}
                          // helperText={
                          //   isError.preRequistie && 'PreRequistie is required'
                          // }
                          value={initialValue.preRequistie}
                          classLabelProps="input-field-dropdown-styling"
                          onChange={handleServiceInfo}
                        />
                      </Grid>
                    </Grid> */}

              <Grid container spacing={2} columns={12} mt={1}>
                {/* <Grid item xs={12} sm={6}>
                        <CustomSelect
                          fullWidth
                          label="LEVEL"
                          name="level"
                          value={initialValue.level}
                          // required={Boolean(true)}
                          onChange={handleServiceInfo}
                          options={allLevels?.map((item) => ({
                            label: item.name,
                            value: item.difficultyLevelId
                          }))}
                        />
                      </Grid> */}
                {serviceType === 'enrollment' && (
                  <Grid item xs={12} sm={6}>
                    <CustomSelect
                      fullWidth
                      multiple
                      // disabled={
                      //   isServiceEdit &&
                      //   disabledFiledValues === 'franchisorEnrollment'
                      // }
                      label="PREREQUISITES"
                      name="preRequistie"
                      // error={isError.preRequistie}
                      // helperText={
                      //   isError.preRequistie && 'PreRequistie is required'
                      // }
                      value={initialValue.preRequistie}
                      classLabelProps="input-field-dropdown-styling"
                      onChange={handleServiceInfo}
                      options={allPreReq?.map((item) => ({
                        label: item.title,
                        value: item.trophyId
                      }))}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="DURATION (IN MINUTES)"
                    type="number"
                    name="duration"
                    required={Boolean(true)}
                    maxLength={3}
                    min={0} // Minimum value set to zero
                    // error={isError.duration}
                    helperText={isError.duration && 'Duration is required'}
                    value={initialValue.duration}
                    onChange={handleServiceInfo}
                    // disabled={
                    //   isServiceEdit &&
                    //   (disabledFiledValues === 'franchisorEnrollment' ||
                    //     disabledFiledValues === 'franchisorAppointment')
                    // }
                  />
                </Grid>
              </Grid>

              {serviceType === 'enrollment' && (
                <Grid container spacing={2} columns={12} mt={1}>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      fullWidth
                      label="MAXIMUM CAPACITY"
                      type="number"
                      required={Boolean(true)}
                      name="maxCapacity"
                      maxLength={3}
                      min={0} // Minimum value set to zero
                      // error={isError.maxCapacity}
                      helperText={isError.maxCapacity && 'Capacity is required'}
                      value={initialValue.maxCapacity}
                      onChange={handleServiceInfo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      fullWidth
                      label="MAXIMUM WAITLIST"
                      type="number"
                      required={Boolean(true)}
                      name="maxWaitlist"
                      maxLength={3}
                      min={0} // Minimum value set to zero
                      // error={isError.maxWaitlist}
                      helperText={isError.maxWaitlist && 'Waitlist is required'}
                      value={initialValue.maxWaitlist}
                      onChange={handleServiceInfo}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2} columns={12} mt={1}>
                {/* {serviceType === 'Enrollment' && (
                        <Grid item xs={12} sm={6}>
                          <CustomTextField
                            fullWidth
                            label="NUMBER OF SESSION"
                            type="number"
                            name="noOfSesssion"
                            required={Boolean(true)}
                            maxLength={2}
                            min={0}
                            error={isError.noOfSesssion}
                            helperText={
                              isError.noOfSesssion &&
                              'Number of sessions is required'
                            }
                            value={initialValue.noOfSesssion}
                            onChange={handleServiceInfo}
                          />
                        </Grid>
                      )} */}
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="DESCRIPTION"
                    multiline
                    rows="6"
                    type="text"
                    name="description"
                    required={Boolean(true)}
                    // disabled={
                    //   isServiceEdit &&
                    //   (disabledFiledValues === 'franchisorEnrollment' ||
                    //     disabledFiledValues === 'franchisorAppointment')
                    // }
                    // disabled={isServiceEdit}
                    // error={isError.description}
                    helperText={
                      isError.description && 'Description is required'
                    }
                    value={initialValue.description}
                    onChange={handleServiceInfo}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="EMAIL NOTE"
                    multiline
                    // required={Boolean(true)}
                    rows="6"
                    // disabled={
                    //   isServiceEdit &&
                    //   disabledFiledValues === 'franchisorEnrollment'
                    // }
                    type="text"
                    name="emailNote"
                    error={isError.emailNote}
                    helperText={
                      (isError.emailNote && 'Email note is required') ||
                      emailError
                    }
                    value={initialValue.emailNote}
                    onChange={handleServiceInfo}
                  />
                </Grid>
              </Grid>
              {isServiceEdit && (
                <Grid container spacing={2} columns={12} mt={1}>
                  <Grid item xs={12} sm={6}>
                    <CustomSelect
                      fullWidth
                      name="status"
                      value={initialValue.status}
                      onChange={handleServiceInfo}
                      disabled={
                        isServiceEdit &&
                        (disabledFiledValues === 'franchisorEnrollment' ||
                          disabledFiledValues === 'franchisorAppointment')
                      }
                      label="STATUS"
                      options={[
                        {
                          label: 'Active',
                          value: 1
                        },
                        {
                          label: 'Inactive',
                          value: 0
                        }
                      ]}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container columns={12} mt={2} justifyContent="flex-end">
                <Grid item>
                  <CustomButton
                    text="Save"
                    variant="contained"
                    loading={btnLoading['update_service']}
                    onClick={() =>
                      document.getElementById('service-save').click()
                    }
                  >
                    Save
                  </CustomButton>
                  <button hidden type="submit" id="service-save">
                    save
                  </button>
                </Grid>
              </Grid>
            </div>
          </form>

          <Dialog
            open={dialog.state}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleDialog('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              {dialog.isCreated ? '' : 'Discard Changes?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                  {!dialog.isCreated ? (
                    <Typography className="title-text-sub">
                      You have unsaved changes.
                      <Typography className="title-text-sub">
                        What do you want to do?
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography className="title-text-sub">
                      {`Service has been ${
                        isServiceEdit ? 'updated' : 'created'
                      } successfully!`}
                    </Typography>
                  )}
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleDialog(dialog.isCreated ? 'Save' : 'Discard')
                }
              >
                {dialog.isCreated ? 'Close' : 'Discard Changes'}
              </Button>
              {!dialog.isCreated && <Divider className="vertical-divider" />}
              {!dialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleDialog('Continue')}
                >
                  Go Back to Editing
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            open={confirmEdit}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleEditDialog('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              Save Changes
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                  <Typography className="title-text-sub">
                    Please confirm that you want to save your changes?
                  </Typography>
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleEditDialog(dialog.isCreated ? 'Discard' : 'Discard')
                }
              >
                Go Back to Editing
              </Button>
              {!dialog.isCreated && <Divider className="vertical-divider" />}
              {!dialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleEditDialog('Continue')}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      </div>
    </>
  );
};
