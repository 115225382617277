import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Container,
  Button,
  Box,
  IconButton,
  Typography,
  Card,
  CardHeader
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ProfileDetails from './ProfileDetails';
import { getLocalData } from '../../utils';
// import AllTasks from './AllTasks';
// import AllNotes from './AllNotes';
import ClockInClockOutDrawer from '../../content/applications/StaffManagement/StaffProfile/ClockInClockOutDrawer';
import ClockIn from '../../content/applications/StaffManagement/clockIn';
import EditStaff from 'src/content/applications/StaffManagement/EditStaff';
import UserEditDetails from 'src/content/applications/UserManagement/userEdit';
import EditOwner from 'src/content/applications/FranchiseeManagement/EditOwner';
import Loader from '../Loader';
import { ApiService } from 'src/services/ApiService';
import { UserContext } from 'src/contexts/UserContext';

function StaffProfile({ Id }) {
  let id = Id;
  const [rolesList, setRolesList] = useState([]);
  const [userAdminRoles, setUserAdminRoles] = useState([]);
  const [statesList, setstatesList] = useState([]);
  const [profileDetails, setProfileDetails] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const [openClock, setOpenClock] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [clockIn, setClockIn] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [clockType, setClockType] = useState();
  const { isUserUpdate, setIsUserUpdate } = useContext(UserContext);

  console.log(clockIn, openClock, anchor, clockType);

  let uName =
    getLocalData('UserDetails') && JSON.parse(getLocalData('UserDetails')).name;
  const locationId = getLocalData('locationId');
  const roleName = getLocalData('userRole');
  const franchiseeId = getLocalData('franchiseeId');
  console.log(uName, 'uNameuNameuName');
  // useeffect state starts here
  useEffect(() => {
    // id = itemDetails;
    setAllTask([]);
    setAllNotes([]);
    // console.log("Id", id);
    // call the get client details method
    if (id) getStaffProfile(id);
  }, [id, isEdit]);

  useEffect(() => {
    getStates();
    getRoles();
    getUserRolesdata();
  }, []);

  const handleClickEdit = () => {
    setIsEdit(true);
  };
  const getUserRolesdata = async () => {
    ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}users/getFranchisorUserRoles`
    )
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data?.data?.Items;
          setUserAdminRoles(Item);
        }
      });
  };

  // Method to get the staff profile details
  const getStaffProfile = async (id) => {
    setIsLoading(true);
    let adminData = {
      userId: id,
      role: roleName
    };

    let ownerData = {
      userId: id,
      role: roleName,
      franchiseeId: franchiseeId
    };

    let managerData = {
      userId: id,
      role: roleName,
      locationId: locationId
    };

    const body =
      roleName === 'Admin' || roleName === 'Super Manager'
        ? adminData
        : roleName === 'Owner'
        ? ownerData
        : managerData;

    let url = `${process.env.REACT_APP_API_END_POINT}staff/getUserDetailsData`;
    try {
      const data = await ApiService.post(`${url}`, body);

      if (data) {
        let items = data?.data?.Items;
        if (items) {
          setProfileDetails(items[0]);
          setAllTask(items[0]?.tasks);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };
  const onMenuItemClick = (staffItem, event, value) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchor(null);
    if (value === 'clockIn') {
      setClockIn(true);
      setClockType('ClockIn');
      setOpenClock(true);
    } else if (value === 'clockOut') {
      setClockIn(true);
      setClockType('ClockOut');
      setOpenClock(true);
    }
  };

  const getRoles = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_FEATURED_API_END_POINT}users/getInvolvementLevel`
      );
      if (response?.statusCode && !response?.error) {
        let Item = response?.data?.Items;
        setRolesList(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`
      );
      if (response.statusCode && !response.error) {
        let Item = response?.data?.Items;
        setstatesList(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Staff Profile Details</title>
      </Helmet>

      <Container sx={{ mt: 2 }} maxWidth="100%" className="p-0">
        {isEdit ? (
          <>
            {roleName === 'Admin' || roleName === 'Super Manager' ? (
              <UserEditDetails
                selectedData={profileDetails}
                refreshUserList={() => ''}
                // showForm={setIsChange}
                setShowScreen={'setShowScreen'}
                setSearchText={() => console.log('')}
                profileUserData={profileDetails}
                setIsEdit={setIsEdit}
                statesList={statesList}
                userRoles={userAdminRoles}
                setIsUserUpdate={setIsUserUpdate}
                isUserUpdate={isUserUpdate}
              />
            ) : (
              ''
            )}

            {roleName !== 'Admin' &&
              roleName !== 'Super Manager' &&
              roleName !== 'Owner' && (
                <EditStaff
                  staffItem={'getStaffItem'}
                  getStaffDetailById={'getStaffDetailById'}
                  setShowScreen={'setShowScreen'}
                  getStaff={'getStaff'}
                  profileUserData={profileDetails}
                  setIsEdit={setIsEdit}
                  setIsUserUpdate={setIsUserUpdate}
                  isUserUpdate={isUserUpdate}
                />
              )}

            {roleName === 'Owner' && (
              <EditOwner
                data={profileDetails}
                refreshFranchiseeData={() => ''}
                Transition={'Transition'}
                setShowScreen={'setShowScreen'}
                statesList={statesList}
                rolesList={rolesList}
                profileUserData={profileDetails}
                setIsEdit={setIsEdit}
                setIsUserUpdate={setIsUserUpdate}
                isUserUpdate={isUserUpdate}
              />
            )}
          </>
        ) : (
          <>
            {/* header section */}
            <Grid container className="location-header-container">
              <Grid
                xs={8}
                sm={8}
                md={8}
                lg={8}
                className="page-header-text title-adjust"
                sx={{ marginTop: '-14px' }}
              >
                <span className="location-name text-wrap">
                  {(profileDetails &&
                    profileDetails.firstName + ' ' + profileDetails.lastName) ||
                    uName}
                </span>
              </Grid>
              {/* edit button */}
              <Grid
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
                className="align-left0"
              >
                {/* Edit Button */}
                <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 1 }}
                  variant="contained"
                  id={profileDetails && profileDetails?.staffId}
                  onClick={handleClickEdit}
                  className="btn-primary edit-button"
                >
                  EDIT PROFILE
                </Button>
                {roleName !== 'Owner' &&
                roleName !== 'Admin' &&
                roleName !== 'super manager' ? (
                  <ClockInClockOutDrawer onMenuItemClick={onMenuItemClick} />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>

            {/* Body section */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
              sx={{ mt: 1 }}
            >
              <Grid item xs={12} md={4} sm={4} className="h-100">
                {/* <ProfileCover user={user} /> */}
                <Box
                  className="product-details-img align-center relative mb-10 d-flex v-middle align-items-center justify-content-center"
                  sx={{ border: '1px solid #A5A5A5' }}
                >
                  {/* {profileDetails && profileDetails.staffProfileImage && ( */}
                  <img
                    className="product-img no-media absolut"
                    src={
                      profileDetails?.profileImage &&
                      profileDetails?.profileImage !== 'undefined'
                        ? profileDetails?.profileImage
                        : profileDetails?.staffProfileImage &&
                          profileDetails?.staffProfileImage !== 'undefined'
                        ? profileDetails?.staffProfileImage
                        : '/static/images/products/ProductImage.png'
                    }
                    alt="product-icon"
                  />
                  {/* // )} */}
                </Box>
                {/* All tasks section */}
                {/* <AllTasks data={allTask} headerText={"ALL TASKS"} headerRequired={false} /> */}
                {roleName !== 'Owner' &&
                  roleName !== 'Admin' &&
                  roleName !== 'super manager' && (
                    <Card
                      sx={{
                        height: '100%',
                        border: '1px solid #A5A5A5',
                        padding: '0 18px'
                      }}
                      className="mt-10"
                    >
                      <Box
                        py={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          sx={{
                            // fontWeight: 600,
                            // color: '#003087'
                            letterSpacing: '0.1em'
                          }}
                          className="weight-700 text-primary"
                        >
                          ALL TASKS{' '}
                        </Typography>
                        {5 > 10 && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              letterSpacing: '0.135em',
                              cursor: 'pointer'
                            }}
                          >
                            <Typography
                              sx={{
                                // fontWeight: 700,
                                // color: '#003087',
                                textDecoration: 'underline',
                                marginRight: '10px'
                              }}
                              className="add-task weight-700 text-primary"
                            >
                              +ADD TASK
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      {/* Start Now */}

                      <Box
                        className="overflow-auto"
                        sx={{ maxHeight: '300px' }}
                      >
                        {(allTask?.length > 0 &&
                          allTask?.map((taskItem) => {
                            return (
                              <Grid
                                className="mb-10"
                                xs={12}
                                container
                                sx={{
                                  p: 2,
                                  border: ' 0.4px solid #E7EFF9',
                                  borderRadius: '5px',
                                  backgroundColor: '#FFFFFF'
                                }}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      pb: 1
                                    }}
                                  >
                                    <span className="task-name">
                                      {taskItem?.title || taskItem?.title}{' '}
                                    </span>
                                  </Typography>

                                  <Typography sx={{ color: '#A8A4A4' }}>
                                    {taskItem?.notes || taskItem?.description}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })) || (
                          <Grid container mb={3} className="task-details-row">
                            No Task Found
                          </Grid>
                        )}
                      </Box>
                    </Card>
                  )}
              </Grid>

              <Grid item xs={12} md={8} sm={8}>
                {/* profile details section */}
                <ProfileDetails profileDetails={profileDetails} />
                {/* Notes section */}
                {5 > 10 && (
                  <Box className="mt-10">
                    {/* <AllNotes data={allNotes} headerText={"NOTES"} headerRequired={Boolean('true')} /> */}
                    <Card
                      sx={{
                        height: '100%',
                        border: '1px solid #A5A5A5',
                        padding: '0 18px'
                      }}
                    >
                      <Box>
                        <CardHeader
                          action={
                            <IconButton aria-label="settings">
                              <MoreVertIcon />
                            </IconButton>
                          }
                          title="NOTES"
                          className="text-primary card-header-style"
                        />
                        {/* <Divider /> */}
                      </Box>

                      {/* Start Now */}

                      <Box className="overflow-auto">
                        {(allNotes.length > 0 &&
                          allNotes.map((taskItem) => {
                            return (
                              <Grid
                                className="mb-10"
                                xs={12}
                                container
                                sx={{
                                  p: 2,
                                  border: ' 0.4px solid #E7EFF9',
                                  borderRadius: '5px',
                                  backgroundColor: '#FFFFFF'
                                }}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      pb: 1
                                    }}
                                  >
                                    <span className="task-name">
                                      {taskItem.notes || taskItem.mgrNotes}{' '}
                                    </span>
                                  </Typography>

                                  <Typography sx={{ color: '#A8A4A4' }}>
                                    {taskItem.notes || taskItem.description}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })) || (
                          <Grid container mb={3} className="notes-details-row">
                            No Notes Found
                          </Grid>
                        )}
                      </Box>
                    </Card>
                  </Box>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
      <Box justifyContent="center" direction="column">
        {openClock && (
          <ClockIn
            getStaffItem={profileDetails ?? {}}
            clockType={clockType}
            openClock={openClock}
            setOpenClock={setOpenClock}
          />
        )}
      </Box>
      <Loader IsLoading={IsLoading} />

      <Footer />
    </>
  );
}

export default StaffProfile;
