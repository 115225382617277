/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { CustomButton, SearchInput } from 'src/components/Global';
import Header from '../../../components/Header';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../components/Loader';
import { getLocalData } from 'src/utils';
import { AddJourney } from './AddJourney';
import { JourneyFilters } from './JourneyFilters';
import { JourneyTable } from './JourneyTable';
import GlobalData from 'src/global';
import FranchiseeJourneyGridView from './FranchiseeJourneyGridView';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Journey = () => {
  const locationId = getLocalData('locationId');
  const title = GlobalData[0].title;
  const [loading, setLoading] = useState(true);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [trophyDetails, setTrophyDetails] = useState();
  const [isServiceEdit, setIsServiceEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
  const [totalCount, setTotalCount] = useState('');
  const [activePagination, setactivePagination] = useState('');

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  // const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    // isActive: null,
    status: 'All',
    activityStatus: null,
    startDate: null,
    endDate: null
  });

  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);

  useEffect(() => {
    const data = servicesData?.filter((item) =>
      selectedRows?.includes?.(item.id)
    );
    setSelectedRowsData(data);
  }, [selectedRows]);

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
    setIsServiceEdit(false);
    setTrophyDetails();
    setSelectedRows([]);
    setSelectedRowsData([]);
    setSelectedStatus('');

    //call api for getting latest data of services
    getAllJourney();
  };

  function findObjectById(id) {
    return servicesData?.find((obj) => obj.id === id);
  }

  const openDetail = (id, screen, e) => {
    e.preventDefault();
    e.stopPropagation();

    setTrophyDetails(findObjectById(id));
    setIsServiceEdit(true);
    handleOpenScreen(screen);
  };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  //   ===============call api here=============

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === 'All' ||
        filtersParams[key] === undefined ||
        (Array.isArray(filtersParams[key]) && filtersParams[key].length === 0)
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getAllJourney = async () => {
    setLoading(true);
    setTableLoading(true);
    let queryString = filtersQuery();
    const encodedLocationId = encodeURIComponent(locationId)
    try {
      let trophyResponse = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAlljourney?locationId=${encodedLocationId}&${queryString}`
      );
      if (!trophyResponse?.data?.error) {
        const modifiedData = trophyResponse?.data?.Items?.map((item) => ({
          ...item,
          id: item.sortKey
        }));
        setTotalCount(trophyResponse?.data?.Count);
        setServicesData(modifiedData);
        setLoading(false);
      } else {
        setLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          trophyResponse.message || 'Something went wrong with Services'
        );
      }
      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  useEffect(() => {
    getAllJourney();
  }, [JSON.stringify(filters)]);

  function getJourneyIds() {
    let array = [];
    selectedRowsData?.map((item) => array.push(item.sortKey));

    return array;
  }

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setLoading(true);
    setOpen(false);
    setBtnLoading(true);

    const data = {
      status: selectedStatus,
      journeyIds: getJourneyIds()
    };

    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/journey/updateJourneyStatus`,
        data
      );
      if (!response?.error) {
        setLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage(response?.message || 'Journey status updated');
        handleResetStates();
        setIsUpdated(true);
      } else {
        setLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          response?.message ||
            'Something went wrong with update journey status'
        );
      }

      setBtnLoading(false);
      getAllJourney();
    } catch (error) {
      console.error(error);
      setLoading(false);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        handleResetStates();
        setIsUpdated(false);
      }
    }
  };
            
  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  return (
    <>
      <Helmet>
        <title>Journey Management | {title}</title>
      </Helmet>
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          getDetails={getAllJourney}
          closePopup={closePopup}
        />
      )}

      <Container maxWidth="100%" className="custom-container">
        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <Box justifyContent="center" direction="column">
                  <AddJourney
                    handleOpenScreen={handleOpenScreen}
                    handleResetStates={handleResetStates}
                    isServiceEdit={isServiceEdit}
                    setIsServiceEdit={setIsServiceEdit}
                    allCategories={allCategories}
                    trophyDetails={trophyDetails}
                  />
                </Box>
              );

            default:
              return (
                <Box justifyContent="center" direction="column">
                  <Header title="Journey" />
                  {isFranchisor === 'true' && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={3}
                    >
                      <Grid item width={'100%'}>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-end"
                          >
                            <Grid item marginY="20px">
                              <CustomButton
                                onClick={() => setIsFilters(!isFilters)}
                                variant={isFilters ? 'contained' : 'outlined'}
                              >
                                Filter (All)
                                <KeyboardArrowDownRoundedIcon
                                  className={clsx(
                                    !isFilters && classes.closeY,
                                    isFilters && classes.openY
                                  )}
                                />
                              </CustomButton>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={6}
                              marginY={{ sm: '20px' }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                              }}
                            >
                              <SearchInput
                                value={searchQuery}
                                setSearchQuery={setSearchQuery}
                                label="Search Journey"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <>
                      {isFilters && isFranchisor === 'true' ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <JourneyFilters
                            filters={filters}
                            setFilters={setFilters}
                            allCategories={allCategories}
                            allLevels={allLevels}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        {isFranchisor === 'true' ? (
                          <JourneyTable
                            tableRows={servicesData}
                            searchQuery={searchQuery}
                            openDetail={openDetail}
                            loading={tableLoading}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            selectedStatus={selectedStatus}
                            setSelectedStatus={setSelectedStatus}
                            handleBulkAdd={handleBulkAdd}
                            btnLoading={btnLoading}
                            handleResetStates={handleResetStates}
                          />
                        ) : (
                          <FranchiseeJourneyGridView
                            journeyLists={servicesData}
                            getAllJourney={ getAllJourney}
                            sideBarfilters={filters}
                            setSidebarFilters={setFilters}
                            totalCount={totalCount}
                            paginationProducts={getAllJourney}
                            setactivePagination={setactivePagination}
                            handleResetStates={handleResetStates}
                          />
                        )}
                      </Grid>
                    </>
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Save Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes, Do you want to
                  <Typography className="title-text-sub">save them?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Journey's status has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'No'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Journey;
