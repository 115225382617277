import moment from 'moment';
import React from 'react';

const PurchaseReceipt = ({ selected }) => {
  const infoWidth = '50%'; // Width for the data above and below the table
  const dateFormate = (value) => {
    if (!value) return '';
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    });

    // Parse the formatted date with Moment.js
    const utc = moment.utc(formattedDate).format('MMM DD, YYYY');
    return utc;
  };

  return (
    <div
      className="receipt"
      style={{ border: '1px solid #000', padding: '10px', textAlign: 'center' }}
    >
      <h2 style={{ textAlign: 'left', width: infoWidth }}>Receipt</h2>
      <div
        className="receipt-info"
        style={{
          borderBottom: '1px solid #000',
          marginBottom: '10px',
          textAlign: 'left',
          width: infoWidth
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '5px'
          }}
        >
          <p>Purchase-Orders:</p>
          <p>{selected?.orderNumber}</p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '5px'
          }}
        >
          <p>Date:</p>
          <p>{dateFormate(selected?.createdAt)}</p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '5px'
          }}
        >
          <p>Due Date:</p>
          <p>{dateFormate(selected?.createdAt)}</p>
        </div>
      </div>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th
              style={{ border: '1px solid #000', padding: '8px', width: '50%' }}
            >
              Item Name
            </th>
            <th
              style={{ border: '1px solid #000', padding: '8px', width: '25%' }}
            >
              Quantity
            </th>
            <th
              style={{ border: '1px solid #000', padding: '8px', width: '25%' }}
            >
              Price
            </th>
            <th
              style={{ border: '1px solid #000', padding: '8px', width: '25%' }}
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {selected?.items?.map((product, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                {product?.itemName}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                {product?.quantity?.toFixed(2) || 1}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                ${parseFloat(product?.cost)?.toFixed(2)}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                ${parseFloat(product?.amount)?.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="totals"
        style={{ marginTop: '10px', textAlign: 'right', width: infoWidth }}
      >
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 'bold' }}>Subtotal:</span>
          <span>${parseFloat(selected?.total)?.toFixed(2)}</span>
        </p>

        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 'bold' }}>Shipping Cost:</span>
          <span>${parseFloat(selected?.shippingCost)?.toFixed(2)}</span>
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 'bold' }}>Total:</span>
          <span>${parseFloat(selected?.total)?.toFixed(2)}</span>
        </p>
      </div>
    </div>
  );
};

export default PurchaseReceipt;
