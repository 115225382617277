import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import Footer from 'src/components/Footer';
import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material';
import TransferHistoryListAPI from './TransferHistoryListAPI';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { getLocalData } from 'src/utils';

function TransferHistory() {
  const navigate = useNavigate();
  const title = GlobalData?.[0]?.title;

  useEffect(() => {
    let isLogin = getLocalData("Login_Sucess");
    if (isLogin !== 'true') {
      navigate("/login");
    }
  }, [])

  const handleCloseDetails = () => {
    navigate('/inventory-managment');
  };

  return (
    <>
      <Helmet>
        <title>Transfer History | {title}</title>
      </Helmet>
      <Box sx={{ paddingX: "22px" }} className='mt-10' >
        <Typography
          variant="variant2"
          sx={{
            fontSize: '24px',
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
          className='text-primary weight-600'
        >
          <Box
            onClick={() => handleCloseDetails()}
            className="back-arrow-close mt-10"
          >
            <ArrowLeft />
          </Box>
          <Typography className="header-blue-40 ">
            Transfer History
          </Typography>
        </Typography>
      </Box>
      <Container maxWidth="100%" sx={{ mt: 3 }} className='main-container relative'>

        {/* container */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item width={'100%'}>
            <TransferHistoryListAPI />
          </Grid>
        </Grid>

      </Container>
      <Footer />

    </>
  );
}

export default TransferHistory;