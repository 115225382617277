import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import { getLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ApiService } from 'src/services/ApiService';
import { makeStyles } from '@material-ui/core/styles';
// import { CustomServiceTable } from './AddServiceTable';
import Loader from 'src/components/Loader';
import ServiceTable from './ServiceTable';

const StaffService = ({ staffItem }) => {
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefe
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();

  const locationId = getLocalData('locationId');

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [servicesList, setServicesList] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [categoriesIds, setCategoriesIds] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  //   const [categorieValue, setcategorieValue] = useState([]);

  const handleServiceInfo = (e, newValue) => {
    console.log('e', e, newValue);
    setSelectedOptions(newValue);
    // const { name, value } = e.target;
    // console.log('idWithName',value);

    const data = newValue?.map((item) => item?.sortKey);
    const idWithName = newValue?.map((item) => ({
      categoryId: item?.sortKey,
      categoryName: item?.name
    }));
    setCategoriesIds(data);
    setCategoryId(idWithName);
    // }
  };
  useEffect(() => {
    if(categoriesIds.length>0){
      fetchServicesBasedOnCategory(categoriesIds);
    }
  }, [categoriesIds]);
  const handleAddService = (e) => {
    e.preventDefault();
  };
  console.log(categoriesIds, 'categoriesIdscategoriesIds');
  useEffect(() => {
    fetchServices();
  }, []);
  // useEffect(() => {
  //   if (staffItem.availableServices?.length > 0) {
  //     setIsDisabled(true);
  //     const availableSarviceIds = staffItem?.availableServices?.map(
  //       (service) => service?.serviceId
  //     );
  //     setSelectedLists(availableSarviceIds);
  //     setCategoryId(availableSarviceIds);
  //   }
  // }, []);
  useEffect(() => {
    if (staffItem.availableServices?.length > 0) {
      setIsDisabled(true);
      // const availableSarviceIds = roomData?.services?.map((service) => {
      //   service?.categoryId;
      // });
      const availableSarviceIds = staffItem?.availableCategories?.map((service) => ({
        categoryName: service?.categoryName, // You can change this value as needed
        categoryId: service?.categoryId
      }));
      const availableSarviceIdsList = staffItem?.availableServices?.map(
        (service) => service?.serviceId
      );
      setSelectedLists(availableSarviceIdsList);
      setCategoryId(availableSarviceIds);
    }
  }, [staffItem]);
  useEffect(() => {
    if (staffItem.availableCategories?.length > 0) {
      setIsDisabled(true);
      
      const availableCategoryIds = staffItem?.availableCategories?.map(
        (category) => category?.categoryId
      );
      // inputOptions.find((option) => option.id === specificKeyToAutoSelect;
      setCategoriesIds(availableCategoryIds);
    }
  }, []);

  useEffect(() => {
    let selectedData = servicesList.filter((item) =>
      categoriesIds.includes(item.sortKey)
    );
    console.log(
      'availableCategoryIds',
      categoriesIds,
      selectedData,
      servicesList
    );
    setSelectedOptions(selectedData);
  }, [servicesList, categoriesIds]);

  // Get all service categories
  const fetchServices = async () => {
    try {
      const categoriesResponse = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pricing-option/getAllServiceCategories`
      );
      if (!categoriesResponse?.data?.error) {
        // console.log('all category', categoriesResponse?.data?.data?.Items);
        const modifiedServicesData = categoriesResponse?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.sortKey,
            label: item.name, // categoryName
            value: item
          })
        );
        setServicesList(modifiedServicesData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          categoriesResponse.message ||
            'Something went wrong with Service categories'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const fetchServicesBasedOnCategory = async (idData) => {
    setIsLoading(true);

    let data = {
      locationId: locationId,
      categoryIds: idData
    };
    try {
      const servicesResponse = await ApiService.post(
        `${process.env.REACT_APP_FEATURED_API_END_POINT}staff/getAllServicesWithCategory`,
        data
      );

      if (!servicesResponse.error) {
        let Item =
          servicesResponse &&
          servicesResponse?.data &&
          servicesResponse?.data?.Items
            ? servicesResponse?.data?.Items
            : [];
        const result = Item?.map((obj) => {
          const { sortKey, partitionKey, name, categoryName, typeOfService } =
            obj;

          return {
            sortKey,
            partitionKey,
            name,
            'Category Name ': categoryName,
            'Service Type': typeOfService==='enrollment'?'Enrollment':'Appointment'
          };
        });
        setTableData(result);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
    } catch (error) {
      setIsLoading(false);

      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  function findObjectsByIds(tableData, idsToFind) {
    return idsToFind
      .map((sortKey) => {
        const foundObject = tableData?.find(
          (item) => item?.sortKey === sortKey
        );
        if (foundObject) {
          return {
            serviceId: foundObject?.sortKey,
            serviceName: foundObject?.name
          };
        }
        return null;
      })
      .filter(Boolean);
  }

  // add new service
  const addNewService = async () => {
    setIsLoading(true);
    const availableServicesss = findObjectsByIds(tableData, selectedLists);
    let data = {
      locationId: locationId,
      staffId: staffItem?.sortKey,
      availableServices: availableServicesss,
      availableCategories: categoryId
      //   availableCategories:
    };
    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_FEATURED_API_END_POINT}staff/addStaffService`,
        data
      );

      if (!response.error) {
        setIsLoading(false);

        setShowSuccessMessage(true);
        setSuccessMessage('Services has been assigned successfully!');
        setIsDisabled(true);
      } else {
        setIsLoading(false);

        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
      if(Array.isArray(selectedOptions) && !selectedOptions.length) setSelectedLists([])
    } catch (error) {
      setIsLoading(false);

      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <div>
        <hr style={{ border: '1px solid #EAEAEA', margin: '40px' }} />
        <form className="custom-form" onSubmit={handleAddService}>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Autocomplete
                disabled={isDisabled}
                multiple
                // value={categoriesIds}
                classes={autoCompleteClasses}
                options={servicesList || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectedOptions}
                onChange={handleServiceInfo}
               
                className="height-auto"
                // options={locationList.map((option) => option.locationName)}
                // options={servicesList || []}
                // getOptionLabel={(option) => option?.name}
                sx={{
                  width: '100%',
                  borderRadius: '19px ! important'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params?.label || 'Service Category'}
                    className="required-field"
                    // error={locationError}
                    InputLabelProps={{
                      className: 'required-label top-0'
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <Typography variant="h1" color="primary" />
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    isDisabled ? setIsDisabled(false) : addNewService();
                  }}
                >
                  {isDisabled ? 'Update Services' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <div className="mt-20">
          {/* <Box className="location-details-container">
            <Grid container mt={4} className="location-details-row">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="location-details-col"
              >
                <Typography className="field-heading-text font-24">
                  Select Services
                </Typography>
              </Grid>
            </Grid>
          </Box> */}

          <ServiceTable
            data={tableData}
            label="All Services"
            selectedLists={selectedLists}
            setSelectedLists={setSelectedLists}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    </>
  );
};
export default StaffService;
