import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getLocalData } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons';
import { ImageSlider } from 'src/components/Global';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ProductDetailsWrapper = styled(Container)(
  ({ theme }) => `
          margin-top: ${theme.spacing(2)};
  `
);

// LocationDetails page main method
const productDetails = ({
  data,
  getItemForEdit,
  setShowScreen,
  refreshProductData
}) => {
  const navigate = useNavigate();
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let productDetails = data;
  const [open, setOpen] = React.useState(false);
  // Get the loggedIn user Id
  const userId = getLocalData('userId');
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [multipleImages, setMultipleImages] = useState([]);
  const [showDeleteProductSuccess, setShowDeleteProductSuccess] =
    useState(false);

  // Method to open the delete dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      // call the api to delete the location
      deleteProduct();
    }
    setOpen(false);
  };
  useEffect(() => {
    if (data) {
      let images = data?.media?.map((item) => item?.media);
      setMultipleImages(images);
    }
  }, [data]);

  // Method to open the edit dialog
  const handleClickEdit = (e) => {
    let crossElm = document.getElementsByClassName('details-remove-icon')[0];
    if (crossElm) crossElm.click();

    let target = e && e.target;
    let lid;
    if (target) lid = target.getAttribute('id');
    let cls = lid;
    getItemForEdit(cls);
    setShowScreen(2);
  };

  // useeffect state starts here
  useEffect(() => {
    // check if the user is logged in or not
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [productDetails, navigate]);

  useEffect(() => {
    // Get the element
    const element = document.getElementById('content-layout');

    // Check if the element exists
    if (element) {
      // Scroll to the top of the element
      element.scrollTop = 0;
    }
  }, []);

  // Method to delete the location
  const deleteProduct = async () => {
    let productId = productDetails?.sortKey;
    if (productId) {
      // Now show the loader
      setIsLoading(true);
      let body = {
        productId: productId,
        userId: userId
      };
      // call the api to delete the location
      let url = `${process.env.REACT_APP_PRODUCT_API_END_POINT}products`;

      // execute the api
      try {
        const response = await ApiService.delete(`${url}`, body);
        if (!response?.error) {
          setIsLoading(false);
          setShowDeleteProductSuccess(true);
          refreshProductData();
        }
      } catch (error) {
        console.error(error);
        setShowError(true);
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    }
  };

  const handleCloseDetails = () => {
    setShowScreen(0);
  };

  const DetailRow = ({ label, value }) => (
    <Grid container mt={3} className="location-details-row">
      <Grid item xs={12} sm={3} className="location-details-col">
        <Box className="location-details-label summary-heading font-16">
          {label}
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} ml={5} className="location-details-col">
        <Box className="location-details-value summary-value capitalize">
          {value}
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      {/* show loaded element */}
      <Loader IsLoading={isLoading} />

      <ProductDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"
              >
                {/* left arrow icon */}
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                Product Details
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className="details-remove-icon no-hover"
                onClick={handleClickOpen}
              >
                {/* Delete Icon */}
                <DeleteIcon />
              </Button>
              {/* Edit Button */}
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                id={productDetails?.sortKey}
                onClick={handleClickEdit}
                className="btn-primary edit-button"
              >
                Edit
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box className="location-details-container" display={'flex'}>
          <Grid container xs={12} sm={5}>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={12} className="location-details-col">
                <Box className="product-details-img align-center relative">
                  {productDetails &&
                  productDetails?.media &&
                  productDetails?.media?.length !== 0 ? (
                    productDetails?.media?.length > 1 ? (
                      <div>
                        <ImageSlider images={multipleImages} />
                      </div>
                    ) : (
                      productDetails?.media[0] && (
                        <img
                          className="absoloute product-img"
                          src={productDetails?.media[0]?.media}
                          alt="product-icon"
                        />
                      )
                    )
                  ) : (
                    <img
                      className=" product-img no-media"
                      src="/static/images/products/ProductImage.png"
                      alt="product-icon"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} sm={7} className="product-right-cont">
            <DetailRow
              label="ZOOM ROOM PRODUCT NAME"
              value={productDetails?.fName}
            />
            <DetailRow
              label="MANUFACTURER PRODUCT NAME"
              value={productDetails?.mfgName}
            />
            <DetailRow label="SKU CODE" value={productDetails?.sku} />
            <DetailRow
              label="MANUFACTURER"
              value={productDetails?.manufacturer}
            />
            <DetailRow label="CATEGORY" value={productDetails?.categoryName} />

            {productDetails?.variants?.filter((variant) => variant?.value)
              ?.length > 0 && (
              <>
                <DetailRow
                  label="VARIANT TYPE"
                  value={
                    productDetails?.variants &&
                    productDetails?.variants
                      ?.filter((variant) => variant?.value)
                      ?.map((item) => item?.variantType)
                      ?.join(', ')
                  }
                />

                <DetailRow
                  label="VARIANTS"
                  value={
                    productDetails?.variants &&
                    productDetails?.variants
                      ?.filter((variant) => variant?.value)
                      ?.map((item) => item?.value)
                      ?.join(', ')
                  }
                />
              </>
            )}

            <DetailRow
              label="MSRP"
              value={
                productDetails?.price ? `$${productDetails?.price}` : '$0.00'
              }
            />
            <DetailRow
              label="BEST SELLER"
              value={productDetails?.isBestSeller ? 'Yes' : 'No'}
            />
            <DetailRow
              label="REQUIRED"
              value={productDetails?.isRequired ? 'Yes' : 'No'}
            />
            <DetailRow
              label="TAX EXEMPT"
              value={productDetails?.taxExempt ? 'Yes' : 'No'}
            />
            <DetailRow
              label="DISCONTINUED"
              value={productDetails?.isDiscontinued ? 'Yes' : 'No'}
            />

            {productDetails?.productDescription && (
              <DetailRow
                label="DESCRIPTION"
                value={productDetails?.productDescription}
              />
            )}

            {productDetails?.sellingPoints && (
              <DetailRow
                label="SELLING POINT"
                value={productDetails?.sellingPoints}
              />
            )}
          </Grid>
          {showError && (
            <Grid container mt={3} className="location-details-row">
              <Box className="error-message">{errorMessage}</Box>
            </Grid>
          )}
        </Box>
      </ProductDetailsWrapper>

      {/* {delete success popup} */}
      <Dialog
        open={showDeleteProductSuccess}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowDeleteProductSuccess(false);
          setShowScreen(0);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected product has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => {
              setShowDeleteProductSuccess(false);
              setShowScreen(0);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default productDetails;
