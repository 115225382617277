import React from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Divider,
  Slide
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ErrorMessage = ({ message, setShowMessage }) => {
  const [openPopup, setOpenPopup] = React.useState(true);

  const handleClosePopup = () => {
    setShowMessage(false);
    setOpenPopup(false);
  };

  return (
    <>
      <Dialog
        open={openPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">{message}</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-error no-hover"
            onClick={() => handleClosePopup()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorMessage;
