/* eslint-disable no-unused-vars */
import {
  Grid,
  Box,
  Typography,
  Paper,
  Menu,
  MenuItem,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Slide,
  Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Loader from '../../../components/Loader';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { getLocalData } from 'src/utils';
import Calendar from './calendar';
// import MonthView from './MonthView';
// import WeekView from './WeekView';
// import DayView from './DayView';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const StaffDetails = ({
  getStaffItem,
  openStaffDetails,
  setOpenStaffDetails,
  refreshStaffData
}) => {
  const navigate = useNavigate();

  // hook
  useEffect(() => {
    console.log(getStaffItem);
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, []);

  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  let staff = getStaffItem;
  let userId = getLocalData('userId');

  useEffect(() => {
    // getStaff();
  }, [staff]);

  const handleClockClose = () => {
    setOpenStaffDetails(false);
  };

  // Method to close the update dialog
  const handleConfirmationClose = (val) => {
    setOpenStaffDetails(false);
    setOpenConfirmation(false);
    refreshStaffData();
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        open={openStaffDetails}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClockClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right"
        maxWidth="lg"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            {/* remove icon */}
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={() => handleClockClose('Close')}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#FFFFFF ! important' }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <Box className="staff-details">
              <Calendar
                staffDetails={getStaffItem}
                openStaffDetails={openStaffDetails}
                refreshStaffData={refreshStaffData}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog
        open={openConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmationClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                "The clock time has been updated successfully!"
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control test">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmationClose('Discard')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StaffDetails;
