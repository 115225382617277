import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Box,
  Card,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  CardHeader,
  TextField,
  Grid
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import Filters from './Filters';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { format } from 'date-fns';
import { getLocalData } from '../../../utils';
import { ApiService } from 'src/services/ApiService';

// Apply franchisee filter
// const applyFilters = (transferHistoryLists, searchText) => {
//   let arrayOfMatchedObjects = [];
//   if (searchText) {
//     arrayOfMatchedObjects = transferHistoryLists.filter((object) => {
//       return JSON.stringify(object)
//         .toString()
//         .toLowerCase()
//         .includes(searchText.toLowerCase());
//     });
//   } else {
//     arrayOfMatchedObjects = transferHistoryLists;
//   }
//   return arrayOfMatchedObjects;
// };

// Apply paination on table
// const applyPagination = (transferHistoryLists, page, limit) => {
//   return transferHistoryLists.slice(page * limit, page * limit + limit);
// };

// Main methdo to draw the franchisee table
const DrawTransferHistoryTable = ({
  transferHistoryLists,
  sideBarfilters,
  setSidebarFilters,
  refreshData
}) => {
  console.log(transferHistoryLists);
  /* Function to redirect on to the other route */
  const navigate = useNavigate();
  // const classes = useStyles();
  const [selectedHistoryLists] = useState([]);
  const containerRef = React.useRef(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [locationList, setLocationList] = useState([]);

  console.log(selectedItem, refreshData);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  console.log(isFranchisor, locationId, isPrimaryOwner);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  useEffect(() => {
    // getStates();
  }, []);

  const classes = useStyles();
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const showFilter = () => {
    if (locationList.length === 0) getLocation();
    setdisplayFilterForm(!displayFilterForm);
  };

  const theme = useTheme();

  // Location details
  const handleEdit = (Item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedItem(Item);
    if(Item?.transactionId) navigate('/inventory-transfer-edit?transactionId=' + encodeURIComponent(Item.transactionId));
  };

  // View location details
  const handleView = (Item, e) => {
    e.preventDefault();
    e.stopPropagation();

    // set edit mode false
    setSelectedItem(Item);
  };

  // Methos to get the location list
  const getLocation = async () => {
    const franchiseeId = getLocalData('franchiseeId');
    const encodedFranchiseeId = encodeURIComponent(franchiseeId);
    let getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations?limit=1000`;

    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && franchiseeId) {
      getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      franchiseeId
    ) {
      getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`
    }
    try{
      const data = await ApiService.get(getLocationsUrl)
        if (data?.statusCode && !data?.error) {
          let Item = data?.data?.Items;
          setLocationList(Item);
        }
      }catch(error){
        console.error(error)
      }
  }
  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = transferHistoryLists.filter((item) => {
    return Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // HTML rendering started
  return (
    <Grid>
      <Grid xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid
            item
            sx={{
              marginTop: '10px',
              marginBottom: '20px'
            }}
          >
            <Button
              onClick={showFilter}
              ref={containerRef}
              variant={displayFilterForm ? 'contained' : 'outlined'}
            >
              Filters
              <KeyboardArrowDownRoundedIcon
                className={clsx(
                  !displayFilterForm && classes.closeY,
                  displayFilterForm && classes.openY
                )}
              />
            </Button>
          </Grid>

          <Grid
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px',
              marginBottom: '20px'
            }}
            className="top-filter-cont"
          >
            {/* Search box on table */}
            {/* <TextField
              type="search"
              className="Search-field"
              label="Search"
              InputProps={{ style: {}, endAdornment: <SearchIcon /> }}
              onChange={handleSearch}
              onClick={handleSearch}
            /> */}
            <TextField
              type="search"
              className="Search-field"
              label="Search"
              InputProps={{ style: {}, endAdornment: <SearchIcon /> }}
              onChange={handleSearchTextChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {displayFilterForm === true ? (
          // <Slide
          //   direction="right"
          //   in={displayFilterForm}
          //   timeout={{ enter: 1200, exit: 10 }}
          //   container={containerRef.current}
          // >
          <Grid item width={getFilterSize()}>
            <Filters
              filters={sideBarfilters}
              setFilters={setSidebarFilters}
              getLocation={locationList}
            />
          </Grid>
        ) : // </Slide>
          null}

        <Grid
          item
          sx={{
            width: `calc(100% - ${getFilterSize()}px)})`
          }}
        >
          <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
            <CardHeader
              // action={

              // }
              // title="All Franchises"
              className="table-header-name"
              sx={{ fontSize: '20px', color: '#003087' }}
            />

            {/* <Divider /> */}
            <TableContainer className="franchise-table-container">
              <Table>
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell>SKU Code</TableCell>
                    <TableCell>From location</TableCell>
                    <TableCell>To location</TableCell>
                    <TableCell>No of items</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((historyItem) => {
                    const isHistoryItemSelected = selectedHistoryLists.includes(
                      historyItem.itemId
                    );
                    return (
                      <TableRow
                        hover
                        key={historyItem.itemId}
                        selected={isHistoryItemSelected}
                        className="table-row cursor-pointe"
                        onClick={(e) => handleView(historyItem, e)}
                      >
                        <TableCell className="table-td">
                          {Array.isArray(historyItem?.transactionItems) && historyItem.transactionItems.length > 0 ? (
                            historyItem.transactionItems.map((item, index) => (
                              <Typography
                                key={index}
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {item?.sku || ''}
                              </Typography>
                            ))
                          ) : null}
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {historyItem.fromLocationName}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {historyItem.toLocationName}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {historyItem.numberOfItems}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {format(
                              new Date(historyItem.createdAt),
                              'MM/dd/yyyy'
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {historyItem.transactionStatus}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          {/* <Tooltip title="Edit Franchise" arrow> */}
                          {/* {(historyItem.transactionStatus === 'draft' || historyItem.transactionStatus === 'sent') ? ( */}
                          {(historyItem.transactionStatus === 'draft') ? (
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                              className={historyItem.transactionStatus === 'draft' ? 'edit-btn' : 'edit-btn'}
                              onClick={(e) => {
                                if (historyItem.transactionStatus === 'draft') {
                                  handleEdit(historyItem, e);
                                } else if (historyItem.transactionStatus === 'sent') {
                                  handleEdit(historyItem, e);
                                }
                              }}
                            >
                              {/* {historyItem.transactionStatus === 'draft' ? 'Edit' : 'View'} */}
                              {historyItem.transactionStatus === 'draft' && 'Edit'}
                            </IconButton>
                          ) : null}
                          {/* </Tooltip> */}
                          {/* <Tooltip title="Delete Franchise" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              {/* <TablePagination
                component="div"
                count={filteredHistoryLists.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 30]}
              /> */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

DrawTransferHistoryTable.propTypes = {
  transferHistoryLists: PropTypes.array.isRequired
};

DrawTransferHistoryTable.defaultProps = {
  transferHistoryLists: []
};

export default DrawTransferHistoryTable;
