import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  MenuItem,
  Select,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import { Buttons } from "../../../../components/Calendar/Button";
import { MonthPicker } from '../../../../components/Calendar/MonthPicker';
import { Card } from '../../../../components/Calendar/Card';
import { WeeklyCard } from '../../../../components/Calendar/WeeklyCard';
// import CircularProgress from "@mui/material/CircularProgress";
import { getCurrentDate } from '../../../../utils';
import Loader from '../../../../components/Loader';
import { ApiService } from "src/services/ApiService";

function Calendar(props) {
  const { staffDetails, openStaffDetails, refreshStaffData } = props;
  const [loading, setLoading] = useState(true);
  const [errorToast, setErrorToast] = useState({
    open: false,
    message: "",
  });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    date: getCurrentDate(),
    time: "Month",
    role: staffDetails.roles[0].role,
    location: staffDetails.locationId,
  });
  const [locations, setLocations] = useState([]);

  const handleFilters = (name, value) => {
    setActiveFilters({ ...activeFilters, [name]: value });
  };

  useEffect(() => {

    const filteredData = data?.filter((obj) => {
      // eslint-disable-next-line no-unused-vars
      const { role, locationId, clockInDate } = obj;
      const {
        role: activeRole,
        location: activeLocation,
        date: activeDate,
        time: activeTime,
      } = activeFilters;
      // Filter based on role, location, date, and time
      if (activeRole && role !== activeRole) {
        return false;
      }

      if (activeLocation && locationId !== activeLocation) {
        return false;
      }

      if (activeTime === 'Month') {
        const activeMonth = parseInt(activeDate.split("-")[0]);
        const activeYear = parseInt(activeDate.split("-")[2]);

        const clockInMonth = parseInt(clockInDate.split("-")[0]);
        const clockInYear = parseInt(clockInDate.split("-")[2]);

        if (activeYear !== clockInYear || activeMonth !== clockInMonth) {
          return false;
        }
        return true;
      }

      if (activeTime === 'Week') {


        // console.log('clockInDate---',clockInDate);
        const clkInDate = new Date(clockInDate.replaceAll('-', '/'));
        const actveDate = new Date(activeDate.replaceAll('-', '/'));

        // Get the week number and year for each date
        const clkObj = getWeekNumber(clkInDate);
        const weekclockInDate = clkObj?.weekNumber;
        const yearclockInDate = clkObj?.year;
        // const yearclockInDate = clockInDate.getFullYear();

        const activeObj = getWeekNumber(actveDate);
        // const yearactiveDate = activeDate.getFullYear();
        const weekactiveDate = activeObj?.weekNumber;
        const yearactiveDate = activeObj?.year;

        // console.log(weekclockInDate, weekactiveDate, yearclockInDate,yearactiveDate);
        // Check if the week numbers and years match
        return weekclockInDate === weekactiveDate && yearclockInDate === yearactiveDate;
      }

      return true;
    });

    // sort object as per the clockin date 
    filteredData?.sort((a, b) => {
      const dateA = new Date(a.clockInDate?.replaceAll('-', '/'));
      const dateB = new Date(b.clockInDate?.replaceAll('-', '/'));
      return dateA - dateB;
    });
    setFilteredData(filteredData);
  }, [activeFilters, data]);

  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return { weekNumber: weekNumber, year: d.getFullYear() };
  }

  const handleToastClose = () => {
    setErrorToast({
      open: false,
      message: "",
    });
  };

  const CustomSelect = styled(Select)(({ theme }) => ({
    background: theme.palette.background.default,
    // border: "2px solid rgba(0, 48, 135, 0.5)",
    borderRadius: "12px",
    height: "45px",
    maxWidth: "200px",
    minWidth: "130px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "30px",
    color: "#525252",
    "& .MuiSelect-select": {
      paddingLeft: "10px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-icon": {
      color: "#003087",
    },
  }));

  const fetchData = async (formatedDate) => {
    setLoading(true)
    try {
      let filter = activeFilters.time ? activeFilters.time.toLowerCase() : 'month';
      // let date = activeFilters.date;
      // let dateSplit = date ? date.split('-') : 0;
      // let lastDay = new Date(dateSplit[1], dateSplit[0], 0);
      // if (dateSplit && dateSplit.length === 2) date = dateSplit[0] + '-' + lastDay.getDate() + '-' + dateSplit[1];
      let url = `${process.env.REACT_APP_API_END_POINT}staff/clock-in-out`;
      let payload = {
        "staffId": staffDetails.sortKey,
        "locationId": staffDetails.locationId,
        "role": activeFilters.role,
        "filter": filter,
        "date": formatedDate
      }
      const response = await ApiService.post(url, payload);
      if (!response.data?.error) {
        setLoading(false)
        setData(response?.data?.Items);
      } else {
        setLoading(false);
        setErrorToast({
          open: true,
          message: response?.data?.message || "Something went wrong, Try again!",
        });
      }
     
    } catch (error) {
      console.warn(" Api Error", error?.response);
      setLoading(false);
      setErrorToast({
        open: true,
        message:
          error?.response?.data?.message || "Something went wrong, Try again!",
      });
    }
  };

  useEffect(() => {
    setLocations([{ locationName: props.staffDetails.locationName, locationId: props.staffDetails.locationId }]);
    fetchData(activeFilters.date);
  }, [props]);

  const refreshCalenderData = () => {
    fetchData(activeFilters.date);
  }

  return (
    <Container
      maxWidth="100%"
      sx={{ mt: 3 }}
      className="main-container relative -calendar-container"
    >
      <Box>
        {/* <Typography
            variant="h1"
            sx={{
              fontSize: "40px",
              fontWeight: 600,
              letterSpacing: "0.005em",
              color: "var(--text-primary)",
              fontFamily: `"Myriad Pro Semibold", Arial, sans-serif`,
            }}
          >
            Staff Management
          </Typography> */}

        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "16px",
            alignItems: 'flex-end',
            marginLeft: "0px",
            width: "100%",
          }}
        >
          <Grid item sm={6} md={8} xs={12} >
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              className="location-filters"
            >
              <Grid item sm={12} md={12} xs={12} lg={3} >
                <Typography
                  variant="body1"
                  className="ipad-screen-resolution-text"
                  sx={{
                    fontSize: "22px",
                    fontWeight: 600,
                    textAlign: "left",
                    color: "#242533",
                    fontFamily: `"Myriad Pro Semibold", Arial, sans-serif`,
                  }}
                >
                  {staffDetails && `${staffDetails.firstName} ${staffDetails.lastName}`}
                </Typography>
              </Grid>

              <Grid item sm={6} md={3} xs={12} >
                <CustomSelect
                sx={{maxWidth:'80px'}}
                  className="ipad-screen-resolution-dropdown"
                  value={activeFilters.location}
                  onChange={(e) => {
                    handleFilters("location", e.target.value);
                  }}
                >
                  {locations.map((item) => (
                    <MenuItem value={item.locationId}>
                      {item.locationName}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>

              <Grid item sm={4} md={3} xs={12}  >
                <CustomSelect
                  className="ipad-screen-resolution-dropdown"
                  value={activeFilters.role}
                  onChange={(e) => {
                    handleFilters("role", e.target.value);
                  }}
                // IconComponent={dropdownIcon}
                >
                  {staffDetails.roles.map((item) => (
                    <MenuItem value={item.role}>
                      {item.role}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>

            </Grid>
          </Grid>

          <Grid item sm={6} md={4} xs={12}>
            <Grid alignItems={'flex-end'} container spacing={1} className="time-filters">
              {["Month", "Week"].map((item) => (
                <Buttons
                  key={item}
                  className='ipad-screen-resolution-dropdown'
                  isActive={activeFilters.time === item}
                  onClick={() => handleFilters("time", item)}
                >
                  {item}
                </Buttons>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <MonthPicker
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          fetchData={fetchData}
        />
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          {loading ? (
            // <CircularProgress sx={{ marginTop: "60px" }} />
            <Loader IsLoading={loading} />
          ) : (
            <>
              {filteredData?.length ? (
                <>
                  {activeFilters.time === "Month" ? (
                    <Grid
                      container
                      spacing={2}
                      className="month-cards-grid"
                    >

                      {filteredData?.map((item, index) => (
                        <Card key={index} data={item} openStaffDetails={openStaffDetails} refreshCalenderData={refreshCalenderData} refreshStaffData={refreshStaffData} />
                      ))}
                    </Grid>
                  ) : (
                    <Grid container spacing={0} className="week-card-grid">
                      {filteredData?.map((item, index) => (
                        <WeeklyCard key={index} data={item} openStaffDetails={openStaffDetails} refreshCalenderData={refreshCalenderData} />
                      ))}
                    </Grid>
                  )}
                </>
              ) : (
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    textAlign: "center",
                    marginTop: "40px",
                    letterSpacing: "0.005em",
                    color: "#003087",
                    fontFamily: `"Myriad Pro Semibold", Arial, sans-serif`,
                  }}
                >
                  No records available
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Box>

      <div>
        <Snackbar
          open={errorToast.open}
          autoHideDuration={6000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          key={"bottom right"}
        >
          <Alert severity="error">{errorToast.message}</Alert>
        </Snackbar>
      </div>
    </Container>
  )
}

export default Calendar