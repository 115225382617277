/* eslint-disable eqeqeq */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../../components/Header';
import {
  Container,
  Box,
  Grid,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Button
} from '@mui/material';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from '../../../components/PopupMessage/SuccessMessage';

import {
  InventoryTable,
  AdjustmentReasons,
  Summary
} from 'src/components/Inventory/index';
import GlobalData from 'src/global';
import {
  CustomButton,
  SearchInput,
  CustomTextField
} from 'src/components/Global';
import { setFullLocationId, getLocalData } from 'src/utils';
import Loader from 'src/components/Loader';
import { is } from 'date-fns/locale';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const InventoryApp = () => {
  // change the header values
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Inventory Adjustment');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Inventory Adjustment';
  const title = GlobalData[0].title;
  const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const adjustmentId = searchParams.get('adjustmentId');
  const userId = getLocalData('userId');
  const userName = getLocalData('UserName');
  const locationId = getLocalData('locationId');
  const locationName = getLocalData('locationName');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showScreen, setShowScreen] = useState(1);
  const [inventoryData, setInventoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [quantity, setQuantity] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedReason, setSelectedReason] = useState('Breakage/Waste');
  const [isInitialState, setIsInitialState] = useState(false);
  const [notesData, setNotesData] = useState('');
  const [sortValue, setSortValue] = useState();
  const [open, setOpen] = React.useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [adjustmentPrdId, setAdjustmentPrdId] = useState();
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [IsFieldTouched, setIsFieldTouched] = useState(false);

  const sortOptions = [
    // { label: 'None', value: 'null' },
    { label: 'Title (A-Z)', value: 'titleAsc' },
    { label: 'Title (Z-A)', value: 'titleDes' },
    {
      label: 'Price (Low - High)',
      value: 'priceLow'
    },
    {
      label: 'Price (High - Low)',
      value: 'priceHigh'
    },
    {
      label: 'Stock Availability (Min - Max)',
      value: 'stockMin'
    },
    {
      label: 'Stock Availability (Max - Min)',
      value: 'stockMax'
    }
  ];

  const [isError, setIsError] = useState({
    notesData: false
  });

  useEffect(() => {
    const data = inventoryData?.filter((item) =>
      selectedRows?.includes?.(item.sortKey)
    );
    setSelectedRowsData(data);
  }, [selectedRows]);

  useEffect(() => {
    setQuantity(defaultQuantity());
  }, [inventoryData]);

  function defaultQuantity() {
    const defaultQuantities = {};
    inventoryData?.forEach((item) => {
      defaultQuantities[item?.sortKey] = 0;
    });

    return defaultQuantities;
  }

  function checkAllSelectedQuantity() {
    return selectedRows.every((itemId) => quantity[itemId] > 0);
  }

  function checkValidity() {
    return selectedReason == 'Other (Provide Reason)' && notesData == '';
  }

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSummaryOpen = () => {
    if (checkValidity()) {
      setIsError({ ...isError, notesData: true });
      setShowErrorMessage(true);
      setErrorMessage('Please fill in all the required fields');
      // toast.error('Please fill all the required fields');
    } else if (!selectedRows?.length) {
      setIsError({ ...isError, notesData: false });
      setShowErrorMessage(true);
      setErrorMessage('Please select atleast one order row');
      // toast.error('Please select atleast one order row');
    }

    // else if (!checkAllSelectedQuantity()) {
    //   setIsError({ ...isError, notesData: false });
    //   setShowErrorMessage(true);
    //   setErrorMessage(
    //     'Please add a minimum quantity of 1 to all selected rows.'
    //   );
    //   // toast.error('All selected rows must have quantity greater than 0');
    // }
    else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setShowScreen(2);
    }
  };

  const handleBackToHistory = () => {
    if (isUpdated) {
      setIsUpdated(false);
      setOpen(true);
    } else {
      setOpen(false);
      navigate('/adjustment-history');
    }
  };

  const handleClose = (val) => {
    setOpen(false);

    if (val === 'Discard') {
      // navigate('/adjustment-history');
      navigate('/inventory-managment');
    }
  };

  useEffect(() => {
    if (selectedReason != 'Other (Provide Reason)') {
      setIsError({ ...isError, notesData: false });
    }
  }, [selectedReason]);

  useEffect(() => {
    if (showScreen == 1 && isInitialState) {
      setSearchQuery('');
      setSelectedRows([]);
      setSelectedRowsData([]);
      setQuantity(defaultQuantity());
      setNotesData('');
      setSelectedReason('Breakage/Waste');
      setIsInitialState(false);
      setBtnLoading(false);
    }
    // setQuantity(defaultQuantity());
  }, [showScreen, isInitialState]);

  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
  };

  const handleChangeNotesInput = (type, value) => {
    setNotesData(value);
    // setIsUpdated(true);
    setIsError({ ...isError, notesData: false });
    setIsFieldTouched(true);
  };

  const handleSortValue = (value) => {
    setLoading(true);
    console.log('Value is as ', value);
    setSortValue(value);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  function getSortedData(value) {
    let sortedData = [];

    switch (value) {
      case 'titleAsc':
        sortedData = [...inventoryData].sort((a, b) =>
          a.fName.localeCompare(b.fName)
        );
        break;
      case 'titleDes':
        sortedData = [...inventoryData].sort((a, b) =>
          b.fName.localeCompare(a.fName)
        );
        break;
      case 'priceLow':
        sortedData = [...inventoryData].sort((a, b) => a.price - b.price);
        break;
      case 'priceHigh':
        sortedData = [...inventoryData].sort((a, b) => b.price - a.price);
        break;
      case 'stockMin':
        sortedData = [...inventoryData].sort((a, b) => a.quantity - b.quantity);
        break;
      case 'stockMax':
        sortedData = [...inventoryData].sort((a, b) => b.quantity - a.quantity);
        break;
      default:
        sortedData = inventoryData;
        break;
    }

    return sortedData;
  }

  const sortedData = getSortedData(sortValue);

  const getInventoryItems = async () => {

    try {
      setLoading(true);
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.get(
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1`
      );
      if (!response?.error) {
        const modifiedData = response?.data?.Items?.map((item) => ({
          ...item,
          id: item.PRDSort
        }));
        setInventoryData(modifiedData);
        setQuantity(defaultQuantity());
        setLoading(false);

      } else {
        setLoading(false);
        throw new Error('Request failed');
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getInventoryItems();
  }, []);

  const calculateTotalPrice = () => {
    const totalPrice = selectedRowsData?.reduce((total, obj) => {
      const price = parseFloat(obj.price) || 0;
      return total + price;
    }, 0);

    return totalPrice?.toFixed(2);
  };

  const postData = async () => {
    setBtnLoading(true);

    let idss = [];
    let itemsData = [];

    selectedRowsData?.map((item) => {
      const quantityValue = quantity[item.sortKey];
      idss.push({ itemId: item.sortKey });
      itemsData.push({
        itemId: item.sortKey,
        quantity: quantityValue || 0,
        category: item?.categoryName,
        itemName: item?.fName,
        variants: item?.variants
      });
      return null;
    });

    const data = {
      locationId: locationId,
      adjustmentType: selectedReason,
      adjustmentReason: selectedReason,
      items: itemsData,
      itemIds: idss,
      totalCost: calculateTotalPrice(),
      updatedBy: userId,
      operatorName: userName,
      locationName: locationName
    };

    if (notesData) {
      data.notes = notesData;
    }

    if (adjustmentId) {
      updateadjustmentHistory(data);
    } else {
      
      try {
        await ApiService.post(
          `${BASE_URL}inventory/adjustInventory`,
          data
        );

        setShowSuccessMessage(true);
        setSuccessMessage('Inventory has been adjusted successfully!');
        getInventoryItems();
        handleResetStates();
      } catch (error) {
        console.error(error);
        setBtnLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(error?.message || 'Something went wrong, Try again!');
      }
    }
  };

  const updateadjustmentHistory = async (data) => {
    data.adjustmentId = adjustmentPrdId;

    try {
      await ApiService.put(
        `${BASE_URL}inventory/updateAdjustmentHistory`,
        data
      );
      setShowSuccessMessage(true);
      setSuccessMessage('Inventory has been adjusted successfully!');
      navigate('/adjustment-history');

    } catch (error) {
      console.error(error);
      setBtnLoading(false);

      setShowErrorMessage(true);
      setErrorMessage(error?.message || 'Something went wrong, Try again!');
    }
  };

  useEffect(() => {
    getAdjustmentHistory();
  }, [searchParams]);

  const getAdjustmentHistory = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);

      if (adjustmentId) {
        const response = await ApiService.get(
          `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/adjustInventoryHistory?locationId=${encodedLocationId}&adjustmentId=${adjustmentId}`
        );

          if (!response.error) {
            let adjustmentData = response?.data?.Items;
            setAllPrefilledValues(adjustmentData);
            setLoading(false);
          } 
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setShowErrorMessage(true);
      setErrorMessage(error?.message || 'Internal Server Error');
    }
  };

  function setAllPrefilledValues(data) {
    setSelectedReason(data[0].adjustmentReason);
    setNotesData(data[0].notes);
    setAdjustmentPrdId(data[0].adjustmentId);
    if (data[0]?.items?.length)
      setTimeout(() => {
        setSelectedProductRows(data[0]?.items);
      }, 500);
  }

  function setSelectedProductRows(array) {
    if (!Array.isArray(array) || !array.length) {
      return false;
    }
    const idsToUpdate = array?.map(({ itemId }) => itemId);
    const updatedStateObject = { ...quantity };
    idsToUpdate.forEach((id) => {
      const matchingItem = array.find((item) => item.itemId == id);
      if (matchingItem) {
        updatedStateObject[id] = matchingItem.quantity;
      }
    });

    setSelectedRows(idsToUpdate);
    setQuantity(updatedStateObject);

    return true;
  }
  const handleButtonClick = () => {
    navigate('/adjustment-history');
  };
  const buttons = [
    {
      label: 'Track Adjustment History',
      onClick: handleButtonClick,
      theam: true
    }
  ];

  const handleBackToDashboard = () => {
    if (IsFieldTouched) setOpen(true);
    else navigate('/inventory-managment');
  };

  // Back arrow icon click and show
  const arrowIcon = {
    onClick: handleBackToDashboard,
    show: true
  };

  const handleWithdraButtonClick = () => {
    navigate('/withdraw-products');
  };

  return (
    <>
      <Helmet>
        <title>Inventory Management | {title}</title>
      </Helmet>
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={() => navigate('/adjustment-history')}
        />
      )}

      <Container maxWidth="100%" className="custom-container">
        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <Summary
                  handleOpenScreen={handleOpenScreen}
                  setIsInitialState={setIsInitialState}
                  selectedRowsData={selectedRowsData}
                  selectedReason={selectedReason}
                  notesData={notesData}
                  postData={postData}
                  quantity={quantity}
                  btnLoading={btnLoading}
                />
              );
            default:
              return (
                <Box justifyContent="center" direction="column">
                  {/*             
                  <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    gap="16px"
                  >
                    <Grid item>
                      <CustomButton
                        text="Add New"
                        variant="contained"
                        onClick={() => navigate('/adjustment-history')}
                      >
                        Track Adjustment History
                      </CustomButton>
                    </Grid>
                    
                  </Grid> */}

                  {!adjustmentId && (
                    <Header
                      title="Inventory Adjustment"
                      buttons={buttons}
                      backArrowIcon={arrowIcon}
                    />
                  )}
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      {adjustmentId && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Box
                            onClick={() => handleBackToHistory()}
                            className="back-arrow-close"
                          >
                            <ArrowLeft />
                          </Box>

                          <Typography className="header-blue-32">
                            Revise Inventory
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                    {/* <Grid item>
                      <Box
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <CustomButton
                          text="Add New"
                          variant="contained"
                          onClick={() => navigate('/adjustment-history')}
                        >
                          Track Adjustment History
                        </CustomButton>
                      </Box>
                    </Grid> */}
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        marginTop="20px"
                      >
                        <Grid item>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <h2 className="title mb-0 mt-10 primary-color">
                              Choose Products
                            </h2>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                        // marginTop="20px"
                        alignItems="flex-end"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            maxWidth: '270px !important',
                            marginTop: '10px',
                            marginBottom: '20px'
                          }}
                        >
                          {/* <CusotmSelect
                            fullWidth
                            name="sort"
                            label="SORT PRODUCTS BY"
                            value={sortValue}
                            onChange={handleSortValue}
                            options={[
                              { label: 'None', value: 'null' },
                              { label: 'Title (A-Z)', value: 'titleAsc' },
                              { label: 'Title (Z-A)', value: 'titleDes' },
                              {
                                label: 'Price (Low - High)',
                                value: 'priceLow'
                              },
                              {
                                label: 'Price (High - Low)',
                                value: 'priceHigh'
                              },
                              {
                                label: 'Stock Availability (Min - Max)',
                                value: 'stockMin'
                              },
                              {
                                label: 'Stock Availability (Max - Min)',
                                value: 'stockMax'
                              }
                            ]}
                          /> */}
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">
                              SORT PRODUCTS BY
                            </InputLabel>
                            <Select
                              id="demo-simple-select-helper"
                              label="SORT PRODUCTS BY"
                              value={sortValue}
                              // onChange={handleSortValue}
                              onChange={(e) => handleSortValue(e.target.value)}
                            >
                              {sortOptions.map((data) => (
                                <MenuItem key={data.value} value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <SearchInput
                            value={searchQuery}
                            setSearchQuery={setSearchQuery}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    marginTop="10px"
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <Grid item width="100%">
                      <InventoryTable
                        tableRows={sortedData}
                        searchQuery={searchQuery}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        setIsFieldTouched={setIsFieldTouched}
                      />
                    </Grid>
                    {/* // )} */}
                  </Grid>

                  <Grid container marginTop="20px">
                    <Grid item xs={12} md={4}>
                      <AdjustmentReasons
                        selectedReason={selectedReason}
                        handleChangeReason={(e) => {
                          setSelectedReason(e.target.value);
                          // setIsUpdated(true);
                          setIsFieldTouched(true);
                        }}
                      />
                    </Grid>
                    <Grid item md={1} />
                    <Grid
                      item
                      xs={12}
                      md={7}
                      display="flex"
                      alignItems="center"
                      marginTop={5}
                      width="100%"
                    >
                      <CustomTextField
                        fullWidth
                        label="Notes"
                        multiline
                        rows="5"
                        type="text"
                        name="notesData"
                        value={notesData}
                        onChange={handleChangeNotesInput}
                        error={isError.notesData}
                        helperText={isError.notesData && 'Notes is required'}
                      />
                    </Grid>
                  </Grid>

                  <Grid container marginTop="20px">
                    <Grid item md={4} />
                    <Grid item md={1} />
                    <Grid
                      item
                      xs={7}
                      display="flex"
                      justifyContent="flex-end"
                      marginTop={1}
                      marginBottom={3}
                    >
                      <CustomButton
                        variant="contained"
                        className="common-button"
                        onClick={handleSummaryOpen}
                        sx={{ width: '50%' }}
                      >
                        Proceed to Summary
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {/* {!isUpdated ? ( */}
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InventoryApp;
