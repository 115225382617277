/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-computed-key */
/* eslint-disable spaced-comment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable react/self-closing-comp */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Slide,
  TextField,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from '../../../utils';
import Loader from '../../../components/Loader';
import StaffSchedulesbyTimeoffTable from './StaffSchedulesbyTimeoffTable';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function AddTimeOffModal({
  open,
  handleClose,
  trainers,
  getAllTrainers,
  fromTime,
  endTime,
  fromDate,
  endDate,
  selectedTrainerData,
  setendTime,
  setfromTime,
  setendDate,
  setFromDate,
  setselectedTrainerData
}) {
  const locationId = getLocalData('locationId');
  const userId = getLocalData('userId');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState('')
  const [formData, setFormData] = useState({
    trainer: null,
    startDate: null,
    sTime: null,
    endDate: null,
    eTime: null
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    trainer: '',
    startDate: '',
    sTime: '',
    endDate: '',
    eTime: ''
  });
  const clearForm = () => {
    setFormData({
      trainer: null,
      startDate: null,
      sTime: null,
      endDate: null,
      eTime: null
    });

    setendTime('');
    setfromTime('');
    setendDate('');
    setFromDate('');
    setselectedTrainerData('');

    setErrors({
      trainer: '',
      startDate: '',
      sTime: '',
      endDate: '',
      eTime: ''
    });
  };

  function convertDateTimeToUTC(dateTimeString) {
    const cleanedDateTimeString = dateTimeString
      ? dateTimeString.replace(/-/g, '/')
      : dateTimeString;
    const userDate = new Date(cleanedDateTimeString);
    return userDate;
  }
  const convertToDateTime = (dateStr, timeStr) => {
    if(dateStr !== null || timeStr !== null){
    const [month, day, year] = dateStr.split('/');
    const [hours, minutes, period] = timeStr.split(/[:\s]/);
    // Convert to 24-hour format
    const hours24 =
      period === 'PM' ? (parseInt(hours) % 12) + 12 : parseInt(hours);
    // Create a new Date object in the local time zone
    const date = new Date(year, month - 1, day, hours24, minutes);

    return date;
  }
  };
  console.log(formData, '22222222222');

  useEffect(() => {
    let edate = formData.endDate;
    let eTime = formData.eTime;
    let date = convertToDateTime(edate, eTime);
    let currentDateTime = new Date();
    if (date < currentDateTime) {
      setIsError(true)
      setErrorMsg('Time off cannot be scheduled for a past date and time. Please select a future date and time.')
    } else  if(date >= currentDateTime){
      setIsError(false)
    }
  }, [formData.endDate, formData.eTime]);

  function convertToCustomFormat(dateTimeString, isTrue) {
    // Parse the input date and time string into a JavaScript Date object
    const userDate = new Date(dateTimeString);

    // Get the date components
    const day = userDate.getDate();
    const month = userDate.getMonth() + 1; // Months are zero-based
    const year = userDate.getFullYear();

    // Get the time components
    const hours = userDate.getHours();
    const minutes = userDate.getMinutes();

    // Format the time as AM or PM
    const ampm = hours >= 12 ? ' PM' : ' AM';

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Create the formatted date and time string
    if (isTrue) {
      return `${month}/${day}/${year}`;
    }
    const formattedDateTime = `${month}-${day}-${year}, ${formattedHours}:${
      (minutes < 10 ? '0' : '') + minutes
    } ${ampm}`;

    return formattedDateTime;
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validation for trainer field (example: not empty)
    if (!formData.trainer) {
      newErrors.trainer = 'Trainer is required';
      isValid = false;
    }

    // Validation for startDate field (example: not empty)
    if (!formData.startDate || formData?.startDate === 'Invalid Date') {
      newErrors.startDate = 'From date is required';
      isValid = false;
    }

    // Validation for sTime field (example: not empty)
    if (!formData.sTime) {
      newErrors.sTime = 'From time is required';
      isValid = false;
    }

    // Validation for endDate field (example: not empty)
    if (!formData.endDate || formData?.endDate === 'Invalid Date') {
      newErrors.endDate = 'To date is required';
      isValid = false;
    }

    // Validation for eTime field (example: not empty)
    if (!formData.eTime) {
      newErrors.eTime = 'To time is required';
      isValid = false;
    }

    // validation for from date greater than to date
    let sDate = new Date(formData?.startDate);
    let eDate = new Date(formData?.endDate);
    if (sDate && eDate && sDate > eDate) {
      newErrors.startDate = 'From date cannot be greater than the To date';
      isValid = false;
    }

    // validation for from time greater than to time for same date
    const parsedStartTime = new Date(`2023/01/01 ${formData?.sTime}`);
    const parsedEndTime = new Date(`2023/01/01 ${formData?.eTime}`);
    if (parsedStartTime >= parsedEndTime) {
      newErrors.sTime =
        'From time cannot be greater than or equal to the To time';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      let fromTime = convertDateTimeToUTC(
        formData.startDate + ',' + formData.sTime
      );
      let toTime = convertDateTimeToUTC(
        formData.endDate + ',' + formData.eTime
      );
      let setFromDatetime = convertToCustomFormat(fromTime);
      let setEndDatetime = convertToCustomFormat(toTime);
      let payload = {
         locationId: locationId,
        staffId: formData.trainer,
        availabilityType: 'unavailable',
        // VV - as per the API changes we changes payload
        startTime: setFromDatetime,
        endTime: setEndDatetime,
        createdBy: userId
      };

      try {
        let response = await ApiService.post(
          `${process.env.REACT_APP_API_END_POINT}staff/addStaffTimeOff`,
          payload
        );

        if (response.statusCode === 200) {
          getAllTrainers();
          clearForm();
          setIsLoading(false);
          handleClose();
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };
  const timeSlots = [
    { value: '12:00 AM', label: '12:00 AM' },
    { value: '12:30 AM', label: '12:30 AM' },
    { value: '01:00 AM', label: '01:00 AM' },
    { value: '01:30 AM', label: '01:30 AM' },
    { value: '02:00 AM', label: '02:00 AM' },
    { value: '02:30 AM', label: '02:30 AM' },
    { value: '03:00 AM', label: '03:00 AM' },
    { value: '03:30 AM', label: '03:30 AM' },
    { value: '04:00 AM', label: '04:00 AM' },
    { value: '04:30 AM', label: '04:30 AM' },
    { value: '05:00 AM', label: '05:00 AM' },
    { value: '05:30 AM', label: '05:30 AM' },
    { value: '06:00 AM', label: '06:00 AM' },
    { value: '06:30 AM', label: '06:30 AM' },
    { value: '07:00 AM', label: '07:00 AM' },
    { value: '07:30 AM', label: '07:30 AM' },
    { value: '08:00 AM', label: '08:00 AM' },
    { value: '08:30 AM', label: '08:30 AM' },
    { value: '09:00 AM', label: '09:00 AM' },
    { value: '09:30 AM', label: '09:30 AM' },
    { value: '10:00 AM', label: '10:00 AM' },
    { value: '10:30 AM', label: '10:30 AM' },
    { value: '11:00 AM', label: '11:00 AM' },
    { value: '11:30 AM', label: '11:30 AM' },
    { value: '12:00 PM', label: '12:00 PM' },
    { value: '12:30 PM', label: '12:30 PM' },
    { value: '01:00 PM', label: '01:00 PM' },
    { value: '01:30 PM', label: '01:30 PM' },
    { value: '02:00 PM', label: '02:00 PM' },
    { value: '02:30 PM', label: '02:30 PM' },
    { value: '03:00 PM', label: '03:00 PM' },
    { value: '03:30 PM', label: '03:30 PM' },
    { value: '04:00 PM', label: '04:00 PM' },
    { value: '04:30 PM', label: '04:30 PM' },
    { value: '05:00 PM', label: '05:00 PM' },
    { value: '05:30 PM', label: '05:30 PM' },
    { value: '06:00 PM', label: '06:00 PM' },
    { value: '06:30 PM', label: '06:30 PM' },
    { value: '07:00 PM', label: '07:00 PM' },
    { value: '07:30 PM', label: '07:30 PM' },
    { value: '08:00 PM', label: '08:00 PM' },
    { value: '08:30 PM', label: '08:30 PM' },
    { value: '09:00 PM', label: '09:00 PM' },
    { value: '09:30 PM', label: '09:30 PM' },
    { value: '10:00 PM', label: '10:00 PM' },
    { value: '10:30 PM', label: '10:30 PM' },
    { value: '11:00 PM', label: '11:00 PM' },
    { value: '11:30 PM', label: '11:30 PM' }
  ];
console.log(isError,'issssssssssss');
  useEffect(() => {
    setFormData({
      trainer: selectedTrainerData,
      startDate: fromDate,
      sTime: fromTime,
      endDate: endDate,
      eTime: endTime
    });
  }, [fromDate, fromTime, endTime, selectedTrainerData]);

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        className="enrollClassDialog"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description "
      >
        <DialogContent className="custom-scroll -team-container-white-box">
          <Box
            // sx={{ width: '100%' }}
            paddingBottom="2
            0px"
            className="Main-container common-form-container"
          >
            <Box className="custom-form">
              <Grid mt={0} marginBottom="8px">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  gap={'15px'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      width: '100%'
                    }}
                    mb={2}
                  >
                    <Typography className={'-dialogPopupHeader'}>
                      {' '}
                      Add Time Off
                    </Typography>
                    <CancelOutlinedIcon
                      color="error"
                      sx={{
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        clearForm();
                        handleClose();
                      }}
                    />
                  </Box>


                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: '30px'
                    }}
                  >
                    <FormControl
                      pt={2}
                      variant="outlined"
                      fullWidth
                      sx={{
                        width: '47.89%'
                      }}
                    >
                      <InputLabel className="text-field-styling">
                        SELECT TRAINER
                      </InputLabel>
                      <Select
                        value={formData.trainer}
                        onChange={(event) => {
                          return (
                            setFormData({
                              ...formData,
                              trainer: event.target.value
                            }),
                            setErrors({ ...errors, trainer: '' })
                          );
                        }}
                        label="SELECT TRAINER"
                        // error={!!errors.trainer}
                        helperText={errors.trainer}
                        className="status-field field-label-css"
                        IconComponent={KeyboardArrowDownIcon}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 250 } }
                        }}
                      >
                        {trainers?.length > 0 &&
                          trainers.map((item, index) => (
                            <MenuItem
                              key={index}
                              id={index}
                              value={item.sortKey}
                            >
                              {item?.firstName || ''} {item?.lastName || ''}
                            </MenuItem>
                          ))}
                      </Select>
                      {errors.trainer && (
                        <Typography color="#d13a00">
                          {errors.trainer}
                        </Typography>
                      )}
                    </FormControl>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        gap: '30px'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '10px'
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="FROM"
                            name="Date"
                            // error={!!errors.startDate}
                            // helperText={errors.startDate}
                            value={formData.startDate}
                            onChange={(value) => {
                              const formattedDate =
                                dayjs(value).format('MM/DD/YYYY');
                              return (
                                setFormData({
                                  ...formData,
                                  startDate: formattedDate
                                }),
                                setErrors({
                                  ...errors,
                                  startDate: '',
                                  endDate: '',
                                  sTime: ''
                                })
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-required"
                                className="text-field-styling"
                                helperText={errors.startDate}
                                // helperText={cardValidThroughError}

                                InputLabelProps={{
                                  className: 'label-style-text-field'
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <FormControl
                          pt={2}
                          variant="outlined"
                          fullWidth
                          sx={{
                            width: '50%'
                          }}
                        >
                          <InputLabel className="text-field-styling">
                            Time
                          </InputLabel>
                          <Select
                            value={formData.sTime}
                            onChange={(event) => {
                              return (
                                setFormData({
                                  ...formData,
                                  sTime: event.target.value
                                }),
                                setErrors({ ...errors, sTime: '', eTime: '' })
                              );
                            }}
                            label="Time"
                            // error={!!errors.sTime}
                            helperText={errors.sTime}
                            className="status-field field-label-css"
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 300 } }
                            }}
                          >
                            {timeSlots?.length > 0 &&
                              timeSlots.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  id={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.sTime && (
                            <Typography color="#d13a00">
                              {errors.sTime}
                            </Typography>
                          )}
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '10px'
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="TO"
                            name="Date"
                            error={!!errors.endDate}
                            helperText={errors.endDate}
                            value={formData.endDate}
                            onChange={(value) => {
                              const formattedDate =
                                dayjs(value)?.format('MM/DD/YYYY');
                              return (
                                setFormData({
                                  ...formData,
                                  endDate: formattedDate
                                }),
                                setErrors({
                                  ...errors,
                                  endDate: '',
                                  startDate: '',
                                  sTime: ''
                                })
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-required"
                                className="text-field-styling"
                                // helperText={cardValidThroughError}
                                helperText={errors.endDate}
                                InputLabelProps={{
                                  className: 'label-style-text-field'
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>

                        <FormControl
                          pt={2}
                          variant="outlined"
                          fullWidth
                          sx={{
                            width: '50%'
                          }}
                        >
                          <InputLabel className="text-field-styling">
                            Time
                          </InputLabel>
                          <Select
                            value={formData.eTime}
                            onChange={(event) => {
                              return (
                                setFormData({
                                  ...formData,
                                  eTime: event.target.value
                                }),
                                setErrors({ ...errors, eTime: '', sTime: '' })
                              );
                            }}
                            label="Time"
                            // error={!!errors.eTime}
                            helperText={errors.eTime}
                            className="status-field field-label-css"
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 300 } }
                            }}
                          >
                            {timeSlots?.length > 0 &&
                              timeSlots.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  id={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.eTime && (
                            <Typography color="#d13a00">
                              {errors.eTime}
                            </Typography>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                    { isError && (
                      <span style={{ color: 'red' }}>
                        {errorMsg}
                      </span>
                    )}
                    { isDisabled && (
                      <span style={{ color: 'red' }}>
                        {showErrorMessage}
                      </span>
                    )}
                    <StaffSchedulesbyTimeoffTable
                      setIsDisabled={setIsDisabled}
                      setShowErrorMessage={setShowErrorMessage}
                      apiData={{
                        locationId: locationId,
                        trainerId: formData.trainer,
                        startTime: convertToCustomFormat(
                          convertDateTimeToUTC(
                            formData.startDate + ',' + formData.sTime
                          )
                        ),
                        endTime: convertToCustomFormat(
                          convertDateTimeToUTC(
                            formData.endDate + ',' + formData.eTime
                          )
                        )
                      }}
                      convertToCustomFormat={(date) =>
                        convertToCustomFormat(date, true)
                      }
                      TableHeading={[
                        'Service Name',
                        'Start Date',
                        'End Date',
                        'Start Time',
                        'End Time'
                      ]}
                    />

                    <Button
                      variant="contained"
                      disabled={isDisabled || isError}
                      className={`${
                        !isDisabled && !isError ? 'top-button-style' : 'bookClassBtndisabled'
                      }`}
                      
                      sx={{ padding: '15px' }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
