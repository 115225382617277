import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton
} from '@mui/material';
import { FormControl, FormHelperText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './Location.css';
import { TextField, Grid } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { getLocalData } from 'src/utils';
// import Environment from '../../../environment';
// import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Loader from '../../../components/Loader';
import { formatPhoneNumber } from '../../../utils/index';

// import { ApiService } from 'src/services/ApiService';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { Toggle } from 'src/components/Global';
import { InfoIcon } from 'src/assets/SVG/SvgIcons';
import { ApiService } from 'src/services/ApiService';

function LocationEdit({
  selectedData,
  refreshLocationData,
  Transition,
  setShowScreen,
  franchiseeList,
  managerList,
  statesList,
  setSearchText
}) {
  // const domain = Environment[0].apiEndPoint;
  // const [locationDetails, setLocationDetailsData] = useState([]);
  // setLocationDetailsData(selectedData);
  let locationDetails;
  let userId = getLocalData('userId');
  // const [imageUrl, setImageUrl] = useState('');
  // check field updated or not
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  // const [imgObj, setImageObj] = useState('');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
  // const [isStepOneValid, setStepOneValid] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = React.useState(false);
  /* States for getting values from input fields of create location form as follows */
  const [locationNameEdit, setlocationNameEdit] = useState('');
  const [primaryContactName, setprimaryContactName] = useState('');
  const [locationUrl, setLocationUrl] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [liveLocation, setLiveLocation] = useState(false);

  const [unitName, setUnitName] = useState('');
  const [locationDescription, setlocationDescription] = useState('');
  const [locationphone, setlocationphone] = useState('');
  const [primaryphone, setprimaryphone] = useState('');

  const [locationEmail, setLocationemail] = useState('');
  const [primaryEmail, setprimaryEmail] = useState('');

  const [openingDate, setopeningDate] = useState('');
  const [assignManagerEdit, setassignManagerEdit] = useState([]);
  const [addressEdit, setAddressEdit] = useState('');
  const [address2Edit, setAddress2Edit] = useState('');

  const [cityEdit, setCityEdit] = useState('');
  const [zipcodeEdit, setZipcodeEdit] = useState('');
  // const [LocationData, setLocationData] = useState('');
  const [stateData, setstateData] = useState('');
  const [businessEntityData, setbusinessEntityData] = useState('');
  const [locationId, setLocationId] = useState('');
  const [franchiseeId, setFranchiseeId] = useState('');
  const [locationUrlHelperText, setLocationUrlHelperText] = useState(false);
  const [facebookUrlHelperText, setFacebookUrlHelperText] = useState(false);
  const [instagramUrlHelperText, setInstagramUrlHelperText] = useState(false);

  // const [isActive, setIsActive] = useState(true);
  const [statusError, setStatusError] = useState(false);
  const [statusvalue, setStatusValue] = useState('');
  const [status, setStatus] = useState();

  /* States for showing error messages for create location data to user */

  const [locationNameEditError, setlocationNameEditError] = useState(false);
  const [unitNameError, setunitNameError] = useState(false);
  const [primaryContactNameError, setprimaryContactNameError] = useState(false);

  // const [locationDescriptionError, setlocationDescriptionError] = useState(false);
  const [locationphoneError, setlocationphoneError] = useState(false);
  const [primaryphoneError, setprimaryphoneError] = useState(false);
  const [twilioNumberError, settwilioNumberError] = useState(false);

  const [locationEmailError, setLocationemailError] = useState('');
  const [primaryEmailError, setPrimaryEmailError] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [openingDateError, setopeningDateError] = useState(false);
  const [assignManagerErrorEdit, setassignManagerErrorEdit] = useState(false);
  const [addressErrorEdit, setAddressErrorEdit] = useState(false);
  // const [address2ErrorEdit, setAddress2ErrorEdit] = useState(false);
  const [stateErrorEdit, setStateErrorEdit] = useState(false);
  const [businessEntityError, setBusinessEntityError] = useState(false);

  const [cityErrorEdit, setCityErrorEdit] = useState(false);
  // const [countryErrorEdit, setCountryErrorEdit] = useState(false);
  const [zipcodeErrorEdit, setZipcodeErrorEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [assignManagerName, setAssignManagerName] = useState('');
  const [assignManagerId, setAssignManagerId] = useState('');
  // const [selectedStatus, setSelectedStatus] = useState('');
  // const [taxGrouping, setTaxGrouping] = useState(false);
  // const [taxExempt, setTaxExempt] = useState(false);
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [twilioNumber, setTwilioNumber] = useState('');

  // Prodile image error text
  // const [locationImageError, setLocationImageError] = useState('');
  // const [locationimageError, setLocationImage] = useState(false);

  /* useEffect calls when the component is first time rendered */

  useEffect(() => {
    locationDetails = selectedData;

    // Set the location details
    setLocationDetails(locationDetails);
    setOpen(false);
  }, [selectedData]);

  /* Method to get the location details */
  const setLocationDetails = (locationDetails) => {
    setTwilioNumber(locationDetails?.twilioNumber);
    setLiveLocation(locationDetails?.openingFlag);
    setLocationId(locationDetails?.locationId);
    setFranchiseeId(locationDetails?.franchiseeId);
    setlocationNameEdit(locationDetails?.locationName);

    setUnitName(locationDetails['unit#'] && locationDetails['unit#']);
    setlocationDescription(locationDetails?.notes);
    setlocationphone(locationDetails?.phone);
    setLocationemail(locationDetails?.email);
    setopeningDate(locationDetails?.openingDate);
    // let value = locationDetails.taxGrouping;
    // setTaxGrouping(value);
    // if (locationDetails && locationDetails.managerInfo) {
    //     setassignManagerEdit(locationDetails.managerInfo);
    //     // setassignManagerEdit(["Prashant Jain"]);
    // }

    setAddressEdit(locationDetails?.addressLine1);
    setAddress2Edit(locationDetails?.addressLine2);
    setstateData(locationDetails?.state);
    setCityEdit(locationDetails?.city);
    setZipcodeEdit(locationDetails?.postalCode);
    let assignedManager =
      locationDetails &&
      locationDetails.managerId + '|' + locationDetails.managerName;

    setassignManagerEdit(assignedManager);
    setAssignManagerId(locationDetails?.managerId);
    setAssignManagerName(locationDetails?.managerName);
    // setSelectedStatus(locationDetails?.status);
    setbusinessEntityData(locationDetails?.franchiseeName);
    setStatusValue(locationDetails?.openingStatus);
    setprimaryContactName(locationDetails?.primaryContactName);
    setprimaryEmail(locationDetails?.primaryContactEmail);
    setLocationUrl(locationDetails?.locationUrl);
    setFacebookUrl(locationDetails?.facebookUrl);
    setInstagramUrl(locationDetails?.instagramUrl);
    setprimaryphone(locationDetails?.primaryContactPhone);
    setStatus(locationDetails?.status);
    // setImageUrl(locationDetails.locationImage);
    // setIsActive(locationDetails.isActive);
  };
  // const clear = () => {
  //     setLocationDetails('')
  //     setLocationId('');
  //     setFranchiseeId('');
  //     setlocationNameEdit('');
  //     setlocationDescription('');
  //     setlocationphone('');
  //     setLocationemail('');
  //     setopeningDate('');
  //     setAddressEdit('');
  //     setAddress2Edit('');
  //     setstateData('');
  //     setassignManagerEdit('');
  //     setAssignManagerName('');
  //     setAssignManagerId('');
  // }
  // const setValuePhone = (val) => {
  //     setlocationphone(val);
  // }

  // Method to open the update dialog
  // const handleClickOpen = () => {
  //   setOpenEdit(true);
  // };

  // Function to close the edit location dialog box

  const handleEditLocationClose = () => {
    if (isFieldUpdated) {
      setOpenConfirmPopup(true);
    } else {
      setShowScreen(0);
      setSearchText('');
      setOpenConfirmPopup(false);
    }
  };
  // Method to close the update dialog
  const handleConfirmClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      setOpenConfirmPopup(false);
      // setOpen(false);
      // showForm(false);
      // clear();
    } else if (val === 'Discard') {
      setOpen(false);
      // setOpen(false);
      setShowScreen(0);
      // showForm(false);
      setSearchText('');
      setOpenConfirmPopup(false);
      setIsFieldUpdated(false);
      setLocationDetails();
    }
  };
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      updateLocation();
    } else if (val === 'Discard') {
      setOpen(false);
      // setShowScreen(0);
      setOpenConfirmPopup(false);
    } else if (val === 'Close') {
      setOpen(false);
      setSearchText('');
      setShowScreen(0);
      setOpenConfirmPopup(false);
    }
  };

  /* Method to clear error messages after closing dialog box */

  // const clearErrors = () => {
  //     setlocationNameEditError("")
  //     setlocationphoneError("");
  //     setLocationemailError("");
  //     setAddressErrorEdit("");
  //     setCityErrorEdit("");
  //     setZipcodeErrorEdit("");
  //     setStateErrorEdit(false);
  // }

  // Method to update the location
  const updateLocation = async () => {
    setIsLoading(true);
    // let locationId = locationId;
    // console.log(locationId);
    let franchiseeName = selectedData?.franchiseeName;
    if (franchiseeId) {
      // Now show the loader

      let data = {
        franchiseeId: franchiseeId,
        franchiseeName: franchiseeName,
        locationName: locationNameEdit,
        'unit#': unitName,
        phone: locationphone?.toString(),
        email: locationEmail?.toLowerCase(),
        notes: locationDescription,
        openingDate: openingDate,
        managerId: assignManagerId,
        managerName: assignManagerName,
        openingStatus: statusvalue,
        primaryContactName: primaryContactName,
        primaryContactEmail: primaryEmail,
        primaryContactPhone: primaryphone,

        addressLine1: addressEdit,
        addressLine2: address2Edit,
        city: cityEdit,
        state: stateData,
        postalCode: zipcodeEdit?.toString(),
        locationUrl: locationUrl?.toString(),
        facebookUrl: facebookUrl?.toString(),
        twilioNumber: twilioNumber || '',

        instagramUrl: instagramUrl,
        openingFlag: liveLocation,

        updatedBy: userId,
        status: status
      };

      try {
        let response = await ApiService.put(
          `${
            process.env.REACT_APP_API_END_POINT
          }locations/updateLocation?id=${encodeURIComponent(locationId)}`,
          data
        );

        if (!response?.error) {
          let crossElm = document.getElementsByClassName(
            'details-remove-icon'
          )[0];
          if (crossElm) crossElm?.click();

          // Now set to all location data updated

          setOpen(true);
          // Now open the confirmation dialog
          // set field updated to false
          refreshLocationData();
          setIsUpdated(true);
          // Now hide the loader
          setIsLoading(false);
          // setOpen(false);
        } else {
          setShowError(true);
          setErrorMessage(data?.message);
          // Now hide the loader
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);

        let msg = error?.response?.data?.errors[0]?.detail;
        setShowMessage(true);
        setErrorMessage(msg || 'Internal Server Error');
      }
    }
  };

  /* Edit location  part these are the functions for validation of data for edit location  */

  /* Function to validate location name validation */

  const locationNameValidate = (locationName) => {
    // set field updated to true

    if (!locationName) {
      setIsFieldUpdated(true);
      setlocationNameEdit(locationName !== '' ? locationName : '');
      setlocationNameEditError('Location name is required');

      // setStepOneValid(false);
    } else {
      setlocationNameEditError('');
      setlocationNameEdit(locationName);
      setIsFieldUpdated(true);

      // setStepOneValid(true);
    }
  };

  /* Function to validate location name validation */

  const unitNameValidate = (unitName) => {
    // set field updated to true

    if (!unitName) {
      setIsFieldUpdated(true);
      setUnitName(unitName !== '' ? unitName : '');
      setunitNameError('Unit name is required');

      // setStepOneValid(false);
    } else {
      setunitNameError('');
      setUnitName(unitName);
      setIsFieldUpdated(true);

      // setStepOneValid(true);
    }
  };

  /* Function to validate primary contact name validation */

  const primaryContactNameValidate = (contactName) => {
    // set field updated to true

    if (!contactName) {
      setIsFieldUpdated(true);
      setprimaryContactName(contactName !== '' ? contactName : '');
      // setprimaryContactNameError('Primary contact name is required');

      // setStepOneValid(false);
    } else {
      setprimaryContactNameError('');
      setprimaryContactName(contactName);
      setIsFieldUpdated(true);

      // setStepOneValid(true);
    }
  };

  /* Function  for validation of location description */

  const locationDescriptionValidate = (locationdescription) => {
    // set field updated to true

    setIsFieldUpdated(true);
    setlocationDescription(
      locationdescription !== '' ? locationdescription : ''
    );

    // setStepOneValid(false);
    // } else {
    //     setlocationDescriptionError("");
    //     setlocationDescription(locationdescription);
    //     setIsFieldUpdated(true);

    //     // setStepOneValid(true);
    // }
  };

  /* Function for validation of phone  number  */

  // const validatePhone = (phone) => {

  //     const validation = /^\d{3}-\d{3}-\d{4}$/;

  //     if (!phone) {
  //         setIsFieldUpdated(true);
  //         setlocationphone(phone !== '' ? phone : '');
  //         setlocationphoneError("Phone number is required");

  //     }

  //     else if (!validation.test(phone)) {
  //         setlocationphone(phone !== '' ? phone : '');
  //         setIsFieldUpdated(true);
  //         setlocationphoneError("Phone number is invalid");

  //     }

  //     else {
  //         setlocationphoneError("");
  //         setlocationphone(phone);
  //         setIsFieldUpdated(true);

  //         // setStepOneValid(true);
  //     }
  // }

  const validatePhone = (mobileNumber) => {
    // const mobileNumber = e.target.value;
    const validation = /^\d{3}-\d{3}-\d{4}$/;

    if (mobileNumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(mobileNumber);
      setlocationphone(formatWorkPhoneNumber);
      setIsFieldUpdated(true);

      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setlocationphoneError(
          'Please enter a valid phone number in format: xxx-xxx-xxxx'
        );
      } else {
        setlocationphoneError('');
      }
    } else {
      setlocationphoneError('');
      setlocationphone('');
    }
  };

  const validatePrimaryPhone = (mobileNumber) => {
    // const mobileNumber = e.target.value;
    const validation = /^\d{3}-\d{3}-\d{4}$/;

    if (mobileNumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(mobileNumber);
      setprimaryphone(formatWorkPhoneNumber);
      setIsFieldUpdated(true);

      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setprimaryphoneError(
          'Please enter a valid primary phone number in format: xxx-xxx-xxxx'
        );
      } else {
        setprimaryphoneError('');
      }
    } else {
      // setprimaryphoneError('Primary phone number is required');
      setprimaryphone('');
    }
  };

  const validateTwilioNumber = (twilioNumber) => {
    // const mobileNumber = e.target.value;
    const validation = /^\d{3}-\d{3}-\d{4}$/;

    if (twilioNumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(twilioNumber);
      setTwilioNumber(formatWorkPhoneNumber);
      setIsFieldUpdated(true);

      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        settwilioNumberError(
          'Please enter a valid primary phone number in format: xxx-xxx-xxxx'
        );
      } else {
        settwilioNumberError('');
      }
    } else {
      settwilioNumberError('');
      setTwilioNumber('');
    }
  };

  /* Function for validation of email */

  const validateEmail = (email) => {
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) {
      setIsFieldUpdated(true);
      setLocationemail(email !== '' ? email : '');
      setLocationemailError('');
    } else if (!validateEmail.test(email)) {
      setLocationemail(email !== '' ? email : '');
      setLocationemailError('Email is invalid');
      setIsFieldUpdated(true);
    } else {
      setLocationemailError('');
      setLocationemail(email);
      setIsFieldUpdated(true);

      // setStepOneValid(true);
    }
  };

  const primaryEmailValidate = (email) => {
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) {
      setIsFieldUpdated(true);
      setprimaryEmail(email !== '' ? email : '');
      // setPrimaryEmailError('Primary Email is required');
    } else if (!validateEmail.test(email)) {
      setprimaryEmail(email !== '' ? email : '');
      setPrimaryEmailError('Primary Email is invalid');
      setIsFieldUpdated(true);
    } else {
      setPrimaryEmailError('');
      setprimaryEmail(email);
      setIsFieldUpdated(true);

      // setStepOneValid(true);
    }
  };
  /* Function for validation of address */

  const validateAddress = (address) => {
    // set field updated to true
    // setIsFieldUpdated(true);

    if (!address) {
      setIsFieldUpdated(true);
      setAddressEdit(address !== '' ? address : '');
      setAddressErrorEdit('Address is requried');
    } else {
      setAddressErrorEdit('');
      setAddressEdit(address);
      setIsFieldUpdated(true);
    }
  };

  /* Function for setting value of location url */

  const locationUrlValidate = (manufacturerUrl) => {
    setIsFieldUpdated(true);
    setLocationUrlHelperText('');
    setLocationUrl(manufacturerUrl);
  };

  /* Function for setting value of facebook url */

  const facebookUrlValidate = (manufacturerUrl) => {
    setIsFieldUpdated(true);
    setFacebookUrlHelperText('');
    setFacebookUrl(manufacturerUrl);
  };

  /* Function for setting value of instagram url */

  const instagramValidate = (instaUrl) => {
    setIsFieldUpdated(true);
    setInstagramUrlHelperText('');
    setInstagramUrl(instaUrl);
  };

  /* Function for validation of address2 */

  const validateAddress2 = (address2) => {
    // set field updated to true
    if (address2) {
      setIsFieldUpdated(true);
      setAddress2Edit(address2);
    } else {
      setIsFieldUpdated(true);
      setAddress2Edit('');
    }
  };

  /* Function for validation of city format */
  const validateCity = (city) => {
    const validation = /^[a-zA-Z ]*$/;
    if (!city) {
      setIsFieldUpdated(true);
      setCityEdit(city !== '' ? city : '');
      setCityErrorEdit('City is required');
    } else if (!validation.test(city)) {
      setCityErrorEdit('Enter alphabet characters only');
      setIsFieldUpdated(true);
      setCityEdit(city !== '' ? city : '');
    } else {
      setCityErrorEdit('');
      setCityEdit(city);
      setIsFieldUpdated(true);
    }
  };

  /* Function to validate of zip code */

  const validateZipCode = (zipCode) => {
    const validateZipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;

    if (!zipCode) {
      setIsFieldUpdated(true);
      setZipcodeEdit(zipCode !== '' ? zipCode : '');
      setZipcodeEdit('');
      setZipcodeErrorEdit('Zip code is required');
      //  setStepOneValid(false);
    } else if (!validateZipcode.test(zipCode)) {
      setZipcodeErrorEdit('Invalid zip code');
      setZipcodeEdit(zipCode !== '' ? zipCode : '');
      setIsFieldUpdated(true);
      // setStepOneValid(false);
    } else {
      setZipcodeErrorEdit('');
      // setIsUpdated(true);
      setZipcodeEdit(zipCode);

      // setStepOneValid(true);
    }
  };

  /* Function for state drop-down to be filled */

  const stateValue = (stateValue) => {
    // set field updated to true
    setIsFieldUpdated(true);

    setStateErrorEdit(false);
    setstateData(stateValue);
    setIsFieldUpdated(true);
  };

  /* Function for state drop-down to be filled */

  const businessEntityValue = (stateValue) => {
    // set field updated to true
    setIsFieldUpdated(true);

    setBusinessEntityError(false);
    setbusinessEntityData(stateValue);
    setIsFieldUpdated(true);
  };

  /* Function for state drop-down to be filled */

  const statusTypeValidate = (Value) => {
    // set field updated to true
    setIsFieldUpdated(true);

    setStatusError(false);
    setStatusValue(Value);
    setIsFieldUpdated(true);
  };

  /* Function for opening date formatting */

  // const validateOpeningDate = (startDate) => {
  //   // console.log("startDate", startDate, Date.parse(startDate));
  //   let objectDate = new Date(startDate);

  //   let date = objectDate.getDate();
  //   let newDate;
  //   if (date < 10) {
  //     newDate = '0' + date;
  //   } else {
  //     newDate = date;
  //   }
  //   let month = objectDate.getMonth() + 1;
  //   let newMonth;
  //   newMonth = month < 10 ? '0' + month : month;
  //   let year = objectDate.getFullYear();
  //   let finalDate = newMonth + '-' + newDate + '-' + year;
  //   // console.log("finalDate", finalDate);

  //   // set field updated to true
  //   setIsFieldUpdated(true);

  //   if (!finalDate) {
  //     setopeningDateError(true);
  //   } else {
  //     // setopeningDate(startDate);
  //     setopeningDate(finalDate);
  //   }
  // };

  const validateOpeningDate = (openingDate) => {
    if (!setopeningDateError) {
      // setopeningDateError('Opening date is required');
      // setIsFieldUpdated(false);
    } else {
      setIsFieldUpdated(true);
      setopeningDate(openingDate);
      setopeningDateError('');
    }
  };

  /* Function for country drop-down to be filled */

  // eslint-disable-next-line no-lone-blocks
  {
    /* const countryValue = (countryValue) => {
        setCountryErrorEdit(false);
        setCountryEdit(countryValue);
    */
  }

  /* Function for assign manager drop down to be filled */

  const assignManagerValue = (assignManager) => {
    // set field updated to true
    setIsFieldUpdated(true);

    setassignManagerErrorEdit(false);
    setassignManagerEdit(assignManager);
    setAssignManagerName(assignManager.split('|')[1]);
    setAssignManagerId(assignManager.split('|')[0]);
    setIsFieldUpdated(true);
  };

  // const handleInputChange = (e) => {
  //   const name = e.target.name;

  //   if (name === 'Unit#') {
  //     setUnitName(e.target.value);
  //   }
  //   // if (name === 'taxGrouping') {
  //   //   setTaxGrouping(e.target.checked);
  //   //   // setTaxGroupingError(false);
  //   //   // setTaxGroupingHelperText('');
  //   //   setIsFieldUpdated(true);
  //   // }
  //   if (name === 'taxExempt') {
  //     setTaxExempt(e.target.checked);
  //     // setTaxExepmtError(false);
  //     // setTaxExepmtHelperText('');
  //     setIsFieldUpdated(true);
  //   }
  // };

  // Method to change the status
  // const handleStatusChange = (val) => {
  //   setSelectedStatus(val);
  //   setIsFieldUpdated(true);
  // };

  /* Function call when user going to  submit edit location  form */

  const locationSubmitValue = (e) => {
    setIsUpdated(false);
    // const validation = /^\d{3}-\d{3}-\d{4}$/;
    const validationEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validationCity = /^[a-zA-Z ]*$/;
    const validateZipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;

    e.preventDefault();
    e.stopPropagation();

    if (!locationNameEdit) {
      setlocationNameEditError('Location name is required');
      return;
    }

    if (!unitName) {
      setunitNameError('Unit name is required');
      return;
    }
    // if (!locationDescription) {
    //     setlocationDescriptionError("Location description is required");
    //     return;
    // }
    // if (!locationphone) {
    //   setlocationphoneError('Phone number is required');
    //   return;
    // }
    // if (!validation.test(locationphone)) {
    //   setlocationphoneError('Entered phone no does not match pattern');
    //   return;
    // }
    // if (!locationEmail) {
    //   setLocationemailError('Email is required ');
    //   return;
    // }
    if (locationEmail && !validationEmail.test(locationEmail)) {
      setLocationemailError('Email is invalid');
      return;
    }

    if (!addressEdit) {
      setAddressErrorEdit('Address is required');
      return;
    }
    // if (!address2Edit) {
    //     setAddress2ErrorEdit("Address 2 field is requried");
    //     return;
    // }
    if (!cityEdit) {
      setCityErrorEdit('City is requried');
      return;
    }
    if (!validationCity.test(cityEdit)) {
      setCityErrorEdit('Enter alphabet characters only');
      return;
    }
    if (!stateData) {
      setStateErrorEdit(true);
      return;
    }

    if (!zipcodeEdit) {
      setZipcodeErrorEdit('Zip code is requried');
      return;
    }
    if (!validateZipcode.test(zipcodeEdit)) {
      setZipcodeErrorEdit('Invalid zip code');
      return;
    }

    if (!assignManagerEdit) {
      setassignManagerErrorEdit(true);
    }

    if (!businessEntityData) {
      setBusinessEntityError(true);
    }
    if (!statusvalue) {
      setStatusError(true);
      // setStatusHelperText('Status is required');
    }

    // check location image in correct format
    // if (locationimageError) {
    //     return;
    // }

    // Now Update the location if all fields are valid

    if (
      !primaryphoneError?.length > 0 &&
      !twilioNumberError?.length > 0 &&
      !locationphoneError?.length > 0 &&
      !locationEmailError?.length > 0 &&
      !primaryEmailError?.length > 0 &&
      !cityErrorEdit?.length > 0 &&
      !zipcodeErrorEdit?.length > 0 &&
      assignManagerName !== '' &&
      assignManagerId !== '' &&
      locationNameEdit !== '' &&
      unitName !== '' &&
      zipcodeEdit &&
      franchiseeId !== '' &&
      franchiseeId !== undefined &&
      statusvalue !== '' &&
      addressEdit !== '' &&
      cityEdit !== '' &&
      stateData !== '' &&
      isFieldUpdated
    ) {
      setOpen(true);
    }
  };

  const handleToggleLiveLocation = (type, value) => {
    setIsFieldUpdated(true);
    setLiveLocation(value);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Grid sx={{ padding: '0 2rem', marginY: '20px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ paddingBottom: '15px' }}
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleEditLocationClose()}
                className="back-arrow-close"
              >
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              <Typography className="header-blue-32">Edit Location</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{ mt: 1 }}
                  type="button"
                  variant="contained"
                  className="common-button"
                  onClick={locationSubmitValue}
                >
                  <Typography className="next-button-text">Save</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Box className="container-text-field">
            <Card
              className="create-franchise-form"
              sx={{ border: '0.4px solid #C5C5C5', padding: '20px' }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Location name"
                      autocomplete="Location name"
                      label="LOCATION NAME"
                      placeholder="Name"
                      id="locationId"
                      // eslint-disable-next-line no-unneeded-ternary
                      value={locationNameEdit}
                      onChange={(e) => locationNameValidate(e.target.value)}
                      className="location-field required-field input-field-styling"
                      error={Boolean(locationNameEditError)}
                      helperText={locationNameEditError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label',
                        shrink: true
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="unit"
                      name="Unit#"
                      label="UNIT #"
                      type="text"
                      className="input-field-styling required-field "
                      value={unitName}
                      onChange={(e) => unitNameValidate(e.target.value)}
                      error={Boolean(unitNameError)}
                      helperText={unitNameError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label',
                        shrink: true
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} spacing={5}>
                    <TextField
                      fullWidth
                      name="Location phone number"
                      autocomplete="off"
                      label="LOCATION PHONE NUMBER"
                      placeholder="777-777-7777"
                      // defaultValue={locationphone}
                      value={locationphone}
                      onChange={(e) => validatePhone(e.target.value)}
                      // onKeyUp={(e) => setValuePhone(e.target.value)}
                      error={Boolean(locationphoneError)}
                      helperText={locationphoneError}
                      className=" location-field input-field-styling"
                      InputLabelProps={{
                        className: 'label-style-create-franchisee',
                        shrink: true
                      }}
                      InputProps={{}}
                      sx={{ mt: 2 }}
                    />
                    <Grid item xs={12} spacing={6}>
                      <TextField
                        fullWidth
                        name="Location email"
                        autocomplete="Location email"
                        label="LOCATION EMAIL"
                        className=" location-field input-field-styling"
                        placeholder="Location email"
                        error={Boolean(locationEmailError)}
                        helperText={locationEmailError}
                        value={locationEmail}
                        onChange={(e) => validateEmail(e.target.value)}
                        InputLabelProps={{
                          className: 'label-style-create-franchisee',
                          shrink: true
                        }}
                        InputProps={{}}
                        sx={{ mt: 4 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} spacing={5}>
                    <TextField
                      fullWidth
                      multiline
                      id="my-textarea"
                      name="Location Description"
                      autocomplete="Location Description"
                      label="LOCATION NOTES"
                      placeholder="Notes here . . . "
                      variant="outlined"
                      value={locationDescription}
                      sx={{ mt: 2 }}
                      className="location-description bg-white"
                      onChange={(e) =>
                        locationDescriptionValidate(e.target.value)
                      }
                      // error={Boolean(locationDescriptionError)}
                      // helperText={locationDescriptionError}
                      InputLabelProps={{
                        className: 'label-style-create-franchisee',
                        shrink: true
                      }}
                      InputProps={{ rows: 7.2 }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="form-datepicker-container pt-36"
                  >
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                    {/* <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    error={openingDateError}
                  >
                    <DatePicker
                      value={openingDate}
                      onChange={validateOpeningDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          className="field-top-margin-edit-location location-field"
                          label="OPENING DATE"
                          InputLabelProps={{
                            className: 'label-style-create-franchisee',
                            shrink: true
                          }}
                          placeholder="MM/DD/YYYY"
                        />
                      )}
                    />
                  </LocalizationProvider> */}
                    <CustomDatePickerForAllBrowser
                      label="OPENING DATE"
                      onChange={validateOpeningDate}
                      fieldValue={openingDate}
                    />
                    {openingDateError && (
                      <FormHelperText>Opening Date is required</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      className="field-top-margin-edit-location location-field input-field-styling required-field"
                      error={assignManagerErrorEdit}
                      fullWidth
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        ASSIGN SUPPORT SPECIALIST
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="ASSIGN SUPPORT SPECIALIST"
                        // multiple
                        value={assignManagerEdit}
                        className="input-field-styling"
                        onChange={(e) => assignManagerValue(e.target.value)}
                        // renderValue={(selected) => selected.join(', ')}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 200 } }
                        }}
                      >
                        {managerList.length > 0 &&
                          managerList.map((item) => (
                            <MenuItem
                              key={item.userId}
                              id={item.userId}
                              // value={item.userId}
                              value={
                                item.userId +
                                '|' +
                                item.firstName +
                                ' ' +
                                item.lastName
                              }
                            >
                              {item.firstName + ' ' + item.lastName}
                            </MenuItem>
                          ))}
                        {/* {assignManagerEdit.map((item) =>
                                                <MenuItem value={item}>{item}</MenuItem>
                                            )} */}
                        {/* <MenuItem value={"A"}>A</MenuItem>
                                                        <MenuItem value={"B"}>B</MenuItem>
                                                        <MenuItem value={"C"}>C</MenuItem> */}
                      </Select>
                      {assignManagerErrorEdit && (
                        <FormHelperText>
                          Please select assign support specialist
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      className="field-top-margin-edit-location location-field input-field-styling required-field"
                      error={businessEntityError}
                      fullWidth
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        BUSINESS ENTITY
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label required-label"
                        id="demo-simple-select-helper"
                        label="BUSINESS ENTITY"
                        value={businessEntityData}
                        className="input-field-styling"
                        onChange={(e) => businessEntityValue(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto'
                            }
                          }
                        }}
                      >
                        {franchiseeList.map((item) => (
                          <MenuItem
                            id={item.franchiseeName}
                            value={item?.franchiseeName || item?.franchiseeId}
                          >
                            {item.franchiseeName}
                          </MenuItem>
                        ))}
                      </Select>
                      {businessEntityError && (
                        <FormHelperText>
                          Please select business entity
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} mt={2}>
                    <FormControl
                      fullWidth
                      className="select-drop-down-label-styling required-field"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        STATUS
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="status"
                        label="STATUS"
                        className="input-field-styling"
                        value={statusvalue}
                        onChange={(e) => statusTypeValidate(e.target.value)}
                        // renderValue={(selected) => selected.join(', ')}
                      >
                        {/* <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem> */}
                        <MenuItem value={'Location Search'}>
                          Location Search
                        </MenuItem>
                        <MenuItem value={'Location Negotiation'}>
                          Location Negotiation
                        </MenuItem>
                        <MenuItem value={'LOI Signed'}>LOI Signed</MenuItem>
                        <MenuItem value={'Lease Signed'}>Lease Signed</MenuItem>
                        <MenuItem value={'Under Construction'}>
                          Under construction
                        </MenuItem>
                        <MenuItem value={'Open'}>Open</MenuItem>
                      </Select>
                      {statusError && (
                        <FormHelperText>Please select status</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} mt={2}>
                    <TextField
                      fullWidth
                      name="primary contact name"
                      autocomplete="primary contact name"
                      label="PRIMARY CONTACT NAME"
                      placeholder="Contact Name"
                      id="locationId"
                      // eslint-disable-next-line no-unneeded-ternary
                      value={primaryContactName}
                      onChange={(e) =>
                        primaryContactNameValidate(e.target.value)
                      }
                      className="location-field  input-field-styling"
                      error={Boolean(primaryContactNameError)}
                      helperText={primaryContactNameError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label',
                        shrink: true
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Primary email"
                      autocomplete="Primary email"
                      label="PRIMARY CONTACT EMAIL"
                      className="field-top-margin-edit-location location-field input-field-styling"
                      placeholder="Location email"
                      error={Boolean(primaryEmailError)}
                      helperText={primaryEmailError}
                      value={primaryEmail}
                      onChange={(e) => primaryEmailValidate(e.target.value)}
                      InputLabelProps={{
                        className: 'label-style-create-franchisee',
                        shrink: true
                      }}
                      InputProps={{}}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Primary phone number"
                      autocomplete="Primary phone number"
                      label="PRIMARY PHONE "
                      placeholder="777-777-7777"
                      // defaultValue={locationphone}
                      value={primaryphone}
                      onChange={(e) => validatePrimaryPhone(e.target.value)}
                      // onKeyUp={(e) => setValuePhone(e.target.value)}
                      error={Boolean(primaryphoneError)}
                      helperText={primaryphoneError}
                      className="field-top-margin-edit-location location-field input-field-styling"
                      InputLabelProps={{
                        className: 'label-style-create-franchisee',
                        shrink: true
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={4} display="flex" alignItems="center" gap={2}>
                    <Typography className="text-fiel check-box-text">
                      Live Location
                    </Typography>
                    <Toggle
                      value={liveLocation}
                      name="liveLocation"
                      onChange={handleToggleLiveLocation}
                      checked={liveLocation}
                    />

                    {/* left arrow icon */}
                    <Tooltip
                      arrow
                      title={
                        'Enabled this to make the location available, or disabled this to make the location unavailable.'
                      }
                      placement="bottom"
                    >
                      <IconButton className="no-hover">
                        <InfoIcon className="info-icon" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="twilioNumber"
                      autoComplete="off"
                      label="TWILIO NUMBER"
                      // placeholder="777-777-7777"
                      // defaultValue={locationphone}
                      value={twilioNumber}
                      onChange={(e) => {
                        validateTwilioNumber(e.target.value);
                        setIsFieldUpdated(true);
                      }}
                      // onKeyUp={(e) => setValuePhone(e.target.value)}
                      error={Boolean(twilioNumberError)}
                      helperText={twilioNumberError}
                      className="field-top-margin-edit-location location-field input-field-styling"
                      InputLabelProps={{
                        className: 'label-style-create-franchisee'
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="field-top-margin"
                    ml={1}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography className="table-heade header-blue-20">
                        Location Address
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Address"
                      autocomplete="Address"
                      label="ADDRESS LINE 1"
                      placeholder="Address"
                      className="field-top-margin-edit-location location-field input-field-styling required-field"
                      value={addressEdit}
                      onChange={(e) => validateAddress(e.target.value)}
                      error={Boolean(addressErrorEdit)}
                      helperText={addressErrorEdit}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee edit-address-label required-label',
                        shrink: true
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Address 2"
                      autocomplete="Address 2"
                      label="ADDRESS LINE 2"
                      className="field-top-margin-edit-location location-field input-field-styling"
                      placeholder="Address"
                      defaultValue={address2Edit}
                      value={address2Edit}
                      onChange={(e) => validateAddress2(e.target.value)}
                      // error={Boolean(address2ErrorEdit)}
                      // helperText={address2ErrorEdit}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee edit-address-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      className="field-top-margin-edit-location location-field input-field-styling required-field"
                      error={stateErrorEdit}
                      fullWidth
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        STATE
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label required-label"
                        id="demo-simple-select-helper"
                        label="STATE"
                        value={stateData}
                        onChange={(e) => stateValue(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto'
                            }
                          }
                        }}
                      >
                        {statesList.map((item) => (
                          <MenuItem id={item.state} value={item.state}>
                            {item.state}
                          </MenuItem>
                        ))}
                      </Select>
                      {stateErrorEdit && (
                        <FormHelperText>Please select state</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="City"
                      autocomplete="City"
                      label="CITY"
                      placeholder="City"
                      className="field-top-margin-edit-location location-field input-field-styling required-field required-field"
                      value={cityEdit}
                      onChange={(e) => validateCity(e.target.value)}
                      error={Boolean(cityErrorEdit)}
                      helperText={cityErrorEdit}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label ',
                        shrink: true
                      }}
                      InputProps={{}}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Zip code"
                      autocomplete="Zip code"
                      label="ZIP CODE"
                      placeholder="44444"
                      className="field-top-margin-edit-location location-field input-field-styling required-field"
                      value={zipcodeEdit}
                      onChange={(e) => validateZipCode(e.target.value)}
                      error={Boolean(zipcodeErrorEdit)}
                      helperText={zipcodeErrorEdit}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee edit-address-label required-label',
                        shrink: true
                      }}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="locationUrl"
                      autocomplete="LOCATION URL"
                      label="LOCATION URL"
                      className="field-top-margin-edit-location location-field input-field-styling"
                      value={locationUrl}
                      // error={Boolean(manufacturerUrlError)}
                      helperText={locationUrlHelperText}
                      onChange={(e) => locationUrlValidate(e.target.value)}
                      placeholder="Location URL"
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee edit-address-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="facebookUrl"
                      autocomplete="FACEBOOK URL"
                      label="FACEBOOK URL"
                      className="field-top-margin-edit-location location-field input-field-styling"
                      value={facebookUrl}
                      // error={Boolean(manufacturerUrlError)}
                      helperText={facebookUrlHelperText}
                      onChange={(e) => facebookUrlValidate(e.target.value)}
                      placeholder="Facebook URL"
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee edit-address-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="instagramHandle"
                      autocomplete="INSTAGRAM HANDLE"
                      label="INSTAGRAM"
                      className="field-top-margin-edit-location location-field input-field-styling"
                      value={instagramUrl}
                      // error={Boolean(manufacturerUrlError)}
                      helperText={instagramUrlHelperText}
                      onChange={(e) => instagramValidate(e.target.value)}
                      placeholder="Instagram"
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee edit-address-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className="field-top-margin-edit-location location-field input-field-styling"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling"
                      >
                        STATUS
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="STATUS"
                        className="iinput-field-dropdown-styling input-field-styling"
                        value={selectedStatus}
                        onChange={(e) => handleStatusChange(e.target.value)}
                      >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={0}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={2}>
                                                <Avatar src={imageUrl} sx={{ width: 90, height: 90 }} />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <span className="add-profile-text">Add Location image</span>
                                                <Box error={locationimageError}>
                                                    <input accept="image/png, image/jpeg, image/jpg" multiple type="file" id="select-file" style={{ display: 'none' }} onChange={(event) => handleFileInput(event)} /><label htmlFor="select-file">
                                                        <Button variant="contained" component="span" className="upload-image" ><Typography className="upload-text">Choose </Typography></Button></label>
                                                </Box>
                                                {locationimageError && <FormHelperText className='error-message'>{locationImageError}</FormHelperText>}
                                            </Grid> */}
                  <Grid item xs={12} sm={6}>
                    {showError && (
                      <Box className="error-message">{errorMessage}</Box>
                    )}
                    {/* <Button
                    type="button"
                    className="next-button pr-0 no-hover"
                    onClick={locationSubmitValue}
                  >
                    <Typography className="next-button-text">Save</Typography>
                  </Button> */}
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2} columns={12} mt={1}>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="remember"
                          name="taxExempt"
                          className="check-box-span"
                          color="default"
                          sx={{
                            color: '#003087',
                            '&.Mui-checked': {
                              color: '#003087'
                            }
                          }}
                        />
                      }
                      label={
                        <Typography className="label-style-create-franchisee">
                          Tax Exempt
                        </Typography>
                      }
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="remember1"
                          name="taxGrouping"
                          className="check-box-span"
                          color="default"
                          checked={taxGrouping}
                          sx={{
                            color: '#003087',
                            '&.Mui-checked': {
                              color: '#003087'
                            }
                          }}
                        />
                      }
                      label={
                        <Typography className="label-style-create-franchisee">
                          Tax grouping
                        </Typography>
                      }
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid> */}
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Save Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Please confirm that you want to save your changes?
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Location details has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose(isUpdated ? 'Close' : 'Discard')}
          >
            {isUpdated ? 'Close' : 'Go Back to Editing'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog
        open={openConfirmPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?{' '}
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LocationEdit;
