/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { forwardRef, useState, useEffect } from 'react';
import {
  Slide,
  Typography,
  Button,
  Box,
  Grid,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Avatar,
  FormHelperText
} from '@mui/material';
import {
  CustomTextField,
  CustomButton,
  Toggle
} from 'src/components/Global';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { getLocalData } from 'src/utils'; // setFullLocationId
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const AddJourney = (props) => {
  const {
    isServiceEdit,
    setIsServiceEdit,
    trophyDetails,
    handleResetStates
  } = props;

  const userId = getLocalData('userId');

  const [btnLoading, setBtnLoading] = useState(false);
  const [preValues, setPreValues] = useState({});
  const [initialValue, setInitialValue] = useState({
    title: '',
    description: '',
    juniorTrophy: false,
    staffAssigned: false,
    displayOnShelf: false,
    status: '',
    sortKey: ''
  });
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);

  const [isError, setIsError] = useState({
    title: false,
    trophyImage: false
  });
  const [imgObjClient, setimgObjClient] = useState([]);
  const [imageSelected, setImageSelected] = useState(false);
  const [imgUrl, setimgUrl] = useState('');
  const [imageError, setImageError] = useState(false);
  const [imageErrorMsg, setImageErrorMsg] = useState('');

  const [dialog, setDialog] = useState({
    state: false,
    isCreated: false
  });


  useEffect(() => {
    if (isServiceEdit && trophyDetails) {
      // setServiceType(trophyDetails?.typeOfService && trophyDetails?.typeOfService.toLowerCase());
      let data = {
        sortKey: trophyDetails?.sortKey,
        title: trophyDetails?.title,
        description: trophyDetails?.description,
        juniorTrophy: trophyDetails?.juniorTrophy,
        staffAssigned: trophyDetails?.staffAssigned,
        displayOnShelf: trophyDetails?.displayOnShelf,
        trophyImage: trophyDetails?.trophyImage,
        status: trophyDetails?.status
      };

      // if trophy image available then set the imageError to be false else true
      if (trophyDetails?.trophyImage) {
        setImageError(false);
        setImageSelected(true);
        setimgUrl(trophyDetails?.trophyImage);
      } else {
        setImageError(true);
        setImageErrorMsg('Image is required');
      }
      // setIsFranchisor(trophyDetails?.isFranchisor);
      
      // TODO: To enable the toggle button if value is true
      // if staff assigned is truen then set the staff assigned to be true
      if (trophyDetails?.staffAssigned) {
        let elm = document.querySelector('.staff-assigned');
        if(elm) elm.click();
      }
      // if juniour trophy is truen then set the staff assigned to be true
      if (trophyDetails?.juniorTrophy) {
        let elm = document.querySelector('.junior-trophy');
        if(elm) elm.click();
      }
      // if display on self is truen then set the staff assigned to be true
      if (trophyDetails?.displayOnShelf) {
        let elm = document.querySelector('.display-on-shelf');
        if(elm) elm.click();
      }
      setInitialValue(data);
      setPreValues(data);
      
    }
  }, []);

  const handleServiceInfo = (type, value) => {
    setFieldUpdated(true);

    // Check for negative values for the 'duration' field
    if (type === 'duration' && value < 0) {
      return; // Exit the function if the value is negative
    }

    setInitialValue({ ...initialValue, [type]: value });
    setIsError({ ...isError, [type]: false });
  };


  const valuesAreDifferent = () => {
    if (!isServiceEdit) {
      return Object.entries(initialValue).some(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0; // Consider non-empty arrays as different
        } else {
          return value !== ''; // Consider non-empty strings as different
        }
      });
    } else {
      const keys = Object.keys(initialValue);
      return keys.some((key) => initialValue[key] !== preValues[key]);
    }
  };

  const handleCloseScreen = () => {
    if (valuesAreDifferent()) {
      setDialog({ ...dialog, state: true });
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        state: false,
        isCreated: false
      });
    } else if (value === 'Discard') {
      handleResetStates();
      setDialog({
        state: false,
        isCreated: false
      });
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
  };

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = [
      'title',
      'trophyImage',
    ];
    for (const key of alwaysCheck) {
      const value = initialValue[key];
      const isEmpty =
        value === '' || (Array.isArray(value) && value.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }

    setIsError(updatedErrors);
    return hasError;
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    if (!checkForEmptyValues() && !imageError) {
      setBtnLoading((prev) => {
        let toggleId;
        if (prev['update_trophy']) {
          toggleId = { ['update_trophy']: false };
        } else {
          toggleId = { ['update_trophy']: true };
        }
        return { ...toggleId };
      });

      if (isServiceEdit) {
        // handleUpdateService();
        if (fieldUpdated) {
          setConfirmEdit(true);
        } else {
          setBtnLoading(false);
        }
      } else {
        handleAddTrophy();
      }
    } else {
      setImageError(true);
      setImageErrorMsg('Image is required');
      // toast.error('Please fill all the required fields');
      console.log('Please fill all the required fields');
    }
  };

  const handleEditDialog = (value) => {
    if (value === 'Continue') {
      handleUpdateTrophy();
      setConfirmEdit(false);
    } else if (value === 'Discard') {
      setBtnLoading(false);
      setConfirmEdit(false);
    } else if (value === 'Cancel') {
      setConfirmEdit(false);
    }
  };

  const handleAddTrophy = async () => {
    // create form data and append all the fields
    const formData = new FormData();
    formData.append('title', initialValue.title);
    formData.append('juniorTrophy', initialValue.juniorTrophy);
    formData.append('description', initialValue.description);
    formData.append('staffAssigned', initialValue.staffAssigned);
    formData.append('displayOnShelf', initialValue.displayOnShelf)
    formData.append('createdBy', userId);
    formData.append('trophyImage', imgObjClient);
    formData.append('status', initialValue.status === '' ? 1 : initialValue.status);

    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}/trophy`,
        formData
      );
      if (!response?.error) {
        setIsServiceEdit(false);
        setFieldUpdated(false);
        setConfirmEdit(false);
        setDialog({
          isCreated: true,
          state: true
        });
        handleResetStates();
      } else {
        console.log(
          response?.message || 'Something went wrong with add Service'
        );
      }

      setBtnLoading(false);
    } catch (error) {
      console.log(error);
      console.error(error);
      setBtnLoading(false);
      console.log('Something went wrong, Try again!');
    }
  };

  const handleUpdateTrophy = async () => {
    const formData = new FormData();
    formData.append('title', initialValue.title);
    formData.append('juniorTrophy', initialValue.juniorTrophy);
    formData.append('description', initialValue.description);
    formData.append('staffAssigned', initialValue.staffAssigned);
    formData.append('displayOnShelf', initialValue.displayOnShelf)
    formData.append('updatedBy', userId);
    formData.append('trophyImage', imgObjClient);
    formData.append('status', initialValue.status === '' ? 1 : initialValue.status);

    let trophyId = encodeURIComponent(initialValue.sortKey);

    try {
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_END_POINT}trophy/updateTrophy?trophyId=${trophyId}`,
        formData
      );
      if (!response?.error) {
        handleResetStates();
        setIsServiceEdit(true);
        setFieldUpdated(false);
        setConfirmEdit(false);
        setDialog({
          isCreated: true,
          state: true
        });
        handleCloseScreen();
      } else {
        console.log(
          response.data?.message || 'Something went wrong with update Service'
        );
      }

      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      console.log('Something went wrong, Try again!');
    }
  };

  const handleUploadTrophyImage = (e) => {
    setimgObjClient(e.target.files[0]);
    setimgUrl(URL.createObjectURL(e.target.files[0]));
    setImageSelected(true);
    setFieldUpdated(true);
    setImageError(false);
    setImageErrorMsg('');
  };

  // Cross icon handler
  const removeImage = () => {
    setImageSelected(false);
    setimgUrl('');
    setimgObjClient([]);
    setImageError(true);
    setImageErrorMsg('Image is required');
  };

  return (
    <div className="service-container">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mt={1}
        mb={4}
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              onClick={() => handleCloseScreen()}
              className="back-arrow-close"
            >
              <ArrowLeft />
            </Box>

            <Typography className="header-blue-32">
              {isServiceEdit ? 'Edit Trophy' : 'Create a Trophy'}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <CustomButton
              variant="contained"
              type="submit"
              onClick={handleFormSubmission}
              className="common-button"
              loading={btnLoading['update_trophy']}
            >
              <Typography>{isServiceEdit ? 'Update' : 'Save'}</Typography>
            </CustomButton>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{ width: '100%' }}
        paddingBottom="20px"
        className="Main-container common-form-container"
      >
        <form className="custom-form" onSubmit={handleFormSubmission}>
          <div>
            <Grid container spacing={2} columns={12} mt={2} />
            <Grid container spacing={2} columns={12} mt={1}>

              <Grid item xs={12} sm={6}>
                <CustomTextField
                  type="text"
                  name="title"
                  label="TITLE"
                  fullWidth
                  required={Boolean(true)}
                  helperText={isError.title && 'Title is required'}
                  value={initialValue.title}
                  onChange={handleServiceInfo}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} mt={1}>
              <Grid item xs={12} sm={4} paddingRight="4px">
                <Toggle
                  fullWidth
                  spaceBetween
                  name="juniorTrophy"
                  label="Junior Trophy"
                  labelPlacement="start"
                  value={Boolean(initialValue.juniorTrophy)}
                  onChange={handleServiceInfo}
                  className="junior-trophy"
                />
              </Grid>
              <Grid item xs={12} sm={4} paddingRight="4px">
                <Toggle
                  fullWidth
                  spaceBetween
                  name="staffAssigned"
                  label="Staff Assigned"
                  labelPlacement="start"
                  value={initialValue.staffAssigned}
                  onChange={handleServiceInfo}
                  className="staff-assigned"
                />
              </Grid>
              <Grid item xs={12} sm={4} paddingRight="4px">
                <Toggle
                  fullWidth
                  spaceBetween
                  name="displayOnShelf"
                  label="Display on the Shelf"
                  labelPlacement="start"
                  value={initialValue.displayOnShelf}
                  onChange={handleServiceInfo}
                  className="display-on-shelf"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} columns={12} mt={1}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  label="DESCRIPTION"
                  multiline
                  rows="6"
                  type="text"
                  name="description"
                  value={initialValue.description}
                  onChange={handleServiceInfo}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} columns={12} mt={1}>
              <Grid item xs={12} sm={10} md={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} className={`${imageSelected ? null : 'mt-10'}`}>
                  {imageSelected ? (
                    <Box className='relative'>
                      <Avatar
                        className='image-avatar'
                        src={imgUrl !== '' ? imgUrl : null}
                        sx={{ marginTop: '40px', height: '165px', width: '165px', borderRadius: 'none', marginRight: '30px' }}
                      />
                      <div
                        className="cross-imageicon text-blue"
                        role='button'
                        onClick={() => removeImage()}
                        onKeyDown={() => removeImage()}
                        tabIndex={0}
                      >
                        x
                      </div>
                    </Box>
                  ) : (
                    <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_896_42450" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="101">
                        <circle cx="50" cy="50.7671" r="50" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_896_42450)">
                      </g>
                      <g clipPath="url(#clip0_896_42450)">
                        <circle cx="62" cy="50.7671" r="35.5" fill="#F8FBFF" stroke="#B2C1D4" />
                        <path d="M50.3684 61.9538L59.3158 53.1443L62.68 56.4567C61.6958 58.06 61.1053 59.9453 61.1053 61.9538H50.3684ZM55.7368 46.9777C55.7368 45.5153 54.5379 44.3348 53.0526 44.3348C51.5674 44.3348 50.3684 45.5153 50.3684 46.9777C50.3684 48.44 51.5674 49.6205 53.0526 49.6205C54.5379 49.6205 55.7368 48.44 55.7368 46.9777ZM61.2663 63.7157H48.5789V35.5253H61.1053V44.3348H70.0526V51.541C70.6432 51.4529 71.2337 51.3824 71.8421 51.3824C72.4505 51.3824 73.0411 51.4529 73.6316 51.541V42.5729L62.8947 32.0015H48.5789C46.5926 32.0015 45 33.5872 45 35.5253V63.7157C45 65.6715 46.5926 67.2396 48.5789 67.2396H62.5547C61.9284 66.1648 61.4811 64.9843 61.2663 63.7157ZM70.0526 54.9062V60.1919H64.6842V63.7157H70.0526V69.0015H73.6316V63.7157H79V60.1919H73.6316V54.9062H70.0526Z" fill="#003087" />
                      </g>
                      <defs>
                        <clipPath id="clip0_896_42450">
                          <rect width="75" height="75" fill="white" transform="translate(24 15.3218)" />
                        </clipPath>
                      </defs>
                    </svg>

                  )}

                  <Box className={`required-field ${imageSelected ? 'mt-40' : null} select-image`} sx={{ marginLeft: '50px', display: 'flex', flexDirection: 'column' }}>
                    <Typography className='add-picture-text weight-700 text-black font-16 required-label'>
                      {isServiceEdit ? 'Edit' : 'Upload'} Trophy Image
                    </Typography>
                    <input
                      accept="image/*"
                      multiple
                      type="file"
                      id="select-file"
                      name="profileImage"
                      style={{ display: 'none' }}
                      onChange={(event) => handleUploadTrophyImage(event)}
                    />
                    <label htmlFor="select-file">
                      <Button
                        type='button'
                        variant="contained"
                        component="span"
                        className="upload-image image-with-txt common-button"
                        sx={{ marginTop: '24px' }}
                      >
                        <Typography className="upload-text">Choose</Typography>
                      </Button>
                    </label>
                  </Box>
                </Box>
                {(imageError) && (
                  <FormHelperText>
                    {imageErrorMsg}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </div>
        </form>

        <Dialog
          open={dialog.state}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleDialog('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            {dialog.isCreated ? 'Congratulations!' : 'Discard Changes?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                {!dialog.isCreated ? (
                  <Typography className="title-text-sub">
                    You have unsaved changes, what do you want
                    <Typography className="title-text-sub">to do?</Typography>
                  </Typography>
                ) : (
                  <Typography className="title-text-sub">
                    {`Trophy has been ${isServiceEdit ? 'updated' : 'created'
                      } successfully!`}
                  </Typography>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() =>
                handleDialog(dialog.isCreated ? 'Continue' : 'Discard')
              }
            >
              {dialog.isCreated ? 'Close' : 'Discard Changes'}
            </Button>
            {!dialog.isCreated && <Divider className="vertical-divider" />}
            {!dialog.isCreated && (
              <Button
                className="font-17 no-hover"
                onClick={() => handleDialog('Continue')}
              >
                Go back to Editing
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmEdit}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleEditDialog('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            Save Changes
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  You have unsaved changes, what do you want
                  <Typography className="title-text-sub">to do?</Typography>
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() =>
                handleEditDialog(dialog.isCreated ? 'Discard' : 'Discard')
              }
            >
              Discard
            </Button>
            {!dialog.isCreated && <Divider className="vertical-divider" />}
            {!dialog.isCreated && (
              <Button
                className="font-17 no-hover"
                onClick={() => handleEditDialog('Continue')}
              >
                Save
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};
