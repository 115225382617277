/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';

const SalesHistoryReceipt = ({ selected }) => {
  const infoWidth = '50%'; // Width for the data above and below the table
  const clientWidth = '100%';
  function getDateFormat(value) {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'long', // Changed from 'numeric' to 'long'
      day: 'numeric',
      year: 'numeric'
    });

    return formattedDate;
  }

  return (
    <div
      className="receipt"
      style={{
        border: '1px solid #000',
        fontFamily: 'Arial, Helvetica, sans-serif',
        padding: '10px',
        textAlign: 'center'
      }}
    >
      <h2 style={{ textAlign: 'left', width: infoWidth }}>
        {' '}
        {selected?.paymentSourceType === 'COMP'
          ? 'Comp Receipt'
          : 'Sales Receipt'}
      </h2>

      <div
        className="receipt-info"
        style={{
          marginBottom: '10px',
          textAlign: 'left',
          width: infoWidth
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '5px',
            marginBottom: '5px'
          }}
        >
          <p style={{ fontWeight: 'bold' }}>Client Name:</p>
          <p>{selected?.clientName || ''}</p>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            marginBottom: '5px'
          }}
        >
          <p style={{ fontWeight: 'bold' }}>Date:</p>
          <p>{getDateFormat(selected?.createdAt)}</p>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            marginBottom: '5px'
          }}
        >
          <p style={{ fontWeight: 'bold', textWrap: 'nowrap' }}>Order ID:</p>
          <p>{selected?.orderId}</p>
        </div>
      </div>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #000',
                padding: '8px',
                width: '50%'
              }}
            >
              Item
            </th>
            <th
              style={{
                border: '1px solid #000',
                padding: '8px',
                width: '25%'
              }}
            >
              Quantity
            </th>
            <th
              style={{
                border: '1px solid #000',
                padding: '8px',
                width: '25%'
              }}
            >
              Price
            </th>
            <th
              style={{
                border: '1px solid #000',
                padding: '8px',
                width: '25%'
              }}
            >
              Total Price
            </th>
          </tr>
        </thead>
        <tbody>
          {selected?.items?.map((product, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                {product.itemName}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                {product?.quantity || 1}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                $
                {parseFloat(
                  product?.itemType === 'Bundle' &&
                    (product?.actualPrice || product?.actualPrice === 0)
                    ? product?.actualPrice
                    : product?.price
                )?.toFixed(2)}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                $
                {parseFloat(
                  (product?.itemType === 'Bundle'
                    ? product.actualPrice * product.quantity
                    : product.price * product.quantity) || 0
                )?.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="totals"
        style={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'end',
          width: clientWidth
        }}
      >
        <div
          style={{
            marginTop: '10px',
            display: 'flex',

            flexDirection: 'column',
            width: '30%'
          }}
        >
          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px'
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Subtotal:</span>
            <span>
              {' '}
              $
              {parseFloat(
                selected?.totalSquareAmountWithoutTaxInDollar +
                  selected?.totalSquareDiscountAmountInDollar || 0
              )?.toFixed(2)}
            </span>
          </p>

          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px'
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Service Fee:</span>
            <span> ${parseFloat(selected?.serviceFee || 0)?.toFixed(2)}</span>
          </p>

          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px'
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Discount:</span>
            <span>
              $
              {parseFloat(
                selected?.totalSquareDiscountAmountInDollar ?? 0
              )?.toFixed(2)}
            </span>
          </p>

          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px',
              borderBottom: '1px solid black',
              paddingBottom: '10px'
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Tax:</span>
            <span>
              $
              {parseFloat(selected?.totalSquareTaxAmountInDollar ?? 0)?.toFixed(
                2
              )}
            </span>
          </p>

          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px'
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Total:</span>
            <span>
              $
              {parseFloat(
                selected?.totalSquareAmountWithTaxInDollar ?? 0
              )?.toFixed(2)}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SalesHistoryReceipt;
