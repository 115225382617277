import React from 'react';
import { Alert, Typography } from '@mui/material';

const CustomAlert = ({ title, description, backgroundColor, icon }) => {
  return (
    <Alert
      sx={{
        backgroundColor: backgroundColor
      }}
      icon={icon}
    >
      <Typography variant="subtitle1">
        <strong>{title}:&nbsp;</strong>
        {description}
      </Typography>
    </Alert>
  );
};

export default CustomAlert;
