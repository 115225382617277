import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import { FormControl, FormHelperText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TextField, Grid } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../../services/ApiService';
import { formatPhoneNumber, getLocalData, setFullLocationId } from 'src/utils';
import Loader from '../../../components/Loader';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddBusinessEntities({ setShowScreen, refreshData }) {
  const locationId = setFullLocationId(getLocalData('locationId'));
  let userId = getLocalData('userId');
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  // check field updated or not
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  /* States for getting values from input fields of Add product form as follows */
  const [businessName, setBusinessName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [address2, setaddress2] = useState('');
  const [businessEntityTypeId, setBusinessEntityTypeId] = useState(null);
  const [stateType, setStateType] = useState('');

  const [manufacturerUrl, setmanufacturerUrl] = useState('');
  const [zoomRoomProductName, setzoomRoomProductName] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [varientType, setvarientType] = useState([]);

  const [productDescription, setproductDescription] = useState('');
  const [productCategoryList, setproductCategoryList] = useState([]);

  const [isUpdated, setIsUpdated] = useState(false);
  /* States for getting and setting add product errors  */
  const [businessNameError, setBusinessNameError] = useState('');
  const [businessEmailerror, setBusinessEmailerror] = useState('');
  const [businessPhoneError, setBusinessPhoneError] = useState('');
  const [contactPhoneError, setContactPhoneError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [contactNameError, setContactNameError] = useState('');
  const [contactEmailError, setContactEmailError] = useState('');
  const [address2Error, setaddress2Error] = useState('');
  const [stateTypeError, setStateTypeError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [manufacturerUrlError, setmanufacturerUrlError] = useState('');
  const [zoomRoomProductNameError, setzoomRoomProductNameError] = useState('');
  const [businessEntityTypeError, setBusinessEntityTypeError] = useState(false);
  const [PopUpAddEntityTypeError, setPopUpAddEntityTypeError] = useState('');

  const [open, setOpen] = React.useState(false);

  const [openAddCategory, setopenAddCategory] = useState(false);

  const [categoryName, setcategoryName] = useState('');

  const [isEntityExist, setisEntityExist] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getCategory();
    getVariantType();
  }, []);

  /* Function to clear form fields when dialog form is closed */
  const clear = () => {
    setmanufacturerUrl('');
    setzoomRoomProductName('');
    setBusinessName('');
    setvarientType('');

    setproductDescription('');
    setproductCategoryList([]);

    setIsFieldUpdated(false);
    setBusinessEmail('');
    setBusinessPhone('');
    setmanufacturerUrl('');
    setaddress2('');
    setCity('');
    setZipCode('');
    setContactName('');
    setContactEmail('');
    setContactPhone('');
    setStateType('');
    setBusinessEntityTypeId({});
  };

  // close variant dialog
  const handleCloseVariant = () => {
    setPopUpAddEntityTypeError('');

    setopenAddCategory(false);
  };

  /* Method for Add product category modal */
  const addNewCategory = () => {
    setcategoryName('');
    setopenAddCategory(true);
  };

  // Entity type pop up error handle here
  const handleEntityPopupError = () => {
    setPopUpAddEntityTypeError('');
  };

  // Method to check the email already exist or not from API
  const checkEntityTypeExist = async (type) => {
    setPopUpAddEntityTypeError('');

    // check the email is valid or not
    if (type && type.length > 0) {
      let value = type;
      // Now check the entity is already exist or not
      let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/checkBusinessEntityTypeExists?businessEntityType=${value}`;
      try {
        let response = await ApiService.get(url);
        if (!response?.error) {
          let entityExist = response?.isExists;
          let msg = response?.message;
          if (response && entityExist) {
            setisEntityExist(true);
            setPopUpAddEntityTypeError(msg);
          } else {
            setisEntityExist(false);
            setPopUpAddEntityTypeError('');
          }
        }
      } catch (error) {
        setPopUpAddEntityTypeError(error?.message);
        setisEntityExist(false);
      }
    }
  };

  // Now create category
  const handleCreateCategory = () => {
    if (!isEntityExist) {
      if (categoryName) {
        setIsLoading(true);
        const data = {
          businessEntityType: categoryName,
          createdBy: userId
        };
        createCategory(data);
      } else {
        setPopUpAddEntityTypeError('Entity Type is required');
      }
    }
  };

  // create category
  const createCategory = async (data) => {
    try {
      await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/addBusinessEntityType`,
        data
      ).then((response) => {
        if (!response?.error) {
          setcategoryName('');
          getCategory();
          setBusinessEntityTypeId(response?.data.businessEntityTypeId);
          setopenAddCategory(false);
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
    }
    if (val === 'Discard') {
      setShowScreen(0);
      clear();
      setOpen(false);
    }
  };

  /* Method for closing of Add product modal */

  const handleCloseAddProduct = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
      clear();
    }
  };

  /* Function to get getAllBusinessEntityTypes */
  const getCategory = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntityTypes`
      );
      setproductCategoryList(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };

  const getVariantType = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`
      );
      setvarientType(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };

  /* Function to set business name setting value */
  const businessNameValidate = (businessName) => {
    if (!businessName) {
      setIsFieldUpdated(true);
      setBusinessName('');
      setBusinessNameError('Business  name is required');
    } else {
      setIsFieldUpdated(true);
      setBusinessNameError('');
      setBusinessName(businessName);
    }
  };

  /* Function to set manufacturer contact name setting value */
  const contactNameValidate = (contactName) => {
    if (!contactName) {
      setIsFieldUpdated(true);
      setContactName('');
      setContactNameError('');
    } else {
      setIsFieldUpdated(true);
      setContactNameError('');
      setContactName(contactName);
    }
  };

  /* Function for validation of Business email  */
  const businessEmailValidate = (businessEmail) => {
    let manufacturerPattern = /\S+@\S+\.\S+/;
    if (!businessEmail) {
      setIsFieldUpdated(true);
      setBusinessEmailerror('');
      setBusinessEmail('');
    } else if (!manufacturerPattern.test(businessEmail)) {
      setIsFieldUpdated(true);
      setBusinessEmailerror('Please enter valid email address');
      setBusinessEmail(businessEmail);
    } else {
      setIsFieldUpdated(true);
      setBusinessEmailerror('');
      setBusinessEmail(businessEmail);
    }
  };

  /* Function for validation of contact email  */
  const contactEmailValidate = (contactEmail) => {
    let manufacturerPattern = /\S+@\S+\.\S+/;
    if (!contactEmail) {
      setIsFieldUpdated(true);
      setContactEmailError('');
      setContactEmail('');
    } else if (!manufacturerPattern.test(contactEmail)) {
      setIsFieldUpdated(true);
      setContactEmailError('Please enter valid email address');
      setContactEmail(contactEmail);
    } else {
      setIsFieldUpdated(true);
      setContactEmailError('');
      setContactEmail(contactEmail);
    }
  };

  /* Function for validation of Business phone  */
  const businessPhoneValidate = (contactPhone) => {
    let formatWorkPhoneNumber = formatPhoneNumber(contactPhone);
    if (!formatWorkPhoneNumber) {
      setIsFieldUpdated(true);
      setBusinessPhoneError('');
      setBusinessPhone('');
    } else if (formatWorkPhoneNumber.length !== 12) {
      setIsFieldUpdated(true);
      setBusinessPhoneError('Business phone should have exactly 10 digits');
      setBusinessPhone(formatWorkPhoneNumber.substring(0, 12)); // Truncate to 10 digits
    } else {
      setIsFieldUpdated(true);
      setBusinessPhoneError('');
      setBusinessPhone(formatWorkPhoneNumber);
    }
  };

  /* Function for validation of contact phone  */
  const contactPhoneValidate = (contactPhone) => {
    let formatWorkPhoneNumber = formatPhoneNumber(contactPhone);
    if (!formatWorkPhoneNumber) {
      setIsFieldUpdated(true);
      setContactPhoneError('');
      setContactPhone('');
    } else if (formatWorkPhoneNumber.length !== 12) {
      setIsFieldUpdated(true);
      setContactPhoneError('Contact phone should have exactly 10 digits');
      setContactPhone(formatWorkPhoneNumber.substring(0, 12)); // Truncate to 10 digits
    } else {
      setIsFieldUpdated(true);
      setContactPhoneError('');
      setContactPhone(formatWorkPhoneNumber);
    }
  };

  /* Function for validation of zipcode  */
  const zipCodeValidate = (zipCode) => {
    let zipCodePattern = zipCode.replace(/\D/g, ''); // Remove non-digit characters
    if (zipCodePattern.length > 5) {
      zipCodePattern = zipCodePattern.substring(0, 5);
    }
    if (!zipCodePattern) {
      setIsFieldUpdated(true);
      setZipCodeError('');
      setZipCode('');
    } else if (zipCodePattern.length !== 5) {
      setIsFieldUpdated(true);
      setZipCodeError('ZIP code should have exactly 5 digits');
      setZipCode(zipCodePattern.substring(0, 5)); // Truncate to 5 digits
    } else {
      setIsFieldUpdated(true);
      setZipCodeError('');
      setZipCode(zipCodePattern);
    }
  };

  const manufacturerUrlvalidate = (manufacturerUrl) => {
    const URL_REGEX =
      /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(\/[^\s]*)?$/;

    if (manufacturerUrl && !URL_REGEX.test(manufacturerUrl)) {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('business url is not valid');

      setmanufacturerUrl(manufacturerUrl);
    } else {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('');
      setmanufacturerUrl(manufacturerUrl);
    }
  };

  /* Function for validation of Zoom room product name  */
  const zoomRoomProductNamevalidate = (zoomRoomProductName) => {
    if (!zoomRoomProductName) {
      setzoomRoomProductName(
        zoomRoomProductName !== '' ? '' : zoomRoomProductName
      );
      setIsFieldUpdated(true);
      setzoomRoomProductNameError('');
    } else {
      setIsFieldUpdated(true);
      setzoomRoomProductNameError('');
      setzoomRoomProductName(zoomRoomProductName);
    }
  };

  const address2validate = (address2) => {
    if (!address2) {
      setaddress2(address2 !== '' ? '' : address2);
      setIsFieldUpdated(true);
      setaddress2Error('');
    } else {
      setIsFieldUpdated(true);
      setaddress2Error('');
      setaddress2(address2);
    }
  };

  /* Function for setting value of product category validate */
  const businessEntityTypeValidate = (businessEntityType) => {
    if (!businessEntityType) {
      setIsFieldUpdated(true);
      setBusinessEntityTypeError(true);
      setBusinessEntityTypeId('');
    } else {
      setIsFieldUpdated(true);
      setBusinessEntityTypeError(false);
      setBusinessEntityTypeId(businessEntityType);
    }
  };

  /* Function for setting value of product category validate */
  const stateTypeValidate = (stateTypeValidate) => {
    if (!stateTypeValidate) {
      setIsFieldUpdated(true);
      setStateTypeError(false);
      setStateType('');
    } else {
      setIsFieldUpdated(true);
      setStateTypeError(false);
      setStateType(stateTypeValidate);
    }
  };

  /* Function for setting value of product category validate */
  const cityValidate = (cityTypeValidate) => {
    if (!stateTypeValidate) {
      setIsFieldUpdated(true);
      setCityError(false);
      setCity('');
    } else {
      setIsFieldUpdated(true);
      setCityError(false);
      setCity(cityTypeValidate);
    }
  };

  /* Function to set value of product description */
  const productDescriptionSet = (productDescription) => {
    setproductDescription(productDescription);
  };

  /* function for add business entity  */
  const addBusinessEntity = async () => {
    setIsLoading(true);
    const payload = {
      locationId: locationId,
      businessEntityTypeId: businessEntityTypeId.sortKey,
      businessEntityType: businessEntityTypeId.businessEntityType,
      businessName: businessName,
      businessEmail: businessEmail,
      phone: businessPhone,
      businessUrl: manufacturerUrl,
      addressLine1: zoomRoomProductName,
      addressLine2: address2,
      state: stateType,
      city: city,
      postalCode: zipCode,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhone: contactPhone,
      notes: productDescription,
      createdBy: userId,
      status: 1
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/addBusinessEntity`,
        payload
      );
      if (response.statusCode) {
        setIsLoading(false);
        setIsUpdated(true);
        setOpen(true);
        refreshData();
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went wrong, Try again!');
      console.log(error);
    }
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (Object.keys(businessEntityTypeId)?.length === 0) {
      setBusinessEntityTypeError(true);
    }

    if (!businessName) {
      setBusinessNameError('Business name is required');
    }
    if (businessName && Object.keys(businessEntityTypeId)?.length > 0) {
      addBusinessEntity();
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Grid sx={{ padding: '0 2rem', mt: 1 }}>
        <Box className="Main-container">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseAddProduct()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>

                <Typography className="header-blue-32">
                  Add Business Entity
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{ mt: 1 }}
                    type="button"
                    variant="contained"
                    className="common-button"
                    onClick={handleAddSubmit}
                  >
                    <Typography className="next-button-text">Save</Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box className="container-text-field">
            <Card
              sx={{
                backgroundColor: '#FFFFFF'
              }}
            >
              <CardContent className="pr-0 pl-0">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <Typography className="table-heade header-blue-20" mb={2}>
                      Business Information
                    </Typography>
                    <Grid container spacing={2} component="form" id="form-add">
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling required-field"
                          fullWidth
                          error={businessEntityTypeError}
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling required-label"
                          >
                            BUSINESS ENTITY TYPE
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="BUSINESS ENTITY TYPE"
                            className="input-field-styling"
                            value={businessEntityTypeId}
                            onChange={(e) =>
                              businessEntityTypeValidate(e.target.value)
                            }
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                            //   options={product.map((option) => option.categoryName)}
                          >
                            {productCategoryList?.length > 0 &&
                              productCategoryList?.map((option) => (
                                <MenuItem key={option?.sortKey} value={option}>
                                  {option?.businessEntityType}
                                </MenuItem>
                              ))}
                          </Select>
                          {businessEntityTypeError && (
                            <FormHelperText>
                              Please select business entity type
                            </FormHelperText>
                          )}
                        </FormControl>

                        {isFranchisor !== 'true' && (
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#003087',
                              textDecoration: 'underline',
                              marginRight: '5px'
                            }}
                            className="add-new-category align-right"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                addNewCategory();
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  addNewCategory();
                                }
                              }}
                              tabIndex="0" // Make the element focusable
                              role="button" // Indicate that the element is interactive
                              aria-label="Add Entity" // Provide an accessible name
                            >
                              +Add ENTITY
                            </span>
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="BUSINESS NAME"
                          autocomplete="BUSINESS NAME"
                          label="BUSINESS NAME"
                          placeholder="BUSINESS NAME"
                          id="locationId"
                          value={businessName}
                          className="location-field input-field-styling required-field"
                          error={Boolean(businessNameError)}
                          helperText={businessNameError}
                          onChange={(e) => businessNameValidate(e.target.value)}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="BUSINESS EMAIL"
                          autocomplete="BUSINESS EMAIL"
                          label="BUSINESS EMAIL"
                          className="field-top-margin-edit-location location-field input-field-styling"
                          error={Boolean(businessEmailerror)}
                          helperText={businessEmailerror}
                          value={businessEmail}
                          placeholder="BUSINESS EMAIL"
                          onChange={(e) =>
                            businessEmailValidate(e.target.value)
                          }
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="BUSINESS PHONE"
                          autocomplete="BUSINESS PHONE"
                          label="BUSINESS PHONE"
                          className="field-top-margin-edit-location location-field input-field-styling"
                          error={Boolean(businessPhoneError)}
                          helperText={businessPhoneError}
                          value={businessPhone}
                          placeholder="BUSINESS PHONE"
                          onChange={(e) =>
                            businessPhoneValidate(e.target.value)
                          }
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="BUSINESS URL"
                          autocomplete="BUSINESS URL"
                          label="BUSINESS URL"
                          className="field-top-margin-edit-location location-field input-field-styling"
                          value={manufacturerUrl}
                          error={Boolean(manufacturerUrlError)}
                          helperText={manufacturerUrlError}
                          onChange={(e) =>
                            manufacturerUrlvalidate(e.target.value)
                          }
                          placeholder="BUSINESS URL"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="ADDRESS"
                          autocomplete="ADDRESS"
                          label="ADDRESS"
                          placeholder="ADDRESS"
                          className=" location-field  input-field-styling"
                          error={Boolean(zoomRoomProductNameError)}
                          helperText={zoomRoomProductNameError}
                          onChange={(e) =>
                            zoomRoomProductNamevalidate(e.target.value)
                          }
                          value={zoomRoomProductName}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="ADDRESS 2"
                          autocomplete="ADDRESS 2"
                          label="ADDRESS 2"
                          placeholder="ADDRESS 2"
                          className=" location-field  input-field-styling"
                          error={Boolean(address2Error)}
                          helperText={address2Error}
                          onChange={(e) => address2validate(e.target.value)}
                          value={address2}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} mt={2}>
                        <FormControl
                          error={stateTypeError}
                          fullWidth
                          className="select-drop-down-label-styling "
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling "
                          >
                            STATE
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="STATE"
                            name="state"
                            value={stateType}
                            onChange={(e) => stateTypeValidate(e.target.value)}
                            error={Boolean(stateTypeError)}
                            className="input-field-styling"
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientType.length > 0 &&
                              varientType.map((r, i) => (
                                <MenuItem key={i} value={r.state}>
                                  {r.state}
                                </MenuItem>
                              ))}
                          </Select>
                          {stateTypeError && (
                            <FormHelperText>Please select state</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="CITY"
                          autocomplete="CITY"
                          label="CITY"
                          placeholder="CITY"
                          className=" location-field  input-field-styling"
                          error={Boolean(cityError)}
                          helperText={cityError}
                          onChange={(e) => cityValidate(e.target.value)}
                          value={city}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="ZIPCODE"
                          autocomplete="ZIPCODE"
                          label="ZIPCODE"
                          placeholder="ZIPCODE"
                          className=" location-field  input-field-styling"
                          error={Boolean(zipCodeError)}
                          helperText={zipCodeError}
                          onChange={(e) => zipCodeValidate(e.target.value)}
                          value={zipCode}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography className="table-heade header-blue-20" mb={2}>
                      Primary Contact Information
                    </Typography>
                    <Grid container spacing={2} component="form" id="form-add">
                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="Contact Name"
                          autocomplete="Contact Name"
                          label="CONTACT NAME"
                          placeholder="Contact Name"
                          className="location-field input-field-styling"
                          error={Boolean(contactNameError)}
                          helperText={contactNameError}
                          onChange={(e) => contactNameValidate(e.target.value)}
                          value={contactName}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="Contact Email"
                          autocomplete="Contact Email"
                          label="CONTACT EMAIL"
                          placeholder="Contact Email"
                          className=" location-field  input-field-styling"
                          error={Boolean(contactEmailError)}
                          helperText={contactEmailError}
                          onChange={(e) => contactEmailValidate(e.target.value)}
                          value={contactEmail}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          name="Contact Phone"
                          autocomplete="Contact Phone"
                          label="CONTACT PHONE"
                          placeholder="Contact Phone"
                          className=" location-field  input-field-styling "
                          error={Boolean(contactPhoneError)}
                          helperText={contactPhoneError}
                          onChange={(e) => contactPhoneValidate(e.target.value)}
                          value={contactPhone}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label '
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          multiline
                          id="my-textarea"
                          name="Notes"
                          autocomplete="Notes"
                          label="NOTES"
                          placeholder="Notes"
                          variant="outlined"
                          value={productDescription}
                          className="location-description field-top-margin-edit-location"
                          onChange={(e) =>
                            productDescriptionSet(e.target.value)
                          }
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{ rows: 6 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>

      {/* Add confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Business Entity has been created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : ' Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Create Category dialog */}
      <Dialog
        open={openAddCategory}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Entity'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name="categoryName"
                    label="BUSINESS ENTITY TYPE"
                    error={PopUpAddEntityTypeError}
                    className="location-field input-field-styling required-field"
                    onChange={(e) => {
                      handleEntityPopupError();
                      setcategoryName(e.target.value);
                    }}
                    onKeyUp={(e) => checkEntityTypeExist(e.target.value)}
                    required
                    value={categoryName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                  <FormHelperText>{PopUpAddEntityTypeError}</FormHelperText>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateCategory()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddBusinessEntities;
