/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import TemplateTable from './TemplateTable';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import Loader from '../../../components/Loader';
// import { toast } from 'react-toastify';
import { getLocalData, setFullLocationId } from 'src/utils';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function TemplateListAPI({ ref, setShowScreen, showScreen,getFranchisorCohort,setIsPopup,TemplateList,dataCount,filters,setSidebarFilters }) {

  const [isLoading, setIsLoading] = useState(false);
  const userId = getLocalData('userId');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  // const [franchiseeId, setFranchiseeId] = useState(false);
 
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [selectedTemplateLists, setSelectedTemplateLists] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);


  console.log(TemplateList, 'locationList');
  // setFranchiseeId(locationList.partitionKey);
  // gets call the method to get the data

  useEffect(() => {
    // Not call the get location method
    getFranchisorCohort();
  }, [JSON.stringify(filters)]);

  // Getting hooks from parent to child
  useImperativeHandle(ref, () => ({
    refreshLocationData: () => {
      // Not call the get location method again
      getFranchisorCohort();
    }
  }));

  // Handle the event from child to parent. It is being used for refresh the data after edit or delete
  const refreshLocationData = () => {
    // When popup is open then not call the loader
    setIsPopup(true);
    // Not call the get location method again
    getFranchisorCohort();
  };

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    // show loader

    setIsLoading(true);
    const data = {
      // isActive: selectedStatus === 'Active',
      status: selectedStatus,
      templateIds: selectedTemplateLists
    };

    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/updateTemplateStatus`,
        data
      );

      if (!response?.error) {
        setShowSuccessMessage(true);
        setSuccessMessage(
          'All the selected templates have been updated successfully!'
        );
        setSelectedStatus('');
        setSelectedTemplateLists([]);
        setIsUpdated(true);
        // setOpen(true);
        // getFranchisorCohort();
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response?.message ||
            'Something went wrong with update template status'
        );
      }
      // show loader
      setIsLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      // show loader
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getFranchisorCohort();
        setIsUpdated(false);
      }
    }
  };

  return (
    <Grid className="card-table-main" xs={12}>
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={getFranchisorCohort}
        />
      )}
      <Loader IsLoading={isLoading} />
      <TemplateTable
        TemplateList={TemplateList}
        sideBarfilters={filters}
        setSidebarFilters={setSidebarFilters}
        refreshLocationData={refreshLocationData}
        dataCount={dataCount}
        setShowScreen={setShowScreen}
        showScreen={showScreen}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleBulkAdd={handleBulkAdd}
        btnLoading={btnLoading}
        selectedTemplateLists={selectedTemplateLists}
        setSelectedTemplateLists={setSelectedTemplateLists}
        open={open}
        setOpen={setOpen}
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Shared/Private Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Are you sure you want to mark Shared/Private,
                  <Typography className="title-text-sub">
                    all the selected items?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected templates have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default React.forwardRef(TemplateListAPI);
