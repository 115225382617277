/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Grid, Stack, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import EditClock from 'src/content/applications/StaffManagement/EditClock';
import {
  getFormattedDate,
  convertTo24HourFormat,
  calculateTotalHours
} from './index';
import moment from 'moment';

export function Card(props) {
  const { key, data, refreshCalenderData, refreshStaffData } = props;
  const cardRef = useRef(null);
  // console.log(data, 'datadatadata');
  const [isEditing, setIsEditing] = useState(false);
  const [editClockInTime, setEditClockInTime] = useState('');
  const [editClockOutTime, setEditClockOutTime] = useState('');

  useEffect(() => {
    setEditClockInTime(convertTo24HourFormat(data.clockInTime));
    if (data.clockOutTime && data.staffId) {
      setEditClockOutTime(convertTo24HourFormat(data.clockOutTime));

    } else {
      setEditClockOutTime('');
    }

  }, [data]);

  useEffect(() => {
    // const handleClickOutside = (event) => {
    //   // if (cardRef.current && !cardRef.current.contains(event.target)) {
    //   //   setIsEditing(false);
    //   // }
    // };

    // document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

 
  const dateFormate = (date) => {
    if (!date) {
      return null;
    }
    let formattedDate = date?.replace(/-/g, '/');
    let dayOfWeek = moment.utc(formattedDate).format('ddd');
    return dayOfWeek;
  };
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={4} key={key} ref={cardRef}  >
        <div className="month-card">
          <EditIcon
            className="card-action primary-color"
            sx={{ fontSize: '16px' }}
            onClick={() => setIsEditing(!isEditing)}
          />

         

          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            alignItems="center"
          >
            <div className="date">
              <Typography
                className='ipad-screen-resolution-text-day-date'
                variant="h2"
                sx={{
                  fontSize: '35px',
                  fontWeight: 700,
                  lineHeight: '42px'
                }}
              >
                {getFormattedDate(data.clockInDate, 'date')}
              </Typography>
              <Typography
                variant="h3"
                className='ipad-screen-resolution-text-day-date'
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  textTransform: 'uppercase',
                  color: '#fff'
                }}
              >
                {dateFormate(data.clockInDate)}
              </Typography>
            </div>




            <Stack
              spacing={{ xs: 1, sm: 1 }}
              direction="column"
              useFlexGap
              flexWrap="wrap"
              className="content"
            >
              <Stack
                spacing={{ xs: 1, sm: 1 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
              >
                <Icon icon="mdi:clock-fast" className="custom-image" />
                {/* {isEditing ? (
                <Typography component="span">
        

                  <input
                    type="time"
                    value={editClockInTime}
                    onChange={(e) => setEditClockInTime(e.target.value)}
                    className="time-picker"
                  />
                </Typography>
              ) : ( */}
                <p>{data?.clockInTime || '---'}</p>
                {/* )} */}
                <Icon
                  icon="mdi:clock-fast"
                  flip
                  vFlip
                  className="custom-image-rotate"
                />

                {/* {isEditing ? (
                <input
                  type="time"
                  value={editClockOutTime}
                  onChange={(e) => setEditClockOutTime(e.target.value)}
                  className="time-picker"
                />
              ) : ( */}
                <p>{editClockOutTime && data?.clockOutTime || '-- : --'}</p>
                {/* )} */}
              </Stack>

              <p className="hours">
                {calculateTotalHours(editClockOutTime, editClockInTime) ? (
                  <>
                    <span className="hour">Total Hours =</span>{' '}
                    {editClockOutTime && editClockInTime ? calculateTotalHours(editClockOutTime, editClockInTime) : '0 hours 0 minutes'}
                  </>
                ) : null}
              </p>
            </Stack>
          </Stack>
        </div>
      </Grid>

      <Box justifyContent="center" direction="column">
        {isEditing && (
          <EditClock
            getStaffItem={data}
            editClock={isEditing}
            setEditClock={setIsEditing}
            disableEditClock={Boolean(true)}
            refreshStaffData={refreshCalenderData}
            refreshGetStaffData={refreshStaffData}
          />
        )}
      </Box>
    </>
  );
}
