/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import Loader from '../../../components/Loader';
import { getLocalData } from 'src/utils';
import { ApiService } from '../../../services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import './index.css';
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar
} from '@syncfusion/ej2-react-richtexteditor';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
  ({ theme }) => `
            margin-top: ${theme.spacing(2)};
    `
);

export const CreateTemplate = (props) => {
  const { selectedRows, setShowScreen, getFranchisee, getTemplate, rowData } =
    props;
  const [oneRowData, setOneRowData] = useState([selectedRows]);
  const [isFiendUpdated, setIsFiendUpdated] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [subjectName, setSubjeName] = useState('');
  const [message, setMessage] = useState('');
  const [messageSMSError, setMessageSMSError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [templateNameError, setTemplateNameError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  // const [isSubjectError, setIsSubjectError] = useState('');

  const [open, setOpen] = useState(false);
  const [isMsg, setIsMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const franchiseeId = getLocalData('franchiseeId');
  const locationId = getLocalData('locationId');
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    let selectedRow = oneRowData[0];

    // Set the location details
    setTemplateData(selectedRow);
    setOpen(false);
  }, [oneRowData]);

  const setTemplateData = (selectedRow) => {
    const htmlContent = selectedRow?.HtmlPart;
    setSubjeName(selectedRow?.Subject);
    if (selectedRow?.TemplateType === 'EMAIL') {
      setMessage(htmlContent);
    } else {
      setMessage(selectedRow?.Body);
    }

    let newArr = rowData.map((item) => {
      return item.TemplateName;
    });
    let newTempName = (selectedRow?.TemplateName || '') + '_Copy';
    for (let i = 0; i < newArr.length; i++) {
      if (newArr.includes(newTempName)) {
        newTempName = `${newTempName}_Copy`;
      }
    }
    setTemplateName(newTempName);
  };
  const closePopup = () => {
    setShowSuccessMessage(false);
    setShowScreen(0);
    getTemplate();
  };
  const handleTemplateClose = () => {
    if (isFiendUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
      setOpen(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'templateName') {
      const regex = /^[a-zA-Z0-9][a-zA-Z0-9_-]{0,127}$/;

      if (!regex.test(value)) {
        setTemplateNameError(
          'Template name is not valid.It can contain letters, digits, underscores, and hyphens'
        );
      } else {
        setTemplateNameError(''); // Clear the error message when the input is valid
      }

      setTemplateName(value);

      setIsFiendUpdated(true);
    } else if (name === 'message') {
      setMessage(value);

      setIsFiendUpdated(true);
    }
    if (name === 'subjectName') {
      setSubjeName(value);
    }

    let subVal = e.target.value;
    if (subVal) {
      setSubjectError('');
    } else {
      setSubjectError('');
    }
    let msgVal = e.target.value;
    if (msgVal) {
      setMessageSMSError('');
    } else {
      setMessageSMSError('');
    }
  };

  const handleConfirmClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
    } else if (val === 'Discard') {
      setOpen(false);
      setShowScreen(0);
      setIsFiendUpdated(false);
      setTemplateData();
    }
  };

  const handleSubmit = () => {
    if (message === '' || message === null) {
      setIsMsg(true);
      setMessageError('Message cannot be blank');
      return;
    }
    if (!templateName) {
      setTemplateNameError('Template name is required');
      return;
    }
    if (!message && selectedRows?.TemplateType === 'SMS') {
      setMessageSMSError('Message is required');
      return;
    }
    if (!subjectName && selectedRows?.TemplateType === 'EMAIL') {
      setSubjectError('Subject is required');
      return;
    }
    templateCreate();
  };

  const templateCreate = async () => {
    setIsLoading(true);
    let body;
    if (selectedRows.TemplateType === 'SMS') {
      // Construct payload for SMS
      body = {
        locationId: locationId || '',
        franchiseeId: franchiseeId || '',
        TemplateType: selectedRows?.TemplateType,
        TemplateName: templateName,
        Version: selectedRows?.Version,
        createdBy: getFranchisee,
        TemplateDescription: selectedRows?.TemplateDescription,
        Body: message
      };
    } else if (selectedRows.TemplateType === 'EMAIL') {
      // Construct payload for other TemplateTypes
      body = {
        locationId: locationId || '',
        franchiseeId: franchiseeId || '',
        TemplateType: selectedRows?.TemplateType,
        TemplateName: templateName,
        Version: selectedRows?.Version,
        createdBy: getFranchisee,
        // CreationDate: selectedRows.CreationDate,
        TemplateDescription: selectedRows?.TemplateDescription,
        HtmlPart: message,
        Subject: subjectName
      };
    }
    try {
      let data = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/duplicateTemplates`,
        body
      );
      if (data?.statusCode && !data?.error) {
        getTemplate();
        setIsLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage('Template has been created successfully');
        // setShowScreen(0);
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setErrorMessage(data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      setShowMessage(true);
      setErrorMessage(error.message);
    }
  };

  const checkTemplateNameExist = async (templatename) => {
    let templateNameCheck = templatename?.value?.toLowerCase();
    console.log(templateNameCheck?.toLowerCase(), 'templateNameCheck');
    const data = {
      TemplateName: templateNameCheck?.toLowerCase() || ''
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/isExistsTemplate`,
        data
      );

      if (response.statusCode && !response.error) {
        console.log(response.data, 'hgghg');
        if (response.data.isExists === true) {
          setTemplateNameError('Template name already exist');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleContentChange = (args) => {
    setIsFiendUpdated(true);
    setMessageError('');
    setIsMsg(false);
    setMessage(args.value);
  };

  console.log(message, 'm,esafdasfd');

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <div className="custom-summary">
        <ServiceDetailsWrapper
          maxWidth={'100%'}
          className="location-details-wrapper p-0"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleTemplateClose()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>

                <Typography className="header-blue-32">
                  Create Template
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Button
                  onClick={(e) => handleSubmit()}
                  variant="contained"
                  className="btn-primary edit-button template-edit-btn"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box className="location-details-container" mt={5} ml={3}>
            <Grid container xs={12} sm={12} mt={2}>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  name="templateName"
                  autoComplete="TEMPLATE NAME"
                  label="TEMPLATE NAME"
                  // error={Boolean(templateNameError)}
                  helperText={templateNameError}
                  placeholder="Template Name"
                  className="location-field input-field-styling"
                  value={templateName}
                  onKeyUp={(e) => checkTemplateNameExist(e.target)}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    className:
                      'label-style-create-franchisee edit-address-label'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          {selectedRows.TemplateType === 'EMAIL' && (
            <>
              <Box className="location-details-container" ml={3}>
                <Grid container xs={12} sm={12} mt={2} mb={3}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      name="subjectName"
                      autoComplete="SUBJECT"
                      label="SUBJECT*"
                      // error={Boolean(templateNameError)}
                      helperText={subjectError}
                      placeholder="Subject"
                      className="text-field-styling input-field-styling"
                      value={subjectName}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        className:
                          'label-style-text-field label-style-create-franchisee'
                      }}
                    />
                    {/* {isSubjectError && (
                  <p className="error-text_template">{subjectError}</p>
                )} */}
                  </Grid>
                </Grid>
              </Box>
              <Box className="location-details-container" ml={3}>
                {/* <ReactQuill
                  name="message"
                  className="field-top-margin-edit-location text-message-template"
                  modules={Editor.modules}
                  formats={Editor.formats}
                  value={message}
                  helperText={messageError}
                  ref={quillRef}
                  // onChange={setMessage}
                  onChange={handleQuillChange}
                /> */}
                <RichTextEditorComponent
                  value={message}
                  change={handleContentChange}
                  editable={message}
                >
                  <Inject
                    services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                  />
                </RichTextEditorComponent>

                {isMsg && <p className="error-text_template">{messageError}</p>}
              </Box>
            </>
          )}
          {selectedRows.TemplateType === 'SMS' && (
            <Box className="location-details-container" ml={3}>
              <Grid container xs={12} sm={12} mt={2}>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    multiline
                    id="my-textarea"
                    name="message"
                    autocomplete="message"
                    label="MESSAGE*"
                    placeholder="Message "
                    variant="outlined"
                    // error={messageSMSError}
                    helperText={messageSMSError}
                    value={message}
                    className=""
                    onChange={handleInputChange}
                    InputLabelProps={{
                      className: 'label-style-text-field'
                    }}
                    InputProps={{ rows: 6 }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </ServiceDetailsWrapper>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleConfirmClose('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            Discard Changes?
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    What do you want to do?{' '}
                  </Typography>
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleConfirmClose('Discard')}
            >
              Discard changes
            </Button>
            <Divider className="vertical-divider" />

            <Button
              className="font-17 no-hover"
              onClick={() => handleConfirmClose('Save')}
            >
              Go Back to Editing
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
