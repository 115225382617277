import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer
} from '@mui/material';
import moment from 'moment';

export const CashBoxTable = ({ tableData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const dateFormate = (value) => {
    if (!value) return '';
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    });

    // Parse the formatted date with Moment.js
    const utc = moment.utc(formattedDate).format('MMM DD, YYYY');
    return utc;
  };
  return (
    <Box>
      <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Order Id</TableCell>
                <TableCell>User / client</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.Items.length > 0 ? (
                tableData?.Items.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((data) => {
                  let type = data?.status?.replaceAll('_', ' ');

                  return (
                    <TableRow
                      hover
                      sx={{ cursor: 'default' }}
                      key={data.sortKey}
                      role="checkbox"
                      tabIndex={-1}
                      className="table-row cursor-pointer"
                    >
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          sx={{ marginTop: 1, textTransform: 'capitalize' }}
                          gutterBottom
                          noWrap
                        >
                          {type.toLowerCase()}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {dateFormate(data.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {data?.status === 'CASH_ADDED' ||
                          data?.status === 'CASH_FROM_SALES'
                            ? `$${parseFloat(data?.deposit)?.toFixed(2)}`
                            : data?.withdrawal || data?.withdrawal === 0
                            ? `-$${parseFloat(data?.withdrawal)?.toFixed(2)}`
                            : '$0.00'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {data.orderId || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {data?.createdByName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  className="table-row cursor-pointer"
                >
                  <h4> No Data Found</h4>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={tableData?.Items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </Box>
  );
};
