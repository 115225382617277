import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import UsersList from './UsersList';
import UserSearch from './UserSearch';
import { useChat } from './context/ChatContext';
import { ApiService } from 'src/services/ApiService';
import { getLocalData, setLocalData } from 'src/utils';
import { useEffect, useState } from 'react';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { CircularProgress } from '@mui/material';

function AllUsersList() {
  const BASE_URL = process.env.REACT_APP_CHAT_API_END_POINT;
  const { setSelectedSearchClient, setLocationDetails } = useChat();
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');
  const { setActiveScreen } = useChat();
  const [activeChatUsers, setActiveChatUsers] = useState([]);

  // states for error handling
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // state for spinner loading
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getActiveUsers();
    handleGetLocationDetails();
  }, []);

  // function to get specific location details
  const handleGetLocationDetails = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    let encodedLocationId = encodeURIComponent(locationId);
    let getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getLocationById?franchiseeId=${encodedFranchiseeId}&locationId=${encodedLocationId}`;

    try {
      let response = await ApiService.get(getLocationsUrl);
      let Item = response?.data?.Item;
      if (Item) {
        setLocationDetails(Item);
        setLocalData('twilioNumber', Item?.twilioNumber);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to  active chat users
  const getActiveUsers = async () => {
    setIsLoading(true); // Start loading
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      const response = await ApiService?.get(
        `${BASE_URL}chat-sms/getActiveUsers?locationId=${encodedLocationId}`
      );
      const searchInputIndicator = { isSearchInput: true };
      const modifiedActiveUsers = [
        searchInputIndicator,
        ...(response?.data?.Items ?? [])
      ];
      setActiveChatUsers(modifiedActiveUsers);
    } catch (error) {
      console?.log(error);
      setShowErrorMessage(true);
      setErrorMessage(error?.message || 'Something went wrong');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // function runs when select client from list
  const handleSelectUser = (clientItem) => {
    setSelectedSearchClient(clientItem);
    setActiveScreen(1);
  };

  // function to show list of all client
  const listTemplate = (data) => {
    // Check if the data is the custom object for search input
    if (data?.isSearchInput) {
      return <UserSearch handleSelectUser={handleSelectUser} />;
    }
    return <UsersList data={data} handleSelectUser={handleSelectUser} />;
  };

  if (isLoading) {
    return (
      <div className="chat-loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      <ListViewComponent
        id="usersList"
        className="users"
        dataSource={activeChatUsers}
        template={listTemplate}
      />
    </>
  );
}

export default AllUsersList;
