import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Divider
} from '@mui/material';
import { FormControl, FormHelperText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TextField, Grid } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { getLocalData } from 'src/utils';
import Loader from '../../../../components/Loader';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { ImageSlider } from 'src/components/Global';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';

import { ApiService } from 'src/services/ApiService';
import CreateVariantModal from './CreateVariantModal';
import CreateCategoryModal from './CreateCategoryModal';
import CreateVariantTypeModal from './CreateVariantTypeModal';
import ConfirmationModal from './ConfirmationModal';
import {
  handleFileInput,
  manufacturerProductNameValidate,
  manufacturervalidate,
  // priceSet,
  productCategoryvalidate,
  productDescriptionSet,
  sellingpointSet,
  skuCodeValidate,
  varientSet,
  varientTypeValidate,
  zoomRoomProductNamevalidate
} from './productvalidationHelpers';

function AddProduct({
  setShowScreen,
  refreshData,
  autoGeneratedSkuCode,
  getAutoGeneratedSkuCode
}) {
  let userId = getLocalData('userId');
  let locationId = getLocalData('locationId');
  let locationName = getLocalData('locationName');
  let franchiseeId = getLocalData('franchiseeId');
  let franchiseeName = getLocalData('franchiseeName');

  // const isFranchisor = 'true';
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [manufacturerProductName, setmanufacturerProductName] = useState('');
  const [manufacturer, setmanufacturer] = useState('');
  const [zoomRoomProductName, setzoomRoomProductName] = useState('');
  const [varientType, setvarientType] = useState([]);
  const [varientProduct, setvarientProduct] = useState({
    0: [],
    1: []
  });
  const [price, setprice] = useState('');
  const [sellingPoint, setsellingPoint] = useState('');
  const [productDescription, setproductDescription] = useState('');
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [isRequired, setisRequired] = useState(false);
  const [isBestSeller, setisBestSeller] = useState(false);
  const [istaxExempt, setistaxExempt] = useState(false);
  const [isDiscontinued, setisDiscontinued] = useState(false);
  const [productCategoryId, setproductCategoryId] = useState('');
  const [variantId, setvariantId] = useState('');
  console.log(variantId);
  const [valueId, setvalueId] = useState('');
  console.log(valueId);

  const [variant, setVariant] = useState([
    {
      valueId: '',
      value: ''
    },
    {
      valueId: '',
      value: ''
    }
  ]);
  const [typesVar1, setTypesVar1] = useState('');
  const [typesVar2, setTypesVar2] = useState('');

  const [isUpdated, setIsUpdated] = useState(false);
  const [multipleImage, setMultipleImage] = useState([]);

  const [imageError, setImageError] = useState('');
  const [skuCode, setSkuCode] = useState(null);
  const [skuCodeError, setSkuCodeError] = useState('');
  /* States for getting and setting add product errors  */
  const [manufacturerProductNameError, setmanufacturerProductNameError] =
    useState('');

  const [manufacturerError, setmanufacturerError] = useState('');

  const [zoomRoomProductNameError, setzoomRoomProductNameError] = useState('');
  const [productCategoryError, setproductCategoryError] = useState(false);

  const [productDescriptionError, setproductDescriptionError] = useState('');
  const [open, setOpen] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [openAddVariant, setopenAddVariant] = useState(false);
  const [openAddCategory, setopenAddCategory] = useState(false);
  const [openAddVarientType, setopenAddVarientType] = useState(false);

  const [variantName, setvariantName] = useState('');
  const [categoryName, setcategoryName] = useState('');
  const [categoryDescription, setcategoryDescription] = useState('');
  const [varientTypeName, setvarientTypeName] = useState('');
  const [variantTypeId, setvariantTypeId] = useState('');
  const [productImage, setProductImage] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [supplier, setSupplier] = useState();
  const [supplierError, setsupplierError] = useState(false);
  const [supplierList, setsupplierList] = useState([]);
  const [supplierName, setsupplierName] = useState('');
  const [supplierId, setsupplierId] = useState('');
  const [variantField, setVariantField] = useState(0);
  // const [wholeSalePrice, setWholeSalePrice] = useState('');
  const [priceError, setpriceError] = useState('');
  const [createCategoryErrors, setCreateCategoryErrors] = useState({
    categoryName: '',
    description: ''
  });
  const [createVariantErrors, setCreateVariantErrors] = useState({
    varientName: '',
    variantType: ''
  });
  const [createVariantTypeErrors, setCreateVariantTypeErrors] = useState({
    varientTypeName: ''
  });

  // All useEffect==>

  useEffect(() => {
    clear();
    getCategory();
    getVariantType();
    getSupplier();
    setSkuCode(autoGeneratedSkuCode);
  }, []);

  useEffect(() => {
    if (multipleImage) {
      let images = multipleImage.map((item) => item.previewURL);
      setProductImage(images);
    }
  }, [multipleImage]);

  /* Function to clear form fields when dialog form is closed */
  const clear = () => {
    setmanufacturer('');
    setzoomRoomProductName('');
    setmanufacturerProductName('');
    setvarientType('');
    setvarientProduct([]);
    setprice('');
    setsellingPoint('');
    setproductDescription('');
    setproductCategoryList([]);
    setisRequired(false);
    setisBestSeller(false);
    setistaxExempt(false);
    setisDiscontinued(false);
    setvariantId('');
    setMultipleImage([]);
    setIsFieldUpdated(false);
  };

  const supplierValidate = (supplier) => {
    if (!supplier) {
      setIsFieldUpdated(true);
      // setsupplierError('Supplier is required');
    } else {
      const [Id, Name] = supplier.split('|');
      setSupplier(supplier);
      setsupplierId(Id);
      setsupplierName(Name);
      setIsFieldUpdated(true);
      setsupplierError('');
      // setsupplierList(supplier);

      // console.log(supplier);
    }
  };

  // const wholeSalePriceSet = (event) => {
  //   let input = event.target.value;
  //   input = input.replace(/[^0-9.]/g, '');
  //   // Allow only two digits after the decimal point
  //   const decimalIndex = input.indexOf('.');
  //   if (decimalIndex !== -1) {
  //     const parts = input.split('.');
  //     input = parts[0] + '.' + parts[1].slice(0, 2);
  //   }
  //   setWholeSalePrice(input);
  // };

  /* Function to set value of price  */
  const priceSet = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, '');
    // Allow only two digits after the decimal point
    const decimalIndex = input.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }
    setprice(input);
    setIsFieldUpdated(true);
    setpriceError('');
  };

  // Api calls starts here  ==>

  const getSupplier = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?businessEntityType=Supplier&locationId=${encodedLocationId}`
      );
      if (!response?.data?.error) {
        setsupplierList(response.data.Items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* Function to get category of products */
  const getCategory = async () => {
    const location = encodeURIComponent(locationId);
    await ApiService.get(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}category?locationId=${location}`
    ).then((data) => {
      setproductCategoryList(data?.data?.Items);
    });
  };

  /* Function to get variant types */
  const getVariantType = async (variantTypeId) => {
    const location = encodeURIComponent(locationId);

    await ApiService.get(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}variants?locationId=${location}`
    ).then((data) => {
      setvarientType(data?.data?.Items);
      if (data?.data?.Items?.length > 0 && variantTypeId) {
        const foundItem = data?.data?.Items?.find(
          (item) => item?.variantId === variantTypeId
        );
        if (variantField === 0) {
          setTypesVar1({
            variantId: foundItem?.variantId,
            variantType: foundItem?.variantType
          });
        }
        if (variantField === 1) {
          setTypesVar2({
            variantId: foundItem?.variantId,
            variantType: foundItem?.variantType
          });
        }

        const selectedVariants = [...variant];
        selectedVariants[variantField] = {
          valueId: '',
          value: ''
        };
        setvarientProduct({ ...varientProduct, [variantField]: [] });
        setVariant(selectedVariants);
      }
    });
    setIsLoading(false);
  };

  /* Function to get variant on basis of variant id */
  const getVariants = async (id, number, setDefault, variantValudId) => {
    let variantId = encodeURIComponent(id);
    const location = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}variant-values/getVariantValues?variantId=${variantId}&locationId=${location}`
      );
      if (!response?.data?.error) {
        setvarientProduct({
          ...varientProduct,
          [number]: response?.data?.Items
        });
        let defaultArr = [...variant];
        if (setDefault === 'setDefault') {
          defaultArr[number] = {
            valueId: response?.data?.Items[0]?.sortKey,
            value: response?.data?.Items[0]?.value
          };
          setVariant(defaultArr);
        }
        const typesVarArray = [typesVar1, typesVar2]; // Add more if needed

        if (typesVarArray[number]?.variantId === id && variantValudId) {
          const newData = response?.data?.Items?.find(
            (item) => variantValudId === item?.sortKey
          );

          if (newData) {
            defaultArr[number] = {
              valueId: newData.sortKey,
              value: newData.value
            };
            setVariant([...defaultArr]); // Use spread operator to create a new array and trigger re-render
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to create variant type
  const handleCreateVariantType = () => {
    if (!varientTypeName) return;
    setIsLoading(true);
    const data = {
      variantType: varientTypeName,
      createdBy: userId,
      locationId: locationId
    };
    ApiService.post(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}variants`,
      data
    )
      .then((res) => {
        if (res?.statusCode === 200 && !res?.error) {
          setvarientTypeName('');
          getVariantType(res?.data?.variantId);
          setvariantId(res?.data?.variantId);
          setopenAddVarientType(false);
        } else {
          setCreateVariantTypeErrors({
            ...createVariantTypeErrors,
            varientTypeName: res?.message
          });
          // Handle response error
          console.error('Error creating variant type:', res?.error);
        }
      })
      .catch((err) => {
        console.error('API error:', err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsFieldUpdated(true);
      });
  };

  // function to create variant
  const handleCreateVariant = () => {
    if (!variantName || !variantTypeId) return;
    setIsLoading(true);
    const data = {
      value: variantName,
      variantId: variantTypeId,
      createdBy: userId,
      locationId: locationId
    };
    ApiService.post(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}variant-values`,
      data
    )
      .then((res) => {
        if (res.statusCode === 200 && !res.error) {
          setvariantName('');
          setvariantTypeId('');
          console.log(res, 'resresresres');
          if (typesVar1?.variantId === variantTypeId) {
            getVariants(variantTypeId, 0, null, res.data?.valueId);
          }
          if (typesVar2?.variantId === variantTypeId) {
            getVariants(variantTypeId, 1, null, res.data?.valueId);
          }

          setvalueId(res.data.valueId);
          setopenAddVariant(false);
        } else {
          setIsLoading(false);
          setCreateVariantErrors({
            ...createVariantErrors,
            varientName: res?.message
          });
          console.error('Error creating variant:', res.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error('API error:', err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsFieldUpdated(true);
      });
  };

  // function to create category
  const handleCreateCategory = () => {
    if (!categoryName || !categoryDescription) return;
    setIsLoading(true);
    const data = {
      categoryName: categoryName,
      description: categoryDescription,
      createdBy: userId,
      locationId: locationId
    };
    ApiService.post(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}category`,
      data
    )
      .then((res) => {
        if (res?.statusCode === 200 && !res?.error) {
          setcategoryName('');
          setcategoryDescription('');
          getCategory();
          setproductCategoryId(res?.data.categoryId);
          setopenAddCategory(false);
          setproductCategoryError(false);
        } else {
          console.log(res, 'res is consoeldd');
          setCreateCategoryErrors({
            ...createCategoryErrors,
            categoryName: res?.message
          });
          console.error('Error creating category:', res.error);
        }
      })
      .catch((err) => {
        console.error('API error:', err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsFieldUpdated(true);
      });
  };
  console.log(createCategoryErrors);

  /*  add product api call  */
  const addProducts = async () => {
    const variantsArr = variant?.map((item, i) => ({
      ...item,
      variantId: i === 0 ? typesVar1?.variantId : typesVar2?.variantId ?? '',
      variantType:
        i === 0 ? typesVar1?.variantType : typesVar2?.variantType ?? ''
    }));
    // Now show the loader
    setIsLoading(true);

    const dataAddProduct = {
      locationId: locationId,
      locationName: locationName,
      franchiseeId: franchiseeId,
      franchiseeName: franchiseeName,
      manufacturer: manufacturer,
      mfgName: manufacturerProductName,
      fName: zoomRoomProductName,
      categoryId: productCategoryId,
      // variantId: variantId,
      supplierName: supplierName,
      supplierId: supplierId,
      // valueId: valueId,
      variants: variantsArr,
      productDescription: productDescription,
      productImage: '',
      productVideo: '',
      price: price?.toString(),
      sellingPoints: sellingPoint,
      isRequired: isRequired,
      isBestSeller: isBestSeller,
      taxExempt: istaxExempt,
      isDiscontinued: isDiscontinued,
      sku: skuCode,
      createdBy: userId
    };

    try {
      await ApiService.post(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}products`,
        dataAddProduct
      ).then((data) => {
        if (data?.statusCode === 200 && data?.data) {
          getAutoGeneratedSkuCode();

          if (multipleImage && multipleImage.length > 0) {
            multipleImage.forEach((image) => {
              const formData = new FormData();
              formData.append('productId', data.data);
              formData.append('media', image.file);
              formData.append('locationId', locationId);
              addProductMedia(formData);
            });
          } else {
            setIsLoading(false);
            setIsUpdated(true);
            setOpen(true);
            clear();
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong ');
    }
  };

  // Function to add product images
  const addProductMedia = async (formData) => {
    setIsLoading(true);
    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/addFranchiseeProductMedia`,
        formData
      );
      if (!response?.error) {
        setIsLoading(false);
        setIsUpdated(true);
        setOpen(true);
        clear();
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Function to remove images
  const removeImage = (idToRemove) => {
    const updatedImages = multipleImage.filter(
      (image) => image.id !== idToRemove
    );
    setMultipleImage(updatedImages);
  };

  /* Function call when user going to  submit Add product  form */
  const addProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!zoomRoomProductName) {
      setzoomRoomProductNameError('Zoom Room product name is required');
    }

    if (!manufacturerProductName) {
      setmanufacturerProductNameError('Manufacturer product name is required');
    }
    if (!productCategoryId) {
      setproductCategoryError(true);
    }

    if (!skuCode) {
      setSkuCodeError('SKU Code is required');
    }

    if (!manufacturer) {
      setmanufacturerError('Manufacturer is required');
    }

    if (!price) {
      setpriceError('Price is required');
    }

    if (!supplierId) {
      setsupplierError(true);
    }

    if (
      manufacturerProductName &&
      zoomRoomProductName &&
      productCategoryId &&
      skuCode &&
      manufacturer &&
      price &&
      supplierId
    ) {
      addProducts();
    }
  };

  // close variant dialog
  const handleCloseVariant = () => {
    setopenAddVariant(false);
    setopenAddCategory(false);
    setopenAddVarientType(false);
  };
  const addNewVariant = (number) => {
    setvarientTypeName('');
    setopenAddVarientType(true);
    setVariantField(number);
  };

  const addVariant = (number) => {
    if (number === 0) {
      setvariantTypeId(typesVar1?.variantId ?? '');
    }
    if (number === 1) {
      setvariantTypeId(typesVar2?.variantId ?? '');
    }
    setvariantName('');
    setopenAddVariant(true);
  };

  // Method to set variant type
  const handleVariantTypeChange = (e) => {
    setvariantTypeId(e.target.value);
  };

  /* Method for Add product  new category modal */
  // const addNewCategory = () => {
  //   setcategoryName('');
  //   setcategoryDescription('');
  //   setopenAddCategory(true);
  // };

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
    }
    if (val === 'Discard') {
      setShowScreen(0);
      clear();
      setOpen(false);
      refreshData();
    }
  };

  /* Method for closing of Add product modal */
  const handleCloseAddProduct = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
      clear();
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Grid sx={{ padding: '0 2rem', mt: 1 }}>
        <Box className="Main-container">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseAddProduct()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>

                <Typography className="header-blue-32">Add Product</Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {' '}
                  <Button
                    sx={{ mt: 1 }}
                    type="button"
                    variant="contained"
                    className="common-button"
                    onClick={addProduct}
                  >
                    <Typography className="next-button-text">Save</Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box className="container-text-field">
            <Card
              sx={{
                backgroundColor: '#FFFFFF'
              }}
            >
              <CardContent className="pr-0 pl-0">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <Box className="product-details-img align-center relative Main-card ">
                      {multipleImage && multipleImage.length > 0 ? (
                        <div>
                          <ImageSlider images={productImage} />
                        </div>
                      ) : (
                        <img
                          src="/static/images/products/ProductImage.png"
                          alt="product-icon"
                          className="product-img"
                        />
                      )}
                    </Box>
                    <Box mt={2}>
                      <Typography className="add-picture-text weight-700 text-black font-16 required-label">
                        Upload Product Image
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        id="select-product-add-file"
                        style={{ display: 'none' }}
                        onChange={(event) =>
                          handleFileInput(
                            event,
                            setMultipleImage,
                            setImageError,
                            setIsFieldUpdated,
                            multipleImage
                          )
                        }
                      />
                      <label htmlFor="select-product-add-file">
                        <Button
                          type="button"
                          variant="contained"
                          component="span"
                          className="upload-image image-with-txt common-button"
                        >
                          <Typography className="upload-text">
                            Upload
                          </Typography>
                        </Button>
                      </label>
                    </Box>
                    <Box className="card-image-carousel">
                      {/* Render the uploaded images */}

                      {multipleImage.map((image) => (
                        <div className="single-uploaded-images" key={image.id}>
                          <img src={image.previewURL} alt="Uploaded" />

                          <div
                            className="remove-image text-blue"
                            onClick={() => {
                              removeImage(image.id);
                              setImageError('');
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter' || event.key === ' ') {
                                removeImage(image.id);
                                setImageError('');
                              }
                            }}
                            tabIndex="0"
                            role="button"
                            aria-label="Remove image"
                          >
                            x
                          </div>
                        </div>
                      ))}
                    </Box>

                    <Box sx={{ float: 'left' }}>
                      {imageError && (
                        <FormHelperText className="error-message">
                          {imageError}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={2} component="form" id="form-add">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="ZOOM ROOM PRODUCT NAME"
                          autocomplete="ZOOM ROOM PRODUCT NAME"
                          label="ZOOM ROOM PRODUCT NAME"
                          placeholder="ZOOM ROOM PRODUCT NAME"
                          className="location-field input-field-styling required-field"
                          error={Boolean(zoomRoomProductNameError)}
                          helperText={zoomRoomProductNameError}
                          onChange={(e) =>
                            zoomRoomProductNamevalidate(
                              e.target.value,
                              setzoomRoomProductName,
                              setIsFieldUpdated,
                              setzoomRoomProductNameError
                            )
                          }
                          value={zoomRoomProductName}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="MANUFACTURER PRODUCT NAME"
                          autocomplete="MANUFACTURER PRODUCT NAME"
                          label="MANUFACTURER PRODUCT NAME"
                          placeholder="MANUFACTURER PRODUCT NAME"
                          id="locationId"
                          value={manufacturerProductName}
                          className="location-field input-field-styling required-field "
                          error={Boolean(manufacturerProductNameError)}
                          helperText={manufacturerProductNameError}
                          onChange={(e) =>
                            manufacturerProductNameValidate(
                              e.target.value,
                              setIsFieldUpdated,
                              setmanufacturerProductName,
                              setmanufacturerProductNameError
                            )
                          }
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="SKU CODE"
                          autocomplete="SKU CODE"
                          label="SKU CODE"
                          className="location-field required-field input-field-styling"
                          error={Boolean(skuCodeError)}
                          helperText={skuCodeError}
                          value={skuCode}
                          placeholder="SKU CODE"
                          onChange={(e) =>
                            skuCodeValidate(
                              e.target.value,
                              setIsFieldUpdated,
                              setSkuCodeError,
                              setSkuCode
                            )
                          }
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="MANUFACTURER"
                          autocomplete="MANUFACTURER"
                          label="MANUFACTURER"
                          className="location-field required-field input-field-styling"
                          error={Boolean(manufacturerError)}
                          helperText={manufacturerError}
                          value={manufacturer}
                          placeholder="MANUFACTURER"
                          onChange={(e) =>
                            manufacturervalidate(
                              e.target.value,
                              setIsFieldUpdated,
                              setmanufacturer,
                              setmanufacturerError
                            )
                          }
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling required-field"
                          fullWidth
                          error={productCategoryError}
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling required-label"
                          >
                            PRODUCT CATEGORY
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="PRODUCT CATEGORY"
                            className="input-field-styling"
                            value={productCategoryId}
                            onChange={(e) =>
                              productCategoryvalidate(
                                e.target.value,
                                setIsFieldUpdated,
                                setproductCategoryError,
                                setproductCategoryId
                              )
                            }
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                            //   options={product.map((option) => option.categoryName)}
                          >
                            {productCategoryList.length > 0 &&
                              productCategoryList.map((option) => (
                                <MenuItem
                                  key={option.categoryId}
                                  value={option.categoryId}
                                >
                                  {option.categoryName}
                                </MenuItem>
                              ))}
                          </Select>
                          {productCategoryError && (
                            <FormHelperText>
                              Please select product category
                            </FormHelperText>
                          )}
                        </FormControl>

                        {/* {isFranchisor === 'true' && (
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#003087',
                              textDecoration: 'underline',
                              marginRight: '5px',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              addNewCategory();
                            }}
                            className="add-new-category align-right"
                          >
                            +Add Category
                          </Typography>
                        )} */}
                      </Grid>

                      {/* DIVIDER LINE ABOVE VARIANTS */}
                      <Grid item xs={12} sm={12}>
                        <Divider style={{ backgroundColor: '#003087' }} />
                      </Grid>

                      {/* FIRST VARIANT ROW */}
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling "
                          fullWidth
                          // error={varientError}
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling"
                          >
                            VARIANT TYPE
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="VARIANT TYPE"
                            className="input-field-styling"
                            onChange={(e) =>
                              // varientTypeValidate(e.target.value, 0)
                              varientTypeValidate(
                                e.target.value,
                                0,
                                getVariants,
                                setvariantId,
                                setTypesVar1,
                                setTypesVar2,
                                variant,
                                setVariant,
                                setIsFieldUpdated
                              )
                            }
                            renderValue={(selected) => selected?.variantType}
                            value={typesVar1}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientType?.length > 0 &&
                              varientType
                                ?.filter(
                                  (option) =>
                                    !typesVar2?.variantId ||
                                    option?.variantId !== typesVar2?.variantId
                                )
                                ?.map((option) => {
                                  return (
                                    <MenuItem
                                      key={option?.variantId}
                                      value={option}
                                    >
                                      {option?.variantType}
                                    </MenuItem>
                                  );
                                })}
                          </Select>
                        </FormControl>

                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: '#003087',
                            textDecoration: 'underline',
                            marginRight: '5px'
                            // display: 'none'
                          }}
                          className="add-new-variant align-right"
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            addNewVariant(0);
                          }}
                        >
                          <span className="cursor-pointer">
                            +Add Variant Type
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling  "
                          fullWidth
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling "
                          >
                            VARIANT
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="VARIENT"
                            className="input-field-styling"
                            onChange={(e) =>
                              varientSet(
                                e.target.value,
                                0,
                                variant,
                                setVariant,
                                setIsFieldUpdated,
                                setvalueId
                              )
                            }
                            value={variant[0]}
                            renderValue={(selected) => selected?.value}
                            disabled={varientProduct[0]?.length === 0}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientProduct[0]?.length > 0 &&
                              varientProduct[0]?.map((option) => (
                                <MenuItem value={option}>
                                  {option.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: '#003087',
                            textDecoration: 'underline',
                            marginRight: '5px'
                            // display: 'none'
                          }}
                          className="add-new-variant align-right"
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            addVariant(0);
                          }}
                        >
                          <span className="cursor-pointer">+Add Variant</span>
                        </Typography>
                      </Grid>
                      {/* SECOND VARIANT ROW */}
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling "
                          fullWidth
                          // error={varientError}
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling"
                          >
                            VARIANT TYPE
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="VARIANT TYPE"
                            className="input-field-styling"
                            onChange={(e) =>
                              varientTypeValidate(
                                e.target.value,
                                1,
                                getVariants,
                                setvariantId,
                                setTypesVar1,
                                setTypesVar2,
                                variant,
                                setVariant,
                                setIsFieldUpdated
                              )
                            }
                            value={typesVar2}
                            renderValue={(selected) => selected?.variantType}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientType?.length > 0 &&
                              varientType
                                ?.filter(
                                  (option) =>
                                    !typesVar1?.variantId ||
                                    option?.variantId !== typesVar1?.variantId
                                )
                                ?.map((option) => {
                                  return (
                                    <MenuItem value={option}>
                                      {option?.variantType}
                                    </MenuItem>
                                  );
                                })}
                          </Select>
                        </FormControl>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: '#003087',
                            textDecoration: 'underline',
                            marginRight: '5px'
                            // display: 'none'
                          }}
                          className="add-new-variant align-right"
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            addNewVariant(1);
                          }}
                        >
                          <span className="cursor-pointer">
                            +Add Variant Type
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling  "
                          fullWidth
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling "
                          >
                            VARIANT
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="VARIENT"
                            className="input-field-styling"
                            onChange={(e) =>
                              varientSet(
                                e.target.value,
                                1,
                                variant,
                                setVariant,
                                setIsFieldUpdated,
                                setvalueId
                              )
                            }
                            value={variant?.length > 0 && variant[1]}
                            renderValue={(selected) => selected?.value}
                            disabled={varientProduct[1]?.length === 0}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientProduct[1]?.length > 0 &&
                              varientProduct[1]?.map((option) => (
                                <MenuItem value={option}>
                                  {option.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: '#003087',
                            textDecoration: 'underline',
                            marginRight: '5px'
                            // display: 'none'
                          }}
                          className="add-new-variant align-right"
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            addVariant(1);
                          }}
                        >
                          <span className="cursor-pointer">+Add Variant</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Divider style={{ backgroundColor: '#003087' }} />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="field-top-margin-edit-location location-field required-field select-drop-down-label-styling "
                          fullWidth
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling required-label"
                          >
                            SUPPLIER
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="SUPPLIER"
                            className="input-field-styling"
                            // value={getProductList.supplierId}
                            value={supplier}
                            onChange={(e) => supplierValidate(e.target.value)}
                          >
                            {supplierList &&
                              supplierList.map((option) => (
                                <MenuItem
                                  value={`${option.sortKey}|${option.businessName}`}
                                >
                                  {option.businessName}
                                </MenuItem>
                              ))}
                          </Select>
                          {supplierError && (
                            <FormHelperText>
                              Please select supplier
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={12} sm={6} mt={2}>
                        <TextField
                          fullWidth
                          disabled
                          name="PURCHASE COST"
                          autocomplete="PURCHASE COST"
                          label="PURCHASE COST"
                          className="field-top-margin-edit-locatio location-field input-field-styling"
                          value={wholeSalePrice}
                          // error={Boolean(priceError)}
                          // helperText={priceError}
                          onChange={wholeSalePriceSet}
                          placeholder="PURCHASE COST"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                  }}
                                >
                                  $
                                </span>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid> */}

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="PRICE"
                          autoComplete="PRICE"
                          label="PRICE"
                          className="field-top-margin-edit-location location-field input-field-styling required-field"
                          error={Boolean(priceError)}
                          helperText={priceError}
                          onChange={priceSet}
                          value={price}
                          placeholder="PRICE"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label required-label',
                            shrink: true
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                  }}
                                >
                                  $
                                </span>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          multiline
                          id="my-textarea"
                          name="PRODUCT Description"
                          autocomplete="PRODUCT Description"
                          label="PRODUCT DESCRIPTION"
                          placeholder="Notes here . . . "
                          variant="outlined"
                          value={productDescription}
                          className="location-description field-top-margin-edit-location"
                          error={Boolean(productDescriptionError)}
                          helperText={productDescriptionError}
                          onChange={(e) =>
                            productDescriptionSet(
                              e.target.value,
                              setIsFieldUpdated,
                              setproductDescriptionError,
                              setproductDescription
                            )
                          }
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{
                            style: { color: 'black' }, // Set the text color to black here
                            rows: 6
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          multiline
                          id="my-textarea"
                          name="Selling point"
                          autocomplete="Selling point"
                          label="SELLING POINT"
                          placeholder="Notes here . . . "
                          variant="outlined"
                          value={sellingPoint}
                          className="location-description field-top-margin-edit-location "
                          // error={Boolean(productDescriptionError)}
                          // helperText={productDescriptionError}
                          onChange={(e) =>
                            sellingpointSet(
                              e.target.value,
                              setIsFieldUpdated,
                              setsellingPoint
                            )
                          }
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{
                            style: { color: 'black' }, // Set the text color to black here
                            rows: 6
                          }}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isRequired}
                              className="check-box-span"
                              color="default"
                              onChange={(e) => setisRequired(e.target.checked)}
                              sx={{
                                color: '#003087',
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Required
                            </Typography>
                          }
                        />
                      </Grid> */}
                      {/* <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isBestSeller}
                              className="check-box-span"
                              color="default"
                              onChange={(e) =>
                                setisBestSeller(e.target.checked)
                              }
                              sx={{
                                color: '#003087',
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Bestseller
                            </Typography>
                          }
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={istaxExempt}
                              className="check-box-span"
                              color="default"
                              onChange={(e) => setistaxExempt(e.target.checked)}
                              sx={{
                                color: '#003087',
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Tax Exempted
                            </Typography>
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isDiscontinued}
                              className="check-box-span"
                              color="default"
                              onChange={(e) =>
                                setisDiscontinued(e.target.checked)
                              }
                              sx={{
                                color: '#003087',
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Discontinued
                            </Typography>
                          }
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>

      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        isUpdated={isUpdated}
      />

      <CreateVariantTypeModal
        openAddVarientType={openAddVarientType}
        handleCloseVariant={handleCloseVariant}
        setvarientTypeName={setvarientTypeName}
        varientTypeName={varientTypeName}
        handleCreateVariantType={handleCreateVariantType}
        createVariantTypeErrors={createVariantTypeErrors}
        setCreateVariantTypeErrors={setCreateVariantTypeErrors}
      />

      <CreateCategoryModal
        openAddCategory={openAddCategory}
        handleCloseVariant={handleCloseVariant}
        setcategoryName={setcategoryName}
        categoryName={categoryName}
        categoryDescription={categoryDescription}
        setcategoryDescription={setcategoryDescription}
        handleCreateCategory={handleCreateCategory}
        createCategoryErrors={createCategoryErrors}
        setCreateCategoryErrors={setCreateCategoryErrors}
      />

      <CreateVariantModal
        openAddVariant={openAddVariant}
        handleCloseVariant={handleCloseVariant}
        handleCreateVariant={handleCreateVariant}
        varientType={varientType}
        variantName={variantName}
        setvariantName={setvariantName}
        handleVariantTypeChange={handleVariantTypeChange}
        variantTypeId={variantTypeId}
        createVariantErrors={createVariantErrors}
        setCreateVariantErrors={setCreateVariantErrors}
      />
    </>
  );
}

export default AddProduct;
