/* eslint-disable no-lone-blocks */
/* Form to create new franchisee */
/* eslint-disable no-debugger */
import {
  Box,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Select,
  FormHelperText,
  MenuItem,
  Typography,
  Grid,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalData } from 'src/utils';
import Loader from '../../../components/Loader';
import { formatPhoneNumber } from '../../../utils/index';
import heic2any from 'heic2any';
import { ApiService } from 'src/services/ApiService';

const EditOwner = ({
  //   data,
  refreshFranchiseeData,
  Transition,
  statesList,
  setShowScreen,
  rolesList,
  data,
  setIsEdit,
  profileUserData,
  setIsUserUpdate,
  isUserUpdate
}) => {
  const navigate = useNavigate();
  let franchiseeItemId;
  let franchiseeDetails = data;
  // hook

  useEffect(() => {
    franchiseeItemId = franchiseeDetails?.franchiseeId;
    // console.log("franchiseeItemId", franchiseeItemId)

    // call the get franchisee details method
    if (franchiseeItemId) getFranchiseeDetails(franchiseeItemId);

    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }

  }, []);

  let userId = getLocalData('userId');
  const [imageUrl, setImageUrl] = useState('');

  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [isStepTwoValid, setStepTwoValid] = useState(false);
  const [imgObj, setImageObj] = useState([]);
  const [franchiseeId, setFranchiseeId] = useState('');
  const [updateUserId, setUserId] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');
  
  console.log(updatedBy);

  /* States for getting values from input fields of create location form as follows */
  const [franchiseeName, setfranchiseeName] = useState('');
  // const [brandName, setbrandName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [stateVal, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  // const [location, setLocation] = useState('');
  const [roleuser, setroleuser] = useState('');

  // const [assignManager, setassignManager] = useState([]);
  // const [taxGrouping, setTaxGrouping] = useState(false);
  const [taxId, settaxId] = useState('');
  // const [open, setOpen] = React.useState(true);
  const [openDialog, setopenDialog] = useState(false);
  const [isCreated, setisCreated] = useState(false);
  const [mud, setMud] = useState('');
  const [roleErroruser, setRoleErroruser] = useState(false);

  // const [formattedPhone, setFormattedPhone] = useState('');

  // State that is to track whether the any of the form field is updated or not
  const [anyFieldUpdated, setanyFieldUpdated] = useState(false);

  /* States for showing error messages for create location data to user */

  // const [brandNameError, setbrandNameError] = useState(false);

  // const [locationError, setLocationError] = useState('');
  // const [assignManagerError, setassignManagerError] = useState(false);
  //  const [taxGroupingError, settaxGroupingError] = useState(false);
  // Prodile image error text
  const [profileImageError, setProfileImageError] = useState('');

  /* User part start */

  /* States for setting the values of form fields of the create user  */
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [emailuser, setEmailuser] = useState('');

  const [phoneNumber, setphoneNumber] = useState('');
  // const [workphoneNumber, setworkphoneNumber] = useState('');
  // const [role, setRole] = useState("");
  const [addressuser, setAddressuser] = useState('');
  const [address2user, setAddress2user] = useState('');
  const [cityuser, setCityuser] = useState('');
  const [stateuser, setStateuser] = useState('');
  const [zipCodeUser, setzipCodeUser] = useState('');
  const [zoomRoomEmail, setZoomRoomEmail] = useState('');

  /* States for setting the validation error for create user form fields */

  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [emailErroruser, setemailErroruser] = useState(false);
  const [phoneNumberError, setphoneNumberError] = useState(false);
  const [ZoomRoomEmailError, setZoomRoomEmailError] = useState(false);

  // const [workphoneNumberError, setworkphoneNumberError] = useState(false);
  // const [roleError, setRoleError] = useState(false);
  const [profileimageError, setProfileImage] = useState(false);
  const [addressErroruser, setAddressErroruser] = useState(false);
  const [cityErroruser, setCityErroruser] = useState(false);
  const [stateErroruser, setStateErroruser] = useState(false);
  const [zipCodeErroruser, setzipCodeErroruser] = useState(false);
  const [finalApiErr, setFinalApiErr] = useState(false);
  const [finalApiErrMsg, setFinalApiErrMsg] = useState('');
  // const [selectedStatus, setSelectedStatus] = useState('');
  /* User part ends */

  /* Function binded on close button of create-franchisee/user form dialog */
  const handleClose = () => {
    if (profileUserData) {
      setIsEdit(false);
    } else if (anyFieldUpdated) {
      setopenDialog(true);
    } else {
      // setOpen(false);
      // showForm(false);
      setShowScreen(3);
      clear();
    }
  };

  // Method to get the franchisee details
  const getFranchiseeDetails = async (franchiseeId) => {
    // Now show the loader
    setIsLoading(true);
    // call the api to get the location details
    let url = `${
      process.env.REACT_APP_API_END_POINT
    }franchisees/getFranchiseDetail?id=${encodeURIComponent(franchiseeId)}`;
    try {
      const data = await ApiService.get(`${url}`);

      if (data) {
        // set the location details
        let items = data?.data?.Items;
        let detailsObj;
        if (items) detailsObj = items;

        if (detailsObj) setLocationDetails(detailsObj);
        // Now hide the loader
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  // Method to set the value of the location and user details
  const setLocationDetails = (locationDetails) => {
    console.log(locationDetails, '---Location details----');
    // set the location details
    if (locationDetails) {
      // set the location details
      setUpdatedBy(locationDetails.updatedBy);
      // setUpdatedAt(locationDetails.updatedAt);

      setfranchiseeName(locationDetails.franchiseeName);
      // setbrandName(locationDetails.brandName);
      // setPhone(locationDetails.phone)
      setPhone(locationDetails.phone);
      setEmail(locationDetails.email);
      setstartDate(locationDetails.faSigningDate);
      setEndDate(locationDetails.faExpirationDate);
      setAddress(locationDetails.addressLine1);
      setAddress2(locationDetails.addressLine2);
      setCity(locationDetails.city);
      setState(locationDetails.state);
      setZipcode(locationDetails.postalCode);
      // setLocation(
      //   locationDetails.locations && locationDetails.locations.locationName
      // );
      // setassignManager(
      //   locationDetails.locations &&
      //     locationDetails.locations.managerId +
      //       '|' +
      //       locationDetails.locations.managerName
      // );
      // setAssignManagerId(
      //   locationDetails.locations && locationDetails.locations.managerId
      // );
      // setAssignManagerName(
      //   locationDetails.locations && locationDetails.locations.managerName
      // );
      // setTaxGrouping(
      //   locationDetails.locations && locationDetails.locations.taxGrouping
      // );
      settaxId(locationDetails.fein);
      setFranchiseeId(locationDetails.franchiseeId);
      setMud(locationDetails.mud);
      // setLocationId(
      //   locationDetails.locations && locationDetails.locations.locationId
      // );
      // setIsPrimaryLocation(
      //   locationDetails.locations && locationDetails.locations.isPrimaryLocation
      // );
      // setSelectedStatus(locationDetails.locations && locationDetails.status);

      // setEntityType(locationDetails.users && locationDetails.users.entityType);
      // set the user details

      setfirstName(franchiseeDetails.firstName);
      setlastName(franchiseeDetails.lastName);
      setAddressuser(franchiseeDetails.addressLine1);
      setEmailuser(franchiseeDetails.email);
      setphoneNumber(franchiseeDetails.phoneNumber);
      // setworkphoneNumber(item.workPhoneNumber);
      // setRole((item.roleId));
      setAddressuser(franchiseeDetails.addressLine1);
      setAddress2user(franchiseeDetails.addressLine2);
      setCityuser(franchiseeDetails.city);
      setStateuser(franchiseeDetails.state);
      setzipCodeUser(franchiseeDetails.postalCode);
      setUserId(franchiseeDetails.sortKey);
      setImageUrl(franchiseeDetails.profileImage);
      setImageObj(franchiseeDetails.profileImage);
      setroleuser(franchiseeDetails.involvementLevel);
      setZoomRoomEmail(franchiseeDetails.zoomRoomEmail);

      // if (locationDetails.owners) {
      //   //  setCreatedBy((locationDetails.users && locationDetails.users.createdBy));
      //   // setCreatedAt((locationDetails.users && locationDetails.users.createdAt));
      //   // setCognitoId((locationDetails.users && locationDetails.users.cognitoId));
      // }
    }
  };

  /* Function for confirmation modal pop up(close,discard,continue) */
  const handleCloseConfirm = (value) => {
    /* if (value === "Discard") {
              setopenDialog(false);
              setOpen(true);
              showForm(true);
          }
          else if (value === "Close") {
              setopenDialog(false);
              setOpen(false);
              showForm(false);
              clear();
          }
          else if (value === "Continue") {
              setopenDialog(false);
              setOpen(false);
              showForm(false);
              clear();
          } */
    if (value === 'Discard') {
      setopenDialog(false);
      if (profileUserData) setIsEdit(false);
      setShowScreen(0);
      // setOpen(false);
      // showForm(false);
      clear();
    } else if (value === 'Close') {
      setopenDialog(false);
      setShowScreen(0);
      // setOpen(false);
      // showForm(false);
      clear();
    } else if (value === 'Continue') {
      setopenDialog(false);
      // setOpen(true);
      // showForm(true);
    }
  };

  /* Function to upload image */
  const handleFileInput = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setImageUrl(URL.createObjectURL(file));
        setImageObj(file);
        setProfileImage(false);
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setImageUrl(URL.createObjectURL(jpegFile));
          setImageObj(jpegFile);
          setProfileImage(false);
        } catch (error) {
          console.error('Error handling HEIC file:', error);
          setProfileImage(true);
          setImageUrl('');
          setImageObj([]);
          setProfileImageError('Error handling HEIC file.');
        }
      } else {
        setProfileImage(true);
        setImageUrl('');
        setImageObj([]);
        setProfileImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  /* Function to clear form fields when dialog form is closed */
  const clear = () => {
    setfranchiseeName('');
    // setbrandName('');
    setPhone('');
    setEmail('');
    setstartDate('');
    setEndDate('');
    setAddress('');
    setAddress2('');
    setCity('');
    setState('');
    setZipcode('');
    // setLocation('');
    // setassignManager('');
    settaxId('');
    // setAssignManagerId('');
    // setAssignManagerName('');
  };

  /* Franchisee part this are the function for validation of data for create franchisee  */

  /* Function  for validation of brand name */

  // const brandnameValidate = (brandname) => {
  //   if (!brandname) {
  //     setbrandName('');
  //     setbrandNameError('Brand name is required');
  //     // formValid = false;
  //     setStepOneValid(false);
  //     setanyFieldUpdated(false);
  //   } else {
  //     setbrandNameError('');
  //     setbrandName(brandname);
  //     // formValid = true;
  //     setStepOneValid(true);
  //     setanyFieldUpdated(true);
  //   }
  // };

  /* const updatePhone = (phone) => {
           // e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,10)
           let value = Math.max(0, parseInt(phone)).toString().slice(0,10);
           if(value.length > 10) return;
   
           let val = formatPhoneNumber(value);
           console.log("phone", value, val);
           setPhone(val);
           setPhoneError("");
           setanyFieldUpdated(true);
       } */

  /*
          const formatPhoneNumber = (phoneNumberString) => {
              let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
              let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
              if (match) {
                  return match[1] + '-' + match[2] + '-' + match[3];
              }
              return null;
          } */

  {
    /* const Phoneformat = (phone) => {
          // validate phone number and format it as needed
          const regex = /^\d{3}-\d{3}-\d{4}$/;
          const formatted = phone.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
  
          if (regex.test(formatted)) {
              
              setPhone(formatted);
              setFormattedPhone(formatted);
              setPhoneError('');
          } else {
              setPhone(phone);
              setFormattedPhone('');
              setPhoneError('Please enter a valid phone number.');
          }
      } */
  }

  /* Function to select role of user from drop-down */
  const roleValueUser = (role) => {
    setRoleErroruser(false);
    setroleuser(role);
    setStepTwoValid(true);
    setanyFieldUpdated(true);
  };

  /* Function for location drop-down to be filled */

  // const locationValue = (location) => {
  //   if (!location) {
  //     setLocation('');
  //     setLocationError('Location name is required');
  //     setStepOneValid(false);
  //     setanyFieldUpdated(false);
  //   } else {
  //     setLocationError('');
  //     setLocation(location);
  //     // formValid = true;
  //     setStepOneValid(true);
  //     setanyFieldUpdated(true);
  //   }
  // };

  /* Function for assign manager drop down to be filled */
  // const assignManagerValue = (assignManager) => {
  //   // if (!assignManager) {
  //   //     setassignManager('');
  //   //     setassignManagerError(true);
  //   //     setStepOneValid(false);
  //   //     setanyFieldUpdated(false);
  //   // }
  //   // else {
  //   //     // formValid = true;
  //   //     setStepOneValid(true);
  //   //     setassignManagerError(false);
  //   //     setassignManager(assignManager);
  //   //     setanyFieldUpdated(true);
  //   // }
  //   // setassignManager(assignManager);
  //   // setanyFieldUpdated(true);
  //   if (!assignManager) {
  //     setassignManager('');
  //     setassignManagerError(true);
  //     setStepOneValid(false);
  //     setanyFieldUpdated(false);
  //     setAssignManagerName('');
  //     setAssignManagerId('');
  //   } else {
  //     // formValid = true;
  //     setStepOneValid(true);
  //     setassignManagerError(false);
  //     setassignManager(assignManager);
  //     setAssignManagerName(assignManager.split('|')[1]);
  //     setAssignManagerId(assignManager.split('|')[0]);
  //     setanyFieldUpdated(true);
  //   }

  //   console.log('assignManager', assignManager);
  //   // setassignManager(assignManager);
  //   // setanyFieldUpdated(true);
  // };

  /* Function for validation of tax id */

  /* Franchisee part ends */

  /* Create  User Part  starts  */

  /* Function to validate the Firstname entered by user */
  const validateFirstname = (firstName) => {
    if (!firstName) {
      setfirstName('');
      setfirstNameError('First name is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else {
      setfirstNameError('');
      setfirstName(firstName);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the last name */

  const validateLastname = (lastName) => {
    if (!lastName) {
      setlastName('');
      setlastNameError('Last name is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else {
      setlastNameError('');
      setlastName(lastName);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the email */

  const validateEmailUser = (Email) => {
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!Email) {
      setEmailuser('');
      setemailErroruser('Email is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else if (!validateEmail.test(Email)) {
      setemailErroruser('Email is invalid');
      setEmailuser(Email);
      setStepTwoValid(false);
      setanyFieldUpdated(true);
    } else {
      setemailErroruser('');
      setEmailuser(Email);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the email */

  const validateZoomRoomEmailUser = (Email) => {
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!Email) {
      setZoomRoomEmail('');
      setZoomRoomEmailError('Zoom room email is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else if (!validateEmail.test(Email)) {
      setZoomRoomEmailError('Zoom room email is invalid');
      setZoomRoomEmail(Email);
      setStepTwoValid(false);
      setanyFieldUpdated(true);
    } else {
      setZoomRoomEmailError('');
      setZoomRoomEmail(Email);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the phone number entered by user */

  // const validatePhonenumber = (phoneNumber) => {
  //     const validatePhonenumber = /^\d{3}-\d{3}-\d{4}$/;

  //     if (!phoneNumber) {
  //         setphoneNumber('');
  //         setphoneNumberError("Phone number is required");
  //         setStepTwoValid(false);
  //         setanyFieldUpdated(false);
  //     }
  //     else if (!validatePhonenumber.test(phoneNumber)) {
  //         setphoneNumberError("Invalid phone number");
  //         setphoneNumber(phoneNumber);
  //         setStepTwoValid(false);
  //         setanyFieldUpdated(true);
  //     }
  //     else {
  //         setphoneNumberError("");
  //         setphoneNumber(phoneNumber);
  //         setStepTwoValid(true);
  //         setanyFieldUpdated(true);
  //     }
  // }

  const validatePhonenumber = (phonenumber) => {
    // const validatePhonenumber = /^\d{3}-\d{3}-\d{4}$/;

    // if (!phonenumber) {
    //     setphoneNumber('');
    //     setphoneNumberError("Phone number is required");
    //     setStepTwoValid(false);
    //     setanyFieldUpdated(true);
    // }

    // else {
    //     setphoneNumberError("");
    //     let formatedPhoneNumber = formatPhoneNumber(phonenumber);
    //     setphoneNumber(formatedPhoneNumber);
    //     setStepTwoValid(true);
    //     setanyFieldUpdated(true);
    // }

    const validation = /^\d{3}-\d{3}-\d{4}$/;
    setanyFieldUpdated(true);
    if (phonenumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(phonenumber);
      setphoneNumber(formatWorkPhoneNumber);
      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setphoneNumberError(
          'Please enter a valid phone number in format: xxx-xxx-xxxx'
        );
      } else {
        setphoneNumberError('');
        setStepTwoValid(true);
      }
    } else {
      setphoneNumberError('Phone number is required');
      setphoneNumber('');
    }
  };

  /* Function to validate work phone number */

  // const validateWorkPhonenumber = (workPhoneNumber) => {
  //   const validation = /^\d{3}-\d{3}-\d{4}$/;
  //   setanyFieldUpdated(true);
  //   if (workPhoneNumber) {
  //     let formatWorkPhoneNumber = formatPhoneNumber(workPhoneNumber);
  //     setworkphoneNumber(formatWorkPhoneNumber);
  //     if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
  //       setworkphoneNumberError(
  //         'Please enter a valid phone number in format: xxx-xxx-xxxx'
  //       );
  //     } else {
  //       setworkphoneNumberError('');
  //       setStepTwoValid(true);
  //     }
  //   } else {
  //     setworkphoneNumberError('Phone number is required');
  //     setworkphoneNumber('');
  //   }
  // };

  /* Function to check the role values to be selected */
  /* const roleValue = (role) => {
           setRoleError(false);
           setRole(role);
           setanyFieldUpdated(true);
       } */

  /* Function for validation of address entered by the user */
  const validateAddressUser = (address) => {
    if (!address) {
      setAddressuser('');
      setAddressErroruser('Address is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else {
      setAddressErroruser('');
      setAddressuser(address);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function for validation of address2 entered by user */
  const validateAddress2User = (address2) => {
    // if (!address2) {
    //     setAddress2Erroruser("Address 2 is required");
    //     setStepTwoValid(false);
    // } else {
    //     setAddress2Erroruser("");
    //     setAddress2user(address2);
    //     setStepTwoValid(true);
    // }
    if (!address2) {
      setAddress2user('');
      setanyFieldUpdated(false);
    } else {
      setAddress2user(address2);
      setanyFieldUpdated(true);
    }
  };

  /* Function to select city from drop-down */

  const cityValueUser = (city) => {
    if (!city) {
      setCityuser('');
      setCityErroruser('City is requried');
      setanyFieldUpdated(false);
    } else {
      setCityErroruser('');
      setCityuser(city);
      setStepTwoValid(true);
    }
  };

  /* Function to select state of user from drop-down */
  const stateValueUser = (state) => {
    setStateErroruser(false);
    setStateuser(state);
    setStepTwoValid(true);
    setanyFieldUpdated(true);
  };

  /* Function to validate entered zip code */

  const validateZipCodeUser = (zipCodeUser) => {
    const validateZipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (!zipCodeUser) {
      setzipCodeUser('');
      setzipCodeErroruser('Zip code is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else if (!validateZipcode.test(zipCodeUser)) {
      setzipCodeErroruser('Invalid zip code');
      setzipCodeUser(zipCodeUser);
      setStepTwoValid(false);
      setanyFieldUpdated(true);
    } else {
      setzipCodeErroruser('');
      setzipCodeUser(zipCodeUser);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  // Method to change the status
  // const handleStatusChange = (val) => {
  //   setSelectedStatus(val);
  //   setanyFieldUpdated(true);
  // };

  /* Function call when user is going to submit the form for user creation */
  const formSubmitValue = (e) => {
    // const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // eslint-disable-next-line no-unused-vars
    e.preventDefault();
    e.stopPropagation();

    if (!firstName) {
      setfirstNameError('First name is required');
    }
    if (!lastName) {
      setlastNameError('Last name is required');
    }
    if (!emailuser) {
      setemailErroruser('Email is required');
    }
    if (!phoneNumber) {
      setphoneNumberError('Phone number is required');
    }
    // if (!workphoneNumber) {
    //   setworkphoneNumberError('Work phone number is required');
    // }
    // if (!role) {
    //     setRoleError(true);
    // }
    if (!addressuser) {
      setAddressErroruser('Address is required');
    }
    if (!cityuser) {
      // setCityErroruser(true);
      setCityErroruser('City is requried');
    }
    if (!stateuser) {
      setStateErroruser(true);
    }
    if (!zipCodeUser) {
      setzipCodeErroruser('Zip code is required');
    }
    //  || !role
    if (
      phoneNumberError.length > 0 ||
      !firstName ||
      !lastName ||
      !emailuser ||
      !phoneNumber ||
      // !workphoneNumber ||
      !addressuser ||
      profileimageError ||
      !cityuser ||
      !stateuser ||
      !zipCodeUser
    ) {
      setStepTwoValid(false);
      setanyFieldUpdated(true);
    } else {
      setStepTwoValid(true);
      setanyFieldUpdated(true);

      // if (isStepTwoValid) {
      // Now call the create franchise service method
      if (franchiseeId) updateFranchisee();
      // else if (franchiseeId) createLocation(franchiseeId);
      // else if (franchiseeId && locationId) updateUser(franchiseeId, locationId);

      // }
    }

    /* if (isStepTwoValid) {
              // Now call the create franchise service method
              createFranchisee();
          } else {
              // Set step two is not valid
              setStepTwoValid(false);
          } */
  };

  // Method to check the email already exist or not from API
  const checkEmailExist = async (email) => {
    // check the email is valid or not
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!validateEmail.test(email.value)) {
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else {
      let emailid = email.value.toLowerCase();
      // Now check the email is already exist or not
      try {
        const res = await ApiService.get(
          `${process.env.REACT_APP_API_END_POINT}users/checkEmailExists?email=${emailid}`
        );

        if (res.statusCode === 200) {
          // Now check the response is true or false
          let emailExist = res?.isExists;
          let msg = res?.message;
          if (res && emailExist) {
            // Now email is already exist
            if (email.name === 'Email') {
              setanyFieldUpdated(false);
            } else {
              setemailErroruser(msg);
              setanyFieldUpdated(false);
            }
          }
        }
      } catch (err) {
        console.log(err);
        // Now email is not exist
        setanyFieldUpdated(false);
      }
    }
  };
  // Method to create franchisee
  const updateFranchisee = async () => {
    if (franchiseeId || isStepTwoValid) {
      // Now show the loader
      setIsLoading(true);
      setFinalApiErr(false);
      setFinalApiErrMsg('');

      // JSON body for create franchisee
      const body = {
        franchiseeName: franchiseeName,
        phone: phone,
        email: email.toLowerCase(),
        faSigningDate: startDate || '',
        faExpirationDate: endDate || '',
        addressLine1: address,
        addressLine2: address2,
        city: city,
        updatedBy: userId,
        postalCode: zipcode.toString(),
        state: stateVal,
        fein: taxId,
        mud: mud,
        status: 1
      };
      // Create franchisee API calling
      try {
        const data = await ApiService.put(
          `${
            process.env.REACT_APP_API_END_POINT
          }franchisees/updateFranchise?id=${encodeURIComponent(franchiseeId)}`,
          body
        );

        if (data.statusCode === 200 && data?.data?.Attributes?.franchiseeId) {
          let franchiseeId =
            data && data.data && data.data.Attributes.franchiseeId
              ? data.data.Attributes.franchiseeId
              : null;
          // set the franchisee id
          setFranchiseeId(franchiseeId);

          // Now call the update location method
          if (franchiseeId) updateUser(franchiseeId);
        } else {
          // Now hide the loader
          setIsLoading(false);
          let errmsg = data.message;
          if (data.error && errmsg) {
            setFinalApiErr(true);
            setFinalApiErrMsg(errmsg);
          }
        }
      } catch (error) {
        console.error('Error:', error);
        let errmsg = error.message;
        // set error message
        setFinalApiErr(true);
        setFinalApiErrMsg(errmsg);
        // Now hide the loader
        setIsLoading(false);
      }
    } else {
      // Now hide the loader
      setIsLoading(false);
      setFinalApiErr(true);
      setFinalApiErrMsg('Something went wrong. Please try again later.');
    }
  };

  // Method to update user
  const updateUser = async (franchId) => {
    if (franchId) {
      let franchiseid = franchId ? String(franchId) : '';

      // Create formdata object
      let formData = new FormData();
      formData.append('franchiseeId', franchiseid);
      formData.append('franchiseeName', franchiseeName);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', emailuser.toLowerCase());
      formData.append('zoomRoomEmail', zoomRoomEmail.toLowerCase());
      formData.append('phoneNumber', phoneNumber);
      formData.append('profileImage', imgObj);
      formData.append('addressLine1', addressuser);
      formData.append('addressLine2', address2user);
      formData.append('city', cityuser);
      formData.append('state', stateuser);
      formData.append('postalCode', zipCodeUser.toString());
      formData.append('status', 1);

      formData.append('updatedBy', userId);
      formData.append('involvementLevel', roleuser);

      // Calling of create user API when user submit details
      try {
        const data = await ApiService.put(
          `${
            process.env.REACT_APP_API_END_POINT
          }users/updateOwner?id=${encodeURIComponent(updateUserId)}`,
          formData
        );

        if (data.statusCode === 200) {
          // Now hide the loader
          setIsLoading(false);
          setisCreated(true);
          setopenDialog(true);

          // Trigger the child component method to parent and refresh the table
          refreshFranchiseeData();
        } else if (data?.error) {
          // Now hide the loader
          setIsLoading(false);
          if (data?.statusCode === 400) {
            // set error message
            setFinalApiErr(true);
            setFinalApiErrMsg(
              'User account was successfully updated, but unfortunately, there was a failure in sending the corresponding email.'
            );
            setisCreated(false);
          } else {
            let errmsg = data?.message;
            if (errmsg) {
              setFinalApiErr(true);
              setFinalApiErrMsg(errmsg);
              setisCreated(false);
            }
          }
        }
        setIsUserUpdate(!isUserUpdate);
      } catch (error) {
        console.error('Error:', error);
        let errmsg = error.message;
        // set error message
        setFinalApiErr(true);
        setFinalApiErrMsg(errmsg);
        // Now hide the loader
        setIsLoading(false);
      }
    } else {
      // Now hide the loader
      setIsLoading(false);
      setFinalApiErr(true);
      setFinalApiErrMsg('Something went wrong. Please try again later.');
    }
  };

  /* User Part */
  return (
    <>
      <Loader IsLoading={isLoading} />
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingRight: '10px', paddingleft: '10px' }}>
                  <h1 className="franchisee-name">Create Franchisee</h1>
              </Box> */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item sx={{ paddingLeft: '2rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box onClick={() => handleClose()} className="back-arrow-close">
              <svg
                width="27"
                height="19"
                viewBox="0 0 27 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                  fill="#003087"
                />
              </svg>
            </Box>

            <Typography className="header-blue-32">Edit Owner</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button
                      sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                       type="submit"
              className="btn-primary m-0">
  
                    Save
  
                  </Button> */}
          </Box>
        </Grid>
      </Grid>
      {/* <Box sx={{ display: 'flex' }}>
                  <ArrowBackIcon
                      className="back-arrow-style"
                      style={{ margin: '14px', fontSize: '30px' }}
                      onClick={() => handleClose()}
                  />
                  <h1 className="franchisee-name mb-0 mt-10">Edit Franchise</h1>
              </Box> */}

      {/* ---------- Create USER form starts -----*/}
      <>
        <Box>
          {/* <Box className="container-text-field"> */}
          <Box className="container-text-field" sx={{ padding: '0 2rem' }}>
            <Card
              component="form"
              id="user-form"
              className="create-franchise-form"
              onSubmit={formSubmitValue}
              sx={{ border: '0.4px solid #C5C5C5' }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="First name"
                      autocomplete="First name"
                      label="FIRST NAME"
                      placeholder="First name"
                      className="input-field-styling required-field"
                      value={firstName}
                      onChange={(e) => validateFirstname(e.target.value)}
                      error={Boolean(firstNameError)}
                      helperText={firstNameError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Last Name"
                      autocomplete="Last Name"
                      label="LAST NAME"
                      placeholder="Last name"
                      value={lastName}
                      className="input-field-styling required-field"
                      onChange={(e) => validateLastname(e.target.value)}
                      error={Boolean(lastNameError)}
                      helperText={lastNameError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="field-top-margin">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="EmailUser"
                      autocomplete="Email"
                      label="PERSONAL EMAIL"
                      placeholder="PERSONAL EMAIL"
                      value={emailuser}
                      className="input-field-styling required-field"
                      onChange={(e) => validateEmailUser(e.target.value)}
                      onKeyUp={(e) => checkEmailExist(e.target)}
                      error={Boolean(emailErroruser)}
                      helperText={emailErroruser}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="ZoomRoomEmailUser"
                      autocomplete="ZoomRoomEmail"
                      label="ZOOM ROOM EMAIL"
                      placeholder="ZOOM ROOM EMAIL"
                      value={zoomRoomEmail}
                      className="input-field-styling required-field"
                      onChange={(e) =>
                        validateZoomRoomEmailUser(e.target.value)
                      }
                      // onKeyUp={(e) => checkEmailExist(e.target)}
                      error={Boolean(ZoomRoomEmailError)}
                      helperText={ZoomRoomEmailError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="field-top-margin">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Phone number"
                      autocomplete="Phone number"
                      label="MOBILE NUMBER"
                      placeholder="777-777-7777"
                      value={phoneNumber}
                      onChange={(e) => validatePhonenumber(e.target.value)}
                      error={Boolean(phoneNumberError)}
                      helperText={phoneNumberError}
                      className="input-field-styling required-field"
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      error={roleErroruser}
                      fullWidth
                      className="select-drop-down-label-styling "
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        ROLE
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={roleuser}
                        label="ROLE"
                        className="input-field-styling"
                        onChange={(e) => roleValueUser(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto'
                            }
                          }
                        }}
                      >
                        {rolesList?.map((data) => (
                          <MenuItem value={data.role}>{data.role}</MenuItem>
                        ))}
                      </Select>
                      {roleErroruser && (
                        <FormHelperText>Please select role</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name="Work phone number"
                            autocomplete="Work phone number"
                            label="WORK PHONE NUMBER"
                            placeholder="777-777-7777"
                            value={workphoneNumber}
                            onChange={(e) =>
                              validateWorkPhonenumber(e.target.value)
                            }
                            error={Boolean(workphoneNumberError)}
                            helperText={workphoneNumberError}
                            className="input-field-styling required-field"
                            InputLabelProps={{
                              className:
                                'label-style-create-franchisee required-label'
                            }}
                            InputProps={{}}
                          />
                        </Grid> */}

                  <Grid item xs={12} sm={2}>
                    <Avatar
                      sx={{ height: '90px', width: '90px' }}
                      className="user-profile-img"
                      src={imageUrl}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <span className="add-profile-text">
                      Add your profile image
                    </span>
                    <Box error={profileimageError}>
                      <input
                        accept="image/png, image/jpeg, image/jpg"
                        multiple
                        type="file"
                        id="select-file"
                        style={{ display: 'none' }}
                        onChange={(event) => handleFileInput(event)}
                      />
                      <label htmlFor="select-file">
                        <Button
                          variant="contained"
                          component="span"
                          className="upload-image"
                        >
                          <Typography className="upload-text">
                            Choose{' '}
                          </Typography>
                        </Button>
                      </label>
                    </Box>
                    {profileimageError && (
                      <FormHelperText className="error-message">
                        {profileImageError}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="field-top-margin">
                  <Grid item xs={12} sm={6}>
                    <Typography className="table-heade header-blue-20">
                      Home Address
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="field-top-margin">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Address"
                      autocomplete="Address"
                      label="ADDRESS"
                      placeholder="Address"
                      value={addressuser}
                      onChange={(e) => validateAddressUser(e.target.value)}
                      error={Boolean(addressErroruser)}
                      helperText={addressErroruser}
                      className="input-field-styling required-field"
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Address 2"
                      autocomplete="Address 2"
                      label="ADDRESS 2"
                      placeholder="Apartment, Suite etc"
                      value={address2user}
                      onChange={(e) => validateAddress2User(e.target.value)}
                      // error={Boolean(address2Erroruser)}
                      // helperText={address2Erroruser}
                      className="input-field-styling"
                      InputLabelProps={{
                        className: 'label-style-create-franchisee'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="field-top-margin">
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      error={stateErroruser}
                      fullWidth
                      className="select-drop-down-label-styling required-field"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        STATE
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={stateuser}
                        label="STATE"
                        className="input-field-styling"
                        onChange={(e) => stateValueUser(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto'
                            }
                          }
                        }}
                      >
                        {statesList?.map((data) => (
                          <MenuItem value={data.state}>{data.state}</MenuItem>
                        ))}
                      </Select>
                      {stateErroruser && (
                        <FormHelperText>Please select state</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="City"
                      autocomplete="City"
                      label="CITY"
                      placeholder="City"
                      className="input-field-styling required-field"
                      value={cityuser}
                      onChange={(e) => cityValueUser(e.target.value)}
                      error={Boolean(cityErroruser)}
                      helperText={cityErroruser}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="field-top-margin ">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="Zip code"
                      autocomplete="Zip code"
                      label="ZIP CODE"
                      placeholder="44444"
                      className="input-field-styling required-field"
                      value={zipCodeUser}
                      onChange={(e) => validateZipCodeUser(e.target.value)}
                      error={Boolean(zipCodeErroruser)}
                      helperText={zipCodeErroruser}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Grid>
                </Grid>
                {finalApiErr && (
                  <FormHelperText className="Mui-error final-error">
                    {finalApiErrMsg}
                  </FormHelperText>
                )}
                <Grid container spacing={2} className="field-top-margin ">
                  <Grid item xs={12} sm={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingTop: '20px'
                      }}
                    >
                      {/* <Button
                        type="button"
                        className="back-button common-button p-0"
                        variant="contained"
                        onClick={handlePrev}
                      >
                        <Typography className="back-button-text">
                          Back
                        </Typography>
                      </Button> */}
                      <Button
                        type="submit"
                        variant="contained"
                        className="next-button common-button p-0"
                      >
                        <Typography className="next-button-text">
                          Save
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? '' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes, what do you want
                  <Typography className="title-text-sub">to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Owner has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditOwner;
