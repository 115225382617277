import React, { useEffect, useState } from 'react';
// Get the loader
import Loader from '../../../components/Loader';
import { ApiService } from 'src/services/ApiService';

const PerformanceReport = () => {
  // get the report url
  const [reportUrl, setReportUrl] = React.useState('');
  // check loader is true or false
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getReport();
  }, []);

  // Get the report method
  const getReport = async () => {
    setIsLoading(true);

    try {
      const data = await ApiService.get(
        'https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev/franchisees/reportPerformance'
      );

      if (data.Status === 200) {
        const url = data.EmbedUrl;
        console.log(url);
        setReportUrl(url);
      }
    } catch (error) {
      console.error(error.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <div className="container align-center">
        {/* <iframe title="Reports" width="96%" height="960" src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/395188757585/dashboards/a9ab0bc0-4018-4918-b5d5-6d08b44fe427?directory_alias=zr" /> */}
        <iframe
          title="Reports"
          className="report-iframe"
          width="96%"
          height="960"
          src={reportUrl}
        />
      </div>
    </>
  );
};

export default PerformanceReport;
