import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { ApiService } from 'src/services/ApiService';
import Header from 'src/components/Header';
import GlobalData from 'src/global';
import { Helmet } from 'react-helmet-async';

const RoyaltyReport = () => {
  const [embedUrl, setEmbedUrl] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [reportId, setReportId] = useState('');
  const [isError, setIsError] = useState(false);
  const title = GlobalData[0].title;

  const getPowerBiDetails = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}reports/getEmbedReport?reportId=2ad19e83-4d2c-4c89-941d-434ae9d91c22&workspaceId=adf52a44-8799-4708-b9ef-181ec598450a&editable=Y`
      );

      const finalUrl = response?.embedUrl[0]?.embedUrl;

      setEmbedUrl(finalUrl);
      setAccessToken(response?.accessToken);
      setReportId(response?.embedUrl[0]?.reportId);
      setIsError(false);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };

  useEffect(() => {
    getPowerBiDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Royalty Report | {title}</title>
      </Helmet>
      <div className="container align-center" style={{ height: '100vh' }}>
        <Container
          maxWidth="100%"
          className="main-container"
          style={{ height: '100%' }}
        >
          <Header title="" />
          {!isError ? (
            <div className="powerbi-container" style={{ height: '100%' }}>
              <PowerBIEmbed
                embedConfig={{
                  type: 'report',
                  id: reportId,
                  embedUrl: embedUrl,
                  accessToken: accessToken,
                  tokenType: models.TokenType.Embed
                  // settings: {
                  //   panes: {
                  //     filters: {
                  //       expanded: true,
                  //       visible: true
                  //     }
                  //   }
                  // }
                }}
                eventHandlers={
                  new Map([
                    [
                      'loaded',
                      function () {
                        console.log('Report loaded');
                      }
                    ],
                    [
                      'rendered',
                      function () {
                        console.log('Report rendered');
                      }
                    ],
                    [
                      'error',
                      function (event) {
                        console.log(event.detail);
                      }
                    ]
                  ])
                }
                cssClassName={'embed-container'}
                getEmbeddedComponent={(embeddedReport) => {
                  window.Report = embeddedReport;
                }}
              />
            </div>
          ) : (
            <h1>Report is not available.</h1>
          )}
        </Container>
      </div>
    </>
  );
};

export default RoyaltyReport;
