import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import moment from 'moment';
import { DateTime } from 'luxon';

export const ConditionalRouteWrapper = ({
  condition,
  redirectPath,
  children,
  ...rest
}) => {
  return (
    <Route {...rest}>
      {condition ? children : <Navigate to={redirectPath} />}
    </Route>
  );
};

export const getHeader = (headerName) => {
  let elm = document.getElementsByClassName('page-header-text')[0];
  if (elm) elm.innerHTML = headerName;
};

// convert base64 data to file object
export const getFileFromUrl = async (fileurl) => {
  let fileSign;
  let filename = (Math.random() + 1).toString(36).substring(7);
  await fetch(fileurl)
    .then((res) => res.blob())
    .then((blob) => {
      fileSign = new File([blob], `${filename}_${Date.now()}.png`, {
        type: 'image/png'
      });
    });
  return fileSign;
};

// set data on the local storage
export const setLocalData = (keyName, keyValue) => {
  localStorage.setItem(keyName, keyValue);
};

// get data from the local storage
export const getLocalData = (keyName) => {
  return localStorage.getItem(keyName);
};

// clear local data from the local storage
export const clearLocalData = () => {
  localStorage.clear();
};

export const formatPhoneNumber = (value) => {
  const input = value.replace(/\D/g, '').substring(0, 10);
  // Divide numbers in 3 parts :"123-456-7890"
  const first = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  let finalValue = '';
  if (input.length > 6) {
    // return `${first}-${middle}-${last}`;
    finalValue = `${first}-${middle}-${last}`;
  } else if (input.length > 3) {
    // return `${first}-${middle}`;
    finalValue = `${first}-${middle}`;
  } else if (input.length >= 0) {
    // return input;
    finalValue = input;
  }
  return finalValue;
};

export const base64ToFile = (base64Data, fileName) => {
  const byteString = window.atob(base64Data.split(',')[1]);
  const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });
  const file = new File([blob], fileName, { type: mimeString });

  return file;
};

export const getImageExtensionFromBase64 = (base64Data) => {
  const regex = /^data:image\/([a-z]+);base64,/;
  const result = regex.exec(base64Data);

  if (result && result.length > 1) {
    return result[1];
  }

  return null; // Unable to extract image extension
};

// Method to convert date in MM-DD-YYYY format when pass to API
export const dateFormat = (inputDate, type) => {
  let date;
  let month;
  let year;
  let newDate = new Date(inputDate);
  date = newDate.getDate();

  month = type ? newDate.getMonth() + 2 : newDate.getMonth() + 1;
  if (month > 12) {
    month = 1;
    year = newDate.getFullYear() + 1;
  } else {
    year = newDate.getFullYear();
  }

  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  return `${month}-${date}-${year}`;
};

export const maskCardNumber = (cardNumber) => {
  const cardNumberStr = cardNumber?.toString();
  const last2Digits = cardNumberStr?.slice(-2);
  const remainingDigits = cardNumberStr.slice(0, -2);
  const maskedDigits = remainingDigits.replace(/./g, 'x');
  return `${maskedDigits}${last2Digits}`;
};

export const dateFormatWithSlash = (inputDate) => {
  let date;
  let month;
  let year;
  let newDate = new Date(inputDate);
  date = newDate.getDate();
  month = newDate.getMonth() + 1;
  year = newDate.getFullYear();
  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  return `${month}/${date}/${year}`;
};

export const CalenderdateFormat = (inputDate) => {
  let date;
  let month;
  let year;
  let newDate = new Date(inputDate);
  date = newDate.getDate();
  month = newDate.getMonth() + 1;
  year = newDate.getFullYear();
  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  return `${month}-${date}-${year}`;
};

export const getCurrentDate = (separator = '-') => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return `${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}${separator}${year}`;
};

// get first day of week
// export const fetFirstWeekDay = (inputDate, next) => {
//   console.log('First', inputDate);
//   let today = new Date(inputDate); // get current date
//   let first = '';
//   if (!next) {
//     first = today.getDate() - today.getDay() + 1 - 7;
//   } else {
//     first = today.getDate() - today.getDay() + 1 + 7; // First day is the day of the month - the day of the week
//   }
//   console.log('Second', inputDate);
//   let firstday = new Date(today.setDate(first));
//   return firstday ? firstday.getDate() : '01';
// };

export const getFirstDateOfCurrentWeek = (inputDate) => {
  let today = new Date(inputDate);
  const dayOfWeek = today.getDay();
  const firstDate = new Date(today);
  firstDate.setDate(today.getDate() - dayOfWeek);
  return firstDate;
};

export const setFullLocationId = (locationId) => {
  // let result = '';
  // if (locationId) {
  //   if (locationId.indexOf('LOC#') > -1) {
  //     result = locationId;
  //   } else {
  //     result = `LOC#${locationId}`;
  //   }
  // }
  return locationId;
};

export const formatTaxId = (value) => {
  const input = value.replace(/\D/g, '').substring(0, 10);
  // Divide numbers in 3 parts :"123-456-7890"
  const first = input.substring(0, 2);
  const middle = input.substring(2, 10);
  // const last = input.substring(6, 10);
  let finalValue = '';
  // if (input.length > 6) {
  //   finalValue = `${first}-${middle}-${last}`;
  // } else
  if (input.length > 2) {
    // return `${first}-${middle}`;
    finalValue = `${first}-${middle}`;
  } else if (input.length >= 0) {
    // return input;
    finalValue = input;
  }

  return finalValue;
};

// Method to enter only number in input field

export const enterNumber = (value) => {
  value = value.replace(/[^0-9.]/g, '');
  // Allow only two digits after the decimal point
  const decimalIndex = value.indexOf('.');
  if (decimalIndex !== -1) {
    const parts = value.split('.');
    value = parts[0] + '.' + parts[1].slice(0, 2);
  }

  return value;
};

export const enterNumberOnly = (value) => {
  value = value.replace(/[^0-9.]/g, '');
  return value;
};

export const addTwoDecimalPlaces = (input) => {
  if (typeof input === 'number') {
    input = input.toString();
  } else if (typeof input === 'string') {
    let parsedNumber = parseFloat(input);
    if (!Number.isNaN(parsedNumber)) {
      input = parsedNumber.toString();
    } else {
      // Handle invalid input if needed
      return null;
    }
  } else {
    // Handle unsupported input types if needed
    return null;
  }

  return parseFloat(input).toFixed(2);
};

export const setFullSupplierId = (supplierId) => {
  let result = '';
  if (supplierId) {
    if (supplierId.indexOf('#SUP#') > -1) {
      result = supplierId;
    } else {
      result = `#SUP#${supplierId}`;
    }
  }
  return result;
};

// // Create filter query String
// export const filtersQuery = (filters) => {
//   let filtersParams = JSON.parse(JSON.stringify(filters));
//   Object.keys(filtersParams).forEach((key) => {
//       if (
//           filtersParams[key] === null ||
//           filtersParams[key] === -1 ||
//           filtersParams[key] === '' ||
//           filtersParams[key] === 'undefined' ||
//           filtersParams[key] === undefined
//       ) {
//           delete filtersParams[key];
//       }
//   });

//   let queryString = Object.keys(filtersParams)
//       .map((key) => key + '=' + encodeURIComponent(filtersParams[key]))
//       .join('&');

//   return queryString;
// }
// Create filter query String

export const filtersQuery = (filters, keys = []) => {
  let filtersParams = JSON.parse(JSON.stringify(filters));
  Object.keys(filtersParams).forEach((key) => {
    if (
      filtersParams[key] === 'All' ||
      filtersParams[key] === null ||
      filtersParams[key] === -1 ||
      filtersParams[key] === 'None' ||
      filtersParams[key] === null ||
      filtersParams[key] === '' ||
      filtersParams[key] === 'undefined' ||
      filtersParams[key]?.length <= 0
    ) {
      delete filtersParams[key];
    }

    keys.forEach((keyName) => {
      delete filtersParams[keyName];
    });
  });

  let queryString = Object.keys(filtersParams)
    .map((key) => key + '=' + encodeURIComponent(filtersParams[key]))
    .join('&');
  return queryString;
};

// for converting date into utc formate
export const convertToUTC = (localDateStr) => {
  const localDate = new Date(localDateStr); // Parse the string into a Date object
  const localISOString = localDate.toISOString();
  const utcISOString = new Date(localISOString).toUTCString();
  const utcDate = new Date(utcISOString);
  return utcDate;
};

// for converting date time into utc formate
export const convertTimeAndDate = (timeString, dateString) => {
  const dateTimeString = `${dateString} ${timeString}`;
  const dateTime = moment(dateTimeString, 'MM-DD-YYYY hh:mm A');
  const utcDate = dateTime.toDate();
  return utcDate.toISOString();
};
export function getUserTimeZone() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimeZone;
}
export function convertUtcToNormal(utcTimeStr) {
  const userTimeZone = getUserTimeZone(); // Replace with your actual function to retrieve the user's time zone

  // Create a new Date object for the current date and UTC time
  const utcDate = new Date();
  utcDate.setUTCHours(parseInt(utcTimeStr?.split(':')[0], 10));
  utcDate.setUTCMinutes(parseInt(utcTimeStr?.split(':')[1], 10));
  const userDate = new Date(
    utcDate.toLocaleString('en-US', { timeZone: userTimeZone })
  );

  // Get hours and minutes in 12-hour format
  let hours = userDate.getHours();
  const minutes = userDate.getMinutes().toString().padStart(2, '0');

  // Determine if it's AM or PM
  let ampm = hours < 12 ? 'AM' : 'PM';

  // Special case: If the time is 12:00 PM, convert it to "12:00 AM"
  if (hours === 12 && ampm === 'PM') {
    hours = 0;
    ampm = 'AM';
  }

  // Convert midnight (12:00 AM) to "12:00 AM"
  if (hours === 0) {
    hours = 12;
  }

  // Concatenate hours, minutes, and AM/PM to create the formatted time string
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return formattedTime;
}

export function convertUtcToNormalHours(utcTimeStr) {
  const userTimeZone = getUserTimeZone(); // Replace with your actual function to retrieve the user's time zone

  // Parse the input time string in 12-hour format (e.g., '07:00 PM')
  const timeComponents = utcTimeStr.match(/(\d+):(\d+) ([APap][Mm])/);
  if (!timeComponents) {
    throw new Error('Invalid time format');
  }

  let hours = parseInt(timeComponents[1], 10);
  const minutes = parseInt(timeComponents[2], 10);
  const period = timeComponents[3].toLowerCase();

  // Adjust hours for PM (add 12) if necessary
  if (period === 'pm' && hours !== 12) {
    hours += 12;
  } else if (period === 'am' && hours === 12) {
    // Handle 12:00 AM (midnight)
    hours = 0;
  }

  // Create a new Date object for the current date and time in UTC
  const utcDate = new Date();
  utcDate.setUTCHours(hours);
  utcDate.setUTCMinutes(minutes);

  // Convert the UTC time to the user's local time
  const userDate = new Date(
    utcDate.toLocaleString('en-US', { timeZone: userTimeZone })
  );

  // Format the user's local time in 24-hour format
  const formattedTime = userDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  });

  return formattedTime;
}
export function convertUtcToUserTimeZone(utcTimeStr, userTimeZone) {
  // Parse the input time string in 12-hour format (e.g., "2:30 PM")
  const timeComponents = utcTimeStr.match(/(\d+):(\d+) ([APap][Mm])/);
  if (!timeComponents) {
    throw new Error('Invalid time format');
  }

  let hours = parseInt(timeComponents[1], 10);
  const minutes = parseInt(timeComponents[2], 10);
  const period = timeComponents[3].toLowerCase();

  // Adjust hours for PM (add 12) if necessary
  if (period === 'pm' && hours !== 12) {
    hours += 12;
  } else if (period === 'am' && hours === 12) {
    // Handle 12:00 AM (midnight)
    hours = 0;
  }

  // Create a new Date object for the current date and time in UTC
  const utcDate = new Date();
  utcDate.setUTCHours(hours);
  utcDate.setUTCMinutes(minutes);
  utcDate.setSeconds(0); // Set seconds to 0 to avoid potential inconsistencies

  // Convert the UTC time to the user's local time in their specified timezone
  const userDate = new Date(
    utcDate.toLocaleString('en-US', { timeZone: userTimeZone })
  );

  // Format the user's local time in 12-hour format with AM/PM
  const formattedTime = userDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return formattedTime;
}
export function convertDateToUserTimeZone(dateString) {
  // Parse the input date string
  const inputDate = DateTime.fromFormat(dateString, 'M/d/yyyy, h:mm a');

  // Get the user's time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert to the user's time zone
  const userLocalDate = inputDate.setZone(userTimeZone);

  // Format the user's date and time
  const formattedDate = userLocalDate.toFormat('MM/dd/yyyy, hh:mm a');

  return formattedDate;
}
export function convertUtcToUserLocalTime(utcTimeString) {
  // Parse the UTC time string into a Date object
  const utcTime = new Date(utcTimeString);

  // Get the user's time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the UTC time to the user's local time
  const userLocalTime = utcTime.toLocaleString('en-US', {
    timeZone: userTimeZone
  });

  return userLocalTime;
}

export function convertUtcTimeToUserTime(timeString) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get the current date in the user's time zone
  const currentDate = new Date();
  const currentDateString = currentDate.toLocaleDateString('en-US', {
    timeZone: userTimeZone
  });

  // Combine the date and time strings
  const dateTimeString = `${currentDateString} ${timeString}`;

  // Parse the combined date-time string into a JavaScript Date object
  const userLocalTime = new Date(dateTimeString);

  // Format the user's local time in the desired format (12:30 AM)
  const formattedTime = userLocalTime.toLocaleTimeString('en-US', {
    timeZone: userTimeZone,
    hour: '2-digit',
    minute: '2-digit'
  });

  return formattedTime;
}
export function convertDateTimeToUserTime(utcDateTimeString) {
  const formattedInput = utcDateTimeString.replace(/\s+/g, ''); // Remove all spaces
  const adjustedFormat = 'M/d/yyyy,h:mma';

  const utcDate = DateTime.fromFormat(formattedInput, adjustedFormat, {
    zone: 'utc'
  });

  if (!utcDate.isValid) {
    return 'Invalid UTC DateTime'; // Handle invalid input
  }

  // No need to set the time zone to userTimeZone

  // Format the date in the desired format: "Thursday October 05"
  const formattedDateString = utcDate.toFormat('EEEE LLLL dd');

  // Format the time in the desired format: "1:30 AM"
  const formattedTimeString = utcDate.toFormat('h:mm a');

  return {
    formattedDate: formattedDateString,
    formattedTime: formattedTimeString
  };
}
export function convertUtcToUserTimeEdit(utcDateTimeString) {
  // Adjust the format to match your input format (no space after date, space before AM/PM)
  const formattedInput = utcDateTimeString?.replace(/\s+/g, ''); // Remove all spaces
  const adjustedFormat = 'M/d/yyyy,h:mma';

  const utcDate = DateTime.fromFormat(formattedInput, adjustedFormat, {
    zone: 'utc'
  });

  if (!utcDate.isValid) {
    return 'Invalid UTC DateTime'; // Handle invalid input
  }

  // No need to set the time zone to userTimeZone

  // Format the date as '10/05/2023'
  const formattedDate = utcDate.toFormat('MM/dd/yyyy');

  // Format the time as '8:00 PM'
  const formattedTime = utcDate.toFormat('hh:mm a');

  return {
    formattedDate,
    formattedTime
  };
}
export function convertToUserTimezoneAndFormat(utcDatetimeString) {
  const utcDate = new Date(utcDatetimeString);

  // Get the user's time zone
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Adjust the UTC date to the user's local time zone
  const userLocalDate = new Date(
    utcDate.toLocaleString('en-US', { timeZone: userTimezone })
  );

  // Format the user's local date and time in the same format as input
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3,
    timeZoneName: 'short'
  };

  const userLocalDatetimeString = userLocalDate.toLocaleString(
    'en-US',
    options
  );

  return userLocalDatetimeString;
}
export function convertToUTCdate(utcDatetimeString) {
  const utcDate = new Date(utcDatetimeString);

  // Get the user's time zone
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC time to user's local time
  const userLocalDate = new Date(
    utcDate.toLocaleString('en-US', {
      timeZone: userTimezone
    })
  );

  // Format the user's local date and time
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const userLocalDatetimeString = userLocalDate.toLocaleString(
    'en-US',
    options
  );

  return userLocalDatetimeString;
}
export function convertToIsoDateTime(utcDateTimeString) {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [date, time] = utcDateTimeString.split(',');
  const [month, day, year] = date.split('/');
  const [timeValue, amOrPm] = time.split(' ');

  // Convert the month and day to 0-based index
  const monthIndex = parseInt(month, 10) - 1;

  // Create a DateTime object in UTC
  const utcDate = DateTime.utc(
    Number(year),
    monthIndex + 1,
    Number(day),
    Number(timeValue.split(':')[0]) + (amOrPm === 'PM' ? 12 : 0),
    Number(timeValue.split(':')[1])
  );

  // Set the timezone to the user's timezone
  const localDate = utcDate.setZone(userTimezone);

  // Format the localDate to ISO 8601 format without changing the date and time
  const isoDateTime = localDate.toISO();

  return isoDateTime;
}
export function convertToUserTimezoneAndFormats(isoDateTime) {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Parse the input ISO date-time string
  const utcDateTime = DateTime.fromISO(isoDateTime, { zone: 'utc' });

  // Set the timezone to the user's timezone
  const localDateTime = utcDateTime.setZone(userTimezone);

  // Extract individual date and time components
  const year = localDateTime.year;
  const month = localDateTime.month.toString().padStart(2, '0');
  const day = localDateTime.day.toString().padStart(2, '0');
  const hour = localDateTime.hour.toString().padStart(2, '0');
  const minute = localDateTime.minute.toString().padStart(2, '0');
  const second = localDateTime.second.toString().padStart(2, '0');

  // Create a custom ISO 8601 formatted string with 'Z' at the end
  const formattedDateTime = `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`;

  return formattedDateTime;
}

export function convertToSyncfusionFormat(dateTimeString) {
  // Parse the input date and time
  const [dateString, timeString] = dateTimeString.split(', ');

  // Parse the date string into parts (month, day, year)
  const [, month, day, year] = dateString.match(/(\d+)\/(\d+)\/(\d+)/);

  // Parse the time string to get the hours, minutes, and AM/PM marker
  const [, hours, minutes, ampm] = timeString.match(
    /(\d+):(\d+):(\d+) (AM|PM)/
  );

  // Convert hours to 24-hour format
  let hours24 = parseInt(hours);
  if (ampm === 'PM' && hours24 !== 12) {
    hours24 += 12;
  } else if (ampm === 'AM' && hours24 === 12) {
    hours24 = 0;
  }

  // Create a Date object for the given date and time in UTC
  const utcDate = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      hours24,
      parseInt(minutes),
      parseInt(0)
    )
  );

  // Format the date and time in ISO 8601 format
  const isoFormattedDate = utcDate.toISOString();

  return isoFormattedDate;
}

// convert 12 hour time format to 24 hour format

export const convertFrom12To24Format = (time12) => {
  const [sHours, minutes, period] = time12
    .match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/)
    .slice(1);
  const PM = period === 'PM';
  const hours = (+sHours % 12) + (PM ? 12 : 0);

  return `${('0' + hours).slice(-2)}:${minutes}`;
};

// formats date or  time
// export const formatDateTimeCalender = (dateTimeString, format) => {
//   const date = new Date(dateTimeString);

//   if (format === 'date') {
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const day = date.getDate().toString().padStart(2, '0');
//     const year = date.getFullYear();
//     const formattedDate = `${month}-${day}-${year}`;
//     return formattedDate;
//   }

//   if (format === 'time') {
//     const options = { hour: '2-digit', minute: '2-digit', hour12: true };
//     const formattedTime = date.toLocaleTimeString('en-US', options);
//     return formattedTime;
//   }

//   if (format === 'day') {
//     const options = { weekday: 'long' };
//     const formattedTime = date.toLocaleString('en-US', options);
//     return formattedTime;
//   }

//   return null;
// };

export const formatDateTimeCalender = (dateTimeString, format) => {
  const date = new Date(dateTimeString);

  if (format === 'date') {
    const formattedDate = date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
    return formattedDate;
  }

  if (format === 'time') {
    const formattedTime = date.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    return formattedTime;
  }

  if (format === 'day') {
    const formattedDay = date.toLocaleString('en-US', { weekday: 'long' });
    return formattedDay;
  }

  return null;
};

// convert time format 24 to 12 hours

export const convertTo12HourFormat = (time24) => {
  const [hours, minutes] = time24.split(':');

  const time = new Date(0, 0, 0, hours, minutes);

  try {
    let hours12 = time.getHours();

    let amPm = 'AM';

    if (hours12 >= 12) {
      // eslint-disable-next-line operator-assignment

      hours12 %= 12;

      amPm = 'PM';
    }

    if (hours < 10) {
      hours12 %= 12;
    }

    if (hours12 === 0) {
      hours12 = 12; // 12:00 should be 12:00 PM in 12-hour format
    }

    let hours1 = String(hours12).padStart(2, '0');

    let hoursMinute1 = String(minutes).padStart(2, '0');

    return `${hours1}:${hoursMinute1} ${amPm}`;
  } catch (e) {
    return `00:00 AM`;
  }
};

export function limitDecimalDigits(value, maxDigits) {
  const formattedValue = value?.replace(/[^0-9.]/g, '');
  const decimalParts = formattedValue?.split('.');

  if (decimalParts.length > 1) {
    decimalParts[1] = decimalParts[1]?.slice(0, maxDigits);
  }
  return decimalParts?.join('.');
}
export const getFirstDateOfWeek = (currentDate) => {
  const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ... , 6 = Saturday)
  const daysUntilMonday = (dayOfWeek + 6) % 7; // Calculate how many days to subtract to reach Monday

  const firstDate = new Date(currentDate);
  firstDate.setDate(currentDate.getDate() - daysUntilMonday);

  return firstDate;
};

export const getLastDateOfCurrentWeek = (currentDate) => {
  const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ... , 6 = Saturday)

  let daysToAdd;
  if (dayOfWeek === 0) {
    // If the current day is Sunday
    daysToAdd = 0; // No days to add as it's already Sunday
  } else {
    daysToAdd = 7 - dayOfWeek; // Calculate how many days to add to reach Sunday
  }

  const lastDate = new Date(currentDate);
  lastDate.setDate(currentDate.getDate() + daysToAdd);

  return lastDate;
};
