/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-else-return */
/* eslint-disable react/self-closing-comp */
import React, { forwardRef, useState, useEffect } from 'react';
import {
  Slide,
  Typography,
  Button,
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Stack,
  TextField
} from '@mui/material';
import {
  CustomTextField,
  CustomSelect,
  CustomButton,
  Toggle
} from 'src/components/Global';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModeDeleteIcon from '@material-ui/icons/Delete';
import { getLocalData } from 'src/utils';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

import { AddBenefits } from './AddBenefits';
import Loader from 'src/components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  colorSelector: {
    display: 'flex',
    alignItems: 'center'
  },
  colorCircle: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    marginRight: '14px',
    cursor: 'pointer'
  },
  activeColor: {
    border: '3px solid black'
  },
  addButton: {
    width: 32,
    height: 32,
    minWidth: 0,
    alignItems: 'center',
    padding: 0,
    borderRadius: '50%',
    marginRight: '14px'
  },
  addIcon: {
    marginLeft: 0,
    marginRight: 0
  },
  colorPicker: {
    display: 'flex',
    gap: '14px',
    alignItems: 'center'
  },
  colorPickerBtn: {
    padding: '4px',
    letterSpacing: '0.5px',
    fontSize: '14px',
    fontWeight: '500'
  },
  label: {
    fontWeight: '700',
    fontSize: '17px',
    letterSpacing: '0.4px',
    paddingLeft: '8px'
  }
}));

export const AddMembership = (props) => {
  const { isMembershipEdit, membershipDetails, handleResetStates } = props;

  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const classes = useStyles();
  const locationId = getLocalData('locationId');
  const userId = getLocalData('userId');
  // const [btnLoading, setBtnLoading] = useState(false);
  const [preValues, setPreValues] = useState({});
  const [initialValue, setInitialValue] = useState({
    membershipId: '',
    name: '',
    price: 0,

    billingCycle: 'every month',
    status: '',
    description: ''
  });
  // console.log(initialValue, 'initialValue');
  const [isError, setIsError] = useState({
    name: false,
    price: false,
    description: false
  });
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [toggle, setToggle] = useState(
    membershipDetails?.autopay === true
      ? true
      : // eslint-disable-next-line no-unneeded-ternary
      membershipDetails?.autopay === false
      ? false
      : true
  );

  const [confirmEdit, setConfirmEdit] = useState(false);

  const [dialog, setDialog] = useState({
    benefit: false,
    state: false,
    isCreated: false
  });

  const [isLoading, setIsLoading] = useState(false);
  const [benefitsList, setBenefitsList] = useState([]);
  const [isEditBenefitsList, setIsEditBenefitsList] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [benefitError, setbenefitError] = useState('');
  const [benefit, setBenefit] = useState({
    itemType: 'PricingOption',
    itemId: '',
    itemName: '',
    itemCategoryName: '',
    itemCategoryId: '',
    discount: 0,
    variants: []
  });

  const [selectedColor, setSelectedColor] = useState('#ffc10d');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorList, setColorList] = useState([
    '#ffc10d',
    '#9f9f9f',
    '#CD853F',
    '#FF0000',
    '#0000FF',
    '#15d515'
  ]);

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [allProducts, setAllProducts] = useState([]);

  const handleColorChange = (value) => {
    setFieldUpdated(true);
    setSelectedColor(value);
  };

  const handleAddColor = () => {
    setShowColorPicker(true);
  };

  const handleColorPickerClose = () => {
    setShowColorPicker(false);
    if (!colorList.includes(selectedColor)) {
      setColorList((prevColorList) => [...prevColorList, selectedColor]);
    }
  };

  function updateColorList(color) {
    if (color) {
      setColorList((prevList) => {
        if (!prevList.includes(color)) {
          return [...prevList, color];
        }
        return prevList;
      });
      setSelectedColor(color);
    }
  }

  useEffect(() => {
    if (isMembershipEdit && membershipDetails) {
      let data = {
        membershipId: encodeURIComponent(membershipDetails?.membershipId),
        name: membershipDetails?.name,
        price: membershipDetails.price,
        // autoPay: membershipDetails.autoPay,
        billingCycle: membershipDetails.billingCycle,
        status: membershipDetails.status,
        description: membershipDetails.description
      };

      setBenefitsList(membershipDetails.benefits);
      updateColorList(membershipDetails?.label);
      setToggle(membershipDetails.autopay);
      setInitialValue(data);
      setPreValues(data);
      // console.log(membershipDetails);
    }
  }, [membershipDetails]);

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleMembershipInfo = (type, value) => {
    console.log(type, value, 'valuevaluesdhgs');
    if (isMembershipEdit) {
      setFieldUpdated(true);
    }

    if (type === 'autoPay') {
      setToggle(value);
    }
    if (type === 'price') {
      const pattern = /^\d{0,4}(\.\d{0,2})?$/;
      if (pattern.test(value)) {
        setInitialValue({ ...initialValue, [type]: value });
        setIsError({ ...isError, [type]: false });
      }
    }
    if (type === 'status') {
      setInitialValue({ ...initialValue, status: value });
      setIsError({ ...isError, [type]: false });
    }
    if (type === 'name') {
      setInitialValue({ ...initialValue, [type]: value });
      setIsError({ ...isError, [type]: false });
    }
    if (type === 'description') {
      setInitialValue({ ...initialValue, description: value });
      setIsError({ ...isError, [type]: false });
    }
    if (type === 'billingCycle') {
      setInitialValue({ ...initialValue, billingCycle: value });
    }
  };

  console.log(toggle, 'ininininini');

  const valuesAreDifferent = () => {
    if (!isMembershipEdit) {
      // eslint-disable-next-line no-unused-vars
      return Object.entries(initialValue).some(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0; // Consider non-empty arrays as different
        } else {
          return value !== ''; // Consider non-empty strings as different
        }
      });
    } else {
      const keys = Object.keys(initialValue);
      return (
        keys.some((key) => initialValue[key] !== preValues[key]) ||
        benefitsList.length >= 0
      );
    }
  };

  const handleCloseScreen = () => {
    if (valuesAreDifferent()) {
      setDialog({ ...dialog, state: true });
    } else {
      handleResetStates();
      setDialog({
        benefit: false,
        state: false,
        isCreated: false
      });
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        isCreated: false,
        state: false
      });
    } else if (value === 'Discard') {
      handleResetStates();
      setDialog({
        benefit: false,
        state: false,
        isCreated: false
      });
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
  };

  const handleEditDialog = (value) => {
    if (value === 'Continue') {
      handleUpdateMembership();
    } else if (value === 'Discard') {
      setConfirmEdit(false);
    } else if (value === 'Cancel') {
      setConfirmEdit(false);
    }
  };

  const editBenefit = (index) => {
    let item = benefitsList[index];
    setSelectedIndex(index);
    setFieldUpdated(true);
    setIsEditBenefitsList(true);
    setBenefit(item);
    setDialog({ ...dialog, benefit: true });
  };

  const deleteBenefit = (index) => {
    let updatedBenefitsList = [...benefitsList];
    let deletedItem = updatedBenefitsList.splice(index, 1)[0];

    setFieldUpdated(true);
    setSelectedIndex(index);
    setBenefitsList(deletedItem);
    setBenefitsList(updatedBenefitsList);
  };

  function getBenefitTitle(index) {
    let item = benefitsList[index];
    const { itemType, itemCategoryName, itemName, discount, itemId } = item;

    let variantsArr;
    const matchingProduct = allProducts?.find(
      (product) => product?.sortKey === itemId
    );
    if (matchingProduct) variantsArr = matchingProduct?.variants || [];
    const variantsRendered = variantsArr
      ?.map((item) => item?.value)
      ?.filter(Boolean)
      ?.join(', ');
    const finalResult = variantsRendered ? `(${variantsRendered})` : '';

    console.log('item-->', item);
    if (itemType === 'PricingOption') {
      // return `Pricing option '${(pricingOptionName || pricingOption.name)}' for service '${itemName}'`;
      let benifitStr = '';
      if (item?.itemId === 'applyToAllPricingOption') {
        benifitStr = `${discount}% discount on ${itemName}`;
      } else {
        benifitStr = `${discount}% discount on ${itemName} - up to ${
          item.numberOfSessions ?? 0
        } Sessions`;
      }
      return benifitStr;
    } else if (itemType === 'Product') {
      let benifitStr = '';
      // eslint-disable-next-line no-unneeded-ternary
      // return `${discount}% discount on '${itemName ? itemName : 'all products'}' in category '${itemCategoryName}'`;
      if (item?.itemId === 'applyToAllProduct') {
        benifitStr = `${discount}% discount on ${itemName}`;
      } else {
        benifitStr = `${discount}% discount on '${itemName || 'all product'} ${
          variantsArr && finalResult ? finalResult : ''
        }' in category '${itemCategoryName}'`;
      }
      return benifitStr;
    }
    return '';
  }

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = ['name', 'description'];
    for (const key of alwaysCheck) {
      const value = initialValue[key];
      const isEmpty =
        value === '' || (Array.isArray(value) && value.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }
    if (benefitsList?.length === 0) {
      setbenefitError('Benefits is required');
    }
    setIsError(updatedErrors);
    return hasError;
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();

    if (!checkForEmptyValues()) {
      if (benefitsList?.length > 0) {
        // alert('form submitted!');
        if (isMembershipEdit) {
          if (fieldUpdated) {
            setConfirmEdit(true);
          }
        } else {
          handleAddMembership();
        }
      } else {
        // setShowMessage(true);
        setbenefitError('Benefits is required');
      }
    } else {
      setShowMessage(true);
      setErrorMessage('Please fill all the required fields');
      // toast.error('Please fill all the required fields');
    }
  };

  const getAllProducts = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    try {
      const productsResponse = await ApiService.get(
        `${BASE_URL}memberships/getProductByCategory?locationId=${encodedLocationId}&status=1&isDiscontinued=false`
      );

      if (!productsResponse?.data?.error) {
        setAllProducts(productsResponse?.data?.Items);
      } else {
        setShowMessage(true);
        setErrorMessage(
          productsResponse.message || 'Something went wrong with products'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const handleAddMembership = async () => {
    setIsLoading(true);
    const data = {
      createdBy: userId,
      name: initialValue.name,
      description: initialValue.description,
      // status: true,
      label: selectedColor,
      price: initialValue?.price > 0 ? initialValue?.price : 0,
      billingCycle: initialValue.billingCycle,
      autopay: toggle,
      benefits: benefitsList,
      locationId: locationId
    };

    try {
      const response = await ApiService.post(
        `${BASE_URL}memberships/addMembership`,
        data
      );

      if (!response.data?.error) {
        setIsLoading(false);
        setInitialValue({
          ...initialValue,
          serviceId: encodeURIComponent(response.data?.data?.membershipId)
        });

        setDialog({
          isCreated: true,
          state: true
        });
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setErrorMessage(
          response.data?.message || 'Something went wrong with add Membership'
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setShowMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  const handleUpdateMembership = async () => {
    setIsLoading(true);

    const updatedBenefitsList = benefitsList?.map((benefit) => {
      if (benefit?.itemType === 'Product') {
        const matchingProduct = allProducts?.find(
          (product) => product?.sortKey === benefit?.itemId
        );
        if (matchingProduct) {
          return {
            ...benefit,
            variants: matchingProduct?.variants || [] // You might want to handle the case when variants is undefined
          };
        }
      }
      return benefit;
    });
    // Not require at the moment but we will see if any issue comes
    // .map((benefit) => {
    //   // Create a copy of the benefit object with pricingOptionId and pricingOptionName removed
    //   if (benefit.itemType === 'PricingOption') {
    //     const {
    //       discount,
    //       pricingOptionId,
    //       pricingOptionName,
    //       product,
    //       ...remainingBenefit
    //     } = benefit;
    //     return remainingBenefit;
    //   } else {
    //     const {
    //       pricingOption,
    //       pricingOptionId,
    //       pricingOptionName,
    //       product,
    //       ...remainingBenefit
    //     } = benefit;
    //     return remainingBenefit;
    //   }
    //   // Return the modified benefit object
    // });
    console.log('benefitsList--321456987-->', updatedBenefitsList);
    const data = {
      // locationId: 'LOC#227a3546-10a3-446b-ba5f-0971212d47bf',
      // locationId: locationId,
      name: initialValue?.name,
      description: initialValue?.description,

      // status: true,
      status: initialValue?.status,
      label: selectedColor,
      price: initialValue?.price > 0 ? initialValue?.price : 0,
      billingCycle: initialValue?.billingCycle,
      autopay: toggle,
      benefits: updatedBenefitsList,
      updatedBy: userId,
      locationId: locationId
    };

    try {
      // const encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.put(
        `${BASE_URL}memberships/updateMembership?membershipId=${initialValue.membershipId}`,
        data
      );
      if (!response.data?.error) {
        setIsLoading(false);
        // setShowMessage(true);
        // setErrorMessage(response.data?.message)
        // toast.success(response.data?.message);
        setDialog({
          isCreated: true,
          state: true
        });
        setFieldUpdated(false);
        setConfirmEdit(false);
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setErrorMessage(
          response.data?.message || 'Something went wrong with update Service'
        );
      }
    } catch (error) {
      setIsLoading(false);
      setConfirmEdit(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <div className="service-container membership-container">
        {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        backgroundColor="#d1dced"
        height="50px"
      >
        <div className="container summary-close-btn">
          <Button
            className="details-remove-icon p-0 no-hover"
            onClick={() => handleCloseScreen()}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="13" cy="13" r="13" fill="white" />
              <path
                d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                fill="#003087"
              />
            </svg>
          </Button>
        </div>
      </Box> */}

        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <form className="custom-form" onSubmit={handleFormSubmission}>
            {/* <Grid mt={1} marginBottom="20px">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <h1 className="title mb-0 mt-10 primary-color">
                    {isMembershipEdit ? 'Edit Membership' : 'Create Membership'}
                  </h1>
                </Box>
              </Grid>

              <Grid item>
                <CustomButton
                  variant="contained"
                  loading={btnLoading}
                  onClick={() =>
                    document.getElementById('membership-save').click()
                  }
                >
                  Save
                </CustomButton>
                <button hidden type="submit" id="membership-save">
                  save
                </button>
              </Grid>
            </Grid>
          </Grid> */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    onClick={() => handleCloseScreen()}
                    className="back-arrow-close"
                  >
                    <ArrowLeft />
                  </Box>

                  <Typography className="header-blue-32">
                    {isMembershipEdit ? 'Edit Membership' : 'Create Membership'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item style={{ marginTop: '15px' }}>
                <CustomButton
                  variant="contained"
                  // loading={btnLoading}
                  onClick={() =>
                    document.getElementById('membership-save').click()
                  }
                >
                  {isMembershipEdit ? 'Update' : 'Save'}
                </CustomButton>
                <button hidden type="submit" id="membership-save">
                  save
                </button>
              </Grid>
            </Grid>

            <Grid container spacing={2} columns={12} mt={1}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  type="text"
                  name="name"
                  label="TITLE"
                  fullWidth
                  required
                  error={isError.name}
                  className="primary-color"
                  helperText={isError.name && 'Title is required'}
                  value={initialValue.name}
                  onChange={handleMembershipInfo}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <Grid container> */}
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    multiline
                    id="my-textarea"
                    name="description"
                    autoComplete="DESCRIPTION"
                    label="DESCRIPTION"
                    placeholder="Description here . . . "
                    variant="outlined"
                    helperText={
                      isError.description && 'Description is required'
                    }
                    value={initialValue.description}
                    onChange={(e) => {
                      handleMembershipInfo('description', e.target.value);
                    }}
                    error={isError.description}
                    className="location-description field-top-margin-edit-location required-field"
                    // error={Boolean(isError.description)}
                    // helperText={productDescriptionError}
                    // onChange={(e) =>
                    //     productDescriptionSet(e.target.value)
                    // }
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                    InputProps={{ rows: 6 }}
                  />
                </Grid>
                {/* </Grid> */}
              </Grid>
              {isMembershipEdit && (
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    fullWidth
                    name="status"
                    value={initialValue.status}
                    onChange={handleMembershipInfo}
                    label="STATUS"
                    options={[
                      {
                        label: 'Active',
                        value: 1
                      },
                      {
                        label: 'Inactive',
                        value: 0
                      }
                    ]}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} columns={12} mt={3} alignItems="center">
              <Grid item>
                <Typography
                  variant="variant6"
                  className={`primary-color ${classes.label}`}
                >
                  Label
                </Typography>
              </Grid>
              <Grid item>
                <div className={classes.colorSelector}>
                  {colorList.map((color, index) => (
                    <div
                      key={index}
                      className={`${classes.colorCircle} ${
                        selectedColor === color ? classes.activeColor : ''
                      }`}
                      style={{ backgroundColor: color }}
                      onClick={() => handleColorChange(color)}
                    ></div>
                  ))}

                  <Button
                    className={classes.addButton}
                    variant="contained"
                    color="primary"
                    onClick={handleAddColor}
                  >
                    <AddIcon className={classes.addIcon} />
                  </Button>

                  {showColorPicker && (
                    <div className={classes.colorPicker}>
                      <input
                        type="color"
                        value={selectedColor}
                        onChange={(e) => handleColorChange(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleColorPickerClose}
                        className={classes.colorPickerBtn}
                      >
                        Add
                      </Button>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} columns={12} mt={3} alignItems="center">
              <Grid item xs={12} sm={3} md={3} lg={4}>
                <CustomTextField
                  type="test"
                  name="price"
                  label="PRICING ($)"
                  fullWidth
                  //  required
                  // min={0}
                  //  error={isError.price}
                  //  helperText={isError.price && 'Pricing is required'}
                  value={initialValue.price}
                  onChange={handleMembershipInfo}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                display={{ sm: 'flex' }}
                justifyContent={{ sm: 'flex-end' }}
              >
                {/* <Grid
                item
                xs={12}
                sm={3}
                md={3}
                lg={4}
                className="toggle-label-black toggle-label-14"
              >
                <Toggle
                  name="free"
                  label="free"
                  labelPlacement="start"
                  value={initialValue.free}
                  onChange={handleMembershipInfo}
                />
              </Grid> */}
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 32,
                        color: '#003087'
                      },
                      '& .MuiFormControlLabel-label': {
                        color: '#000000',
                        fontWeight: '600',
                        letterSpacing: '0.5px'
                      },
                      '& .MuiFormControlLabel-root': {
                        marginRight: '40px'
                      }
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Per Month"
                      checked={initialValue.billingCycle === 'every month'}
                      onChange={() =>
                        handleMembershipInfo('billingCycle', 'every month')
                      }
                      //  disabled={!toggle}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Per Year"
                      checked={initialValue.billingCycle === 'every year'}
                      onChange={() =>
                        handleMembershipInfo('billingCycle', 'every year')
                      }
                      //  disabled={!toggle}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                lg={4}
                className="toggle-label-black toggle-label-14"
              >
                <Toggle
                  name="autoPay"
                  label="Auto-Pay"
                  labelPlacement="start"
                  value={toggle}
                  onChange={handleMembershipInfo}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} columns={12} mt={4}>
              <Grid item xs={12}>
                <div className="benefit-section">
                  <Typography
                    variant="variant6"
                    className="benefit-heading primary-color"
                  >
                    Benefits*
                  </Typography>

                  <div
                    className={`benefit-list ${
                      benefitsList?.length > 3 && 'more-benefits'
                    }`}
                  >
                    {benefitsList?.length ? (
                      <Stack
                        spacing={{ xs: 1.5 }}
                        direction="column"
                        width="100%"
                        alignItems="center"
                      >
                        {benefitsList?.map((item, index) => (
                          <Stack
                            spacing={{ xs: 1, sm: 2 }}
                            direction="row"
                            useFlexGap
                            flexWrap="wrap"
                            justifyContent="space-between"
                            width="100%"
                            paddingRight="60px"
                            alignItems="center"
                          >
                            <Typography
                              variant="variant6"
                              sx={{ color: '#000' }}
                            >
                              {getBenefitTitle(index)}
                            </Typography>
                            <Box>
                              <Button
                                className={classes.addButton}
                                variant="contained"
                                color="primary"
                                onClick={() => editBenefit(index)}
                              >
                                <ModeEditIcon className={classes.addIcon} />
                              </Button>
                              <Button
                                className={classes.addButton}
                                variant="contained"
                                color="primary"
                                onClick={() => deleteBenefit(index)}
                              >
                                <ModeDeleteIcon className={classes.addIcon} />
                              </Button>
                            </Box>
                          </Stack>
                        ))}
                      </Stack>
                    ) : (
                      // 'Sample Benefits'
                      'No Benefits Added Yet'
                    )}
                  </div>

                  <div
                    className={`add-btn ${
                      benefitsList?.length ? '' : 'no-benefit'
                    }`}
                  >
                    <Button
                      className={classes.addButton}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setDialog({ ...dialog, benefit: true });
                        setIsEditBenefitsList(false);
                      }}
                    >
                      <AddIcon className={classes.addIcon} />
                    </Button>
                  </div>
                </div>
                <Typography sx={{ color: '#d13a00' }}>
                  {benefitError}
                </Typography>
              </Grid>
            </Grid>
          </form>

          <Dialog
            open={dialog.state}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleDialog('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              {dialog.isCreated ? '' : 'Discard Changes?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                  {!dialog.isCreated ? (
                    <Typography className="title-text-sub">
                      You have unsaved changes.
                      <Typography className="title-text-sub">
                        What do you want to do?
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography className="title-text-sub">
                      {`Membership ${
                        isMembershipEdit ? 'details has been' : 'has been'
                      } ${
                        isMembershipEdit ? 'updated' : 'created '
                      } successfully! `}
                    </Typography>
                  )}
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleDialog(dialog.isCreated ? 'Discard' : 'Discard')
                }
              >
                {dialog.isCreated ? 'Close' : 'Discard Changes'}
              </Button>
              {!dialog.isCreated && <Divider className="vertical-divider" />}
              {!dialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleDialog('Continue')}
                >
                  Go Back to Editing
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            open={confirmEdit}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleEditDialog('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              Save Changes
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                  <Typography className="title-text-sub">
                    Please confirm that you want to save your changes?
                  </Typography>
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleEditDialog(dialog.isCreated ? 'Discard' : 'Discard')
                }
              >
                Go Back to Editing
              </Button>
              {!dialog.isCreated && <Divider className="vertical-divider" />}
              {!dialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleEditDialog('Continue')}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </Dialog>
          <AddBenefits
            dialog={dialog}
            setDialog={setDialog}
            benefit={benefit}
            setBenefit={setBenefit}
            benefitsList={benefitsList}
            setBenefitsList={setBenefitsList}
            isEdit={isEditBenefitsList}
            setFieldUpdated={setFieldUpdated}
            selectedIndex={selectedIndex}
            setbenefitError={setbenefitError}
          />
        </Box>
      </div>
    </>
  );
};
