import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  MenuItem,
  Avatar,
  InputLabel,
  FormControl,
  Select,
  Slide
} from '@mui/material';
import { getLocalData } from '../../../utils'; // setFullLocationId
// import AddchartIcon from '@mui/icons-material/Addchart';
// import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import Loader from '../../../components/Loader';
import BundleTable from './BundlesTable';
import MembershipTable from './MembershipTable';
// import Header from '../../../components/Header';
import ContractTable from './ContractTable';
import { ApiService } from '../../../services/ApiService';
import { PictureSnapshot } from 'src/assets/SVG/SvgIcons';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import heic2any from 'heic2any';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CreateFeaturedItem = ({ setShowScreen }) => {
  const [open, setOpen] = React.useState(false);
  const [imgUrl, setimgUrl] = useState('');
  const [showData, setShowData] = useState('');
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectItem, setSelectItem] = useState('');
  const [bundlesList, setbundlesList] = useState([]);
  const [locationData, setLocationData] = useState([]);
  // const [locationId, setLocationId] = useState([]);
  const [imgObjClient, setimgObjClient] = useState([]);
  // const [imgUrlClient, setimgUrlClient] = useState('');
  // const [imgObj, setimgObj] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [titleError, setTitleError] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [descriptionError, setDescriptionError] = useState('');
  const [selectItemError, setSelectItemError] = useState('');
  const [locationIdError, setlocationIdError] = useState('');
  // const [selectedBundle, setSelectedBundle] = useState(null);
  const [membershipList, setmembershipList] = useState('');
  const [contractList, setcontractList] = useState([]);
  const [imageSelected, setImageSelected] = useState(false);
  const [isLocationid, setLocationId] = useState('');
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Add New Featured Item');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  // const [selectFeaturedItemError, setSelectFeaturedItemError] = useState('');
  // let itemValue = {};
  const [itemValue, setitemValue] = useState({});
  const [imageError, setImageError] = useState(false);
  const [imageErrorMsg, setImageErrorMsg] = useState('');
  const [filtersParams, setFiltersParams] = useState(''); // filtersParams
  const [filtersMemberParams, setFiltersMemberParams] = useState(''); // filtersParams
  const [filtersContractParams, setFiltersContractParams] = useState(''); // filtersParams
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  // const [storedNames, setStoredNames] = useState([]);
  // const [itemData,setitemData] = useState({
  //   name:'',
  //   price:'',
  //   id:''
  // });
  //   console.log(selectedBundle);
  // const handleEditClientClose = () => {
  //   setIsUpdated(false);
  //   if (isFieldUpdated) {
  //     setOpen(true);
  //   } else {
  //     setShowScreen(0)
  //     clear();
  //   }
  // }
  console.log(isFieldUpdated);
  let userId = getLocalData('userId');

  const handleClosePopup = (val) => {
    if (val === 'Save') {
      setOpen(false);
    } else if (val === 'Discard') {
      setOpen(false);
      setShowScreen(0);
    }
  };
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');

  //  console.log(franchiseeId, "franchiseeid")
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'title') {
      if (value === '') {
        setTitle('');
        setTitleError('Title is required');
      } else {
        setTitleError('');
        setTitle(value);
        setIsFieldUpdated(true);
      }
    }
    if (name === 'description') {
      if (value === '') {
        setDescription('');
        setDescriptionError('Description is required');
      } else {
        setDescriptionError('');
        setDescription(value);
        setIsFieldUpdated(true);
      }
    }
    if (name === 'locationId') {
      if (value === '') {
        setLocationId('');
        setlocationIdError('Location is required');
      } else {
        setlocationIdError('');
        setLocationId(value);
        setIsFieldUpdated(true);
      }
    }
    if (name === 'selectItem') {
      if (value === '') {
        // setSelectItem('');
        setSelectItemError('Select item is required');
      } else {
        setSelectItemError('');
        setSelectItem(value);
        setIsFieldUpdated(true);
      }
    }
  };
  const handleLocationChange = (e) => {
    setLocationId(e);
    setlocationIdError('');
    setIsFieldUpdated(true);
  };

  const getBundle = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllBundles?locationId=${encodedLocationId}${filtersParams}`
      );
      setbundlesList(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };
  const getMembership = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllMemberships?locationId=${encodedLocationId}${filtersMemberParams}`
      );
      setmembershipList(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };

  const getContract = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllContracts?locationId=${encodedLocationId}${filtersContractParams}&sellOnline=true`
      );
      const sellOnlineList = response?.data?.Items?.filter(
        (item) => item?.sellOnline
      );
      setcontractList(sellOnlineList);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocation = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllLocations?franchiseeId=${encodedFranchiseeId}`
      );
      setLocationData(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };
  // const clear = () => {
  //   setTitle('')
  //   setDescription('')
  //   setLocationId('')
  //   setSelectItem('')
  // }

  useEffect(() => {
    // clear();
    getBundle();
    getContract();
    getMembership();
    getLocation();
  }, []); // selectItem

  useEffect(() => {
    getBundle();
  }, [filtersParams]);

  useEffect(() => {
    getMembership();
  }, [filtersMemberParams]);

  useEffect(() => {
    getContract();
  }, [filtersContractParams]);

  // Set the locationId from local storage
  useEffect(() => {
    if (locationId) setLocationId(locationId);
  }, [locationId]);

  const SelectBundle = () => {
    setShowData('Bundles');
  };
  const SelectMembership = () => {
    setShowData('Memberships');
  };
  const SelectContract = () => {
    setShowData('Contracts');
  };

  const confirmAddFeaturedItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(selectItem, 'selectItem');
    let itemValue = {};

    if (showData === 'Bundles') {
      // itemValue.bundleName = selectItem.bundleName;
      // itemValue.price = selectItem.bundlePrice;
      itemValue.id = selectItem?.sortKey;

      setitemValue(itemValue);
    } else if (showData === 'Memberships') {
      // itemValue.name = selectItem.name;
      // itemValue.discount = selectItem.discount;
      // itemValue.price = selectItem.price;
      itemValue.id = selectItem?.sortKey;
      setitemValue(itemValue);
    } else if (showData === 'Contracts') {
      // itemValue.name = selectItem.contractName;
      //  itemValue.discount = selectItem.discount;
      // itemValue.price = selectItem.price;
      itemValue.id = selectItem?.sortKey;
      setitemValue(itemValue);
    }

    if (!title) {
      setTitleError('Title is required');
    }
    if (!description) {
      setDescriptionError('Description is required');
    }
    if (!isLocationid) {
      setlocationIdError('Location is required');
    }
    if (!selectItem) {
      setSelectItemError('Select item is required');
    }
    if (!itemValue.id && showData) {
      setShowMessage(true);
      setErrorMessage(
        `Select at least one item from ${
          selectItem ? selectItem.toLowerCase() : ''
        }`
      );
      // setSelectFeaturedItemError(`Select at least one item from ${selectItem ? selectItem.toLowerCase() : ''}`);
    }
    let data = imgObjClient?.name ? Object.keys(imgObjClient.name).length : 0;
    if (imgObjClient.length === 0) {
      setImageError(true);
      setImageErrorMsg('Image is required');
    }

    // selecte atleast one item

    // } else {
    //   setSelectFeaturedItemError('');
    // }

    console.log(itemValue, 'itemValue', imgObjClient.length, data);
    if (
      title &&
      description &&
      isLocationid &&
      selectItem &&
      itemValue.id &&
      data > 0
    ) {
      // setOpenConfirmation(true);
      addFeaturedItem([itemValue]);
    }
  };
  // console.log(itemId, '------id')
  const addFeaturedItem = async (itemValue) => {
    // debuggerisLocationid
    setIsLoading(true);
    // let fullLocId = setFullLocationId(getLocalData('locationId'));
    let locId;
    if (isLocationid) locId = isLocationid;
    else {
      let fullLocId = getLocalData('locationId');
      locId = fullLocId;
    }

    const formData = new FormData();
    formData.append('featuredImage', imgObjClient);
    formData.append('title', title);
    formData.append('description', description);

    // Make sure `showData` has a valid value before appending it
    if (showData) {
      formData.append('itemType', showData);
    }

    formData.append('itemValue', JSON.stringify(itemValue));
    formData.append('locationId', locId);
    // formData.append('locationId', fullLocId);
    formData.append('createdBy', userId);
    // setIsLoading(true);

    try {
      let data = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}featured-items/createFeaturedItem`,
        formData
      );

      if (data.statusCode === 200) {
        // Success logic
        setOpen(true);
        setIsUpdated(true);
        setIsLoading(false);
      } else {
        // Error logic
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  /* const handleImageUploadfeatured = (e) => {
    setimgObjClient(e.target.files[0]);
    setimgUrl(URL.createObjectURL(e.target.files[0]));
    setImageSelected(true);
    setIsFieldUpdated(true);
    setImageError(false);
    setImageErrorMsg('');
  }; */
  // const allowedExtensions = ['jpg', 'jpeg', 'png', 'heic'];

  // const handleImageUploadfeatured = async (e) => {
  //   const selectedFile = e.target.files[0];

  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

  //     if (allowedExtensions.includes(fileExtension)) {
  //       if (fileExtension === 'heic') {
  //         try {
  //           const jpegBlob = await heic2any({
  //             blob: selectedFile,
  //             toType: 'image/jpeg'
  //           });

  //           setimgObjClient(jpegBlob);
  //           setimgUrl(URL.createObjectURL(jpegBlob));
  //           setImageSelected(true);
  //           setIsFieldUpdated(true);
  //           setImageError(false);
  //           setImageErrorMsg('');
  //         } catch (error) {
  //           setImageError(true);
  //           setImageErrorMsg('Error converting HEIC file to JPEG.');
  //         }
  //       } else {
  //         // For allowed extensions other than HEIC, no conversion is needed.
  //         setimgObjClient(selectedFile);
  //         setimgUrl(URL.createObjectURL(selectedFile));
  //         setImageSelected(true);
  //         setIsFieldUpdated(true);
  //         setImageError(false);
  //         setImageErrorMsg('');
  //       }

  //       // Set the name of the image
  //       setimgObjClient({ ...imgObjClient, name: selectedFile.name });
  //     } else {
  //       setImageError(true);
  //       setImageErrorMsg(
  //         'Invalid file format. Please select a jpg, jpeg, png, or heic file.'
  //       );
  //     }
  //   }
  // };

  const handleImageUploadfeatured = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();
      setImageSelected(true);

      console.log(file, 'filefile');

      // Check if the file size is greater than 5 MB
      if (file.size > 5 * 1024 * 1024) {
        // 5 MB in bytes
        setImageError(true);
        setimgUrl('');
        setimgObjClient([]);
        setImageErrorMsg('Image size exceeds 5 MB limit.');
        return; // Exit function
      }

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setimgUrl(URL.createObjectURL(file));
        setImageSelected(true);
        setimgObjClient(file);
        setImageError(false);
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setimgUrl(URL.createObjectURL(jpegFile));
          setImageSelected(true);
          setimgObjClient(jpegFile);
          setImageError(false);
        } catch (error) {
          console.error('Error handling HEIC file:', error);
          setImageError(true);
          setimgUrl('');
          setimgObjClient([]);
          setImageErrorMsg('Error handling HEIC file.');
        }
      } else {
        setImageError(true);
        setimgUrl('');
        setimgObjClient([]);
        setImageErrorMsg(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
  };

  console.log(imgUrl, 'imgObjClientimgObjClient');

  const handleCloseDetails = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
    }
  };
  // Handle save popup
  const handleConfirmPopup = (val) => {
    console.log(itemValue, 'itemValue4444');
    if (val === 'Save') {
      setOpenConfirmation(false);
      addFeaturedItem([itemValue]);
    } else {
      setOpenConfirmation(false);
    }
  };

  // Cross icon handler
  const removeImage = () => {
    // const updatedImages = newImages.filter((image) => image.id !== idToRemove);
    // setNewImages(updatedImages);
    setImageSelected(false);
    setimgUrl('');
    setimgObjClient([]);
    setImageError(true);
    setImageErrorMsg('Image is required');
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Box
        component="form"
        className="common-form-container"
        sx={{ margin: 'auto', padding: '0 2rem', mt: 1 }}
        onSubmit={(e) => confirmAddFeaturedItem(e)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"
              >
                {/* left arrow icon */}
                <ArrowLeft sx={{ mt: 3 }} />
              </Box>
              <Typography className="header-blue-32">
                Add New Featured Item
              </Typography>
              {/* <Header title="Add New Featured Item" /> */}
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Button
                variant="contained"
                type="submit"
                className="common-button"
              >
                <Typography>Save</Typography>
              </Button>
            </Box>
          </Grid>
          {/* <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}> */}
          {/* Edit Button */}
          {/* <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                className="btn-primary featured-btn"
                type='submit'
              >
                Save
              </Button>

            </Box>

          </Grid> */}
        </Grid>

        <Grid className="feature-add-adjust">
          <Grid container spacing={4} columns={12}>
            <Grid item xs={12} sm={6} mt={2}>
              <TextField
                fullWidth
                label="TITLE"
                name="title"
                onChange={handleInputChange}
                className="featured-grid-item required-field input-field-styling"
                helperText={titleError}
                value={title}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={2}>
              <TextField
                fullWidth
                label="DESCRIPTION"
                multiline
                onChange={handleInputChange}
                helperText={descriptionError}
                value={description}
                className="featured-grid-item required-field"
                rows="7"
                type="text"
                name="description"
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
              />
            </Grid>
          </Grid>

          <Grid
            className="feature-add-adjust"
            container
            spacing={4}
            columns={12}
            mt={-10}
          >
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  SELECT ITEM TO INCLUDE IN FEATURED
                </InputLabel>
                <Select
                  onChange={handleInputChange}
                  className="input-field-styling"
                  label="SELECT ITEM TO INCLUDE IN FEATURED"
                  name="selectItem"
                >
                  <MenuItem key={1} onClick={SelectBundle} value="Bundle">
                    Bundle
                  </MenuItem>
                  <MenuItem
                    key={2}
                    onClick={SelectMembership}
                    value="Membership"
                  >
                    Membership
                  </MenuItem>
                  <MenuItem key={3} onClick={SelectContract} value="Contract">
                    Contract
                  </MenuItem>
                </Select>
                {selectItemError && (
                  <FormHelperText>{selectItemError}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            className="feature-add-adjust"
            container
            spacing={4}
            columns={12}
            mt={1}
          >
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                name="location"
                className="select-drop-down-label-styling required-field"
                disabled
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  LOCATION
                </InputLabel>
                <Select
                  // labelId="demo-simple-select-helper-label"
                  // id="demo-simple-select-helper"
                  value={isLocationid}
                  label="LOCATION"
                  name="locationId"
                  className="input-field-styling required-field"
                  onChange={(e) => handleLocationChange(e.target.value)}
                >
                  {locationData.map((r, i) => (
                    <MenuItem key={i} value={r.locationId}>
                      {r.locationName}
                    </MenuItem>
                  ))}
                </Select>
                {locationIdError && (
                  <FormHelperText>Please select location</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={10} md={10}>
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              className={`${imageSelected ? null : 'mt-40'}`}
            >
              {imageSelected ? (
                <Box className="relative">
                  <Avatar
                    className="image-avatar"
                    src={imgUrl !== '' ? imgUrl : null}
                    sx={{
                      marginTop: '40px',
                      height: '165px',
                      width: '165px',
                      borderRadius: 'none',
                      marginRight: '30px'
                    }}
                  />
                  <div
                    className="cross-imageicon text-blue"
                    role="button"
                    onClick={() => removeImage()}
                    onKeyDown={() => removeImage()}
                    tabIndex={0}
                  >
                    x
                  </div>
                </Box>
              ) : (
                // <div className="ellipes d-flex justify-content-center align-items-center">
                //   <Grid className="image-tag text-primary">
                //     <AddchartIcon className='font-35' />
                //   </Grid>
                // </div>
                <PictureSnapshot />
              )}

              {/* marginTop: '40px', */}
              <Box
                // className={`${imageSelected ? 'mt-40' : null} select-image`}
                className={`required-field ${
                  imageSelected ? 'mt-40' : null
                } select-image`}
                sx={{
                  marginLeft: '50px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {/* <Typography className="add-picture-text weight-700 text-black font-16"> */}
                <Typography className="add-picture-text weight-700 text-black font-16 required-label">
                  Upload Featured Image
                </Typography>
                <input
                  accept="image/*"
                  multiple
                  type="file"
                  id="select-file"
                  name="profileImage"
                  style={{ display: 'none' }}
                  onChange={(event) => handleImageUploadfeatured(event)}
                />
                <label
                  htmlFor="select-file"
                  style={{ width: '85px', marginTop: '24px' }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    component="span"
                    className="upload-image image-with-txt common-button"
                    sx={{ marginTop: '0px !important' }}
                  >
                    <Typography className="upload-text">Upload</Typography>
                  </Button>
                </label>
              </Box>
            </Box>
            {imageError && <FormHelperText>{imageErrorMsg}</FormHelperText>}
          </Grid>

          {/* {selectFeaturedItemError && (
            <FormHelperText className='select-item-error'>
              {selectFeaturedItemError}
            </FormHelperText>
          )} */}
          {showMessage && (
            <ErrorMessage
              message={errorMessage}
              setShowMessage={setShowMessage}
            />
          )}
          <Grid className="mt-20">
            {showData === 'Bundles' && (
              <BundleTable
                setSelectItem={setSelectItem}
                bundlesList={bundlesList}
                getBundle={getBundle}
                setFiltersParams={setFiltersParams}
              />
            )}
            {showData === 'Memberships' && (
              <MembershipTable
                setSelectItem={setSelectItem}
                membershipList={membershipList}
                getMembership={getMembership}
                setFiltersMemberParams={setFiltersMemberParams}
              />
            )}
            {showData === 'Contracts' && (
              <ContractTable
                setSelectItem={setSelectItem}
                contractList={contractList}
                getContract={getContract}
                setFiltersContractParams={setFiltersContractParams}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Featured Item has been created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClosePopup('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmPopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You are about to save the changes,
                <Typography className="title-text-sub">
                  Do you want to continue?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmPopup('Discard')}
          >
            Discard
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmPopup('Save')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateFeaturedItem;
