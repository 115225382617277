import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CustomDate, DynamicButton } from 'src/components/GlobalComponents';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { ApiService } from 'src/services/ApiService';
// import ClickAwayListener from '@mui/base/ClickAwayListener';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export default function BasicModal({ values, selected, closeSidebar }) {
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());

  const API_URL = 'https://ifcxqbb98k.execute-api.us-east-1.amazonaws.com/dev';
  const handleClose = () => {
    setOpen(false);
    closeSidebar();
  };

  const handleEndDateChange = (type, value) => {
    setStartDate(value);
  };

  function convertDate(dateStr) {
    let dateString = dateStr.toLocaleString();
    const parts = dateString.split(',');
    const month = parts[0];
    return `${month.replaceAll('/', '-')}`;
  }

  const pauseContract = async () => {
    let payload = {
      locationId: 'LOC#121231',
      contractId: selected.sortKey,
      terminationDate: convertDate(startDate)
    };

    try {
      await ApiService.post(`${API_URL}/contracts/terminateContract`, payload);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  const handleOpen = async () => {
    // closeSidebar();
    let payload = {
      locationId: 'LOC#121231',
      contractId: selected.sortKey,
      fromDate: convertDate(values.startD),
      toDate: convertDate(values.toDate)
    };

    try {
      await ApiService.post(`${API_URL}/contracts/pauseContract`, payload);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, Try again!');
    }
    setOpen(true);
  };
  return (
    <div className="dynamicModalMain">
      <DynamicButton
        variant="contained"
        fullWidth
        className="modalPauseBtn"
        onClick={handleOpen}
      >
        Pause
      </DynamicButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        data-backdrop-static="static"
        className="pauseModalMain"
      >
        <Box sx={style}>
          <div className="modalCloseSec">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="button" onClick={handleClose}>
              <HighlightOffIcon />
            </button>
          </div>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Termination Confirmation
          </Typography>
          <Typography
            id="modal-modal-description"
            className="pauseModContSec"
            sx={{ mt: 2 }}
          >
            <h4>Termination Date</h4>
          </Typography>

          <div className="pauseContSideBar nDynModCdSec">
            <CustomDate
              label="Start"
              name="startDate"
              fullWidth
              disablePast
              required
              disabled={startDate == null}
              //     minDate={
              //         pricingInfo.startDate && pricingInfo.startDate
              //     }
              inputFormat="MM/dd/yyyy"
              value={startDate}
              onChange={handleEndDateChange}
              // error={isError.endDate}
              // helperText={isError.endDate}
            />
          </div>

          <div className="pauseModalBtnSec">
            <Grid item xs={12} className="pauseModCancelBtn">
              <DynamicButton
                variant="contained"
                fullWidth
                onClick={handleClose}
              >
                Cancel
              </DynamicButton>
            </Grid>
            <Grid item xs={12} className="pauseModProcBtn">
              <DynamicButton
                variant="contained"
                fullWidth
                onClick={pauseContract}
              >
                Proceed
              </DynamicButton>
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
