import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NestedTable = ({ tableItem }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{tableItem?.itemType} Items</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Item Name</TableCell>
                <TableCell>Credits/Quantity</TableCell>
                <TableCell>Remaining Credits</TableCell>
                <TableCell>Variants</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableItem?.items?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="table-td -flex"
                    sx={{ minWidth: '200px' }}
                  >
                    {item?.itemName}
                  </TableCell>
                  <TableCell
                    className="table-td -flex"
                    sx={{ minWidth: '200px' }}
                  >
                    {item?.quantity || item?.remainingSession}
                  </TableCell>
                  <TableCell
                    className="table-td -flex"
                    sx={{ minWidth: '200px' }}
                  >
                    {item?.remainingSession ?? '-'}
                  </TableCell>
                  <TableCell
                    className="table-td -flex"
                    sx={{ minWidth: '200px' }}
                  >
                    {item?.variants
                      ?.filter((value) => value?.value)
                      ?.map?.((item, index, array) =>
                        index === array.length - 1
                          ? item?.value
                          : `${item?.value}, `
                      )
                      ?.join('') || '-'}
                  </TableCell>
                  <TableCell
                    className="table-td -flex"
                    sx={{ minWidth: '200px' }}
                  >
                    ${parseFloat(item?.price)?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default NestedTable;
