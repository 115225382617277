import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Divider
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { getLocalData } from 'src/utils';
import { ApiService } from 'src/services/ApiService';
// import { getLocalData, setFullLocationId } from '../../utils';

export const ContractFilter = ({ setFilters, filters, isFeatured }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
  const classes = useStyles();

  const [pricingDataPrice, setPricingDataPrice] = useState([]); // getting dynamic list
  const locationId = getLocalData('locationId');
  console.log(pricingDataPrice);
  const encodedLocationId = encodeURIComponent(locationId);

  const [openFilter, setOpenFilter] = useState({
    contractName: false,
    soldOnline: false,
    contractType: false,
    typeOfService: false,
    billingCycle: false,
    sellOnline: false
  });
  const API_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const getGiftCardPricing = async () => {
    try {
      let contractsResponse = await ApiService.get(
        `${API_URL}contracts?locationId=${encodedLocationId}`
      );

      let Item =
        contractsResponse && contractsResponse.data.Items
          ? contractsResponse.data.Items
          : [];
      const giftcardprice = Array.from(
        new Set(Item.map((obj) => obj.contractName))
      );

      setPricingDataPrice(giftcardprice);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // get all categories
    getGiftCardPricing();
  }, []);

  const changeType = (value) => {
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const changeBillingCycle = (value) => {
    setFilters((prevState) => ({ ...prevState, billingCycle: value }));
  };

  const changeSellOnline = (value) => {
    setFilters((prevState) => ({ ...prevState, sellOnline: value }));
  };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      contractType: field === 'contractType' ? !prevState.contractType : false,
      typeOfService:
        field === 'typeOfService' ? !prevState.typeOfService : false,
      giftCardPriceValue:
        field === 'giftCardPriceValue' ? !prevState.giftCardPriceValue : false,
      contractName: field === 'contractName' ? !prevState.contractName : false,
      billingCycle: field === 'billingCycle' ? !prevState.billingCycle : false,
      sellOnline: field === 'sellOnline' ? !prevState.sellOnline : false
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: 1,
      contractType: '',
      typeOfService: '',
      contractName: '',
      billingCycle: ''
    });

    setOpenFilter({
      contractName: false,
      contractType: false,
      typeOfService: false,
      billingCycle: false
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Type</InputLabel>
          <Select
            value={filters.status ?? 1}
            onChange={(event) => changeType(event.target.value)}
            label="Type"
            className="status-field field-label-css"
          >
            {!isFeatured && (
              <MenuItem key={1} id={1} value={-1}>
                All
              </MenuItem>
            )}
            <MenuItem key={2} id={2} value={1}>
              Active
            </MenuItem>
            {!isFeatured && (
              <MenuItem key={3} id={3} value={0}>
                Inactive
              </MenuItem>
            )}
            {/* <MenuItem key={3} id={3} value={3}>Expired</MenuItem> */}
          </Select>
        </FormControl>
        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                {/* Contract Type */}
                Filters
              </Typography>
            </Grid>
            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('billingCycle')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.contractName && classes.closeY,
                    openFilter.contractName && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Billing Cycle</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.billingCycle && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Billing Cycle</InputLabel>
              <Select
                onChange={(event) => changeBillingCycle(event.target.value)}
                value={filters.billingCycle ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Select Item"
                className="status-field field-label-css"
              >
                <MenuItem key={1} id={1} value="every month">
                  Monthly
                </MenuItem>
                <MenuItem key={2} id={2} value="every quarter">
                  Quarterly
                </MenuItem>
                <MenuItem key={3} id={3} value="every 6 months">
                  Every 6 months
                </MenuItem>
                <MenuItem key={4} id={4} value="every year">
                  Every year
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        {!isFeatured && (
          <Grid
            item
            xs={12}
            onClick={() => handleOpenFilter('sellOnline')}
            pt={0.5}
          >
            <Paper>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                className="pointer"
              >
                <Grid item>
                  <KeyboardArrowRightRoundedIcon
                    className={clsx(
                      !openFilter.contractName && classes.closeY,
                      openFilter.contractName && classes.openY
                    )}
                  />
                </Grid>
                <Grid item>
                  <Typography>Sell Online</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {openFilter.sellOnline && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Sell Online</InputLabel>
              <Select
                onChange={(event) => changeSellOnline(event.target.value)}
                value={filters.sellOnline ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Select Item"
                className="status-field field-label-css"
              >
                <MenuItem key={1} id={1} value="true">
                  Yes
                </MenuItem>
                <MenuItem key={2} id={2} value="false">
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};
