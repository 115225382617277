import { ApiService } from 'src/services/ApiService';

// API TO GET THE LIST OF CLIENTS
const getClientList = async (
  searchText,
  BASE_URL,
  locationId,
  setClientLoading,
  setClientList,
  setLoading
) => {
  let encodedLocationId = encodeURIComponent(locationId);

  setClientLoading(true);
  try {
    let response = await ApiService.get(
      `${BASE_URL}checkout/getClientsBySearch?searchText=${searchText}&locationId=${encodedLocationId}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    if (response?.statusCode === 200) {
      setClientList(Item);
    } else {
      setClientList([]);
    }
    setClientLoading(false);
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
    setClientLoading(false);
  }
};

// API TO GET THE LIST OF PETS
const getPetList = async (
  clientId,
  location,
  API_BASE_URL,
  setPetList,
  setLoading
) => {
  setLoading(true);
  let encodedLocationId = encodeURIComponent(location);
  let encodedClientId = encodeURIComponent(clientId);
  try {
    let response = await ApiService.get(
      `${API_BASE_URL}client/getClientDetails?locationId=${encodedLocationId}&clientId=${encodedClientId}`
    );
    let Item =
      response && response.data && response.data.Items[0]
        ? response.data.Items[0]?.pets
        : [];

    let filteredData = Item?.filter((pet) => pet?.status === 1);
    if (response?.statusCode === 200) {
      setPetList(filteredData);
    } else {
      setPetList([]);
    }
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

// API FOR GUEST SIGN UP
const guestSignUp = async (
  event,
  API_BASE_URL,
  handleNext,
  setLoading,
  locationId,
  franchiseeId,
  guestCheckout,
  selected,
  setSelected,
  setShowMessage,
  setErrorMessage
) => {
  setLoading(true);
  const payload = {
    locationId: locationId,
    name: guestCheckout?.name?.trim(),
    franchiseeId: franchiseeId
  };
  if (guestCheckout?.contact && guestCheckout?.contact !== '') {
    payload.mobilePhone = guestCheckout?.contact;
  }

  if (guestCheckout?.email && guestCheckout?.email !== '') {
    payload.email = guestCheckout?.email;
  }
  try {
    const data = await ApiService.post(
      `${API_BASE_URL}checkout/guestSignUp`,
      payload
    );

    if (data?.statusCode === 200 && !data?.error) {
      setSelected({
        ...selected,
        client: {
          clientId: data?.data?.id,
          sortKey: data?.data?.id,
          firstName: guestCheckout.name,
          lastName: '',
          email: guestCheckout.email,
          phone: guestCheckout.contact
        }
      });
      handleNext(event);
    }
    setLoading(false);
  } catch (error) {
    console.error(error);
    setShowMessage(true);
    setErrorMessage(error?.message || 'Something went wrong');
    setLoading(false);
  }
};
export { getClientList, getPetList, guestSignUp };
