import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

export default function dynamicDateField({
  setDateValue,
  dateValue,
  label,
  name,
  minDate,
  maxDate
}) {
  const parsedDateValue = (inputValue) => {
    return inputValue ? dayjs(inputValue, 'MM/DD/YYYY') : null;
  };

  const handleChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      setDateValue(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        name={name}
        inputFormat="MMM DD, YYYY"
        minDate={minDate ? parsedDateValue(minDate) : null}
        maxDate={maxDate ? parsedDateValue(maxDate) : null}
        value={dateValue && parsedDateValue(dateValue)}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            id="outlined-required"
            className="text-field-styling"
            sx={{ marginTop: '20px' }}
            InputLabelProps={{
              className: 'label-style-text-field'
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
