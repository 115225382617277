import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export function DynamicTabs({ list }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dynamicTabs clienttab">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="Tabs">
            {list?.map((item, index) => (
              // <Tab key={index} label={item.label} {...a11yProps(index)} />
              <Tab
                className="weight-700"
                key={index}
                label={item.customLabel}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>

        <TabPanel value={value} index={value}>
          {list[value]?.label === 'Alerts' && list[value]?.component}
          {list[value]?.label === 'Notes' && list[value]?.component}
          {list[value]?.label === 'Purchase History' && list[value]?.component}
          {list[value]?.label === 'Upcoming Schedule' && list[value]?.component}
          {list[value]?.label === 'Past Visit' && list[value]?.component}
          {list[value]?.label === 'Credits' && list[value]?.component}
          {list[value]?.label === 'Contract' && list[value]?.component}
        </TabPanel>
      </Box>
    </div>
  );
}
