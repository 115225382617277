import React, { useEffect, useState } from 'react';
import { Box, Button, styled, Container, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from 'src/utils';

const AuthorizationMessage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let authCode = searchParams.get('code');
  let errorCode = searchParams.get('error');
  const MainContent = styled(Box)(
    () => `
          height: 100%;
          display: flex;
          flex: 1;
          overflow: auto;
          flex-direction: column;
          align-items: center;
          justify-content: center;
      `
  );

  const [isAuthorizationSuccess, setIsAuthorizationSuccess] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [initializing, setInitializing] = useState(0);
  // const [icon, setIcon] = useState('');

  useEffect(() => {
    // console.log("authCode", authCode, errorCode);

    let hash = window.location.href;
    let slace = hash && hash.split('/');
    let fId = slace && slace?.length > 0 && slace[4] && slace[4];

    // If get an access code
    if (authCode) {
      // setIsAuthorizationSuccess('code');
      authorizationCheck(fId);
    }
    // if get an error code
    if (errorCode) {
      setIsAuthorizationSuccess('error');
      setIsAuthorized(false);
      setInitializing(1);
    }
  }, [authCode, errorCode]);

  // Method to call the API for the authorization
  const authorizationCheck = async () => {
    // console.log("API calling for the authorization", fId);
    // check if franchisee id is available and it has a #FR# then do no attach #FR# again
    // let franchiseeId;
    // if (fId && !fId.includes('#FR#') && fId !== 'undefined') {
    //   franchiseeId = '#FR#' + fId;
    // }
    const franchiseeId = getLocalData('franchiseeId')

    const data = {
      franchiseeId: franchiseeId,
      code: authCode
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}franchisees/_create-access-token`,
        data
      );
      if (response.statusCode && !response.error) {
        // console.log('Success');
        setIsAuthorized(true);
        setIsAuthorizationSuccess('code');
        setInitializing(2);
      } else {
        // console.log('Error');
        setIsAuthorized(false);
        setInitializing(1);
      }
    } catch (error) {
      console.log(error);
      setIsAuthorized(false);
      setInitializing(1);
    }
  };

  useEffect(() => {}, [isAuthorized, initializing]);

  return (
    <>
      <MainContent>
        <Container
          maxWidth="xxl"
          sx={{
            padding: '0px !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          {initializing !== 0 && (
            <>
              <img
                src={
                  isAuthorizationSuccess === 'code' && isAuthorized
                    ? '/static/images/logo/AuthSuccess.png'
                    : '/static/images/logo/AuthFailed.png'
                }
                style={{ maxWidth: '100%' }}
                className="tick-image"
                alt="zoom-room-logo"
              />

              <Typography sx={{ mt: 2 }} className="header-orange-30-700">
                {isAuthorizationSuccess === 'code' && isAuthorized
                  ? 'SUCCESSFULLY AUTHORIZED'
                  : 'AUTHORIZATION FAILED'}
              </Typography>

              <Typography sx={{ mt: 1.5 }} className="header-black-16-400 ">
                {isAuthorizationSuccess === 'code' && isAuthorized
                  ? 'Congratulations!'
                  : 'Unable to Authorize. Visit your email to reattempt'}
                <Typography
                  sx={{ textAlign: 'center', mt: -0.5 }}
                  className="header-black-16-400 "
                >
                  {isAuthorizationSuccess === 'code' && isAuthorized
                    ? 'your franchise authorized successfully!'
                    : 'the authorization process.'}
                </Typography>
              </Typography>

              {isAuthorizationSuccess === 'code' && isAuthorized && (
                <Button
                  type="submit"
                  variant="contained"
                  className="button-style"
                  sx={{ width: '18%' }}
                  onClick={() => navigate('/')}
                >
                  <Typography className="button-text">GO TO DASHBOARD</Typography>
                </Button>
              )}
            </>
          )}
        </Container>
      </MainContent>
    </>
  );
};

export default AuthorizationMessage;
