import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Slide,
  Typography
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConfirmationModal = ({ open, handleDialog }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleDialog('No')}
      aria-describedby="alert-dialog-slide-description"
    >
      {/* <DialogTitle className="alert-title align-center">
        {'Comp Item'}
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription align-center"
        >
          <Box display="flex" justifyContent="center" direction="column">
            <Typography className="title-text-sub">
              Are you sure you want to comp this item?
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className="alert-button-control">
        <Button
          className="font-17 no-hover"
          onClick={() => handleDialog('Yes')}
        >
          Yes
        </Button>
        <Divider className="vertical-divider" />
        <Button
          className="font-17 cancel-btn no-hover"
          onClick={() => handleDialog('No')}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
