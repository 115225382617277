import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import React from 'react';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const ShowNotesModal = ({
  isNotesModalOpen,
  notesData,
  setIsNotesModalOpen
}) => {
  return (
    <Dialog
      open={isNotesModalOpen}
      keepMounted
      aria-describedby="details-dialog"
      className="dialog-box dialog-box-right not scheduling-dialog"
      sx={{ maxHeight: '400px', mt: 25, ml: 2 }}
    >
      <Box>
        <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
          {/* this class is commented for the above code className="alert-title align-right dialog-header" */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              className="alert-data-scheduling"
              textAlign="center"
              sx={{
                marginTop: '0',
                marginBottom: '10px',
                fontWeight: '700',
                fontSize: '30px'
              }}
            >
              All Notes
            </Typography>
            <Button
              className="details-remove-icon task-cross p-0 no-hover"
              onClick={() => setIsNotesModalOpen(false)}
            >
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                  fill="#E64646"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
      </Box>
      <DialogContent className="-team-container-white-box scheduling-alert-dialog">
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription"
        >
          <Grid container spacing={2}>
            <Grid
              className="alert-padding-dialog"
              item
              sx={{
                width: '100%'
              }}
            >
              <Box
                className="alert-text-scheduling"
                sx={{ textAlign: 'left !important' }}
              >
                {notesData &&
                  notesData?.length > 0 &&
                  notesData?.map((item) => {
                    return (
                      <>
                        <Grid container className="alert-container-scheduling">
                          <Grid
                            item
                            display="flex"
                            alignItems="flex-start"
                            marginLeft={2}
                          >
                            <DoubleArrowIcon
                              style={{
                                color: '#000000',
                                marginRight: '5px',
                                fontSize: '20px'
                              }}
                            />
                            <Typography sx={{ fontSize: '16px' }}>
                              {item?.notes}
                            </Typography>
                          </Grid>
                        </Grid>
                        <hr className="horizontal-line-scheduling" />
                      </>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ShowNotesModal;
