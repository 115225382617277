/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import {
  CustomDate,
  DynamicButton,
  DynamicTextField,
  DynamicToggle,
  SignatureCard
} from 'src/components/GlobalComponents';
import BasicModal from './DynamicModal';
import { toast } from 'react-toastify';
import { Image } from '@material-ui/icons';
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const DynamicRightSidebar = ({
  open,
  setOpen,
  type,
  contractDetails,
  selectedContract,
  contractPricing,
  setContractPricing,
  handleContractInfo,
  callContractApi,
  setPageType
}) => {
  const steps = ['Add Pricing Option', 'Assign Pricing Option'];
  const [activeStep, setActiveStep] = useState(0);
  const [pricingInfo, setPricingInfo] = useState({
    activeStep: 0,
    name: null,
    price: null,
    free: false,
    noOfSessions: null,
    sellOnline: false,
    includePromotion: true,
    validThrough: '',
    startDate: null,
    expireDate: null,
    endDate: null,
    startD: null,
    toDate: null,
    isDefault: false
  });
  const [pricingError, setPricingError] = useState({
    nameError: false,
    priceError: false,
    noOfSessionsError: false,
    startDateError: false,
    expireDateError: false,
    startDError: false,
    toDateError: false
  });

  const clearValues = () => {
    setActiveStep(0);
    setPricingInfo({
      activeStep: 0,
      name: null,
      price: null,
      free: false,
      noOfSessions: null,
      sellOnline: false,
      includePromotion: true,
      validThrough: '',
      startDate: null,
      expireDate: null,
      endDate: null,
      startD: null,
      toDate: null,
      isDefault: false
    });
  };
  const API_URL = 'https://ifcxqbb98k.execute-api.us-east-1.amazonaws.com/dev';

  const handleDialogClose = async () => {
    if (type === 'assign') {
      const date = new Date(pricingInfo.startDate);
      const dateEMd = new Date(pricingInfo.endDate);

      const formattedDateStart = date.toLocaleDateString('en-GB');
      const formattedDateENd = dateEMd.toLocaleDateString('en-GB');
      let payload = {
        locationId: 'LOC#454651321',
        name: pricingInfo.name,
        price: pricingInfo.price,
        numberOfSessions: pricingInfo.noOfSessions,
        includeInPromotion: pricingInfo.includePromotion,
        sellOnline: pricingInfo.sellOnline,
        free: pricingInfo.free,
        validThrough: {
          startDate: formattedDateStart.replaceAll('/', '-'),
          endDate: formattedDateENd.replaceAll('/', '-')
        },
        createdBy: 'test user',
        isActive: true,
        defaultPricingOption: pricingInfo.isDefault
      };

      try {
        await ApiService.post(`${API_URL}/pricing-options`, payload);
        setOpen(false);
        setPageType(2);
        clearValues();
      } catch (error) {
        console.error(error);
      }
    } else {
      setOpen(false);
    }
  };

  const handlePricingInfo = (type, value) => {
    if (type === 'free' && value === true) {
      setPricingInfo({ ...pricingInfo, free: true, price: 0 });
    } else if (type === 'free' && value === false) {
      setPricingInfo({ ...pricingInfo, free: false, price: '' });
    } else {
      setPricingInfo({ ...pricingInfo, [type]: value });
      if (type === 'name') {
        setPricingError({ ...pricingError, nameError: false });
      }
      if (type === 'price') {
        setPricingError({ ...pricingError, priceError: false });
      }
      if (type === 'noOfSessions') {
        setPricingError({ ...pricingError, noOfSessionsError: false });
      }
      if (type === 'startDate') {
        setPricingError({ ...pricingError, startDateError: false });
      }
      if (type === 'endDate') {
        setPricingError({ ...pricingError, expireDateError: false });
      }
      if (type === 'startD') {
        setPricingError({ ...pricingError, startDError: false });
      }
      if (type === 'toDate') {
        setPricingError({ ...pricingError, toDateError: false });
      }
    }
  };

  const getCreditValue = () => {
    let text = null;
    if (pricingInfo.free) {
      text = 'Price per credit: $0.00';
    } else if (
      !pricingInfo.free &&
      pricingInfo.price > 0 &&
      pricingInfo.noOfSessions > 0
    ) {
      text = `Price per credit: $${(
        Number(pricingInfo.price) / pricingInfo.noOfSessions
      ).toFixed(2)}`;
    }

    return text;
  };

  const handleProceed = (e) => {
    e.preventDefault();

    setActiveStep(1);
  };

  useEffect(() => {
    console.log('pricingInfo', pricingInfo);
  }, [pricingInfo]);

  const canvasRef = useRef(null);
  const handleProceedToNextStep = () => {
    setPricingError({
      nameError: pricingInfo.name === null || pricingInfo.name === '',
      priceError: pricingInfo.price === null || pricingInfo.price <= 0,
      noOfSessionsError:
        pricingInfo.noOfSessions === null || pricingInfo.noOfSessions <= 0,
      startDateError: pricingInfo.startDate === null,
      expireDateError: pricingInfo.endDate === null,
      startDError: pricingInfo.startD === null,
      toDateError: pricingInfo.toDate === null
    });

    if (
      pricingInfo.name !== null &&
      pricingInfo.price !== null &&
      pricingInfo.noOfSessions !== null
    ) {
      if (
        pricingInfo.validThrough === 'StartAndEndDate' &&
        pricingInfo.startDate !== null &&
        pricingInfo.endDate !== null
      ) {
        document.getElementById('pricing-save').click();
      } else if (
        pricingInfo.validThrough === 'AfterActivation' &&
        pricingInfo.endDate !== null
      ) {
        document.getElementById('pricing-save').click();
      } else if (
        pricingInfo.validThrough === 'NeverExpire' &&
        pricingInfo.startDate !== null
      ) {
        document.getElementById('pricing-save').click();
      }
    }
    // document.getElementById('pricing-save').click()
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={() => setOpen(false)}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right"
        maxWidth="xs"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={() => setOpen(false)}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
            style={{ backgroundColor: 'white' }}
          >
            {type === 'terms' ? (
              <div className="contractRuleMain">
                <h2 className="themeColor createTermtext">Create Terms</h2>
                <div className="textAreaMain">
                  <Grid item xs={12} sm={6}>
                    <DynamicTextField
                      fullWidth
                      required
                      label="Write terms here.."
                      multiline
                      rows="6"
                      type="text"
                      name="terms"
                      value={contractPricing.terms}
                      error={contractPricing.termsError}
                      helperText={
                        contractPricing.termsError && 'Terms are Required'
                      }
                      onChange={handleContractInfo}
                    />
                  </Grid>
                </div>
                <div className="termsCheckBoxSec">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={contractPricing.termsCheckBox}
                          onChange={(e) => {
                            setContractPricing({
                              ...contractPricing,
                              termsCheckBox: e.target.checked
                            });
                          }}
                        />
                      }
                      label="I hereby confirm that I read all the terms and understand the legal, financial, and other implications associated with them, and I am willingly bound by these terms and conditions."
                    />
                  </FormGroup>
                </div>
                {contractPricing ? null : ( // </Box> //     <Image src={contractPricing?.signatureImage}/> //  <Box sx={{width: 50, height: 50}}>
                  <div className="signatureMain">
                    <SignatureCard
                      contractPricing={contractPricing}
                      setContractPricing={setContractPricing}
                      canvasRef={canvasRef}
                    />
                    <a
                      href="#"
                      className="clearLink"
                      onClick={() => canvasRef.current.clear()}
                    >
                      Clear
                    </a>
                  </div>
                )}

                <div className="sideBarBottomBtnSec">
                  <Grid item xs={12} className="fullwidth-btn">
                    <DynamicButton
                      variant="contained"
                      fullWidth
                      disabled={!contractPricing?.termsCheckBox}
                      onClick={() => {
                        const image =
                          canvasRef?.current?.toDataURL() ||
                          contractDetails?.signatureImage;
                        callContractApi(image);
                      }}
                    >
                      Save
                    </DynamicButton>
                  </Grid>
                </div>
              </div>
            ) : type === 'assign' ? (
              <Box>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    width: '100%',
                    background: 'transparent',
                    paddingY: '30px'
                  }}
                  className="custom-stepper"
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <form className="custom-form" onSubmit={handleProceed}>
                  {activeStep === 0 ? (
                    <Grid container spacing={2} columns={12}>
                      <Grid item xs={12}>
                        <DynamicTextField
                          type="text"
                          name="name"
                          label="NAME"
                          required
                          fullWidth
                          value={pricingInfo.name}
                          onChange={handlePricingInfo}
                          error={pricingError.nameError}
                          helperText={
                            pricingError.nameError && 'Name is required'
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <DynamicTextField
                          type="number"
                          name="price"
                          label="Price"
                          required
                          fullWidth
                          error={pricingError.priceError}
                          helperText={
                            pricingError.priceError && 'price is required'
                          }
                          value={pricingInfo.price}
                          onChange={handlePricingInfo}
                          disabled={pricingInfo.free}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        display="flex"
                        width="100%"
                        justifyContent="flex-end"
                      >
                        <DynamicToggle
                          name="free"
                          label="Free"
                          labelPlacement="start"
                          value={pricingInfo.free}
                          onChange={handlePricingInfo}
                          className="marginRight: 16px"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <div className="sessionNoSec">
                          <DynamicTextField
                            type="number"
                            name="noOfSessions"
                            label="NO OF SESSIONS (CREDIT)"
                            required
                            fullWidth
                            value={pricingInfo.noOfSessions}
                            onChange={handlePricingInfo}
                            helperText={
                              pricingError.noOfSessionsError &&
                              'Number of session is required'
                            }
                            error={pricingError.noOfSessionsError}
                          />
                          <span>{getCreditValue()}</span>
                        </div>
                      </Grid>

                      <Grid item xs={12} paddingRight="4px">
                        <DynamicToggle
                          fullWidth
                          spaceBetween
                          name="sellOnline"
                          label="Sell Online"
                          labelPlacement="start"
                          value={pricingInfo.sellOnline}
                          onChange={handlePricingInfo}
                        />
                      </Grid>

                      <Grid item xs={12} paddingRight="4px">
                        <DynamicToggle
                          fullWidth
                          spaceBetween
                          name="includePromotion"
                          label="Include in promotion"
                          labelPlacement="start"
                          value={pricingInfo.includePromotion}
                          onChange={handlePricingInfo}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl className="custom-radio-group">
                          <FormLabel
                            sx={{
                              color: '#000 !important',
                              fontWeight: '600',
                              fontSize: '18px'
                            }}
                          >
                            Valid Through
                          </FormLabel>
                          <RadioGroup
                            defaultValue="AfterActivation"
                            name="radio-buttons-group"
                            value={pricingInfo.validThrough}
                            onChange={(e) =>
                              setPricingInfo({
                                ...pricingInfo,
                                validThrough: e.target.value
                              })
                            }
                          >
                            <FormControlLabel
                              value="AfterActivation"
                              control={<Radio />}
                              label="After Activation"
                              labelPlacement="start"
                            />
                            <FormControlLabel
                              value="StartAndEndDate"
                              control={<Radio />}
                              label="Start and End Date"
                              labelPlacement="start"
                            />
                            <FormControlLabel
                              value="NeverExpire"
                              control={<Radio />}
                              label="Never Expire"
                              labelPlacement="start"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className="custom-radio-group">
                          {pricingInfo.validThrough === 'AfterActivation' ? (
                            <>
                              <FormLabel
                                sx={{
                                  color: '#000 !important',
                                  fontWeight: '600',
                                  fontSize: '18px',
                                  marginBottom: '10px'
                                }}
                              >
                                Expire On
                              </FormLabel>
                              <CustomDate
                                label="MM/DD/YYYY"
                                name="endDate"
                                fullWidth
                                disablePast
                                required
                                // disabled={pricingInfo.startDate == null}
                                minDate={
                                  pricingInfo.startDate && pricingInfo.startDate
                                }
                                inputFormat="MM/dd/yyyy"
                                value={pricingInfo.endDate}
                                onChange={handlePricingInfo}
                                error={pricingError.expireDateError}
                                helperText={
                                  pricingError.expireDateError &&
                                  'end date is required'
                                }
                              />
                            </>
                          ) : pricingInfo.validThrough === 'StartAndEndDate' ? (
                            <>
                              <CustomDate
                                label="Start Date"
                                name="startDate"
                                fullWidth
                                disablePast
                                required
                                // disabled={pricingInfo.startDate == null}
                                minDate={
                                  pricingInfo.startDate && pricingInfo.startDate
                                }
                                inputFormat="MM/dd/yyyy"
                                value={pricingInfo.startDate}
                                onChange={handlePricingInfo}
                                error={pricingError.startDateError}
                                helperText={
                                  pricingError.startDateError &&
                                  'start date is required'
                                }
                              />
                              <br />
                              <CustomDate
                                label="MM/DD/YYYY"
                                name="endDate"
                                fullWidth
                                disablePast
                                required
                                // disabled={pricingInfo.startDate == null}
                                minDate={
                                  pricingInfo.startDate && pricingInfo.startDate
                                }
                                inputFormat="MM/dd/yyyy"
                                value={pricingInfo.endDate}
                                onChange={handlePricingInfo}
                                error={pricingError.expireDateError}
                                helperText={
                                  pricingError.expireDateError &&
                                  'end date is required'
                                }
                              />
                            </>
                          ) : (
                            <CustomDate
                              label="Start Date"
                              name="startDate"
                              fullWidth
                              disablePast
                              required
                              // disabled={pricingInfo.startDate == null}
                              minDate={
                                pricingInfo.startDate && pricingInfo.startDate
                              }
                              inputFormat="MM/dd/yyyy"
                              value={pricingInfo.startDate}
                              onChange={handlePricingInfo}
                              error={pricingError.startDateError}
                              helperText={
                                pricingError.startDateError &&
                                'start date is required'
                              }
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} className="fullwidth-btn">
                        <DynamicButton
                          variant="contained"
                          fullWidth
                          onClick={handleProceedToNextStep}
                        >
                          Proceed
                        </DynamicButton>
                        <button hidden type="submit" id="pricing-save">
                          save
                        </button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        width="100%"
                        justifyContent="flex-start"
                        marginLeft="16px"
                      >
                        <DynamicToggle
                          name="isDefault"
                          label="Set Default Pricing"
                          labelPlacement="end"
                          value={pricingInfo.isDefault}
                          onChange={handlePricingInfo}
                          className="marginRight: 16px"
                        />
                      </Grid>
                      <FormHelperText
                        sx={{
                          marginLeft: '100px',
                          marginTop: '-5px',
                          color: '#6d6b6b',
                          fontSize: '13px',
                          letterSpacing: '0.4px'
                        }}
                      >
                        This applies Pricing Option to Clients with no
                        Membership or Contract
                      </FormHelperText>

                      <Grid item xs={12} className="fullwidth-btn">
                        <DynamicButton
                          variant="contained"
                          fullWidth
                          onClick={handleDialogClose}
                        >
                          Save & Close
                        </DynamicButton>
                      </Grid>
                    </Grid>
                  )}
                </form>
              </Box>
            ) : (
              <div className="pauseContSideBar">
                <div className="pauseSbHead">
                  <h2>Pause Contract</h2>
                  <h4>Pause Till</h4>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <CustomDate
                    label="Start"
                    name="startD"
                    fullWidth
                    disablePast
                    required
                    disabled={pricingInfo.startD == null}
                    minDate={pricingInfo.startD && pricingInfo.startD}
                    inputFormat="MM/dd/yyyy"
                    value={pricingInfo.startD}
                    onChange={handlePricingInfo}
                    // error={isError.endDate}
                    // helperText={isError.endDate}
                  />
                </div>
                <div>
                  <CustomDate
                    label="To (Optional)"
                    name="toDate"
                    fullWidth
                    disablePast
                    disabled={pricingInfo.toDate == null}
                    minDate={pricingInfo.toDate && pricingInfo.toDate}
                    inputFormat="MM/dd/yyyy"
                    value={pricingInfo.toDate}
                    onChange={handlePricingInfo}
                    // error={isError.endDate}
                    // helperText={isError.endDate}
                  />
                </div>
                <div className="terminationModalSec">
                  <BasicModal
                    values={pricingInfo}
                    selected={selectedContract}
                    closeSidebar={handleDialogClose}
                  />
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <Divider />
      </Dialog>
    </div>
  );
};
