/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  useTheme,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReactDOMServer from 'react-dom/server';
import PurchaseReceipt from './PurchaseReceipt';
import { ApiService } from 'src/services/ApiService';
import SuccessMessage from '../PopupMessage/SuccessMessage';
import ErrorMessage from '../PopupMessage/ErrorMessage';
import { getLocalData } from 'src/utils';
import moment from 'moment';

export const PurchaseTable = (props) => {
  const { tableRows, searchQuery, openSummary, handleEditOrder } = props;
  const locationId = getLocalData('locationId');

  const theme = useTheme();
  const [anchor, setAnchor] = useState(null);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getRowItem, setGetRowItem] = useState({});

  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  let BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;

  const handleSearchTextChange = (searchText) => {
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedRows = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  console.log(paginatedRows);

  function getDateFormat(date) {
    let utc;
    utc = date ? moment?.utc(date).format('MMM DD, YYYY') : '';
    return utc;
  }

  useEffect(() => {
    let searchTxt = searchQuery ? searchQuery.toLowerCase() : '';
    handleSearchTextChange(searchTxt);
  }, [searchQuery]);

  const openMenu = (rowDetails, event) => {
    event.stopPropagation();
    setGetRowItem(rowDetails);
    setAnchor(event.currentTarget);
  };

  const closeMenu = (e) => {
    e.stopPropagation();
    setGetRowItem({});
    setAnchor(null);
  };

  const printReceipt = () => {
    const receiptHtml = ReactDOMServer.renderToStaticMarkup(
      <PurchaseReceipt selected={getRowItem} />
    );

    const printWindow = window.open('', '', 'width=600,height=600');

    if (printWindow) {
      // Ensure the new window is opened only once
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Receipt</title>
          </head>
          <body>
            ${receiptHtml}
          </body>
        </html>
      `);
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    } else {
      alert('Pop-up window blocked. Please allow pop-ups for this site.');
    }
  };

  const handleEmailReceipt = async () => {
    const id = encodeURIComponent(getRowItem?.PRDSort);
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let response = await ApiService.get(
        `${BASE_URL}purchase-orders/mailSentToselfAndSupplier?locationId=${encodedLocationId}&id=${id}&sendMe=false&sendToSupplier=true&`
      );

      if (response?.statusCode === 200) {
        setShowSuccessMessage(true);
        setSuccessMessage(
          response?.data?.message || 'Email has been sent successfully'
        );
      }
    } catch (error) {
      console.error(error);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went Wrong');
    }
  };

  return (
    <div>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={() => setShowSuccessMessage(false)}
        />
      )}
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <CardHeader
          // action={
          // }
          title="All Purchase Order"
          className="table-header-name"
          sx={{ fontSize: '30px', color: '#003087' }}
        />

        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>PO #</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Shipping Cost</TableCell>
                <TableCell>Sub Total</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.PRDSort}
                    className="table-row cursor-pointer"
                    onClick={(e) => openSummary(row.PRDSort, e)}
                  >
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row.orderNumber}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {row.supplierName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {getDateFormat(row.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.subTotal &&
                          `$${parseFloat(row.shippingCost).toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.subTotal &&
                          `$${parseFloat(row.subTotal).toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.subTotal && `$${parseFloat(row.total).toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {row.orderStatus}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      {row?.orderStatus === 'draft' && (
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          className="edit-btn"
                          onClick={(event) =>
                            handleEditOrder(row.PRDSort, 'create', event)
                          }
                        >
                          Edit
                        </IconButton>
                      )}
                      <MoreVertIcon
                        onClick={(e) => openMenu(row, e)}
                        color="grey"
                        variant="contained"
                        className="three-dots-icon"
                        sx={{
                          marginLeft:
                            row.orderStatus === 'draft' ? '0px' : '32px'
                        }}
                      />

                      <Menu
                        open={Boolean(anchor)}
                        anchorEl={anchor}
                        onClose={(e) => closeMenu(e)}
                        keepMounted
                        PaperProps={{
                          style: {
                            maxHeight: 40 * 5,
                            width: '25ch'
                          }
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEmailReceipt();
                          }}
                        >
                          Email Receipt
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            printReceipt();
                          }}
                        >
                          Print Receipt
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
