/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Grid,
  Slide,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Container,
  Typography
} from '@mui/material';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from '../../../utils';
import Loader from '../../../components/Loader';
// import { HeaderContext } from 'src/contexts/HeaderContext';
// import {ArrowLeft} from '../../assets/SVG/ArrowLeft'
import TablePagination from '@mui/material/TablePagination';

// const [page, setPage] = useState(0);
// const [rowsPerPage, setRowsPerPage] = useState(10);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
// const handleChangePage = (event, newPage) => {
//     setPage(newPage);
// };

// const handleChangeRowsPerPage = (event) => {
//     // setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0); // Reset page when rows per page changes
// };

// , setShowEditPromotionDialog, setShowEditPromotion
const PromotionDetails = ({
  setShowScreen,
  selectedData,
  getPromotionDetails
}) => {
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Promotion Management');

  let userId = getLocalData('userId');

  let details = selectedData[0];
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [membershipData, setMembershipData] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allBundles, setAllBundles] = useState([]);
  const [allContracts, setAllContracts] = useState([]);
  const [allMemberships, setAllMemberships] = useState([]);
  const [servicepage, setServicePage] = useState(0);
  const [serviceRowsPerPage, setServiceRowsPerPage] = useState(5);
  const [productpage, setProductPage] = useState(0);
  const [productRowsPerPage, setProductRowsPerPage] = useState(5);
  const [bundlepage, setBundlePage] = useState(0);
  const [bundleRowsPerPage, setBundleRowsPerPage] = useState(5);
  const [contractpage, setContractPage] = useState(0);
  const [contractRowsPerPage, setContractRowsPerPage] = useState(5);
  const [membershippage, setMembershipPage] = useState(0);
  const [membershipRowsPerPage, setMembershipRowsPerPage] = useState(5);

  // const [itemType,setItemType]= useState('')
  // const [promotionDetails,setPromotionDetails]= useState([]);
  const locationId = getLocalData('locationId');
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  useEffect(() => {
    // console.log(selectedData[0].items[0].itemType);
    // let data=selectedData[0].items[0].itemType;
    // const getItemType = data && data.map((item) => ({ itemType: item.itemType }));
    // setItemType(getItemType)
    setTableData(selectedData[0].items);
    let sortKey = selectedData[0].sortKey;
    // console.log(sortKey);
    getPromotionDetailsById(sortKey); // locationId,
    // console.log("End date is --->", details.endDate);
  }, []);

  const handleChangePage = (newPage, type) => {
    if (type === 'Service') setServicePage(newPage);
    else if (type === 'Product') setProductPage(newPage);
    else if (type === 'Bundle') setBundlePage(newPage);
    else if (type === 'Contract') setContractPage(newPage);
    else if (type === 'Membership') setMembershipPage(newPage);
  };

  const handleChangeRowsPerPage = (event, type) => {
    if (type === 'Service') {
      setServiceRowsPerPage(parseInt(event.target.value, 10));
      setServicePage(0); // Reset page when rows per page changes
    } else if (type === 'Product') {
      setProductRowsPerPage(parseInt(event.target.value, 10));
      setProductPage(0); // Reset page when rows per page changes
    } else if (type === 'Bundle') {
      setBundleRowsPerPage(parseInt(event.target.value, 10));
      setBundlePage(0); // Reset page when rows per page changes
    } else if (type === 'Contract') {
      setContractRowsPerPage(parseInt(event.target.value, 10));
      setContractPage(0); // Reset page when rows per page changes
    } else if (type === 'Membership') {
      setMembershipRowsPerPage(parseInt(event.target.value, 10));
      setMembershipPage(0); // Reset page when rows per page changes
    }
  };

  // now set the paginated data for service product, bundle, membership and contract
  const servicePaginatedData =
    serviceData &&
    serviceData?.length > 0 &&
    serviceData?.slice(
      servicepage * serviceRowsPerPage,
      servicepage * serviceRowsPerPage + serviceRowsPerPage
    );

  const productPaginatedData =
    productData &&
    productData?.length > 0 &&
    productData?.slice(
      productpage * productRowsPerPage,
      productpage * productRowsPerPage + productRowsPerPage
    );

  const bundlePaginatedData =
    bundleData &&
    bundleData?.length > 0 &&
    bundleData?.slice(
      bundlepage * bundleRowsPerPage,
      bundlepage * bundleRowsPerPage + bundleRowsPerPage
    );

  const contractPaginatedData =
    contractData &&
    contractData?.length > 0 &&
    contractData?.slice(
      contractpage * contractRowsPerPage,
      contractpage * contractRowsPerPage + contractRowsPerPage
    );

  const membershipPaginatedData =
    membershipData &&
    membershipData?.length > 0 &&
    membershipData?.slice(
      membershippage * membershipRowsPerPage,
      membershippage * membershipRowsPerPage + membershipRowsPerPage
    );

  useEffect(() => {
    // filter the data based on the item type and store into a global variable

    // let service = (tableData && tableData.length > 0) && tableData.filter(item => (item.itemType === 'Service'));
    let servicelist =
      tableData &&
      tableData?.length > 0 &&
      tableData
        .filter((services) => services?.itemType === 'PricingOption')
        .map((item) => item.sortKey);

    let postService =
      allServices &&
      allServices?.length &&
      allServices?.filter((service) => {
        return (
          servicelist &&
          servicelist?.length > 0 &&
          servicelist?.includes(service.sortKey)
        );
      });

    // let product = (tableData && tableData.length > 0) && tableData.filter(item => (item.itemType === 'Product'));
    let productlist =
      tableData &&
      tableData.length > 0 &&
      tableData
        .filter((products) => products.itemType === 'Product')
        .map((item) => item.sortKey);

    let postProduct =
      allProducts?.length &&
      allProducts.filter((products) => {
        return (
          productlist &&
          productlist.length > 0 &&
          productlist?.includes(products.sortKey)
        );
      });

    // let bundle = (tableData && tableData.length > 0) && tableData.filter(item => (item.itemType === 'Bundle'));
    let bundlelist =
      tableData &&
      tableData.length > 0 &&
      tableData
        .filter((bundles) => bundles.itemType === 'Bundle')
        .map((item) => item.sortKey);

    let postBundle =
      allBundles?.length &&
      allBundles.filter((bundle) => {
        return (
          bundlelist &&
          bundlelist.length > 0 &&
          bundlelist?.includes(bundle.sortKey)
        );
      });

    // let contract = (tableData && tableData.length > 0) && tableData.filter(item => (item.itemType === 'Contract'));
    let contractlist =
      tableData &&
      tableData.length > 0 &&
      tableData
        .filter((contracts) => contracts.itemType === 'Contract')
        .map((item) => item.sortKey);

    let postContract =
      allContracts?.length &&
      allContracts.filter((contract) => {
        return (
          contractlist &&
          contractlist.length > 0 &&
          contractlist?.includes(contract.sortKey)
        );
      });

    // let membership = (tableData && tableData.length > 0) && tableData.filter(item => (item.itemType === 'Membership'));
    let membershiplist =
      tableData &&
      tableData.length > 0 &&
      tableData
        .filter((memberships) => memberships.itemType === 'Membership')
        .map((item) => item.sortKey);

    let postMembership =
      allMemberships?.length &&
      allMemberships.filter((member) => {
        return (
          membershiplist &&
          membershiplist.length > 0 &&
          membershiplist?.includes(member.sortKey)
        );
      });

    // let servicelist = allServices && allServices.filter((item) => item.sortKey === serviceData.sortKey);
    // let productlist = allProducts && allProducts.filter((item) => item.sortKey === product.sortKey);
    // let bundlelist = allBundles && allBundles.filter((item) => item.sortKey === bundleData.sortKey);
    // let contractlist = allContracts && allContracts.filter((item) => item.sortKey === contractData.sortKey);
    // let memberlist = allMemberships && allMemberships.filter((item) => item.sortKey === membershipData.sortKey);
    // console.log("productlist", postService, postProduct, postBundle, postContract, postMembership);

    // // Now set the state
    // setServiceData(servicelist);
    // setProductData(productlist);
    // setBundleData(bundlelist);
    // setContractData(contractlist);
    // setMembershipData(memberlist);

    // setServiceData(service);
    // setProductData(product);
    // setBundleData(bundle);
    // setContractData(contract);
    // setMembershipData(membership);
    setServiceData(postService);
    setProductData(postProduct);
    setBundleData(postBundle);
    setContractData(postContract);
    setMembershipData(postMembership);
  }, [
    tableData,
    allServices,
    allProducts,
    allBundles,
    allContracts,
    allMemberships
  ]);

  const getPromotionDetailsById = async (sortKey) => {
    // locationId
    setIsLoading(true);
    let formattedLocationId = encodeURIComponent(locationId);
    let data = await ApiService.get(
      `${
        process.env.REACT_APP_MEMBERSHIP_API_END_POINT
      }promotions/getPromosDetails?locationId=${formattedLocationId}&promoId=${encodeURIComponent(
        sortKey
      )}`
    );
    // let data = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}promotions/getPromosDetails?promoId=${encodeURIComponent(sortKey)}`)
    // setPromotionDetails(data.data.Items);
    console.log(data);
    setIsLoading(false);
  };

  const handleClosePromotionDetails = () => {
    setShowScreen(0);
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const deleteDetails = async () => {
    setIsLoading(true);
    // let locationID = locationId;
    let promoID = details.sortKey;
    let Id = userId;
    let dataDelete = {
      promoId: promoID,
      locationId: locationId,
      userId: Id
    };

    let data = await ApiService.delete(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}promotions`,
      dataDelete
    );

    if (data.statusCode === 200) {
      setIsLoading(false);
      setOpenMessageDialog(true);
      setOpenDeleteDialog(false);
      getPromotionDetails();
    }
  };

  const handleClose = (val) => {
    if (val === 'Cancel') {
      setOpenDeleteDialog(false);
    }
    if (val === 'Delete') {
      deleteDetails();
    }
    if (val === 'message-close') {
      setOpenMessageDialog(false);
      setShowScreen(0);
    }
  };

  const PromotionDetails = styled(Container)(
    ({ theme }) => `
              margin-top: ${theme.spacing(0)};
      `
  );

  const handleClickEdit = () => {
    // setShowEditPromotion(true);
    // setShowEditPromotionDialog(true);
    setShowScreen(3);
  };

  const getAllProducts = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}promotions/getAllProducts?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      // console.log(Item, "Item---9ItemItemItem");
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllProducts(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllServices = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}promotions/getAllPricingOptions?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      // console.log(Item, 'Item----');
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllServices(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllBundles = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}promotions/getAllBundles?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllBundles(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllContracts = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}promotions/getAllContracts?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllContracts(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllMemberships = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}promotions/getAllMembership?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllMemberships(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (!details.applyToAll) {
      getAllServices();
      getAllProducts();
      getAllBundles();
      getAllContracts();
      getAllMemberships();
    }
  }, [details]);

  function getBillingCycleValue(value) {
    if (value === 'every year') {
      return 'Per Year';
      // eslint-disable-next-line no-else-return
    } else if (value === 'every month') {
      return 'Per Month';
    }
    return '';
  }

  const getOptionLabel = (option) => {
    const { itemName, variants } = option;

    if (
      variants &&
      variants?.filter((variant) => variant?.value)?.length === 0
    ) {
      return `${itemName}`;
    }
    if (variants && variants?.filter((variant) => variant?.value)?.length > 0) {
      const variantLabels = variants
        ?.filter((variant) => variant?.value)
        ?.map((v) => v?.value);
      const variantLabel =
        variantLabels?.length === 1
          ? `${itemName} (${variantLabels[0]})`
          : `${itemName} (${variantLabels?.join(', ')})`;

      return variantLabel;
    }
    return `${itemName}`;
  };

  // useEffect(() => {
  //     let servicelist = allServices && allServices.filter((item) => item.sortKey === serviceData.sortKey);
  //     let productlist = allProducts && allProducts.filter((item) => item.sortKey === productData.sortKey);
  //     //     let productlist = tableData
  //     //   .filter((products) => products.itemType === 'Product')
  //     //   .map((item) => item.sortKey);
  //     //   let postProduct = allProducts.filter((products) => {
  //     //     return productlist.includes(products.sortKey);
  //     //   });
  //     let bundlelist = allBundles && allBundles.filter((item) => item.sortKey === bundleData.sortKey);
  //     let contractlist = allContracts && allContracts.filter((item) => item.sortKey === contractData.sortKey);
  //     let memberlist = allMemberships && allMemberships.filter((item) => item.sortKey === membershipData.sortKey);
  //     // console.log("productlist--", productlist);

  //     // Now set the state
  //     setServiceData(servicelist);
  //     setProductData(productlist);
  //     setBundleData(bundlelist);
  //     setContractData(contractlist);
  //     setMembershipData(memberlist);
  // }, [allServices, allProducts, allBundles, allContracts, allMemberships])

  return (
    <>
      <Loader IsLoading={isLoading} />
      <PromotionDetails maxWidth={'100%'} className="location-details-wrapper">
        <Grid
          className="location-header-container"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleClosePromotionDetails()}
                className="back-arrow-close"
                sx={{ mt: 2 }}
              >
                {/* <ArrowLeft/> */}
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              <Box>
                <Typography className="header-blue-40">
                  {details.name}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* Delete icon and edit button */}
          <Grid xs={4} sm={4} md={4} lg={4} className="align-right">
            {/* remove icon */}

            {/* Edit Button */}
            <Button
              className="details-remove-icon no-hover"
              onClick={handleClickOpenDeleteDialog}
              sx={{ mt: 2 }}
            >
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button>
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={handleClickEdit}
              className="btn-primary edit-button common-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Box className="location-details-container">
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} md={12} sm={12}>
              <Typography className="header-blue-22">
                Promotion Setup
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row mt-20">
            <Grid item xs={12} sm={2.5} className="location-details-col">
              <Box className="location-details-label">PROMOTION NAME</Box>
            </Grid>
            <Grid item xs={12} sm={3.5} className="location-details-col">
              <Box className="location-details-value">{details.name}</Box>
            </Grid>
            <Grid item xs={12} sm={2.5} className="location-details-col">
              <Box className="location-details-label">PROMO CODE</Box>
            </Grid>
            <Grid item xs={12} sm={3.5} className="location-details-col">
              <Box className="location-details-value uppercase-text">
                {details.promoCode}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2.5}
              md={2.5}
              className="location-details-col mt-20"
            >
              <Box className="location-details-label">DISCOUNT</Box>
            </Grid>
            <Grid item xs={12} sm={3.5} className="location-details-col mt-20">
              <Box className="location-details-value">
                {details.discount && details?.discountType === 'Fixed'
                  ? `$${parseFloat(details.discount)?.toFixed(2)}`
                  : `${parseFloat(details.discount)?.toFixed(2)}%`}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2.5}
              md={2.5}
              className="location-details-col mt-20"
            >
              <Box className="location-details-label">LIMIT</Box>
            </Grid>
            <Grid item xs={12} sm={3.5} className="location-details-col mt-20">
              <Box className="location-details-value">
                {details.limit + ' time per dog'}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2.5}
              md={2.5}
              className="location-details-col mt-20"
            >
              <Box className="location-details-label">DISCOUNT TYPE</Box>
            </Grid>
            <Grid item xs={12} sm={3.5} className="location-details-col mt-20">
              <Box className="location-details-value">
                {details.discountType === 'Fixed' ? 'Fixed' : 'Percentage'}
              </Box>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} md={12} sm={12}>
                <Typography className="header-blue-22">
                  Promotion Usability
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={2.5} className="location-details-col mt-20">
                            <Box className="location-details-label">USE AT STORE</Box>
                        </Grid>
                        <Grid item xs={12} sm={3.5} className="location-details-col mt-20">
                            <Box className="location-details-value">
                                {details.useOnline ? 'No' : 'Yes'}
                            </Box>
                        </Grid> */}

            <Grid item xs={12} sm={2.5} className="location-details-col mt-20">
              <Box className="location-details-label">USE ONLINE</Box>
            </Grid>
            <Grid item xs={12} sm={3.5} className="location-details-col mt-20">
              <Box className="location-details-value">
                {details.useOnline ? 'Yes' : 'No'}
              </Box>
            </Grid>

            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} md={12} sm={12}>
                <Typography className="header-blue-22">
                  Promotion Schedule
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2.5} className="location-details-col mt-20">
              <Box className="location-details-label">Never Expire</Box>
            </Grid>
            <Grid item xs={12} sm={3.5} className="location-details-col mt-20">
              <Box className="location-details-value">
                {details.neverExpire ? 'Yes' : 'No'}
              </Box>
            </Grid>
            <Grid container className="location-details-row">
              <Grid
                item
                xs={12}
                sm={2.5}
                className="location-details-col mt-20"
              >
                <Box className="location-details-label">Start Date</Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3.5}
                className="location-details-col mt-20"
              >
                <Box className="location-details-value">
                  {details.startDate}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={2.5}
                className="location-details-col mt-20"
              >
                <Box className="location-details-label">End Date</Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3.5}
                className="location-details-col mt-20"
              >
                <Box className="location-details-value">
                  {details.neverExpire
                    ? 'No End date available'
                    : details.endDate}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="location-details-container">
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={12} md={12} className="location-details-col">
              <Typography className="field-heading-text header-blue-22">
                Promotion Target Selections
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          className="location-details-row location-details-wrapper"
        >
          <Grid item xs={12} sm={2.5} className="location-details-col mt-20">
            <Box className="location-details-label location-name">
              Apply to All
            </Box>
          </Grid>
          <Grid item xs={12} sm={3.5} className="location-details-col mt-20">
            <Box className="location-details-value">
              {details.applyToAll ? 'Yes' : 'No'}
            </Box>
          </Grid>

          <Grid item xs={12} sm={2.5} className="location-details-col mt-20">
            <Box className="location-details-label location-name">
              Allow discount on Gift card Purchase
            </Box>
          </Grid>
          <Grid item xs={12} sm={3.5} className="location-details-col mt-20">
            <Box className="location-details-value">
              {details.allowDiscountOnGiftCardPurchase ? 'Yes' : 'No'}
            </Box>
          </Grid>
        </Grid>

        {details.applyToAll ? (
          <Box className="location-details-labe header-blue-22 location-name mt-20">
            This promo code has been applied to all items.
          </Box>
        ) : (
          <>
            <Box className="location-details-container">
              <Grid container mt={3} className="location-details-row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="location-details-col"
                >
                  <Typography className="field-heading-text font-17">
                    Chosen Pricing Option
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Card className="mt-20">
              <TableContainer className="client-table-container mb-20 location-table-container">
                <Table>
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell>Name</TableCell>
                      {/* <TableCell>Category</TableCell> */}
                      <TableCell className="text-transform-none">
                        Credits
                      </TableCell>
                      <TableCell>Price</TableCell>

                      {/* <TableCell>Price</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicePaginatedData && servicePaginatedData.length > 0 ? (
                      Object.entries(servicePaginatedData).map(
                        ([key, value]) => {
                          // const itemValue = value.itemValue;
                          const itemValue = value;
                          return (
                            <TableRow key={key}>
                              <TableCell className="table-td">
                                {itemValue?.name}
                              </TableCell>
                              {/* <TableCell className="table-td">{itemValue?.categoryName}</TableCell> */}
                              <TableCell className="table-td">
                                {itemValue?.numberOfSessions}
                              </TableCell>
                              <TableCell className="table-td">
                                ${parseFloat(itemValue?.price ?? 0)?.toFixed(2)}
                              </TableCell>
                              {/* <TableCell className="table-td">{itemValue?.price && '$' + itemValue.price}</TableCell> */}
                            </TableRow>
                          );
                        }
                      )
                    ) : (
                      <TableRow>
                        <TableCell className="table-td">
                          No data available
                        </TableCell>
                        <TableCell className="table-td" />
                        <TableCell className="table-td" />
                        <TableCell className="table-td" />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]} // Customize the options as needed
                component="div"
                count={serviceData.length} // Replace 'serviceData' with your actual data array
                rowsPerPage={serviceRowsPerPage}
                page={servicepage}
                onPageChange={(event, newPage) =>
                  handleChangePage(newPage, 'Service')
                }
                onRowsPerPageChange={(event) =>
                  handleChangeRowsPerPage(event, 'Service')
                }
              />
            </Card>
            <Box className="location-details-container">
              <Grid container mt={3} className="location-details-row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="location-details-col"
                >
                  <Typography className="field-heading-text font-17">
                    Chosen Products
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Card className="mt-20">
              <TableContainer className="client-table-container mb-20">
                <Table>
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell>Name</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Variants</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productPaginatedData && productPaginatedData.length > 0 ? (
                      Object.entries(productPaginatedData).map(
                        ([key, value]) => {
                          // const itemValue = value.itemValue;
                          const itemValue = value;
                          return (
                            <TableRow key={key}>
                              <TableCell className="table-td">
                                {itemValue?.fName}
                              </TableCell>
                              <TableCell className="table-td">
                                {itemValue?.categoryName}
                              </TableCell>

                              <TableCell className="table-td">
                                {(itemValue?.variants &&
                                  itemValue?.variants
                                    ?.filter((variant) => variant?.value)
                                    ?.map?.((item, index, array) =>
                                      index === array.length - 1
                                        ? item?.value
                                        : `${item?.value}, `
                                    )
                                    .join('')) ||
                                  '-'}
                              </TableCell>
                              {/* <TableCell className="table-td">{itemValue?.price && '$' + itemValue.price}</TableCell> */}
                              <TableCell className="table-td">
                                ${parseFloat(itemValue?.price || 0)?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    ) : (
                      <TableRow>
                        <TableCell className="table-td">
                          No data available
                        </TableCell>
                        <TableCell className="table-td" />
                        <TableCell className="table-td" />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]} // Customize the options as needed
                component="div"
                count={productData.length} // Replace 'serviceData' with your actual data array
                rowsPerPage={productRowsPerPage}
                page={productpage}
                onPageChange={(event, newPage) =>
                  handleChangePage(newPage, 'Product')
                }
                onRowsPerPageChange={(event) =>
                  handleChangeRowsPerPage(event, 'Product')
                }
              />
            </Card>
            <Box className="location-details-container">
              <Grid container mt={3} className="location-details-row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="location-details-col"
                >
                  <Typography className="field-heading-text font-17">
                    Chosen Bundles
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Card className="mt-20">
              <TableContainer className="client-table-container mb-20">
                <Table>
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell>Name</TableCell>
                      <TableCell>Pricing Options & Products</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bundlePaginatedData && bundlePaginatedData.length > 0 ? (
                      Object.entries(bundlePaginatedData).map(
                        ([key, value]) => {
                          // const itemValue = value.itemValue;
                          const itemValue = value;
                          return (
                            <TableRow key={key}>
                              <TableCell className="table-td">
                                {itemValue?.bundleName}
                              </TableCell>
                              <TableCell className="table-td">
                                {itemValue?.items?.length > 0 ? (
                                  itemValue?.items?.length === 1 ? (
                                    getOptionLabel(itemValue?.items[0])
                                  ) : (
                                    <>
                                      {itemValue?.items
                                        ?.slice(0, 2)
                                        .map((item, index) => (
                                          <span key={index}>
                                            {getOptionLabel(item)}
                                            {index === 0 && ', '}
                                          </span>
                                        ))}
                                      {itemValue?.items?.length > 2 && ', ...'}
                                    </>
                                  )
                                ) : (
                                  'No items available'
                                )}
                              </TableCell>
                              {/* <TableCell className="table-td">{itemValue?.bundlePrice && '$' + itemValue.bundlePrice}</TableCell> */}
                              <TableCell className="table-td">
                                $
                                {parseFloat(
                                  itemValue?.bundlePrice || 0
                                )?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    ) : (
                      <TableRow>
                        <TableCell className="table-td">
                          No data available
                        </TableCell>
                        <TableCell className="table-td" />
                        <TableCell className="table-td" />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]} // Customize the options as needed
                component="div"
                count={bundleData.length} // Replace 'serviceData' with your actual data array
                rowsPerPage={bundleRowsPerPage}
                page={bundlepage}
                onPageChange={(event, newPage) =>
                  handleChangePage(newPage, 'Bundle')
                }
                onRowsPerPageChange={(event) =>
                  handleChangeRowsPerPage(event, 'Bundle')
                }
              />
            </Card>
            <Box className="location-details-container">
              <Grid container mt={3} className="location-details-row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="location-details-col"
                >
                  <Typography className="field-heading-text font-17">
                    Chosen Contracts
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Card className="mt-20">
              <TableContainer className="client-table-container mb-20">
                <Table>
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell>Name</TableCell>
                      <TableCell>Contract Type</TableCell>
                      <TableCell>Billing Cycle</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contractPaginatedData &&
                    contractPaginatedData.length > 0 ? (
                      Object.entries(contractPaginatedData).map(
                        ([key, value]) => {
                          // const itemValue = value.itemValue;
                          const itemValue = value;
                          return (
                            <TableRow key={key}>
                              <TableCell className="table-td">
                                {itemValue?.contractName}
                              </TableCell>
                              <TableCell className="table-td">
                                {itemValue?.contractType}
                              </TableCell>
                              <TableCell className="table-td">
                                {itemValue?.billingCycle}
                              </TableCell>
                              <TableCell className="table-td">
                                {/* ${itemValue?.items && itemValue?.items.length > 0 && itemValue?.items.map((item) => (item?.pricingOption && item?.pricingOption?.price) || 0)} */}
                                ${parseFloat(itemValue?.price || 0)?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    ) : (
                      <TableRow>
                        <TableCell className="table-td">
                          No data available
                        </TableCell>
                        <TableCell className="table-td" />
                        <TableCell className="table-td" />
                        <TableCell className="table-td" />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]} // Customize the options as needed
                component="div"
                count={contractData.length} // Replace 'serviceData' with your actual data array
                rowsPerPage={contractRowsPerPage}
                page={contractpage}
                onPageChange={(event, newPage) =>
                  handleChangePage(newPage, 'Contract')
                }
                onRowsPerPageChange={(event) =>
                  handleChangeRowsPerPage(event, 'Contract')
                }
              />
            </Card>
            <Box className="location-details-container">
              <Grid container mt={3} className="location-details-row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="location-details-col"
                >
                  <Typography className="field-heading-text font-17">
                    Chosen Memberships
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Card className="mt-20">
              <TableContainer className="client-table-container mb-20">
                <Table>
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell>Name</TableCell>
                      <TableCell>Billing Cycle</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {membershipPaginatedData &&
                    membershipPaginatedData.length > 0 ? (
                      Object.entries(membershipPaginatedData).map(
                        ([key, value]) => {
                          // const itemValue = value.itemValue;
                          const itemValue = value;
                          return (
                            <TableRow key={key}>
                              <TableCell className="table-td">
                                {itemValue?.name}
                              </TableCell>
                              {/* <TableCell className="table-td">{itemValue?.billingCycle && `Every ${itemValue?.billingCycle} months`}</TableCell> */}
                              <TableCell className="table-td">
                                {getBillingCycleValue(itemValue?.billingCycle)}
                              </TableCell>
                              {/* <TableCell className="table-td">{itemValue?.price && '$' + itemValue.price}</TableCell> */}
                              <TableCell className="table-td">
                                ${parseFloat(itemValue?.price || 0)?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    ) : (
                      <TableRow>
                        <TableCell className="table-td">
                          No data available
                        </TableCell>
                        <TableCell className="table-td" />
                        <TableCell className="table-td" />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]} // Customize the options as needed
                component="div"
                count={membershipData.length} // Replace 'serviceData' with your actual data array
                rowsPerPage={membershipRowsPerPage}
                page={membershippage}
                onPageChange={(event, newPage) =>
                  handleChangePage(newPage, 'Membership')
                }
                onRowsPerPageChange={(event) =>
                  handleChangeRowsPerPage(event, 'Membership')
                }
              />
            </Card>
          </>
        )}
      </PromotionDetails>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMessageDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('message-close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected promotion has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('message-close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PromotionDetails;
