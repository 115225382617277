import './index.css';
import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { closest } from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Slide,
  Divider,
  FormHelperText
} from '@mui/material';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import Loader from '../../../components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ApiService } from '../../../services/ApiService';
import { getComponent } from '@syncfusion/ej2-base';
import { getLocalData } from 'src/utils';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export const EditCohorts = ({
  selectedRow,
  setShowScreen,
  getFranchisee,
  getCohorts
}) => {
  const locationId = getLocalData('locationId');
  let dropDownObj = useRef(null);
  const qryBldrObj = useRef(null);
  const [cohortNameError, setCohortNameError] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [columnData, setcolumnData] = useState([]);
  const [selectedGroupData, setSelectedGroupData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [importRules, setImportRules] = useState({});
  const [columns, setColumns] = useState([]);
  const [creteriaError, setCreteriaErrors] = useState('');
   const [cohortData, setCohortData] = useState([]);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [open, setOpen] = useState(false);

  const franchiseeId = getLocalData('franchiseeId');
  let cohortId = selectedRow?.sortKey;
  useEffect(() => {
    setCohortName(selectedRow?.Name);

    if (cohortData?.Segments?.length > 0) {
      const updatedImportRules = { ...cohortData.Segments[0] };

      if (updatedImportRules.rules.length === 0) {
        // If rules are empty, set importRules with default values
        setImportRules({
          condition: 'and',
          rules: [
            {
              label: '',
              field: '',
              type: '',
              operator: '',
              value: ''
            }
          ]
        });
      } else {
        // If rules have values, map and set the modified rules
        mapFieldRules(updatedImportRules.rules);
        setImportRules(updatedImportRules);
        // qryBldrObj.current.setRules(updatedImportRules)
      }
    }
  }, [cohortData]);
console.log(importRules,'selectedRowselectedRowselectedRow');
  useEffect(() => {
    setSelectedGroupData(importRules);
  }, [importRules]);
  function getStatusNames(status) {
    switch (status) {
      case 'earlyCancel':
        return 'Early Cancel';
      case 'lateCancel':
        return 'Late Cancel';
      case 'checkIn':
        return 'Check In';
      case 'arrivedLate':
        return 'Arrived Late';
      case 'absent':
        return 'Absent';
      // Add more cases as needed
      default:
        return '';
    }
  }
  function mapFieldRules(rules) {
    console.log(rules,'rulesrulesrulesrules');
    rules?.forEach((rule) => {
      if (
        rule.operator === 'between' &&
        Array.isArray(rule.value) &&
        rule.value.length === 2
      ) {
        rule.value[0] = convertDateFormat(rule.value[0]);
        rule.value[1] = convertDateFormat(rule.value[1]);
      }
      if (rule.field === 'status') {
        rule.value = getStatusNames(rule.value);
      }
   
      if (rule.rules && rule.rules.length > 0) {
        mapFieldRules(rule.rules); // Recursively call the function for nested rules
      }
    });
  }

  function convertDateFormat(inputDate) {
    const date = new Date(inputDate);

    if (Number.isNaN(date)) {
      console.error('Invalid date format. Please provide a valid date.');
      return null; // Or handle the error in your specific way
    }

    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  // State to hold selected group data
  const dataValueToAppend = (data) => {
    return {
      create: () => {
        const elem = document.createElement('input');
        elem.setAttribute('type', 'text');
        return elem;
      },
      destroy: (args) => {
        dropDownObj.current = getComponent(
          document.getElementById(args.elementId),
          'dropdownlist'
        );
        if (dropDownObj.current) {
          dropDownObj.current.destroy();
        }
      },
      write: (args) => {
        const ds = data;
        dropDownObj.current = new DropDownList({
          dataSource: ds,
          value: args.values ? args.values : ds[0],
          change: (e) => {
            qryBldrObj.current.notifyChange(e.itemData.value, e.element);
          }
        });
        dropDownObj.current.appendTo('#' + args.elements.id);
      }
    };
  };
  const statusName = [
    "",
    "Arrived Late",
    "Early Cancel",
    "Late Cancel",
    "Absent",
    "Check In"
]
  useEffect(() => {
    if (columnData?.length > 0) {
      const updatedColumnData = columnData.map((field) => {
        if (field?.dataValue) {
          // Append the dataValue field to the object
          if(field.field==='status'){
            return{
              ...field,
              template: dataValueToAppend(statusName)
            }
          }
          return {
            ...field,
            template: dataValueToAppend(field.dataValue)
          };
        }
        if(field.field==='booked'||field.field==='purchase'){
          return {
            ...field,
            values: ['True', 'False']
          };
  
        }
        return field;
      }); 
      setColumns(updatedColumnData);
    }
  }, [columnData]);
  const getCohortDetail = async () => {
    let encodedLocationId = encodeURIComponent(franchiseeId);
    let encodedCohortId = encodeURIComponent(cohortId);
    setIsLoading(true);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getCohortsDetails?partitionKey=${encodedLocationId}&sortKey=${encodedCohortId}`
      );

      if (!response.error) {
        setCohortData(response?.data?.Items[0]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  console.log(cohortData,'responseeeeeeeeeecohortData');

  const getAllAttribute = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    setIsLoading(true);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAttributeFilter?locationId=${encodedLocationId}`
      );

      if (!response.error) {
        setcolumnData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllAttribute();
    getCohortDetail();
  }, []);
  const handleCreateCohortClose = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
    }
  };
  const handleQueryBuilderChange = () => {
    setCreteriaErrors('');
  };

  const handleInputChange = (e) => {
    const { name } = e.target;
    if (name === 'cohortName') {
      setIsFieldUpdated(true);
      setCohortName(e.target.value);
      setCohortNameError('');
      //   setIsFiendUpdated(true);
    }
  };
  function convertDate(inputDate) {
    let dateParts;
    if (inputDate?.includes('/')) {
      dateParts = inputDate?.split('/');
    } else {
      return inputDate;
    }

    // Reformatting the date parts into the desired format
    const formattedDate = `${dateParts[2]}-${dateParts[0]?.padStart(
      2,
      '0'
    )}-${dateParts[1]?.padStart(2, '0')}T00:00:00.000Z`;
    return formattedDate;
  }
  function getStatusName(status) {
    switch (status) {
      case 'Early Cancel':
        return 'earlyCancel';
      case 'Late Cancel':
        return 'lateCancel';
      case 'Check In':
        return 'checkIn';
      case 'Arrived Late':
        return 'arrivedLate';
      case 'Absent':
        return 'absent';
      // Add more cases as needed
      default:
        return '';
    }
  }
  function mapFieldDataToRules(rules) {
    rules.forEach((rule) => {
      if (rule.field) {
        const fieldInfo = columnData.find(
          (field) => field.field === rule.field
        );
        if (fieldInfo) {
          rule.label = fieldInfo.label;
          rule.type = fieldInfo.type;
          if (fieldInfo.gsi) {
            rule.gsi = fieldInfo.gsi;
          }
          if (fieldInfo.data) {
            rule.data = fieldInfo.data;
          }
          if (fieldInfo.tables) {
            rule.tables = fieldInfo.tables;
          }
          if (fieldInfo.masterData) {
            rule.masterData = fieldInfo.masterData;
          }
          if (rule.operator === 'between') {
            rule.value[0] = convertDate(rule.value[0]);
            rule.value[1] = convertDate(rule.value[1]);
          }
          if (fieldInfo.field === "status") {
            rule.value = getStatusName(rule.value)
          }
          if (fieldInfo.field === "purchase"||fieldInfo.field === "booked") {
            rule.value = rule?.value?.toLowerCase()
          }
          // if (fieldInfo.values) {
          //     rule.values = fieldInfo.values;
          // }
        }
      }

      // Check if the current rule has nested rules
      if (rule.rules && rule.rules.length > 0) {
        mapFieldDataToRules(rule.rules); // Recursively call the function for nested rules
      }
    });
  }
  // Usage: Check for empty fields in selectedGroupData
  function validateRules(rules) {
    rules.forEach((rule) => {
      if (rule.rules) {
        // If the rule contains nested rules, recursively validate them
        validateRules(rule.rules);
      } else if (
        !rule.field ||
        !rule.operator ||
        rule.value === 0 ||
        rule.value === ''
      ) {
        setCreteriaErrors('Criteria has missing field, operator or value');
        throw new Error(
          "Rule with blank 'field', 'operator', or 'value' equal to 0 detected"
        );
      }
    });
  }
  const handleSubmit = async () => {
    let data = qryBldrObj.current.getRules();
    if (data) mapFieldDataToRules(data?.rules);
    setSelectedGroupData(data);

    try {
      // Validate cohort name
      const cohortNameValidation = new Promise((resolve) => {
        if (!cohortName || cohortName.trim() === '') {
          setCohortNameError('Cohort name is required.');
        } else {
          resolve();
        }
      });

      // Run both validations concurrently
      await Promise.all([cohortNameValidation, validateRules(data.rules)]);

      // Both validations succeeded, continue with other actions
      await UpdateCohorts(data);
      // Further logic for form submission or other actions
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle errors if necessary
    }
  };
  console.log(importRules,'importtttttttttttsssssssss');
  console.log(selectedGroupData,'importttttttttttt');
  const UpdateCohorts = async (item) => {
    let encodedCohortId = encodeURIComponent(cohortId);
    setIsLoading(true);
    let data = {
      franchiseeId: franchiseeId,
      Name: cohortName,
      updatedBy: getFranchisee,
      Segments: [item]
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/updateFranchiseeCohort?cohortId=${encodedCohortId}`,
        data
      );

      if (response.statusCode === 200) {
        if (response.error === false) {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setSuccessMessage('Cohort updated successfully');
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
    }
  };
  const closePopup = () => {
    setShowSuccessMessage(false);
    setShowScreen(0);
    getCohorts();
  };
  const handleCloseConfirmation = (val) => {
    if (val === 'Save') {
      setOpen(false);
    }

    if (val === 'Discard') {
      setOpen(false);
      getCohorts();
      // Here redirecting to the client details page.
      setShowScreen(0);
    }
  };
  const headerTemplate = (props) => {
    let items;
    let fields;
    console.log(qryBldrObj, 'importrulesssss');

    items = [
      { key: 'AND', value: 'and' },
      { key: 'OR', value: 'or' }
    ];
    fields = { text: 'key', value: 'value' };
    let state = { ...props };
    const conditionChange = (args) => {
      qryBldrObj.current.notifyChange(args.value, args.element, 'condition');
      //   handleQueryBuilderChange()
    };
    const addGroupClick = () => {
      let addbtn = 'querybuilder_group0';
      let ddb = addbtn.split('_');
      qryBldrObj.current.addGroups([{ condition: 'and', rules: [{}] }], ddb[1]);
      //   }
    };
    const addRuleClick = (args) => {
        let addbtn = args.currentTarget.offsetParent.id;
        let ddb = addbtn.match(/group(\d+)/); // Use regex to extract the group number
        if (ddb && ddb[0]) {
            console.log(ddb[0],'ddb[1]');
          qryBldrObj.current.addRules([{}], ddb[0]);
        } else {
          console.error('Group ID not found');
        }
      };
      
    const onClick = (args) => {
      qryBldrObj.current.deleteGroup(
        closest(args.target.offsetParent, '.e-group-container')
      );
    };
    const args = state;
    return (
      <div className="query-template-control">
        <div className="e-groupheader">
          <DropDownListComponent
            id={args.ruleID + '_cndtn'}
            cssClass="e-custom-group-btn"
            width="100px"
            dataSource={items}
            fields={fields}
            value={args.condition}
            change={conditionChange}
          />
          <div className="e-header">
            {(() => {
              if (args.groupID === null) {
                return (
                  <div className="e-qb-hdr-content">
                    <ButtonComponent
                      className="e-grp-btn btn-style-cohort"
                      cssClass="e-primary"
                      onClick={addGroupClick}
                    >
                      Add Group
                    </ButtonComponent>
                  </div>
                );
              }
              return null;
            })()}

            <div className="e-qb-hdr-content">
              <ButtonComponent
                className="e-cond-btn btn-style-cohort"
                cssClass="e-primary"
                onClick={addRuleClick}
              >
                Add Condition
              </ButtonComponent>
            </div>

            {(() => {
              if (args.groupID !== null) {
                return (
                  <div className="e-qb-hdr-content ">
                    <ButtonComponent
                      id={args.ruleID + '_dltbtn'}
                      cssClass="e-danger"
                      onClick={onClick}
                      className="btn-style-cohort"
                    >
                      Remove
                    </ButtonComponent>
                  </div>
                );
              }
              return null;
            })()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
            mt={1}
          >
            <Box
              onClick={() => handleCreateCohortClose()}
              className="back-arrow-close"
            >
              <ArrowLeft />
            </Box>

            <Typography className="header-blue-32">Edit Cohort</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className="" mt={2} ml={1}>
        <Grid container xs={12} sm={12} mt={2}>
          <Grid item xs={6} sm={6} mt={2}>
            <TextField
              fullWidth
              name="cohortName"
              error={Boolean(cohortNameError)}
              helperText={cohortNameError}
              // onKeyUp={(e) => checkCohortNameExist(e.target)}
              autoComplete="SENDER NAME"
              label="COHORT NAME *"
              placeholder="Cohort Name"
              className="location-field input-field-styling"
              value={cohortName}
              onChange={handleInputChange}
              InputLabelProps={{
                className: 'label-style-create-franchisee edit-address-label'
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px',
          mt: 5
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          ml={2}
        >
          <Grid item>
            <Typography className="table-heade header-blue-20">
              {' '}
              Criteria
            </Typography>
          </Grid>
        </Grid>
        {importRules?.rules?.length>0 && columns.length > 0 && (
          <div className="control-pane">
            <div className="control-section">
              <div className="row">
                <div className="col-lg-12 control-section">
                  <QueryBuilderComponent
                    columns={columns}
                    rule={importRules}
                    ref={qryBldrObj}
                    headerTemplate={headerTemplate}
                    change={handleQueryBuilderChange}
                    className="outlined-inputs"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <FormHelperText>{creteriaError}</FormHelperText>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="right"
        gap={2}
        mt={5}
      >
        {/* <Grid item>
                    <Button className="cohorts-outlined-btn" variant="outlined"
                      onClick={handleCreateCohortClose}
                    >
                        Cancel
                    </Button>
                </Grid> */}
        <Grid item mb={3}>
          <Button
            onClick={() => handleSubmit()}
            className="cohorts-outlined-btn"
            variant="contained"
          >
            Update Cohort
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleCloseConfirmation('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirmation('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseConfirmation('Save')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
