import React, { useEffect, useState } from 'react';
import AddAndWithdralCash from './AddAndWithdralCash';
import { CashBoxTable } from './CashBoxTable';
import { TextField, Button, Typography } from '@mui/material';
import Header from 'src/components/Header';
import { Box } from '@mui/system';
import CashBoxFilter from './CashBoxFilter';
import SearchIcon from '@mui/icons-material/Search';
import { getCashBoxData } from './ApiCall';
import Loader from '../../../components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

function Index() {
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [tableData, setTableData] = useState({ Items: [] });
  const [searchText, setSearchText] = useState('');
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));
  const classes = useStyles();
  const setInitialDates = () => {
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setMonth(currentDate.getMonth() - 3);
    getCashBoxData(
      startDate,
      currentDate,
      setLoading,
      setShowErrorMessage,
      setErrorMessage,
      setTableData,
      setBalance
    );
  };
  useEffect(() => {
    if (startDate === null && endDate === null) {
      setInitialDates();
    } else if (startDate !== null && endDate !== null) {
      const selectedDate = new Date(startDate);
      const endSelectDate = new Date(endDate);
      if (selectedDate <= endSelectDate) {
        getCashBoxData(
          startDate,
          endDate,
          setLoading,
          setShowErrorMessage,
          setErrorMessage,
          setTableData,
          setBalance
        );
      }
    }
  }, [startDate, endDate, showSucessMessage]);
  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
  };
  const filteredData = tableData?.Items?.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchText) : ''
    );
  });
  return (
    <div style={{ padding: '10px 30px' }}>
      <Header title="Cashbox" />
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
        />
      )}
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 20,
            margin: '20px 0px'
          }}
        >
          {/* Start Date DropDown */}
          <div
            style={{
              display: 'flex',
              gap: 20,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                width: '224px',
                height: '82px',
                background: '#F8FBFF',
                border: '1px',
                borderColor: 'rgba(0, 48, 135, 0.1)',
                borderRadius: '10px',
                padding: '15px'
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  color: 'rgba(0, 48, 135, 1)',
                  fontWeight: 700
                }}
              >
                CASH IN-HAND
              </Typography>
              <Typography
                sx={{ fontSize: '20px', fontWeight: 700, color: 'black' }}
              >
                ${parseFloat(balance || 0)?.toFixed(2)}
              </Typography>
            </Box>
          </div>
          <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
            {['Add Amount', 'Withdraw'].map((title) => {
              return (
                <AddAndWithdralCash
                  setLoading={setLoading}
                  setShowSuccessMessage={setShowSuccessMessage}
                  setSuccessMessage={setSuccessMessage}
                  setShowErrorMessage={setShowErrorMessage}
                  setErrorMessage={setErrorMessage}
                  heading={title}
                />
              );
            })}
          </div>
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            sx={{
              width: '130px',
              height: '50px',
              marginBottom: '30px',
              display: 'flex',
              justifyContent: 'space-between',
              background: !isFilterShow ? '#F8FBFF' : ''
            }}
            onClick={() => setIsFilterShow(!isFilterShow)}
            variant={isFilterShow ? 'contained' : 'outlined'}
          >
            Filters
            <KeyboardArrowDownRoundedIcon
              className={clsx(
                !isFilterShow && classes.closeY,
                isFilterShow && classes.openY
              )}
            />
          </Button>
          <TextField
            sx={{ width: '320px', background: '#F8FBFF' }}
            type="search"
            className="Search-field"
            label="Search"
            InputProps={{
              style: {},
              endAdornment: !searchText ? <SearchIcon /> : null
            }}
            onChange={handleSearchTextChange}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {isFilterShow && (
            <Box sx={{ width: '20%' }}>
              <CashBoxFilter
                setstartDate={setstartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
              />
            </Box>
          )}
          <Box sx={{ width: isFilterShow ? '80%' : '100%' }}>
            <CashBoxTable tableData={{ Items: filteredData }} />
          </Box>
        </Box>
      </div>
    </div>
  );
}
export default Index;
