/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import DrawClientTable from './drawClientTable';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import Loader from '../../../components/Loader';
// import { getLocalData } from '../../../utils';
import { getLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ApiService } from '../../../services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function ClientListAPI({
  ref,
  setShowScreen,
  showScreen,
  setallClientList,
  allClientList,
  allLocationList
}) {
  const [clientList, setClientLists] = useState([]);
  const [dataCount, setdataCount] = useState(0);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let [filters, setSidebarFilters] = useState({
    // isActive: null,
    status: 1,
    state: null,
    isAllLocation: null
    // franchiseeId: null,
    // locationId: null
  });
  const userId = getLocalData('userId');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');
  // const locationId = setFullLocationId(getLocalData('locationId'));
  const [selectedClientLists, setSelectedClientLists] = useState([]);
  const [open, setOpen] = React.useState(false);
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // default locationn for franchisor
  const [defaultLocId, setDefaultLocId] = useState('');

  // gets call the method to get the data
  useEffect(() => {
    // Not call the get franchisee method again
    getClientList();
  }, [JSON.stringify(filters), allLocationList, defaultLocId]);

  // Getting hooks from parent to child
  useImperativeHandle(ref, () => ({
    refreshClientData: () => {
      // Not call the get franchisee method again
      getClientList();
    }
  }));

  const refreshClientData = () => {
    getClientList();
  };

  // Get Franchise method to get the list of franchisee
  const getClientList = async () => {
    try {
      // Now show the loader
      setIsLoading(true);
      let filtersParams = JSON.parse(JSON.stringify(filters));
      Object.keys(filtersParams).forEach((key) => {
        if (
          filtersParams[key] === null ||
          filtersParams[key] === '' ||
          filtersParams[key] === 'undefined' ||
          filtersParams[key] === undefined ||
          (Array.isArray(filtersParams[key]) && filtersParams[key].length === 0)
        ) {
          delete filtersParams[key];
        }
      });

      // remove the staus key from the object if value is -1
      if (filtersParams.status === -1) {
        delete filtersParams.status;
      }

      let queryString = Object.keys(filtersParams)
        .map((key) => key + '=' + filtersParams[key])
        .join('&');

      let url;
      let encodedLocationId = encodeURIComponent(locationId);
      let encodedFranchiseeId = encodeURIComponent(franchiseeId);

      if (
        isFranchisor === 'false' &&
        isPrimaryOwner === 'true' &&
        (franchiseeId || locationId)
      ) {
        // if isAllLocation is true then get all client list with location parameter else use franchisee parameter
        if (filters.isAllLocation) {
          url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllClients?franchiseeId=${encodedFranchiseeId}&${queryString}`;
        } else {
          url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllClients?locationId=${encodedLocationId}&${queryString}`;
        }
      } else if (
        isFranchisor === 'false' &&
        isPrimaryOwner === 'false' &&
        (franchiseeId || locationId)
      ) {
        if (filters.isAllLocation) {
          url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllClients?franchiseeId=${encodedFranchiseeId}&${queryString}`;
        } else {
          url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllClients?locationId=${encodedLocationId}&${queryString}`;
        }
      } else {
        let encodedLocId = defaultLocId
          ? encodeURIComponent(defaultLocId)
          : encodeURIComponent(localStorage.getItem('SelectedLocationId')) ||
            (allLocationList &&
              allLocationList?.length > 0 &&
              encodeURIComponent(allLocationList[0]?.locationId));

        url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllClients?locationId=${encodedLocId}&${queryString}`;
        if (!encodedLocId) return false;
      }

      const response = await ApiService.get(`${url}`);
      let data = response;

      if (data.statusCode && !data.error) {
        // Set the list of franchisee
        setClientLists(data.data.Items);
        setallClientList(data.data.Items);
        // Set the list of franchisee count
        setdataCount(data.data.Count);
        // Now hide the loader
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    setIsLoading(true);
    let locId =
      isFranchisor === 'true'
        ? localStorage.getItem('SelectedLocationId')
        : locationId;
    const data = {
      locationId: locId,
      status: selectedStatus,
      updatedBy: userId,
      clientIds: selectedClientLists
    };

    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}client/updateBulk`,
        data
      );

      if (!response?.error) {
        setShowSuccessMessage(true);
        setSuccessMessage(
          'All the selected clients have been updated successfully!'
        );
        setSelectedStatus('');
        setSelectedClientLists([]);
        setIsUpdated(true);
      } else {
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong with update client status');
      }
      setIsLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getClientList();
        setIsUpdated(false);
      }
    }
  };

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
    getClientList();
  };

  return (
    <Grid className="card-table-main" xs={12}>
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={closePopup}
        />
      )}
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      <Loader IsLoading={isLoading} />
      {/* If data count is greater the 0 then pass the value into the parameter  refreshFranchiseeData={ refreshFranchiseeData } */}
      {/* {dataCount > 0 ? */}
      <DrawClientTable
        allClientList={allClientList}
        clientLists={clientList}
        sideBarfilters={filters}
        setSidebarFilters={setSidebarFilters}
        dataCount={dataCount}
        refreshClientData={refreshClientData}
        setShowScreen={setShowScreen}
        showScreen={showScreen}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleBulkAdd={handleBulkAdd}
        btnLoading={btnLoading}
        selectedClientLists={selectedClientLists}
        setSelectedClientLists={setSelectedClientLists}
        open={open}
        setOpen={setOpen}
        allLocationList={allLocationList}
        defaultLocId={defaultLocId}
        setDefaultLocId={setDefaultLocId}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? '' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Are you sure you want to mark Activate/Deactivate,
                  <Typography className="title-text-sub">
                    all the selected items?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected clients have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default React.forwardRef(ClientListAPI);
