import React, { useState, useEffect } from 'react';

// MUI components
import {
  Box,
  Checkbox,
  Divider,
  Stack,
  Typography,
  Grid,
  TextField,
  InputAdornment
} from '@mui/material';

// Custom components
import { DynamicButton, DynamicModal } from '../../../../components/Checkout';
import { CustomTable } from '../Components/CustomTable';
import Loader from 'src/components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SignatureDialog from '../Components/SignatureDialog';

// Utility functions
import { getLocalData, limitDecimalDigits } from 'src/utils';

// Functions specific to this component
import {
  handleConfirm,
  calculateOrder,
  handleCloseSignature
} from '../Functions/OrderSummaryFunctions';
import ConfirmationModal from '../Components/ConfirmationModal';

// ORDER SUMMARY COMPONENT
export const OrderSummary = ({
  selected,
  handlePrevious,
  setSuccess,
  setSelected,
  setActiveStep
}) => {
  const componentConsts = {
    userId: getLocalData('userId'),
    locationId: getLocalData('locationId'),
    franchiseeId: getLocalData('franchiseeId'),
    BASE_URL: process.env.REACT_APP_MEMBERSHIP_API_END_POINT,
    PAYMENT_APP: process.env.REACT_APP_PAYMENTAPPNAME,
    DOMAIN_URL: process.env.REACT_APP_DOMAIN_END_POINT,
    locationName: getLocalData('locationName'),
    franchiseeName: getLocalData('franchiseeName'),
    UserName: getLocalData('UserName'),
    accessToken: getLocalData('accessToken')
  };

  const [successOrder, setSuccessOrder] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceFee, setServiceFee] = useState();
  const [openComp, setOpenComp] = useState(false);

  const [zipCode, setZipCode] = useState('');
  const [disableButton, setDisableButton] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [signature, setSignature] = useState({
    open: false,
    value: ''
  });
  const [formErrors, setFormErrors] = useState({
    zipCode: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'service') {
      const formattedValue = value?.replace(/(\..*)\./g, '$1');
      const limitedValue = limitDecimalDigits(formattedValue, 2);
      setServiceFee(limitedValue);
    }
    if (name === 'zipCode') {
      setFormErrors({
        ...formErrors,
        zipCode: ''
      });
      setZipCode(value);
    }
  };

  const discount = selected?.totalDiscount || 0.0;

  const orderSummaryValues = [
    {
      label: 'SUBTOTAL',
      value: parseFloat(
        // serviceFee && serviceFee !== '.'
        // ? parseFloat(selected.subTotal) + parseFloat(serviceFee) :
        selected?.subTotal || selected?.total || 0
      )?.toFixed(2),
      adornment: '$'
    },
    {
      label: 'GIFT CARD DISCOUNT',
      value: parseFloat(selected?.giftCardDiscount)?.toFixed(2),
      adornment: '$',
      skip: !selected?.giftCardDiscount
    },
    {
      label: 'GIFT CARD REMAINING',
      value: parseFloat(selected?.giftCardRemaining)?.toFixed(2),
      adornment: '$',
      skip: !selected?.giftCardDiscount
    },
    {
      label: 'TOTAL DISCOUNT',
      value: parseFloat(selected?.discount ?? discount)?.toFixed(2),
      adornment: '$'
    },
    {
      label: 'TAX',
      value: parseFloat(selected?.tax || 0.0)?.toFixed(2),
      adornment: '$'
    },
    {
      label: 'TOTAL',
      value: parseFloat(
        serviceFee && serviceFee !== '.'
          ? parseFloat(selected.totalPrice) + parseFloat(serviceFee)
          : selected?.totalPrice || 0
      )?.toFixed(2),
      adornment: '$'
    }
  ];

  const isContract =
    (selected?.items?.filter((item) => item?.itemType === 'Contract')?.length >
      0 &&
      selected?.items?.filter((item) => item?.itemType === 'Contract')) ||
    [];

  const isAutopay = selected?.items?.some((item) => item?.autopay === true);

  useEffect(() => {
    if (successOrder) {
      setSuccess(successOrder);
    }
    if (error) {
      setOpen(true);
    }
  }, [successOrder, error]);

  useEffect(() => {
    localStorage.setItem('activeStep', '3');

    calculateOrder(
      componentConsts,
      payload,
      setLoading,
      selected,
      setSelected,
      setSuccessOrder,
      setError,
      setErrorMessage
    );
  }, []);

  useEffect(() => {
    if (disableButton) {
      setTimeout(() => {
        setDisableButton(false);
      }, 1500);
    }
  }, [disableButton]);

  //  PAYLOAD FOR PLACE ORDER & CALCULATE ORDER API

  const payload = {
    locationId: componentConsts?.locationId,
    clientId: selected?.client?.sortKey || '',
    clientName: selected?.client?.firstName || '',
    guestClient: selected?.guestClient || false,
    createdBy: componentConsts?.userId || '',
    queueId: selected?.queueId,
    petId: selected?.pet?.sortKey || '',
    petName: selected?.pet?.firstName || '',
    franchiseeId: componentConsts?.franchiseeId
  };

  const validateZipCode = (zip) => {
    const zipRegex = /^\d{5}(?:-\d{4})?$/;
    return zipRegex?.test(zip);
  };


  const handleCompDialog = (value) => {
    if(value === 'Yes'){
      handleProceed('Comp')
    }
    setOpenComp(false)
  }

  const handleComp = () => {
    if (isAutopay && !zipCode) {
      setFormErrors({
        ...formErrors,
        zipCode: 'Zip code is required.'
      });
      return;
    }
    if (isAutopay && zipCode && !validateZipCode(zipCode)) {
      setFormErrors({
        ...formErrors,
        zipCode: 'Enter a valid Zip code.'
      });
      return;
    }
    setOpenComp(true)
  }

  const handleProceed = (buttonValue) => {
    if (isAutopay && !zipCode) {
      setFormErrors({
        ...formErrors,
        zipCode: 'Zip code is required.'
      });
      return;
    }

    if (isAutopay && zipCode && !validateZipCode(zipCode)) {
      setFormErrors({
        ...formErrors,
        zipCode: 'Enter a valid Zip code.'
      });
      return;
    }

      handleConfirm(
        buttonValue,
        componentConsts,
        payload,
        serviceFee,
        selected,
        setSelected,
        setDisableButton,
        setSuccessOrder,
        setError,
        setLoading,
        isContract,
        signature,
        isAutopay,
        zipCode,
        setErrorMessage
      );
  };

  const generateStack = (label, value, adornment) => (
    <Stack
      key={label}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      margin="20px 0px"
      marginBottom={label === 'ZIP CODE *' ? '10px' : '20px'}
    >
      <Typography variant="h4" color="primary">
        {label}
      </Typography>

      {adornment ? (
        <>
          <Typography variant="h5">
            {adornment}
            {value}
          </Typography>
        </>
      ) : (
        value
      )}
    </Stack>
  );
  
  // MAIN UI CONTENT
  return (
    <>
    <ConfirmationModal 
      open={openComp}
      handleDialog={handleCompDialog}
    />
      <SignatureDialog
        open={signature?.open}
        signature={signature}
        setSignature={setSignature}
        handleClose={() => handleCloseSignature(signature, setSignature)}
        setLoading={setLoading}
        componentConsts={componentConsts}
      />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Loader IsLoading={loading} />
      <Typography
        variant="h1"
        align="center"
        color="primary"
        style={{ marginBottom: '15px' }}
      >
        Order Summary
      </Typography>

      <Stack direction="column" justifyContent="left" alignItems="left">
        <Box sx={{ maxWidth: '40%', margin: '0 auto 60px' }}>
          <Typography
            variant="h4"
            align="left"
            color="primary"
            style={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            Client
          </Typography>
          <Box className="clientItem">
            <Box>
              {selected?.client?.firstName} {selected?.client?.lastName}
            </Box>
          </Box>
        </Box>
        <Box sx={{ alignItems: 'center' }}>
          <Typography variant="h4" color="primary" sx={{ fontSize: '20px' }}>
            All Added Items
          </Typography>
          <CustomTable
            data={selected?.items || []}
            isEdit={false}
            isSearch={false}
            selected={selected}
          />
        </Box>
      </Stack>

      <Box
        sx={{
          display: 'flex',
          justifyContent: isContract?.length > 0 ? 'space-between' : 'flex-end',
          mr: 1
        }}
      >
        <Box mt={2} width="100%">
          {isContract?.length > 0 && (
            <Grid
              container
              item
              sm={8}
              md={5}
              lg={5}
              sx={{
                border: '2px solid lightgrey',
                borderRadius: '12px',
                padding: '15px',
                height: '230px'
              }}
            >
              <Box style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <Typography style={{ whiteSpace: 'pre-line', color: 'black' }}>
                  {isContract?.[0]?.terms}
                </Typography>
              </Box>
            </Grid>
          )}
          {isContract?.length > 0 && (
            <Box className="termsFlex">
              <Box className="right">
                <Box>
                  <Checkbox
                    label="Accept Terms"
                    checked={signature?.value}
                    onChange={() => {
                      setSignature({
                        ...signature,
                        open: true
                      });
                      // setCheckTerms(e.target.checked)
                    }}
                  />
                  <Typography variant="body1">Accept Terms</Typography>
                </Box>
                <Box title="by clicking this box you will find details">
                  By purchasing the contracts above, I agree to the terms and
                  conditions of the contract.
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Grid
          container
          item
          sm={6}
          md={4}
          lg={4}
          // sx={{border: "1px solid #D9D9D9", borderRadius: "19px 19px 0 0"}}
        >
          <Box
            sx={{
              marginTop: '2%',
              direction: 'column',
              padding: '10px',
              width: '100%'
            }}
          >
            {isAutopay &&
              generateStack(
                'ZIP CODE *',
                <Grid container item md={6} sm={6.5} xs={6}>
                  <TextField
                    name="zipCode"
                    fullWidth
                    id="outlined-required"
                    className="text-field-styling"
                    onChange={handleInputChange}
                    value={zipCode}
                    sx={{
                      width: '100%',
                      height: '50px!important'
                    }}
                    InputLabelProps={{
                      className: 'label-style-text-field'
                    }}
                  />
                </Grid>
              )}
            {formErrors?.zipCode && (
              <Typography variant="body2" textAlign="right" color="error">
                {formErrors?.zipCode}
              </Typography>
            )}
            {generateStack(
              'SERVICE FEE',
              <Grid container item md={6} sm={6.5} xs={6}>
                <TextField
                  name="service"
                  fullWidth
                  id="outlined-required"
                  className="text-field-styling"
                  onChange={handleInputChange}
                  value={serviceFee}
                  sx={{
                    width: '100%',
                    height: '50px!important'
                  }}
                  InputLabelProps={{
                    className: 'label-style-text-field'
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
              </Grid>
            )}

            {orderSummaryValues?.map(({ label, value, adornment, skip }) => {
              if (skip) {
                return null;
              }
              return generateStack(label, value, adornment);
            })}

            <Divider />
          </Box>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ paddingBottom: '20px' }}
      >
        <DynamicButton
          title={'Go Back'}
          variant="contained"
          onClick={handlePrevious}
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            marginRight: '10px',
            background: '#fff',
            color: '#003087',
            border: '2px solid #003087'
          }}
        />
        <DynamicButton
          onClick={() => handleProceed('Confirm')}
          variant={'contained'}
          title={'Confirm'}
          disabled={
            isContract?.length > 0
              ? !signature?.value || disableButton
              : disableButton
          }
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            marginRight: '10px',
            border: '2px solid #003087'
          }}
        />
        <DynamicButton
          onClick={() => handleComp()}
          variant={'contained'}
          title={'Comp'}
          disabled={
            isContract?.length > 0
              ? !signature?.value || disableButton
              : disableButton
          }
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            border: '2px solid #003087'
          }}
        />
      </Box>
      <DynamicModal
        className={'checkoutModal transactionModal'}
        open={open}
        handleDialog={() => {
          setOpen(false);
          setActiveStep(1);
        }}
        type
        selected={selected}
        message={errorMessage}
      />
    </>
  );
};
