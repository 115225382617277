import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormHelperText,
  Slide,
  DialogTitle
} from '@mui/material';
import { dateFormat } from '../../../utils';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { getLocalData } from '../../../utils';
import Loader from '../../../components/Loader';
import Header from 'src/components/Header';
import { ApiService } from '../../../services/ApiService';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import HolidayTable from './HolidayTable';
import HolidayConfirmationDialog from './HolidayConfirmationDialog';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Holiday = (props) => {
  const { setShowScreen } = props;
  //   const [isFiendUpdated, setIsFiendUpdated] = useState(false);
  const locationId = getLocalData('locationId');
  let encodedLocationId = encodeURIComponent(locationId);
  const userDetails = getLocalData('UserDetails');
  const userName = JSON.parse(userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [tableInfo, setTableInfo] = useState([]);
  const [openAddTimeOff, setOpenAddTimeOff] = useState(false);
  const [openAddTimeOffDialog, setOpenAddTimeOffDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [holidayCancelled, setHolidayCancelled] = useState(false);
  const [holidayUpdated, setHolidayUpdated] = useState(false);
  const [holidayId, setHolidayId] = useState('');
  const [open, setOpen] = useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /* States for errors */
  const [titleError, setTitleError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  console.log(startDate);
  console.log(endDate);
  useEffect(() => {
    holidayInfo();
    setEndDateError('');
  }, [holidayUpdated]);

  const holidayInfo = async () => {
    try {
      let data = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getLocatinHoliday?locationId=${encodedLocationId}`
      );
      if (data.statusCode === 200) {
        console.log(data);
        setTableInfo(data.data.Items);
      }
      console.log(data, 'datadata');
    } catch (error) {
      console.log(error);
    }
  };

  const clearFields = () => {
    setTitle('');
    setStartDate(null);
    setEndDate(null);
  };

  const openDialog = (action) => {
    if (action === 'Cancel Holiday') {
      // Skip validation for "Cancel Holiday" action
      setOpenAddTimeOff(true);
      setOpenAddTimeOffDialog(true);
    } else {
      let valid = validateForm();
      if (valid) {
        return 0;
      }
    }
    return 1;
  };

  const closeDialog = () => {
    setOpenAddTimeOff(false);
    setOpenAddTimeOffDialog(false);
  };

  const handleChange = (event) => {
    setTitleError('');
    setTitle(event);
  };

  const handleConfirmClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
    } else if (val === 'Discard') {
      setOpen(false);
      setShowScreen(0);
      //   setIsFiendUpdated(false);
    }
  };

  const handleStartDate = (value) => {
    setStartDateError('');
    setStartDate(value);
    setEndDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const addHoliday = async () => {
    setIsLoading(true);
    try {
      const body = {
        title: title,
        locationId: locationId,
        createdBy: userName.name,
        startDate: startDate,
        endDate: !endDate ? startDate : endDate,
        timeZone: 'America/New_York'
      };
      // let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/addLocationHolidays`;
      const apiResponse = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/addLocationHolidays`,
        body
      );
      if (apiResponse.statusCode === 200) {
        clearFields();
        setStartDate(null);
        setHolidayUpdated(!holidayUpdated);
        setIsLoading(false);
        closeDialog();
        console.log(apiResponse);
        setShowSuccessMessage(true);
        setSuccessMessage('Holiday has been added successfully!');
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  console.log(startDate, endDate, 'saddddddddddddddd');
  const getAllAppointments = async () => {
    console.log(startDate);
    setIsLoading(true);
    try {
      let fromDate = startDate.replaceAll('-', '/');
      let toDate = endDate.replaceAll('-', '/');
      let sdate = new Date(fromDate);
      let edate = new Date(toDate);
      let payload = {
        locationId: locationId,
        fromDate: dateFormat(sdate),
        toDate: dateFormat(edate)
      };
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAppointments`,
        payload
      );
      if (
        response.statusCode === 200 &&
        !response.error &&
        response?.data?.Items?.length > 0
      ) {
        setIsLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          'Scheduling a holiday on this day is not possible as schedules are already in place for the same day.'
        );
      } else {
        setIsLoading(false);
        setOpenAddTimeOff(true);
        setOpenAddTimeOffDialog(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleCancelHoliday = (val) => {
    setIsLoading(true);
    if (val === 'Yes') {
      cancelHoliday(holidayId);
      closeDialog();
    } else {
      closeDialog();
      setIsLoading(false);
    }
  };

  const cancelHoliday = async () => {
    setHolidayCancelled(false);
    try {
      const body = {
        locationId: locationId,
        holidayId: holidayId
      };

      // let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/cancelLocatinHoliday`;
      const apiResponse = await ApiService.delete(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/cancelLocatinHoliday`,
        body
      );
      if (apiResponse.statusCode === 200) {
        setIsLoading(false);
        setHolidayUpdated(!holidayUpdated);
        setShowSuccessMessage(true);
        setSuccessMessage('Holiday has been canceled successfully!');
      } else {
        setIsLoading(false);
        setShowSuccessMessage(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setShowSuccessMessage(false);
    }
  };
  const holidayAlreadyCreated = () => {
    // Check if a holiday with the same start date already exists in the tableInfo array
    return tableInfo.some((data) => data.startDate === startDate);
  };

  const validateForm = () => {
    let hasErrors = false;

    if (!title) {
      setTitleError('Title is required');
      hasErrors = true;
    } else {
      setTitleError('');
    }

    if (!startDate) {
      setStartDateError('Select From Date');
      hasErrors = true;
    } else {
      setStartDateError('');
    }

    if (!hasErrors) {
      // Check if a holiday is already created (you should implement this check)
      if (holidayAlreadyCreated()) {
        setStartDateError('Holiday is already created'); // Set the error message
        hasErrors = true;
      }
      getAllAppointments();
    }

    return hasErrors;
  };

  const staffTimeOff = () => {
    console.log('startDate:', startDate);

    if (!startDate) {
      const valid = validateForm();
      console.log('valid:', valid);

      if (valid) {
        return 0;
      }
    }

    addHoliday();
    return 1;
  };

  const handleAddHoliday = (val) => {
    if (val === 'Yes') {
      staffTimeOff();
      closeDialog();
    } else {
      closeDialog();
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={() => setShowSuccessMessage(false)}
        />
      )}
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      <Container maxWidth="100%" className="custom-container">
        <Header title="Holiday" />
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="Title"
              label="TITLE"
              type="text"
              value={title}
              onChange={(e) => handleChange(e.target.value)}
              // error={Boolean(titleError)}
              helperText={titleError}
              className="input-field-styling required-field"
              InputLabelProps={{
                className: 'label-style-create-franchisee required-label'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            <CustomDatePicker
              label={<span className="required-label">FROM</span>}
              fieldValue={startDate}
              fullWidth
              disablePast
              name="startDate"
              onChange={handleStartDate}
              error={Boolean(startDateError)}
              className="required-field show"
            />
            {startDateError && (
              <FormHelperText>{startDateError}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            <CustomDatePicker
              label={<span>TO</span>}
              fieldValue={endDate}
              name="endDate"
              fullWidth
              disablePast
              onChange={handleEndDate}
              error={Boolean(endDateError)}
              className="show"
            />
            {endDateError && <FormHelperText>{endDateError}</FormHelperText>}
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="button-style-add-staff field-top-margin-edit-location"
              onClick={openDialog}
              sx={{ marginTop: '20px' }}
            >
              <Typography className="submit-button-text">
                Add Holiday
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {/* Holiday table */}
        <HolidayTable
          setHolidayId={setHolidayId}
          tableInfo={tableInfo}
          setHolidayCancelled={setHolidayCancelled}
          openDialog={openDialog}
        />
      </Container>

      {/* Holiday confirmation table */}
      {openAddTimeOff && (
        <HolidayConfirmationDialog
          openAddTimeOffDialog={openAddTimeOffDialog}
          handleAddHoliday={handleAddHoliday}
          handleCancelHoliday={handleCancelHoliday}
          holidayCancelled={holidayCancelled}
          setOpenAddTimeOffDialog={setOpenAddTimeOffDialog}
        />
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            Discard changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Go Back to editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Holiday;
