/* eslint-disable react/self-closing-comp */
/* eslint-disable no-else-return */
/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Stack
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { ApiService } from 'src/services/ApiService';
import { getLocalData, setFullLocationId } from 'src/utils';
import { ArrowLeft, DoubleArrowRight } from 'src/assets/SVG/SvgIcons';
import ErrorMessage from '../PopupMessage/ErrorMessage';
import Loader from 'src/components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

export const Summary = (props) => {
  const { membershipDetails, handleResetStates, handleOpenScreen } = props;
  console.log(membershipDetails);
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  // const membershipId = encodeURIComponent(membershipDetails.membershipId);
  const membershipId = membershipDetails.membershipId;
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [btnLoading, setBtnLoading] = useState(false);
  const [benefitsList, setBenefitsList] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);

  // Get the loggedIn user Id
  const userId = getLocalData('userId');

  useEffect(() => {
    setBenefitsList(membershipDetails.benefits);
  }, [membershipDetails]);

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleDeleteDialog = (type) => {
    if (type === 'cancel') {
      setDeleteDialog(false);
    } else {
      setDeleteDialog(false);
      deleteMembership();
    }
  };

  const handleClose = (val) => {
    if (val === 'message-close') {
      setOpenMessageDialog(false);
      handleResetStates();
    }
  };

  const getAllProducts = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    try {
      const productsResponse = await ApiService.get(
        `${BASE_URL}memberships/getProductByCategory?locationId=${encodedLocationId}&status=1&isDiscontinued=false`
      );

      if (!productsResponse?.data?.error) {
        setAllProducts(productsResponse?.data?.Items);
      } else {
        setShowMessage(true);
        setErrorMessage(
          productsResponse.message || 'Something went wrong with products'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const deleteMembership = async () => {
    setBtnLoading(true);
    setIsLoading(true);
    try {
      const body = {
        locationId: locationId,
        membershipId: membershipId,
        userId: userId
      };
      const response = await ApiService.delete(`${BASE_URL}memberships`, body);

      if (!response?.error) {
        setIsLoading(false);
        setOpenMessageDialog(true);
        setBtnLoading(false);
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went wrong, Try again!');
    }
  };

  function getBenefitTitle(index) {
    let item = benefitsList[index];
    const { itemType, itemCategoryName, itemName, discount, itemId } = item;

    let variantsArr;
    const matchingProduct = allProducts?.find(
      (product) => product?.sortKey === itemId
    );
    if (matchingProduct) variantsArr = matchingProduct?.variants || [];
    const variantsRendered = variantsArr
      ?.map((item) => item?.value)
      ?.filter(Boolean)
      ?.join(', ');
    const finalResult = variantsRendered ? `(${variantsRendered})` : '';

    if (itemType === 'PricingOption') {
      // return `Pricing option '${(pricingOptionName || pricingOption.name)}' for service '${itemName}'`;
      let benifitStr = '';
      if (item?.itemId === 'applyToAllPricingOption') {
        benifitStr = `${discount}% discount on ${itemName}`;
      } else {
        benifitStr = `${discount}% discount on ${itemName} - up to ${
          item.numberOfSessions ?? 0
        } Sessions`;
      }
      return benifitStr;
    } else if (itemType === 'Product') {
      let benifitStr = '';
      // eslint-disable-next-line no-unneeded-ternary
      // return `${discount}% discount on '${itemName ? itemName : 'all products'}' in category '${itemCategoryName}'`;
      if (item?.itemId === 'applyToAllProduct') {
        benifitStr = `${discount}% discount on ${itemName}`;
      } else {
        benifitStr = `${discount}% discount on '${itemName || 'all product'} ${
          variantsArr && finalResult ? finalResult : ''
        }' in category '${itemCategoryName}'`;
      }
      return benifitStr;
    }
    return '';
  }

  function getBillingCycleValue(value, price) {
    if (value === 'every year') {
      return `$${parseFloat(price ?? 0)?.toFixed(2)} Per Year`;
    } else if (value === 'every month') {
      return `$${parseFloat(price ?? 0)?.toFixed(2)} Per Month`;
    } else {
      return '';
    }
  }

  return (
    <div className="custom-summary">
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <ServiceDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          backgroundColor="#d1dced"
          height="50px"
          marginBottom="20px"
        >
          <div className="container summary-close-btn">
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={() => handleResetStates()}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </div>
        </Box> */}

        {/* <Grid container className="location-header-container">
          <Grid
            xs={8}
            sm={8}
            md={8}
            lg={8}
            className="page-header title-adjust"
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box onClick={handleResetStates} className="back-arrow-close">
                <ArrowLeft />
              </Box>
              <Typography className="header-blue-32">
                {membershipDetails.name}
              </Typography>
            </Box>
 
          </Grid>

          <Grid xs={4} sm={4} md={4} lg={4} className="align-right">
            <Button
              disabled={btnLoading}
              className="details-remove-icon"
              onClick={() => setDeleteDialog(true)}
            >
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button>

            <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              onClick={() => handleOpenScreen(2)}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleResetStates()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {membershipDetails.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              disabled={btnLoading}
              className="details-remove-icon no-hover"
              onClick={() => setDeleteDialog(true)}
            >
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button>

            <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              onClick={() => handleOpenScreen(2)}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid>

        <Box className="location-details-container">
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} className="location-details-col">
              <Box className="location-details-label summary-heading">
                LABEL
              </Box>
              <Box className="location-details-value summary-value ">
                <div className="membership-details-colorSelector">
                  <div
                    className="membership-details-colorCircle membership-details-activeColor"
                    style={{ backgroundColor: membershipDetails.label }}
                  >
                    &nbsp;
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} className="location-details-col">
              <Box className="location-details-label summary-heading">
                PRICE
              </Box>
              <Box className="location-details-value summary-value">
                {getBillingCycleValue(
                  membershipDetails.billingCycle,
                  membershipDetails.price
                )}
              </Box>
            </Grid>
          </Grid>

          {/* <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} className="location-details-col">
              <Box className="location-details-label summary-heading">
                RENEW
              </Box>
              <Box className="location-details-value summary-value"></Box>
            </Grid>
          </Grid> */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} className="location-details-col">
              <Box className="location-details-label summary-heading">
                STATUS
              </Box>
              <Box className="location-details-value summary-value">
                {membershipDetails.status === 1 && 'Active'}
                {membershipDetails.status === 0 && 'Inactive'}
                {membershipDetails.status === 2 && 'Deleted'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} className="location-details-col">
              <Box className="location-details-label summary-heading">
                AutoPay
              </Box>
              <Box className="location-details-value summary-value">
                {membershipDetails.autopay ? 'Yes' : 'No'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} className="location-details-col">
              <Box className="location-details-label summary-heading">
                Description
              </Box>
              <Box className="location-details-value summary-value">
                {membershipDetails?.description}
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} className="location-details-col">
              <Box className="location-details-label summary-heading">
                BENEFITS
              </Box>
              <Box className="location-details-value summary-value">
                {benefitsList?.length ? (
                  <Stack
                    spacing={{ xs: 1.5 }}
                    direction="column"
                    width="100%"
                    alignItems="center"
                  >
                    {benefitsList?.map((item, index) => (
                      <Stack
                        spacing={{ xs: 1, sm: 2 }}
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        justifyContent="space-between"
                        width="100%"
                        paddingRight="60px"
                        alignItems="center"
                      >
                        <Typography
                          variant="variant6"
                          sx={{ color: '#000' }}
                          className="weight-400"
                        >
                          <Box sx={{ float: 'left' }}>
                            <DoubleArrowRight />
                          </Box>
                          {'  '}
                          {getBenefitTitle(index)}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                ) : (
                  // 'Sample Benefits'
                  'No Benefits Added Yet'
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ServiceDetailsWrapper>

      <Dialog
        open={deleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDeleteDialog('cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleDeleteDialog('delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleDeleteDialog('cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMessageDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('message-close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected membership has been deactivate successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('message-close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
