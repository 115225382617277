import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import { useEffect, useState } from 'react';
import { Grid, Container, Button, Box, Typography, Card } from '@mui/material';
import ProfileDetails from './ProfileDetails';
import Loader from 'src/components/Loader';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons'; // DeleteIcon
import { getLocalData } from '../../../../utils';
import ClockInClockOutDrawer from './ClockInClockOutDrawer';
import { ApiService } from 'src/services/ApiService';
// import AllTasks from './AllTasks';
// import AllNotes from './AllNotes';

function StaffProfile({
  data,
  setShowScreen,
  handleOpenAddTask,
  taskApiUpdate,
  onMenuItemClick
}) {
  let id = data;
  const [profileDetails, setProfileDetails] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  // const [allNotes, setAllNotes] = useState([]);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const locationId = getLocalData('locationId');

  // useeffect state starts here
  useEffect(() => {
    // id = itemDetails;
    setAllTask([]);
    setAllDocs([]);
    // setAllNotes([]);
    console.log('Id', id);
    // call the get client details method
    if (id) getStaffProfile(id);
  }, [id, taskApiUpdate]);

  // const handleClickEdit = (e) => {
  //   console.log(e.currentTarget.id);
  // };

  const getDocTypeAndName = (document) => {
    let documentName = document?.split('-').pop();
    return documentName;
  };

  // Method to get the staff profile details
  const getStaffProfile = async (id) => {
    const inputDate = new Date();
    const fromDate = inputDate
      ?.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
      ?.replaceAll('/', '-');
    const fromTime = inputDate?.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }); // Now show the loader
    setIsLoading(true);
    // call the api to get the staff details
    if (!id) return;
    id = encodeURIComponent(id);
    let encodedLocationId = encodeURIComponent(locationId);
    let url = `${process.env.REACT_APP_API_END_POINT}staff/getStaffProfileDetails?staffId=${id}&locationId=${encodedLocationId}&fromDate=${fromDate}&fromTime=${fromTime}`;

    try {
      let response = await ApiService.get(url);
      if (!response?.error) {
        let items = response.data.Items;
        if (items) {
          setProfileDetails(items[0]);
          if (items[0] && items[0].allTasks) setAllTask(items[0].allTasks);
          if (items[0] && items[0]?.attachments)
            setAllDocs(items[0]?.attachments);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  /* Function to hide the current screen */
  const handleClose = () => {
    setShowScreen(0);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Helmet>
        <title>Staff Profile Details</title>
      </Helmet>

      <Container sx={{ mt: 2, padding: '0 2rem' }} maxWidth="100%">
        {/* header section */}

        {/* <Grid xs={8} sm={8} md={8} lg={8} className="page-header-text title-adjust" sx={{ marginTop: '-14px' }}> */}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className="location-header-container"
          alignItems="flex-start"
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box onClick={() => handleClose()} className="back-arrow-close">
                <ArrowLeft />
              </Box>
              <Typography className="header-blue-32">
                {profileDetails.firstName} {profileDetails.lastName}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <Button
                sx={{ mt: { xs: 0, md: 0 }, mr: 1 }}
                variant="contained"
                id={profileDetails.staffId}
                onClick={handleClickEdit}
                className="btn-primary edit-button"
              >
                EDIT PROFILE
              </Button> */}
              <ClockInClockOutDrawer onMenuItemClick={onMenuItemClick} />
            </Box>
          </Grid>
        </Grid>

        {/* Body section */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} md={4} sm={4} className="">
            {/* <ProfileCover user={user} /> */}
            <Box
              className="product-details-img align-center relative mb-10 d-flex align-items-center justify-content-center"
              sx={{ border: '1px solid #A5A5A5', height: '330px' }}
            >
              {/* {profileDetails.staffProfileImage && ( */}
              <img
                className=" product-img no-media absolut"
                src={
                  profileDetails && profileDetails.profileImage !== 'undefined'
                    ? profileDetails.profileImage
                    : '/static/images/products/ProductImage.png'
                }
                alt="product-icon"
              />
              {/* )} */}
            </Box>
            {/* All tasks section */}
            {/* <AllTasks data={allTask} headerText={"ALL TASKS"} headerRequired={false} /> */}
            <Box mt={4}>
              <Card
                sx={{
                  border: '1px solid #A5A5A5',
                  padding: '0 10px',
                  width: '310%'
                }}
                className="mt-10 task-container staff-task-height"
              >
                <Box
                  py={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    sx={{
                      //   fontWeight: 600,
                      //   color: '#003087'
                      letterSpacing: '0.1em'
                    }}
                    className="weight-700 text-primary"
                  >
                    ALL TASKS{' '}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      letterSpacing: '0.1em',
                      cursor: 'pointer'
                    }}
                  >
                    <Button
                      sx={{
                        // fontWeight: 700,
                        // color: '#003087',
                        textDecoration: 'underline',
                        marginRight: '10px'
                      }}
                      onClick={() => handleOpenAddTask()}
                      className="add-task weight-700 text-primary"
                    >
                      +ADD TASK
                    </Button>
                  </Box>
                </Box>

                {/* Start Now */}

                <Box className=" task-view overflow-auto task-items">
                  {(allTask.length > 0 &&
                    allTask.map((taskItem) => {
                      return (
                        <Grid
                          className="mb-10"
                          xs={12}
                          container
                          sx={{
                            p: 2,
                            border: ' 0.4px solid #E7EFF9',
                            borderRadius: '5px',
                            backgroundColor: '#FFFFFF'
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                pb: 1
                              }}
                            >
                              <span className="task-name font-17 weight-700">
                                {taskItem.title ||
                                  taskItem.notes ||
                                  taskItem.mgrNotes}{' '}
                              </span>
                            </Typography>

                            <Typography sx={{ color: '#A8A4A4' }}>
                              {taskItem.notes || taskItem.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })) || (
                    <Grid container mb={3} className="task-details-row">
                      No Task Found
                    </Grid>
                  )}
                </Box>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} sm={8}>
            <ProfileDetails profileDetails={profileDetails} />

            {/* <Box className="mt-10 notes-container">
              <Card
                sx={{
                  height: '100%',
                  border: '1px solid #A5A5A5',
                  padding: '0 10px'
                }}
              >
                <Box>
                  <CardHeader
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title="NOTES"
                    className="text-primary card-header-style"
                  />
                </Box>


                <Box className="overflow-auto task-items">
                  {(allNotes.length > 0 &&
                    allNotes.map((taskItem) => {
                      return (
                        <Grid
                          className="mb-10"
                          xs={12}
                          container
                          sx={{
                            p: 2,
                            border: ' 0.4px solid #E7EFF9',
                            borderRadius: '5px',
                            backgroundColor: '#FFFFFF'
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                pb: 1
                              }}
                            >
                              <span className="task-name font-17 weight-700">
                                {taskItem.notes || taskItem.mgrNotes}{' '}
                              </span>
                            </Typography>

                            <Typography sx={{ color: '#A8A4A4' }}>
                              {taskItem.notes || taskItem.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })) || (
                    <Grid container mb={3} className="notes-details-row">
                      No Notes Found
                    </Grid>
                  )}
                </Box>
              </Card>
            </Box> */}
          </Grid>

          <Grid item xs={12} md={12} sm={8}>
            <Card
              sx={{
                border: '1px solid #A5A5A5',
                padding: '0 10px',
                width: '100%'
              }}
            >
              {/* <Typography
                className="weight-700 text-primary"
              >
                UPLOADED DOCUMENTS{' '}
              </Typography> */}
              <Typography className="weight-700 text-primary" sx={{ py: 1 }}>
                UPLOADED DOCUMENTS{' '}
              </Typography>

              {/* Start Now */}

              {(allDocs?.length > 0 &&
                allDocs?.map((docsItem) => {
                  return (
                    <Grid
                      className="mb-10"
                      xs={12}
                      container
                      sx={{
                        p: 2,
                        border: ' 0.4px solid #E7EFF9',
                        borderRadius: '5px',
                        backgroundColor: '#FFFFFF'
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            pb: 1
                          }}
                        >
                          <span className="task-name font-17 weight-700">
                            {getDocTypeAndName(docsItem?.attachment)}{' '}
                          </span>
                        </Typography>

                        {/* <Typography sx={{ color: '#A8A4A4' }}>
                          {docsItem.notes || docsItem.description}
                        </Typography> */}
                        <Typography sx={{ color: '#A8A4A4' }}>
                          {docsItem?.documentName}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })) || (
                <Grid container mb={3} className="task-details-row">
                  No documents Found
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default StaffProfile;
